import { Box, Card, Divider, Grid, Group, Paper, SimpleGrid, Text } from "@mantine/core";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { Hidden } from "shared/custom/Hidden";

export const OrcamentoPanel = ({ orcamento, loading }: any) => {

    return (
        <Paper withBorder p="md">
            <SimpleGrid
                cols={1}
                breakpoints={[
                    { minWidth: "xl", cols: 4 },
                    { minWidth: "lg", cols: 3 },
                    { minWidth: "md", cols: 2 },
                    { minWidth: "sm", cols: 2 },
                ]}
            >
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Solicitante</Text>
                        <EmpresaChip empresa={orcamento?.solicitante} />
                    </Box>
                </Group>
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Solicitada</Text>
                        <EmpresaChip empresa={orcamento?.solicitada} />
                    </Box>
                </Group>
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Stakeholder Solicitante</Text>
                        <Text size="sm">{orcamento?.stakeholderSolicitante?.nome}</Text>
                    </Box>
                </Group>
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Status</Text>
                        <CustomBadge value={orcamento?.status} options={[
                            { value: "AGUARDANDO_RESPOSTA", text: "Aguardando Resposta", color: "blue" },
                            { value: "RESPONDIDO", text: "Respondido", color: "teal" },
                        ]} />
                    </Box>
                </Group>
                <Hidden hide={!orcamento?.validade}>
                    <Group>
                        <Divider size="md" orientation="vertical" />
                        <Box>
                            <Text size="xs" fw={500} color="gray">Status</Text>
                            <>{moment(orcamento?.validade, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY")}</>
                        </Box>
                    </Group>
                </Hidden>
            </SimpleGrid>
        </Paper>
    );
};
