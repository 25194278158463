import { Avatar, Badge, Grid, Paper, Text } from "@mantine/core";
import moment from "moment";
import { Stakeholder } from "partials/chip/Stakeholder.chip";


export const ProspeccaoPanel = ({ prospeccao }: any) => {

  return (
    <Paper withBorder p="md">
      <Grid>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Iniciado em</Text>
          <Text size="sm">{moment(prospeccao?.iniciadoEm).format("DD/MM/YYYY [as] HH:mm")}</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Status</Text>
          <Badge color="blue">{prospeccao?.status}</Badge>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Iniciado por</Text>
          <Stakeholder stakeholder={prospeccao?.iniciadoPor} />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Objetivo</Text>
          <Text size="sm">{prospeccao?.objetivo}</Text>
        </Grid.Col>
      </Grid>
    </Paper>
  );

}