import React, { useState } from "react";

export const useModal = () => {
  const [modalState, setModalState] = useState<any>({ show: false });

  const openModal = (parameters?: any | undefined) =>
    setModalState({ parameters: parameters, show: true });

  const closeModal = () => setModalState({ show: false });

  return {
    state: modalState,
    openModal: openModal,
    closeModal: closeModal,
  };
};
