import {
    Box,
    Button,
    CloseButton,
    Group,
    Input,
    Text,
    Paper,
    Select,
    Textarea,
    LoadingOverlay,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconFile } from "@tabler/icons-react";
import moment from "moment";
import { useEffect } from "react";
import { NumberUtils } from "shared/Utils";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import { UploadDropZone } from "shared/components/UploadDropZone.component";
import { Hidden } from "shared/custom/Hidden";

export const ContratoForm = ({ onUploadContrato, reducer }: any) => {

    const { data, status } = reducer.state;

    const form = useForm({
        validateInputOnBlur: ["file"],
        initialValues: {
            file: new File([], "", undefined),
        },
        validate: {
            file: (value) => (value.name !== "" ? null : "Informe um arquivo."),
        },
    });

    const onSubmit = async (e: any) => {
        if (form.values.file?.name) {
            onUploadContrato(form.values.file);
        }
    };

    const onFormError = (e: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    const onDrop = (files: any) => {
        form.setValues({ file: files[0] });
        onUploadContrato(files[0]);
    }

    return (
        <>
            <form onSubmit={form.onSubmit((a) => onSubmit(a), onFormError)}>
                <LoadingOverlay visible={status?.isSubmitting} />
                <Input.Wrapper label="Documento do Contrato (em PDF)" required mt="xs">
                    <Hidden hide={form.values.file?.name}>
                        <UploadDropZone
                            mt="xs"
                            multiple={false}
                            onDrop={(files) => onDrop(files)}
                            accept={["application/pdf"]}
                        />
                    </Hidden>
                    <Hidden hide={!form.values.file?.name}>
                        <Paper withBorder p="xs" mt="md">
                            <Group position="apart" noWrap spacing="xl">
                                <Group noWrap>
                                    <IconFile size={32} color="gray" />
                                    <div>
                                        <Text size="sm">{form.values.file.name}</Text>
                                    </div>
                                </Group>
                                <CloseButton
                                    onClick={() =>
                                        form.setValues({ file: new File([], "", undefined) })
                                    }
                                    aria-label="Remover item selecionado"
                                />
                            </Group>
                        </Paper>
                    </Hidden>
                </Input.Wrapper>
                {/*<Group position="apart" mt="lg">
                    <Box></Box>
                    <Button type="submit" loading={status?.isSubmitting}>
                        Confirmar
                    </Button>
                            </Group>*/}
            </form>
        </>
    );

};
