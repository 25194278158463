import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useNavigate } from "react-router-dom";
import { useRemoteDataReducer } from "./remote/useCRUDHooks";
import { RemoteClient } from "hooks";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const useCheckSecurityStatus = () => {

  const navigate = useNavigate();
  const reducer = useRemoteDataReducer();

  const check = async () => {
    if (SecurityProvider.checkTokenExpired()) {
      const username = "algafood-web";
      const password = "euqueroumcachodocabelodoseukoo";
      const auth = btoa(username + ":" + password);
      const basic_auth_header = { Authorization: "Basic " + auth };

      const params = new URLSearchParams();
      params.append("grant_type", "refresh_token");
      params.append("refresh_token", SecurityProvider.getRefreshToken());

      try {
        reducer.dispatch("FETCHING");
        const response = await RemoteClient.create(
          RemoteEndpoints.refreshToken,
          params,
          {
            headers: {
              ...basic_auth_header,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          },
        );
        if (response.code == 200 && response.data) {
          SecurityProvider.authenticate(
            response.data.access_token,
            response.data.refresh_token,
          );
          reducer.dispatch("SUCCESS_SINGLE");
          //navigate(0);
        } else {
          throw Error("Não foi possivel renovar o token");
        }
      } catch (e) {
        SecurityProvider.deauthenticate();
        navigate("/login");
      }
    }
  };

  return { check: check, reducer: reducer };

};
