import {
  createStyles,
  Paper,
  Title,
  Text,
  TextInput,
  Button,
  Container,
  Group,
  Anchor,
  Center,
  Box,
  Input,
  rem,
  PinInput,
  PasswordInput,
  Stepper,
  ThemeIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconArrowLeft, IconCheck, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ICON_SIZE, useStyles } from "./RecuperarSenha.style";

export const RecoverPasswordPage = () => {
  const { classes } = useStyles();
  const [active, setActive] = useState(0);
  const reducer = useRemoteDataReducer();
  const { create, update, remove, fetchPage, patch } = useRemoteOperation({
    reducer: reducer,
  });

  const form = useForm({
    initialValues: {
      email: "",
      token: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email inválido"),
      newPassword: (value) =>
        active === 1 && value.length < 4 ? "Informe uma senha" : null,
      token: (value) =>
        active === 1 && value.length < 4 ? "Informe uma senha" : null,
      newPasswordConfirm: (value) =>
        active === 1 && value?.length < 4
          ? "Informe um código de autenticação"
          : null,
    },
  });

  useEffect(() => {
    if (reducer.state.status?.isFailed) {
      notifications.show({
        message: reducer.state.data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    }
    if (reducer.state.status?.isSubmitted) {
      setActive(active + 1);
    }
  }, [reducer.state]);

  const onSubmit = async (data: any) => {
    let cleanData = Object.assign({}, data);
    delete cleanData.newPasswordConfirm;

    if (active === 0) {
      create({
        endpoint: `${RemoteEndpoints.publicPasswordChangeRequest}`,
        properties: { reducer: reducer },
        data: cleanData,
      });
    } else if (active === 1) {
      create({
        endpoint: `${RemoteEndpoints.publicPasswordChangeConfirm}?code=${reducer.state.data?.object?.code}`,
        properties: { reducer: reducer },
        data: cleanData,
      });
    }
  };

  const onError = (errors: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário!",
      color: "orange",
    });
  };

  return (
    <Container size={460} my={30}>
      <Title className={classes.title} align="center">
        Recuperação de Senha
      </Title>
      <Text c="dimmed" fz="sm" mt="sm" ta="center">
        Informe seu email para receber o código de recuperação.
      </Text>
      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <form onSubmit={form.onSubmit((a) => onSubmit(a), onError)}>
          <Stepper active={active} size="xs" mb="md">
            <Stepper.Step label="Email" description="Informar email">
              <TextInput
                label="Email"
                placeholder="email@email.com.br"
                {...form.getInputProps("email")}
                autoComplete="off"
              />
              <Group position="apart" mt="lg" className={classes.controls}>
                <Button
                  loading={reducer.state.status?.isSubmitting}
                  fullWidth
                  type="submit"
                  className={classes.control}
                >
                  Enviar
                </Button>
              </Group>
            </Stepper.Step>
            <Stepper.Step label="Senha" description="Trocar senha">
              <Input.Wrapper mt="md" label="Token">
                <Group position="center">
                  <PinInput
                    length={6}
                    mt="sm"
                    {...form.getInputProps("token")}
                  />
                </Group>
              </Input.Wrapper>
              <PasswordInput
                label="Nova senha"
                placeholder="**********"
                mt="md"
                size="md"
                {...form.getInputProps("newPassword")}
                autoComplete="new-password"
              />
              <PasswordInput
                label="Repita a nova senha"
                placeholder="**********"
                mt="md"
                size="md"
                {...form.getInputProps("newPasswordConfirm")}
                autoComplete="new-password"
              />
              <Group position="apart" mt="lg" className={classes.controls}>
                <Button
                  loading={reducer.state.status?.isSubmitting}
                  fullWidth
                  type="submit"
                  className={classes.control}
                >
                  Enviar
                </Button>
              </Group>
            </Stepper.Step>
            <Stepper.Completed>
              <Box mt="xl">
                <ThemeIcon
                  className={classes.icon}
                  size={ICON_SIZE}
                  radius={ICON_SIZE}
                >
                  <IconCheck size={ICON_SIZE} />
                </ThemeIcon>
                <Text size="sm">
                  Senha redefinida, você pode acessar sua conta com a nova
                  senha.
                </Text>
                <Button component="a" href="/" mt="md" fullWidth>
                  Ir para o login
                </Button>
              </Box>
            </Stepper.Completed>
          </Stepper>
        </form>
      </Paper>
    </Container>
  );
};
