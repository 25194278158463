import { ActionIcon, Box, Center, CloseButton, Group, LoadingOverlay, Pagination, Paper, Skeleton, Table, Text } from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { IconEye, IconPlus, IconSearch } from "@tabler/icons-react";
import moment from "moment";
import { Stakeholder } from "partials/chip/Stakeholder.chip";
import DataTableEmptyState from "partials/panels/DataTableEmptyState.panel";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { Hidden } from "shared/custom/Hidden";

export const ProspeccaoSelectComponent = ({ reducer, value, onCreateAction, onSelect, onPaginationChange, hideAdd }: any) => {

    const ref = useClickOutside(() => dropdown[1].close());
    const dropdown = useDisclosure(false);
    const result = reducer?.state.data?.page;
    const status = reducer?.state.status;

    const AutoCompleteItem = forwardRef<HTMLDivElement, any>(
        ({ objetivo, status, numeroProspeccao, iniciadoPor, image, ...rest }: any, ref) => (
            <Box ref={ref} {...rest} my="xs">
                <Text size="sm" fw={500}>{numeroProspeccao} - {InfoUtils.limitText(objetivo || "", 100)}</Text>
                <Group noWrap>
                    <CustomBadge value={status} options={[
                        { value: "INICIADO", text: "Iniciado", color: "blue" },
                        { value: "FINALIZADO", text: "Finalizado", color: "gray" },
                    ]} />
                    <Text size="xs" color="dimmed">
                        De {value?.dataInicioObjetivo} até {value?.dataFinalObjetivo}
                    </Text>
                    <Stakeholder stakeholder={iniciadoPor} />
                </Group>
            </Box>
        ),
    );

    return (
        <>
            <Hidden hide={value}>
                <Box w="100%">
                    <Group noWrap>
                        <Box w="100%" style={{ position: "relative" }} >
                            <Box w="100%">
                                <Hidden hide={!status?.isFetching || dropdown[0]}>
                                    <Skeleton height={8} radius="xl" width="40%" />
                                </Hidden>
                                <Hidden hide={status?.isFetching && !dropdown[0]}>
                                    <Text size="sm" fw={500}>
                                        Selecione ou Inicie uma Prospecção
                                    </Text>
                                </Hidden>
                            </Box>
                            <Hidden hide={!dropdown[0]}>
                                <Box style={{ zIndex: 10, top: "100%" }} pos="absolute" w="100%">
                                    <Paper
                                        ref={ref}
                                        shadow="xs"
                                        mt="xs"
                                        style={{
                                            maxHeight: "max-content",
                                            width: "100%",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <LoadingOverlay visible={status?.isFetching} />
                                        <Hidden hide={result?.content || (result?.content?.lenght > 0)}>
                                            <DataTableEmptyState text="Nenhuma prospecção encontrada" pt={20} pb={20} active={true}></DataTableEmptyState>
                                        </Hidden>
                                        <Hidden hide={!result?.content}>
                                            <Table
                                                highlightOnHover
                                                style={{
                                                    maxHeight: "300px",
                                                    width: "100%",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <tbody>
                                                    {result?.content?.map((result: any, index: number) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <AutoCompleteItem
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => onSelect(result)}
                                                                    objetivo={result.objetivo}
                                                                    status={result.status}
                                                                    iniciadoPor={result.iniciadoPor}
                                                                    numeroProspeccao={result.numeroProspeccao}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <Center>
                                                <Pagination
                                                    my="md"
                                                    value={(result?.number || 0) + 1}
                                                    onChange={(value: any) => onPaginationChange({ page: value - 1, size: 5, sort: "desc" })}
                                                    total={result?.totalPages || 0}
                                                />
                                            </Center>
                                        </Hidden>
                                    </Paper>
                                </Box>
                            </Hidden>
                        </Box>
                        <Group position="apart" noWrap>
                            <ActionIcon variant="filled" disabled={status?.isFetching} color="blue" onClick={() => { dropdown[0] ? dropdown[1].close() : dropdown[1].open() }}>
                                <IconSearch
                                    style={{ width: '70%', height: '70%' }}
                                    aria-label="Remover item selecionado"
                                />
                            </ActionIcon>
                            <Hidden hide={hideAdd}>
                                <ActionIcon variant="filled" color="teal" onClick={onCreateAction}>
                                    <IconPlus
                                        style={{ width: '70%', height: '70%' }}
                                    />
                                </ActionIcon>
                            </Hidden>
                        </Group>
                    </Group>
                </Box>
            </Hidden>
            <Hidden hide={!value}>
                <Group position="apart" noWrap spacing="xl" w="100%">
                    <Group noWrap>
                        <div>
                            <Text size="sm" fw={500}>{InfoUtils.limitText(value?.objetivo || "", 100)}</Text>
                            <Text size="xs" color="dimmed">
                                De {moment(value?.dataInicioObjetivo, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY") } até { moment(value?.dataFinalObjetivo, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY") }
                            </Text>
                        </div>
                    </Group>
                    <Group noWrap>
                        <ActionIcon variant="filled" color="blue" component={Link} to={`/prospeccoes/${value?.id}`}>
                            <IconEye
                                style={{ width: '70%', height: '70%' }}
                            />
                        </ActionIcon>
                        <CloseButton color="gray"
                            onClick={() => onSelect(null)}
                            aria-label="Remover item selecionado"
                        />
                    </Group>
                </Group>
            </Hidden>
        </>
    );

}
