import { Group, Paper, Title } from "@mantine/core";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { LogoEmpresaUploadPanel } from "partials/panels/uploads/LogoEmpresaUpload.panel";

import { EmpresaPanel } from "partials/views/Empresa.view";
import { useEffect } from "react";
import GaugeChart from "react-gauge-chart";
import { useLocation, useParams } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";

export const OrganizationViewPage = () => {
  const reducer = useRemoteDataReducer();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });
  const location = useLocation();
  const params = useParams<any>();
  const empresaKey = params.id;
  const { data, status } = reducer.state;
  const empresa = data?.object;

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
    findEmpresa(empresaKey);
  }, [location]);

  const findEmpresa = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.empresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <PageLayout
      title={empresa?.nomeFantasia}
      header={
        <Group noWrap mb="md">
          <LogoEmpresaUploadPanel
            empresaKey={empresa?.id}
            logo={empresa?.logo?.thumbnailSourceSet}
          />
          <Title order={1}>{empresa?.nomeFantasia}</Title>
        </Group>
      }
    >
      <Title mt="md" mb="md" order={3}>
        Dados da empresa
      </Title>
      <EmpresaPanel empresa={empresa} />
      <Hidden hide={process.env.REACT_APP_PROFILE === "PROD"}>
        <Title mt="md" mb="md" order={3}>Histórico da Empresa</Title>
        <Paper withBorder p="md">
          <GaugeChart id="gauge-chart2"
            style={{ width: "20%" }}
            animate={false}
            nrOfLevels={20}
            percent={Math.random()}
          />
        </Paper>
      </Hidden>
    </PageLayout>
  );
};
