import { Group, Paper, Skeleton, Tabs, Text, Title } from "@mantine/core";
import {
  IconChecklist,
  IconClipboardData,
  IconFileDescription,
  IconFileDollar,
  IconInfoCircle,
  IconUser,
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { FinancialListPanel } from "pages/empresa/minha-empresa/tabs/FinancialList.tab";
import { LogoEmpresaUploadPanel } from "partials/panels/uploads/LogoEmpresaUpload.panel";
import { ParametrizacaoEmpresaPanel } from "pages/empresa/minha-empresa/tabs/ParametrizacaoEmpresa.tab";
import { StakeholderListPanel } from "pages/empresa/minha-empresa/tabs/StakeholderList.tab";
import { UploadDocumentosEmpresa } from "pages/empresa/minha-empresa/tabs/UploadDocumentosEmpresa.tab";
import { EmpresaPanel } from "partials/views/Empresa.view";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageLayout } from "shared/custom/page/PageLayout";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { CondicoesTab } from "./tabs/Condicoes.tab";
import { Hidden } from "shared/custom/Hidden";

export const MinhaEmpresaViewPage = () => {

  const navigate = useNavigate();
  const reducer = useRemoteDataReducer();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });
  const location = useLocation();
  const empresaKey = new URLSearchParams(location.search).get("empresa");
  const { data, status } = reducer.state;
  const empresa = data?.object;
  const [currentTab, setCurrentTab] = useState<string>("business-data");

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
    const authority = SecurityProvider.getAuthority();
    if (empresaKey && authority === "STAKEHOLDER") {
      navigate("/404");
      return;
    } else if (empresaKey && authority === "ADMINISTRATOR") {
      findEmpresa(empresaKey);
    } else if (authority === "STAKEHOLDER") {
      const authorizationToken = SecurityProvider.getAuthorizationToken();
      findEmpresa(authorizationToken.resource_group);
    } else {
      navigate("/404");
    }
  }, [location]);

  const findEmpresa = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.empresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <PageLayout
      title={empresa?.nomeFantasia}
      header={
        <Group noWrap mb="md">
          <LogoEmpresaUploadPanel
            empresaKey={empresa?.id}
            logo={empresa?.logo}
          />
          <Hidden hide={!status?.isFetching}>
            <Skeleton height={20} mt={5} width="40%" radius="xl" />
          </Hidden>
          <Hidden hide={!empresa?.nomeFantasia}>
            <Title order={1}>{empresa?.nomeFantasia}</Title>
          </Hidden>
        </Group>
      }
    >
      <Tabs
        radius="xs"
        defaultValue="business-data"
        value={currentTab}
        onTabChange={(value) => setCurrentTab(`${value}`)}
      >
        <Tabs.List>
          <Tabs.Tab
            value="business-data"
            icon={<IconInfoCircle color="gray" size="1.3rem" />}
          >
            Informações
          </Tabs.Tab>
          <Tabs.Tab
            value="financial"
            icon={<IconFileDollar color="gray" size="1.3rem" />}
          >
            Dados bancários
          </Tabs.Tab>
          <Tabs.Tab
            value="stakeholders"
            icon={<IconUser color="gray" size="1.3rem" />}
          >
            Stakeholders
          </Tabs.Tab>
          <Tabs.Tab
            value="parametrizacao"
            disabled={["LOCATARIO", "AMBOS"].includes(data?.object?.modo)}
            icon={<IconChecklist color="gray" size="1.3rem" />}
          >
            Parametrização
          </Tabs.Tab>
          <Tabs.Tab value="messages" disabled icon={<IconFileDescription color="gray" size="1.3rem" />}>Documentos (Empresa)</Tabs.Tab>
          <Tabs.Tab value="settings" disabled icon={<IconClipboardData color="gray" size="1.3rem" />}>Condições</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="business-data" pt="xs">
          <Title mt="md" mb="md" order={3}>
            Dados da empresa
          </Title>
          <EmpresaPanel empresa={empresa} status={status} />
        </Tabs.Panel>
        <Tabs.Panel value="financial" pt="xs">
          <FinancialListPanel empresaID={empresa?.id} />
        </Tabs.Panel>
        <Tabs.Panel value="messages" pt="xs">
          <Title mt="md" mb="md" order={3}>Documentos da empresa</Title>
          <Paper withBorder p="md">
            <Text color="gray" size="xs" mb="md">
              Informe documentos solicitados
            </Text>
            <UploadDocumentosEmpresa empresaID={empresa?.id} />
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel value="settings" pt="xs">
          <Title mt="md" mb="md" order={3}>Condições de negociação</Title>
          <CondicoesTab empresaID={empresa?.id} />
        </Tabs.Panel>
        <Tabs.Panel value="stakeholders" pt="xs">
          <StakeholderListPanel empresaID={empresa?.id} />
        </Tabs.Panel>
        <Tabs.Panel value="parametrizacao" pt="xs">
          <ParametrizacaoEmpresaPanel empresaID={empresa?.id} />
        </Tabs.Panel>
      </Tabs>
    </PageLayout >
  );
  
};
