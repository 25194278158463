import { Group, Avatar, Box, Text } from "@mantine/core";

export const Stakeholder = ({ stakeholder }: any) => {

    return (
        <Group noWrap>
            <Avatar src={stakeholder?.photo?.thumbnailSourceSet} radius="sm" />
            <Box>
                <Text>
                    {stakeholder?.nome}
                </Text>
            </Box>
        </Group>
    )

}
