import {
  Avatar,
  Badge,
  CloseButton,
  Group,
  Loader,
  Paper,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { FormEvent, forwardRef, useEffect, useState } from "react";
import { Hidden } from "shared/custom/Hidden";

export const ProdutoSelectForm = ({ form, error, value, onChange }: any) => {
  const reducer = useRemoteDataReducer();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  const [currentInput, setCurrentInput] = useDebouncedState("", 750);
  const [currentValue, setCurrentValue] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<any>(
    data?.object ? data?.object : [],
  );

  useEffect(() => {
    if (status?.isSuccessful && data) {
      setSearchResults(data?.object);
    }
  }, [status]);

  const onSearch = (codigo: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.produtos}/buscar/codigo?codigo=${codigo}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onType = (event: FormEvent<HTMLInputElement>) => {
    const term = event.currentTarget.value;
    setSearchResults([]);
    if (term.length > 5) {
      onSearch(term);
    }
  };

  const onSelect = (item: any) => {
    setCurrentValue(item);
    onChange(item?.id);
  };

  const AutoCompleteItem = forwardRef<HTMLDivElement, any>(
    ({ descricao, marca, modelo, image, ...rest }: any, ref) => (
      <div ref={ref} {...rest}>
        <Group noWrap>
          <Avatar size="lg" src={marca?.logo?.url} />
          <div>
            <Text>{descricao}</Text>
            <Text>{modelo}</Text>
            <Text size="xs" color="dimmed">
              {marca?.nome}
            </Text>
          </div>
        </Group>
      </div>
    ),
  );

  const resultData = searchResults?.map((item: any) => ({
    ...item,
    value: item?.id,
  }));

  return (
    <>
      <Hidden hide={currentValue}>
        <TextInput
          mt="md"
          description="Esta informação ajudará aos locatários a encontrar o seu ativo"
          label="Código de barras"
          onInput={onType}
          rightSection={status?.isFetching ? <Loader size="xs" /> : <></>}
          placeholder="7890000000000"
          error={error}
        />
        {resultData && resultData.length > 0 && (
          <Paper
            shadow="xs"
            pos={"absolute"}
            p="xs"
            style={{
              maxHeight: "200px",
              width: "96%",
              overflowY: "auto",
              zIndex: 10,
            }}
          >
            <Table>
              <tbody>
                {resultData.map((result: any, z: number) => (
                  <tr>
                    <AutoCompleteItem
                      style={{ cursor: "pointer" }}
                      onClick={() => onSelect(result)}
                      descricao={result.descricao}
                      modelo={result.modelo}
                      marca={result.marca}
                    />
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        )}
      </Hidden>
      <Hidden hide={!currentValue}>
        <Paper withBorder p="xs" mt="md">
          <Group position="apart" noWrap spacing="xl">
            <Group noWrap>
              <Avatar size="xl" src={currentValue?.foto?.url} />
              <div>
                <>
                  <Badge size="xs" mr="xs">
                    {currentValue?.descricaoClasse}
                  </Badge>
                  <Badge size="xs" mr="xs">
                    {currentValue?.descricaoSubclasse}
                  </Badge>
                  <Badge size="xs" mr="xs">
                    {currentValue?.descricaoGrupo}
                  </Badge>
                  <Badge size="xs" mr="xs">
                    {currentValue?.descricaoFamilia}
                  </Badge>
                </>
                <Text>{currentValue?.descricao}</Text>
                <Text size="xs" color="dimmed">
                  {currentValue?.modelo}
                </Text>
                <Group noWrap>
                  <Avatar size="md" src={currentValue?.marca?.logo?.url} />
                  <div>
                    <Text size="xs" color="dimmed">
                      {currentValue?.marca?.nome}
                    </Text>
                  </div>
                </Group>
              </div>
            </Group>
            <CloseButton
              onClick={() => setCurrentValue(null)}
              aria-label="Remover item selecionado"
            />
          </Group>
        </Paper>
      </Hidden>
    </>
  );
};

/*const consultarDados = async (values: any) => {
       if(values.cnpj !== '' && values.cnpj.length > 17  && values.razaoSocial === '') {
           const resultadoCNPJ = await ConsultaCNPJ.consultar(values.cnpj.replace(/\D/g,''));
           form.setValues({ ...values, ...resultadoCNPJ });
       }
       if(values.endereco.cep !== '' && values.endereco.cep.length > 8 && values.endereco.logradouro === ''){
           const resultadoCEP = await ConsultaCEP.consultar(values.endereco.cep.replace(/\D/g,''));
           form.setValues({ endereco: { ...values.endereco, ...resultadoCEP } });
       }
   }

   const [debounced] = useDebouncedValue(form.values, 1000);
   useEffect(() => {
       consultarDados(form.getTransformedValues());
   }, [debounced]);*/
