import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  Drawer,
  Group,
  Pagination,
  Text
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEye, IconSearch } from "@tabler/icons-react";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { OrcamentoDataGrid } from "partials/datagrids/Orcamento.datagrid";
import { Link } from "react-router-dom";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import "../../styles/styles.css";
import { useRemoteOperation } from "hooks/remote/useRemoteOperation";
import { OrcamentoItemDataGrid } from "partials/datagrids/OrcamentoItem.datagrid";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator } from "hooks";
import { InfoUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";
import { useState } from "react";
import { ProspeccaoItemPanel } from "../views/ProspeccaoItem.view";

export const AtivosALocarPanel = ({ reducer, prospectionID, onPaginationChange }: any) => {

  const itensProspeccao = reducer.state.data?.page?.content;
  const [opened, { open, close }] = useDisclosure(false);
  const prospeccaoItem = useDisclosure(false);
  const [prospectionItem, setProspectionItem] = useState<any>({});

  const reducerProspeccaoItemOrcamento = useRemoteDataReducer();
  const prospeccaoItemOrcamentoOperations = useRemoteOperation({
    reducer: reducerProspeccaoItemOrcamento
  });

  const viewProspectionItemOrcamentos = (prospectionID: any, prospectionItemID: any, item: any) => {
    setProspectionItem(item);
    prospeccaoItemOrcamentoOperations.fetchPage({
      endpoint: `${RemoteEndpoints.prospeccao}/${prospectionID}/orcamentos/item/${prospectionItemID}`,
      headers: RequestConfigGenerator.build(),
    });
    //prospeccaoItem[1].open();
  }

  const onPaginationChangeOrcamentos = (rer: any) => {

  }

  return (
    <>
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        size="xl"
        title={
          <Text fz="xl" fw={500}>
            Novo Item para prospecção
          </Text>
        }
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Divider />

      </Drawer>
      <Drawer
        opened={prospeccaoItem[0]}
        onClose={prospeccaoItem[1].close}
        position="right"
        size="75%"
        title={
          <Text fz="xl" fw={500}>
            Orçamentos deste Item
          </Text>
        }
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Divider />
        <Box mt="md">
          <Group noWrap>
            <ProspeccaoItemPanel prospectionItem={prospectionItem} />
          </Group>
        </Box>
        <Box mt="md">
          <OrcamentoItemDataGrid
            reducer={reducerProspeccaoItemOrcamento}
            onPaginationChange={onPaginationChangeOrcamentos}
            flags={["view"]}
          />
        </Box>
        <Box mt="md">
          <Button fullWidth component={Link} to={`/prospeccoes/${prospectionID}/item/${prospectionItem?.id}`}>
            Ver página completa
          </Button>
        </Box>
      </Drawer>
      {itensProspeccao?.map((item: any, index: any) => (
        <Card withBorder my={"xs"} key={index}>
          <Group position="apart" noWrap spacing="xl">
            <div>
              <Text>{item.descricao}</Text>
              <Text size="xs" color="dimmed">
                {item?.marca?.nome}
              </Text>
              <Text size="xs" color="dimmed">
                {item?.dataInicio} - {item?.periodo}
              </Text>
            </div>
            <Group position="apart">
              <ActionIcon variant="transparent" color="gray" onClick={() => viewProspectionItemOrcamentos(prospectionID, item?.id, item)}>
                <IconEye color="gray" />
              </ActionIcon>
              <ActionIcon variant="transparent" color="gray">
                <Link to={`/portal/prospeccao?prospectionID=${prospectionID}&prospectionItemID=${item?.id}`}>
                  <IconSearch color="gray" />
                </Link>
              </ActionIcon>
            </Group>
          </Group>
        </Card>
      ))}
      <Center>
        <Pagination
          mt="md"
          total={reducer?.state.data?.page?.totalPages || 0}
          onChange={(page: number) =>
            onPaginationChange({ page: page, size: 12, sort: "desc" })
          }
        />
      </Center>
    </>
  );
};
