import { Box, Card, Divider, Grid, Group, LoadingOverlay, Paper, SimpleGrid, Text } from "@mantine/core";
import moment from "moment";
import { InfoUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";

export const EmpresaPanel = ({ empresa, status }: any) => {

  return (
    <Paper withBorder p="md">
      <Hidden hide={!status?.isFetching}>
        <LoadingOverlay visible={false} />
      </Hidden>
      <SimpleGrid
        cols={1}
        breakpoints={[
          { minWidth: "xl", cols: 4 },
          { minWidth: "lg", cols: 3 },
          { minWidth: "md", cols: 2 },
          { minWidth: "sm", cols: 2 },
        ]}
      >
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Razão social</Text>
            <Text size="sm">{empresa?.razaoSocial}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Nome Fantasia</Text>
            <Text size="sm">{empresa?.nomeFantasia}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">CNPJ</Text>
            <Text size="sm">{InfoUtils.formatCNPJ(empresa?.cnpj)}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Inscrição Estadual / Municipal</Text>
            <Text size="sm">{empresa?.inscricaoEstadual} / {empresa?.inscricaoMunicipal}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Data de Abertura</Text>
            <Text size="sm">{moment(empresa?.dataAbertura).format("DD/MM/YYYY").toString()}</Text>
          </Box>
        </Group>
      </SimpleGrid>
    </Paper>
  );
};
