import {
  Button,
  CloseButton,
  Group,
  Input,
  Paper,
  Select,
  Text,
  Textarea
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconFile } from "@tabler/icons-react";
import moment from "moment";
import { useEffect } from "react";
import { NumberUtils } from "shared/Utils";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import { UploadDropZone } from "shared/components/UploadDropZone.component";
import { Hidden } from "shared/custom/Hidden";

export const PagamentoForm = ({ reducer, editMode, onSubmit }: any) => {

  const { data, status } = reducer.state;

  const format = (data: any) => {
    if (data) {
      return moment(data, "YYYY-MM-DD HH:mm:ss").toDate();
    }
    return moment().toDate();
  };

  const form = useForm({
    validateInputOnBlur: ["status", "valor", "descricao", "dataVencimento"],
    initialValues: {
      id: null,
      status: "GENERATED",
      valor: "",
      descricao: "",
      dataVencimento: moment().toDate(),
    },
    validate: {
      status: (value) =>
        value.length > 1 ? null : "Informe um status do pagamento",
      valor: (value) => (value !== "" ? null : "Informe o valor do pagamento"),
      descricao: (value) =>
        value !== "" ? null : "Informe uma descrição do pagamento",
      dataVencimento: (value) =>
        value !== null ? null : "Informe a data de vencimento do pagamento",
    },
  });

  useEffect(() => {
    if (data.object && editMode) {
      let finalData = data.object;
      finalData.dataVencimento = format(finalData?.dataVencimento);
      form.setValues(finalData);
    }
  }, [data]);

  const _onSubmit = async (submitData: any) => {
    let data = Object.assign({}, submitData);
    Object.keys(data).forEach((k) => data[k] == null && delete data[k]);
    delete data.error; // não sei de onde, mas ta vindo isso
    data.valor = NumberUtils.valorToNumber(data.valor);


    data.dataVencimento = moment(
      data.dataVencimento,
      "YYYY-MM-DD HH:mm:ss",
    ).toISOString();

    const id = data.id;
    if (editMode) {
      data = {
        status: data.status,
        observacoes: data.observacoes,
      }
    }

    onSubmit(data, id);
  };

  const onFormError = (event: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  return (
    <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
      <Select
        {...form.getInputProps("status")}
        disabled={!editMode}
        label="Status do pagamento"
        placeholder="Status do pagamento"
        mt="md"
        data={[
          { label: "Gerado", value: "GENERATED", key: 'GENERATED' },
          { label: "Em analise", value: "ANALISYS", key: 'ANALISYS' },
          { label: "Negado", value: "DENIED", key: 'DENIED' },
          { label: "Confirmado", value: "CONFIRMED", key: 'CONFIRMED' },
          { label: "Cancelado", value: "CANCELLED", key: 'CANCELLED' },
        ]}
        {...form.getInputProps("status")}
      />
      <Input.Wrapper label="Valor do Pagamento" required mt="md">
        <ConcurrencyInput
          label="Valor do Pagamento"
          disabled={editMode}
          {...form.getInputProps("valor")}
          placeholder="250,00"
        />
      </Input.Wrapper>
      <Textarea
        disabled={editMode}
        mt="md"
        placeholder="Referente a ..."
        label="Descrição"
        {...form.getInputProps("descricao")}
      />
      <Textarea
        mt="md"
        placeholder="Quaisquer informações sobre o pagamento"
        label="Observações"
        {...form.getInputProps("observacoes")}
      />
      <DateInput
        disabled={editMode}
        {...form.getInputProps("dataVencimento")}
        valueFormat="DD/MM/YYYY"
        mt="md"
        label="Data vencimento"
        placeholder="dd/mm/aaaa"
      />
      <Group position="apart" mt="lg">
        <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
          Limpar
        </Button>
        <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
          Confirmar
        </Button>
      </Group>
    </form>
  );

};
