import {
  ActionIcon,
  Avatar,
  Button,
  Divider,
  Group,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconEdit,
  IconEye,
  IconScanEye,
  IconShoppingCart,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import moment from "moment";
import { EmpresaForm } from "partials/forms/Empresa.form";
import { AvatarResponsive } from "partials/responsive/Avatar.responsive";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const OrganizationListPage = () => {
  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { fetchSingle, update, remove, fetchPage, create } = useRemoteOperation(
    { reducer: reducer },
  );
  const { data, status } = reducer.state;
  const action = new URLSearchParams(useLocation().search).get("action");

  useEffect(() => {
    if (action === "cadastrar-empresa") {
      drawerForm.open("cadastroEmpresa");
    }
  }, [action]);

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Empresa cadastrada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      //cadastroEmpresa[1].close();
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Empresa atualizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      //editarEmpresa[1].close();
    } else if (status?.isSuccessful && data?.object){ 
      drawerForm.open("editarEmpresa"); 
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.empresas}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.empresas}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.empresas}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    update({
      endpoint: `${RemoteEndpoints.empresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  };

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.empresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onCreate = (entity: any) => {
    create({
      endpoint: RemoteEndpoints.empresas,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  const editInfo = (id: any) => {
    findItem(id);
  };

  const addEmpresa = () =>  drawerForm.open("cadastroEmpresa");

  return (
    <PageLayout
      title="Empresas"
      headerActions={
        <Group position="center">
          <Button onClick={addEmpresa}>Cadastrar Empresa</Button>
        </Group>
      }
    >
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastroEmpresa"
        title="Cadastrar Empresa"
      >
        <Divider />
        <EmpresaForm
          reducer={reducer}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarEmpresa"
        title="Editar Empresa"
      >
        <EmpresaForm
          reducer={reducer}
          editMode={true}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
        />
      </CustomDrawer>
      <DataGridComponent
        visible={true}
        state={reducer.state}
        noRecordText="Nenhuma empresa encontrada"
        onPaginationChange={onPaginationChange}
        columns={[
          {
            accessor: "logo",
            verticalAlignment: "center",
            title: "Logo",
            render: ({ logo }: any) => (
              <Avatar size="lg" src={logo?.thumbnailSourceSet} />
            ),
          },
          {
            accessor: "cnpj", title: "CNPJ", sortable: true, render: ({ cnpj }: any) => <>{InfoUtils.formatCNPJ(cnpj)}</>
          },
          { accessor: "nomeFantasia", title: "Nome Fantasia" },
          {
            accessor: "endereco", title: "Localização", render: ({ endereco }: any) => (
              <Text size="xs" color="dimmed">
                {endereco.cidade} - {endereco.estado}
              </Text>
            ),
          },
          {
            accessor: "modo",
            title: "Modo",
            render: ({ modo, id }: any) =>
              <CustomBadge key={id} value={modo} options={[
                { value: "LOCADOR", text: "Locador", color: "blue" },
                { value: "LOCATARIO", text: "Locatário", color: "teal" },
                { value: "AMBOS", text: "Ambos", color: "gray" },
              ]} />
          },
          {
            accessor: "criacao",
            title: "Criação",
            render: ({ creationTime }: any) => (
              <Text size="xs" color="dimmed">
                {" "}
                {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "atualizacao",
            title: "Atualização",
            render: ({ modificationTime }: any) => (
              <Text size="xs" color="dimmed">
                {" "}
                {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <a href={`/minha-empresa?empresa=${id}`}>
                  <ActionIcon color="gray">
                    <IconScanEye size={26} />
                  </ActionIcon>
                </a>
                <a href={`/loja-ativos/${id}`}>
                  <ActionIcon color="gray">
                    <IconShoppingCart size={26} />
                  </ActionIcon>
                </a>
                <a href={`/empresas/${id}`}>
                  <ActionIcon color="gray">
                    <IconEye size={26} />
                  </ActionIcon>
                </a>
                <ActionIcon color="blue" onClick={() => editInfo(id)}>
                  <IconEdit size={26} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
