import { Button, Group, Input, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import moment from "moment";
import { NumberUtils, ObjectUtils } from "shared/Utils";


export const OrcamentoRespostaForm = ({
    reducer,
    onSubmit
}: any) => {

    const { data, status } = reducer.state;

    const form = useForm({
        validateInputOnBlur: [],
        initialValues: {
            validade: "",
            observacoes: "",
        },
        validate: {
            validade: (value) => (value !== "" ? null : "Informe a validade do orçamento"),
        },
    });

    const _onSubmit = async (submitData: any) => {
        let cleanData = ObjectUtils.clone(submitData);
        cleanData = ObjectUtils.cleanNulls(cleanData);
        cleanData.validade = moment(
            cleanData.validade,
            "DD/MM/YYYY",
        ).format("YYYY-MM-DD");
        onSubmit(cleanData);
    };

    const onFormError = (event: any) => {
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });
    };

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <Input.Wrapper label="Validade do Orçamento" required mt="md">
                <DateInput
                    {...form.getInputProps("validade")}
                    valueFormat="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                />
            </Input.Wrapper>
            <Textarea
                mt="md"
                placeholder="Observações"
                label="Observações"
                {...form.getInputProps("observacoes")}
            />
            <Group position="apart" mt="lg">
                <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
                    Limpar
                </Button>
                <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
                    Confirmar
                </Button>
            </Group>
        </form>
    );

}