import { Group, ActionIcon, Text, Tooltip, Avatar, Badge } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconScanEye, IconEye, IconEdit, IconBox, IconUpload, IconTrash, IconCamera } from "@tabler/icons-react";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { Link } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const AtivoDataGrid = ({ reducer, onPaginationChange, onView, onEdit, onDelete, flags }: any) => {

    const authority = SecurityProvider.getAuthority();

    const openDeleteConfirmModal = (id: any) => modals.openConfirmModal({
        centered: true,
        title: 'Tem certeza?',
        children: (
            <Text size="sm">
                Tem certeza que deseja remover este ativo da lista?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => onDelete(id),
    });

    return (
        <DataGridComponent
            visible={true}
            fetching={reducer.state.status?.isFetching}
            state={reducer.state}
            noRecordText="Nenhum ativo encontrado"
            onPaginationChange={onPaginationChange}
            columns={[
                {
                    accessor: "numeroAtivo",
                    title: "Numero",
                },
                {
                    hidden: !["ADMINISTRATOR"].includes(SecurityProvider.getAuthority()),
                    accessor: "empresa",
                    title: "Empresa",
                    width: '300px',
                    render: ({ empresa }: any) => <EmpresaChip empresa={empresa} />

                },
                {
                    accessor: "descricao",
                    title: "Descricao",
                    render: ({ dadosIdentificacaoProduto, produto }: any) => (
                        <>{produto ? "" + produto?.descricao : "" + (dadosIdentificacaoProduto?.descricao || "-")}</>
                    ),
                },
                {
                    accessor: "marca",
                    title: "Marca",
                    width: '300px',
                    render: ({ produto, dadosIdentificacaoProduto }: any) => <>{ produto ? produto?.marca?.nome : dadosIdentificacaoProduto?.marca?.nome }</>

                },
                {
                    accessor: "criacao",
                    title: "Criação",
                    render: ({ creationTime }: any) =>
                        <Text size="xs" color="dimmed">
                            {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                },
                {
                    accessor: "atualizacao",
                    title: "Atualização",
                    render: ({ modificationTime }: any) =>
                        <Text size="xs" color="dimmed">
                            {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ id }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            <Tooltip label="Visualizar Ativo">
                                <Link to={`/ativos/${id}`}>
                                    <ActionIcon color="gray">
                                        <IconEye size={26} />
                                    </ActionIcon>
                                </Link>
                            </Tooltip>
                            <Link to={`/ativo-locacao/${id}`}>
                                <ActionIcon color="gray">
                                    <IconScanEye size={26} />
                                </ActionIcon>
                            </Link>
                            <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
                                <Tooltip label="Editar Ativo">
                                    <ActionIcon color="blue" onClick={() => onEdit(id)}>
                                        <IconEdit size={26} />
                                    </ActionIcon>
                                </Tooltip>
                                <ActionIcon color="blue" onClick={() => openDeleteConfirmModal(id)}>
                                    <IconTrash size={26} />
                                </ActionIcon>
                            </Hidden>
                        </Group>
                    ),
                },
            ]}
        />
    );

}
