import { useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { LatLng } from "leaflet";
import { Box } from "@mantine/core";

export const MapGeolocationComponent = ({ value, onChange }: any) => {

  function LocationMarker() {
    const [position, setPosition] = useState<LatLng>(
      new LatLng(value?.latitude || -15.7801, value?.longitude || -47.9292),
    );
    
    /*const map = useMapEvents({
      click(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
        if (onChange) {
          onChange({ latitude: e.latlng.lat, longitude: e.latlng.lng });
        }
      },
    });

    map.locate();*/

    return position === null ? null : (
      <Marker position={position}>
        <Popup></Popup>
      </Marker>
    );
  }

  return (
    <Box>
      <MapContainer
        center={[value?.latitude || -15.7801, value?.longitude || -47.9292]}
        className="mt-2"
        zoom={4}
        scrollWheelZoom={true}
      >
        <TileLayer
          attribution='&copy; <a h"http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
    </Box>
  );
};
