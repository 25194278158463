import {
    Button,
    CloseButton,
    Group,
    Image,
    Box,
    Input,
    LoadingOverlay,
    Paper,
    Select,
    Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconPhoto } from "@tabler/icons-react";
import { UploadDropZone } from "shared/components/UploadDropZone.component";
import { Hidden } from "shared/custom/Hidden";
import { IconFile } from "@tabler/icons-react";

export const ContratoVisualizacaoAssinaturaUploadPanel = ({
    onUploadAssinatura,
    contrato,
    status,
}: any) => {

    const form = useForm({
        validateInputOnBlur: ["file", "imagemAssinatura", "documentoAssinado"],
        initialValues: {
            tipo: "IMAGEM",
            imagemAssinatura: new File([], "", undefined),
            documentoAssinado: new File([], "", undefined),
            file: new File([], "", undefined),
        },
        validate: {
            file: (value) => (value.name !== "" ? null : "Informe um arquivo."),
            tipo: (value) => (value !== "" ? null : "Informe um tipo."),
            //imagemAssinatura: (value) => value.name !== '' ? null : 'Informe um arquivo.',
            //documentoAssinado: (value) => value.name !== '' ? null : 'Informe um arquivo.'
        },
    });

    const onSubmit = async (e: any) => {
        const id = contrato?.id;
        if (form.values.imagemAssinatura?.name) {
            debugger
            onUploadAssinatura(id, form.values.imagemAssinatura);
        }

    };

    const onFormError = (e: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    // form.setValues({ imagemAssinatura: files[0] })

    return (
        <>
            <Box style={{ display: 'flex', height: '90vh' }} mt="xs">
                <Box style={{ flex: 7 }}>
                    <LoadingOverlay visible={status?.isFetching} overlayBlur={2} />
                    <iframe
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                        src={contrato?.documentoContrato?.url}
                    ></iframe>
                </Box>
                <Box mx="md" style={{ flex: 3, boxSizing: 'border-box' }}>
                    <LoadingOverlay visible={status?.isFetching} overlayBlur={2} />
                    <Hidden hide={!form.values.file?.name}>
                        <Paper withBorder p="xs" mt="md">
                            <Group position="apart" noWrap spacing="xl">
                                <Group noWrap>
                                    <IconFile size={32} color="gray" />
                                    <div>
                                        <Text size="sm">{form.values.file.name}</Text>
                                    </div>
                                </Group>
                                <CloseButton
                                    onClick={() =>
                                        form.setValues({ file: new File([], "", undefined) })
                                    }
                                    aria-label="Remover item selecionado"
                                />
                            </Group>
                        </Paper>
                    </Hidden>
                    <Hidden
                        hide={
                            contrato?.comprovanteAssinaturaLocador || contrato?.comprovanteAssinaturaLocatario
                        }
                    >
                        <Select
                            label="Forma de Comprovante"
                            placeholder="Forma de Comprovante"
                            disabled
                            mt="md"
                            data={[
                                { value: "IMAGEM", label: "Imagem" },
                                { value: "DOCUMENTO", label: "Documento" },
                            ]}
                            {...form.getInputProps("tipo")}
                        />
                    </Hidden>
                    <Input.Wrapper mt="xs" label="Foto da assinatura no contrato">
                        <Hidden
                            hide={
                                contrato?.comprovanteAssinaturaLocador
                            }
                        >
                            <Hidden hide={form.values.imagemAssinatura?.name}>
                                <UploadDropZone
                                    mt="md"
                                    multiple={false}
                                    onDrop={(files) =>
                                        onUploadAssinatura(contrato?.id, files[0])
                                    }
                                    accept={["image/jpeg", "image/jpg", "image/png"]}
                                />
                            </Hidden>
                            <Hidden hide={!form.values.imagemAssinatura?.name}>
                                <Paper withBorder p="xs" mt="md">
                                    <Group position="apart" noWrap spacing="xl">
                                        <Group noWrap>
                                            <IconPhoto size={32} color="gray" />
                                            <div>
                                                <Text size="sm">
                                                    {form.values.imagemAssinatura?.name}
                                                </Text>
                                            </div>
                                        </Group>
                                        <CloseButton
                                            onClick={() =>
                                                form.setValues({
                                                    imagemAssinatura: new File([], "", undefined),
                                                })
                                            }
                                            aria-label="Remover item selecionado"
                                        />
                                    </Group>
                                </Paper>
                            </Hidden>
                        </Hidden>
                        <Hidden
                            hide={
                                contrato?.comprovanteAssinaturaLocatario
                            }
                        >
                            <Hidden hide={form.values.imagemAssinatura?.name}>
                                <UploadDropZone
                                    mt="md"
                                    multiple={false}
                                    onDrop={(files) =>
                                        onUploadAssinatura(contrato?.id, files[0])
                                    }
                                    accept={["image/jpeg", "image/jpg", "image/png"]}
                                />
                            </Hidden>
                            <Hidden hide={!form.values.imagemAssinatura?.name}>
                                <Paper withBorder p="xs" mt="md">
                                    <Group position="apart" noWrap spacing="xl">
                                        <Group noWrap>
                                            <IconPhoto size={32} color="gray" />
                                            <div>
                                                <Text size="sm">
                                                    {form.values.imagemAssinatura?.name}
                                                </Text>
                                            </div>
                                        </Group>
                                        <CloseButton
                                            onClick={() =>
                                                form.setValues({
                                                    imagemAssinatura: new File([], "", undefined),
                                                })
                                            }
                                            aria-label="Remover item selecionado"
                                        />
                                    </Group>
                                </Paper>
                            </Hidden>
                        </Hidden>
                    </Input.Wrapper>
                    <Hidden hide={!contrato?.comprovanteAssinaturaLocador}>
                        <Text fz="md" mt="md" fw={500}>
                            Assinatura do Locador
                        </Text>
                        <Image
                            maw={240}
                            mx="auto"
                            radius="md"
                            src={contrato?.comprovanteAssinaturaLocador?.url}
                            alt="Imagem Assinatura do Locador"
                        />
                    </Hidden>
                    <Hidden hide={!contrato?.comprovanteAssinaturaLocatario}>
                        <Text fz="md" mt="md" fw={500}>
                            Assinatura do Locatário
                        </Text>
                        <Image
                            maw={240}
                            mx="auto"
                            radius="md"
                            src={contrato?.comprovanteAssinaturaLocatario?.url}
                            alt="Imagem Assinatura do Locatário"
                        />
                    </Hidden>

                </Box>
            </Box>
        </>
    );
};

