import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Text,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconEdit,
  IconEye,
  IconQrcode,
  IconUserCog,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { StakeholderForm } from "partials/forms/Stakeholder.form";
import { StakeholderPanel } from "partials/views/Stakeholder.view";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { useModal } from "shared/hooks/ModalProvider";
import { AccountPanel } from "../../../../partials/panels/Account.panel";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { PaginationProps } from "shared/models/Pagination.props";

export const StakeholderListPanel = ({ empresaID }: any) => {

  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer });
  const internalAccountReducer = useRemoteDataReducer();
  const { fetchSingle, update, create, remove, fetchPage } = useRemoteOperation(
    { reducer: reducer },
  );
  const internalAccountOperations = useRemoteOperation({
    reducer: internalAccountReducer,
  });
  const [conta, setConta] = useState<any>(null);
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Stakeholder cadastrado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (status?.isDeleted) {
      notifications.show({
        message: "Stakeholder deletado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (
      status?.isInitiating &&
      !modal.state?.parameters?.id &&
      !params.id
    )
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) onFindItem(params.id);
    else if (status?.isUpdated) {
      notifications.show({
        message: "Stakeholder atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [status, location?.pathname]);

  useEffect(() => {
    if (empresaID) {
      reducer.dispatch({ type: "INITIATING" });
      internalAccountReducer.dispatch({ type: "INITIATING" });
    }
  }, [empresaID]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.stakeholders}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.stakeholders}`,
      requestParameters: {
        empresa: empresaID,
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    if (empresaID) {
      fetchPage({
        endpoint: `${RemoteEndpoints.stakeholders}`,
        requestParameters: {
          empresa: empresaID,
          page: pagination.page,
          size: pagination.size,
          sort: pagination.sort || "desc",
        },
        headers: RequestConfigGenerator.build(),
      });
    }
  };

  const onCreate = (entity: any) =>
    create({
      endpoint: RemoteEndpoints.stakeholders,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });

  const onUpdate = (entity: any, id: any) => {
    update({
      endpoint: `${RemoteEndpoints.stakeholder}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  };

  const onFindItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.stakeholder}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const showInfo = (company: any) => {
    onFindItem(company.id);
    drawerForm.open("visualizarStakeholder");
  };

  const viewAccount = (account: any) => {
    setConta(account);
    drawerForm.open("visualizarConta");
  };

  const editInfo = (company: any) => {
    onFindItem(company?.id);
    drawerForm.open("editarStakeholder");
  };

  return (
    <>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastrarStakeholder"
        title="Cadastrar Stakeholder"
      >
        <StakeholderForm
          reducer={reducer}
          empresa={empresaID}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarStakeholder"
        title="Editar Stakeholder"
      >
        <StakeholderForm
          reducer={reducer}
          editMode={true}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
          data={data?.object ? data?.object : {}}
          submiting={status?.isUpdating}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="visualizarStakeholder"
        title="Dados do Stakeholder"
      >
        <StakeholderPanel loading={status?.isFetching} data={data?.object} />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="visualizarConta"
        title="Dados da conta"
      >
        <AccountPanel account={conta} />
      </CustomDrawer>
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Stakeholders</Title>
        <Button onClick={() => drawerForm.open("cadastrarStakeholder")}>Cadastrar Stakeholder</Button>
      </Group>
      <DataGridComponent
        state={reducer.state}
        visible={true}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhum stakeholder encontrado"
        columns={[
          { accessor: "nome", title: "Nome" },
          { accessor: "matricula", title: "Matricula" },
          { accessor: "papel", title: "Papel" },
          {
            accessor: "status",
            title: "Status",
            render: ({ status }: any) => <Badge>{status}</Badge>,
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (company: any) => (
              <Group spacing={12} position="right" noWrap>
                <Hidden hide={!company?.account}>
                  <ActionIcon
                    color="gray"
                    onClick={() => viewAccount(company?.account)}
                  >
                    <IconUserCog size={26} />
                  </ActionIcon>
                </Hidden>
                <ActionIcon color="gray" onClick={() => showInfo(company)}>
                  <IconEye size={26} />
                </ActionIcon>
                <ActionIcon color="blue" onClick={() => editInfo(company)}>
                  <IconEdit size={26} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
      />
    </>
  );
};
