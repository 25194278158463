import { ActionIcon, Avatar, Badge, Box, Center, CloseButton, Group, Pagination, Paper, Skeleton, Table, Text } from "@mantine/core";
import { useClickOutside, useDisclosure } from "@mantine/hooks";
import { IconEye, IconPlus, IconSearch } from "@tabler/icons-react";
import DataTableEmptyState from "partials/panels/DataTableEmptyState.panel";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { InfoUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";

export const ProspeccaoItemSelectComponent = ({ reducer, value, prospection, disabled, onCreateAction, onSelect, onPaginationChange, hideAdd }: any) => {

    const ref = useClickOutside(() => dropdown[1].close());
    const dropdown = useDisclosure(false);
    const result = reducer?.state.data?.page;
    const status = reducer?.state.status;

    const AutoCompleteItem = forwardRef<HTMLDivElement, any>(
        ({ descricao, marca, modelo, descricaoClasse, descricaoSubclasse, descricaoGrupo, descricaoFamilia, image, ...rest }: any, ref) => (
            <Box ref={ref} {...rest} my="xs">
                <Group noWrap>
                    <div>
                        <Text size="sm" fw={500}>{InfoUtils.limitText(descricao || "", 100)}</Text>
                        <Hidden hide={!descricaoClasse && !descricaoSubclasse && !descricaoGrupo && !descricaoFamilia}>
                            <Badge size="xs" mr="xs">
                                {descricaoClasse}
                            </Badge>
                            <Badge size="xs" mr="xs">
                                {descricaoSubclasse}
                            </Badge>
                            <Badge size="xs" mr="xs">
                                {descricaoGrupo}
                            </Badge>
                            <Badge size="xs" mr="xs">
                                {descricaoFamilia}
                            </Badge>
                        </Hidden>
                        <Text size="xs" color="dimmed">
                            {modelo}
                        </Text>
                        <Hidden hide={!marca?.nome}>
                            <Group noWrap mt="xs">
                                <Avatar size="sm" src={marca?.logoURL} />
                                <div>
                                    <Text size="xs" color="dimmed">
                                        {marca?.nome}
                                    </Text>
                                </div>
                            </Group>
                        </Hidden>
                    </div>
                </Group>
            </Box>
        ),
    );

    return (
        <>
            <Hidden hide={value}>
                <Box w="100%">
                    <Group noWrap>
                        <Box w="100%" style={{ position: "relative" }}>
                            <Box w="100%">
                                <Hidden hide={!status?.isFetching || dropdown[0]}>
                                    <Skeleton height={8} radius="xl" width="40%" />
                                </Hidden>
                                <Hidden hide={status?.isFetching && !dropdown[0]}>
                                    <Text size="sm" fw={500}>
                                        Selecione ou Adicione Item da Prospecção
                                    </Text>
                                </Hidden>
                            </Box>
                            <Hidden hide={!dropdown[0]}>
                                <Box style={{ zIndex: 10, top: "100%" }} pos="absolute" w="100%">
                                    <Paper
                                        ref={ref}
                                        shadow="xs"
                                        p="xs"
                                        style={{
                                            maxHeight: "max-content",
                                            width: "100%",
                                            overflowY: "auto",
                                        }}
                                    >
                                        <Hidden hide={result?.content || (result?.content?.lenght > 0)}>
                                            <DataTableEmptyState text="Nenhum item encontrado" pt={20} pb={20} active={true}></DataTableEmptyState>
                                        </Hidden>
                                        <Hidden hide={!result?.content}>
                                            <Table
                                                style={{
                                                    maxHeight: "300px",
                                                    width: "100%",
                                                    overflowY: "auto",
                                                }}
                                            >
                                                <tbody>
                                                    {result?.content?.map((result: any, index: number) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <AutoCompleteItem
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => onSelect(result)}
                                                                    descricao={result.descricao}
                                                                    modelo={result.modelo}
                                                                    marca={result.marca}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <Center>
                                                <Pagination
                                                    my="md"
                                                    value={(result?.number || 0) + 1}
                                                    onChange={(value: any) => onPaginationChange({ page: value - 1, size: 5, sort: "desc" })}
                                                    total={result?.totalPages || 0}
                                                />
                                            </Center>
                                        </Hidden>
                                    </Paper>
                                </Box>
                            </Hidden>
                        </Box>
                        <Group position="apart" noWrap>
                            <ActionIcon disabled={disabled} variant="filled" color="blue" onClick={() => { dropdown[0] ? dropdown[1].close() : dropdown[1].open() }}>
                                <IconSearch
                                    style={{ width: '70%', height: '70%' }}
                                    aria-label="Remover item selecionado"
                                />
                            </ActionIcon>
                            <Hidden hide={hideAdd}>
                                <ActionIcon disabled={disabled} variant="filled" color="teal" onClick={onCreateAction}>
                                    <IconPlus
                                        style={{ width: '70%', height: '70%' }}
                                    />
                                </ActionIcon>
                            </Hidden>
                        </Group>
                    </Group>
                </Box>
            </Hidden>
            <Hidden hide={!value}>
                <Group position="apart" noWrap spacing="xl" w="100%">
                    <Group noWrap>
                        <div>
                            <Text size="sm" fw={500}>{InfoUtils.limitText(value?.descricao || "", 100)}</Text>
                            <Hidden hide={!value?.descricaoClasse && !value?.descricaoSubclasse && !value?.descricaoGrupo && !value?.descricaoFamilia}>
                                <Badge size="xs" mr="xs">
                                    {value?.descricaoClasse}
                                </Badge>
                                <Badge size="xs" mr="xs">
                                    {value?.descricaoSubclasse}
                                </Badge>
                                <Badge size="xs" mr="xs">
                                    {value?.descricaoGrupo}
                                </Badge>
                                <Badge size="xs" mr="xs">
                                    {value?.descricaoFamilia}
                                </Badge>
                            </Hidden>
                            <Text size="xs" color="dimmed">
                                {value?.modelo}
                            </Text>
                            <Hidden hide={!value?.marca?.nome}>
                                <Group noWrap>
                                    <Avatar size="sm" src={value?.marca?.logoURL} />
                                    <div>
                                        <Text size="xs" color="dimmed">
                                            {value?.marca?.nome}
                                        </Text>
                                    </div>
                                </Group>
                            </Hidden>
                        </div>
                    </Group>
                    <Group noWrap>
                        <ActionIcon variant="filled" color="blue" component={Link} to={`/prospeccoes/${prospection?.id}/item/${value?.id}`}>
                            <IconEye
                                style={{ width: '70%', height: '70%' }}
                            />
                        </ActionIcon>
                        <CloseButton color="gray"
                            onClick={() => onSelect(null)}
                            aria-label="Remover item selecionado"
                        />
                    </Group>
                </Group>
            </Hidden>
        </>
    );

}