import {
  Center,
  Pagination,
  Paper,
  SimpleGrid
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { ProdutoCard } from "partials/cards/Produto.card";
import { BuscaProspeccaoNadaEncontrado } from "partials/panels/BuscaProspeccaoNadaEncontrado.panel";
import { InformeParametrosBuscaProspeccaoPanel } from "partials/panels/InformeParametrosBuscaProspeccao.panel";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { ProspeccaoPanelComponent } from "../../../partials/panels/ProspeccaoPanel.panel";
import { PaginationProps } from "shared/models/Pagination.props";
import { ObjectUtils } from "shared/Utils";

export const PortalProspeccaoPage = () => {

  const prospectionID = new URLSearchParams(useLocation().search).get("prospectionID");
  const prospectionItemID = new URLSearchParams(useLocation().search).get("prospectionItemID");

  const reducer = useRemoteDataReducer();
  const { fetchPage } = useRemoteOperation({ reducer: reducer });
  const [dadosBuscaProspeccao, setDadosBuscaProspeccao] = useState<any>({
    prospeccao: null as any,
    prospeccaoItem: null as any
  });

  const { data, status } = reducer.state;
  const ativos = data?.page?.content;

  useEffect(() => {
    if (status?.isFailed) {
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  useEffect(() => {
    if (dadosBuscaProspeccao.prospeccao && dadosBuscaProspeccao.prospeccaoItem) {
      onPaginationChange({ page: 0, size: 12, sort: "desc" });
    }
  }, [dadosBuscaProspeccao]);

  const onPaginationChange = (pagination: PaginationProps) => {

    if (dadosBuscaProspeccao?.prospeccaoItem) {
      const values = dadosBuscaProspeccao?.prospeccaoItem;
      const parameters = {
        termo: values?.descricao,
        marca: values?.marca?.code
      };

      fetchPage({
        endpoint: `${RemoteEndpoints.buscarAtivos}`,
        requestParameters: {
          ...ObjectUtils.cleanNulls(parameters),
          page: pagination.page,
          size: pagination.size,
          sort: pagination.sort || "desc",
        },
        headers: RequestConfigGenerator.build(),
      });
    }
    
  };

  const generateUrlParameters = () => {
    return `?context=prospection&prospectionID=${dadosBuscaProspeccao.prospeccao?.id}&prospectionItemID=${dadosBuscaProspeccao.prospeccaoItem?.id}`
  }


  return (
    <PageLayout
      title="Buscar Ativos"
      header={
        <Paper p="md">
          <ProspeccaoPanelComponent
            parameters={{ prospectionID: prospectionID, prospectionItemID: prospectionItemID }}
            value={dadosBuscaProspeccao}
            setValue={(result: any) => setDadosBuscaProspeccao(result)}
          />
        </Paper>
      }
    >
      <Hidden hide={!ativos}>
        <SimpleGrid
          mt="md"
          cols={4}
          breakpoints={[
            { maxWidth: '62rem', cols: 4, spacing: 'md' },
            { maxWidth: '48rem', cols: 2, spacing: 'sm' },
            { maxWidth: '36rem', cols: 1, spacing: 'sm' },
          ]}
        >
          {ativos?.map((item: any, index: any) =>
            <ProdutoCard
              index={index}
              ativo={item}
              url={`/ativo-locacao/${item?.id}${generateUrlParameters()}`}
            />
          )}
        </SimpleGrid>
        <Center>
          <Pagination
            mt="md"
            total={reducer?.state.data?.page?.totalPages || 0}
            onChange={(page: number) =>
              onPaginationChange({ page: page, size: 12, sort: "desc" })
            }
          />
        </Center>
      </Hidden>
      <Hidden hide={data?.page}>
        <InformeParametrosBuscaProspeccaoPanel />
      </Hidden>
      <Hidden hide={!data?.page?.empty}>
        <BuscaProspeccaoNadaEncontrado />
      </Hidden>
    </PageLayout >
  );
};
