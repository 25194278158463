import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { ExemplarAtivoDataGrid } from "partials/datagrids/ExemplarAtivo.datagrid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import Lightbox from "yet-another-react-lightbox";

export const AtivosLocadosListPage = () => {

  const authority = SecurityProvider.getAuthority();
  const location = useLocation();
  const { t } = useTranslation();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { fetchSingle, update, remove, fetchPage, create } = useRemoteOperation(
    { reducer: reducer },
  );
  const { data, status } = reducer.state;
  const action = new URLSearchParams(useLocation().search).get("action");

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isDeleted)
      notifications.show({
        message: "Ativo removido com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Ativo cadastrado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Ativo atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.ativoitens}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.ativoitens}/locados`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.ativoitens}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const editInfo = (id: any) => {
    findItem(id);
    drawerForm.open("editarAtivo");
  };

  const [modalState, setModalState] = useState({ images: [], open: false });

  const onImageView = (images: any) => {
    const image = images.map((item: any) => { return { src: item.imageSourceSet } })
    setModalState({ images: image, open: true });
  }

  return (
    <PageLayout
      title="Ativos em locação"
    >
      <Lightbox
        open={modalState.open}
        close={() => setModalState({ images: [], open: false })}
        slides={modalState.images}
      />
      <ExemplarAtivoDataGrid
        reducer={reducer}
        onPaginationChange={onPaginationChange}
        flags={["view"]}
        onImageView={onImageView}
      />
    </PageLayout >
  );
};

/*
                <Tooltip label="Cadastrar outro ativo semelhante a este">
                                        <ActionIcon color="blue" onClick={() => clonarAtivo(id)}>
                                            <IconCopy size={26} />
                                        </ActionIcon>
                            </Tooltip>
*/