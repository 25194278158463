import {
  Avatar,
  Box,
  Burger,
  Drawer,
  Group,
  Header,
  Menu,
  Text
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconDotsVertical,
  IconLogout,
  IconUserCog
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import logo from "../../../assets/images/logoipsum-297.svg";
import { NavBarMobile } from "../navbar/Menu";
import { useStyles } from "./HeaderBar.style";
import { Hidden } from "../Hidden";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";

export function HeaderMegaMenu({ mobileHeaderActions }: any) {

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });

  useEffect(() => {
    const authority = SecurityProvider.getAuthority();
    reducer.dispatch({ type: "INITIATING" });
    if (authority === "STAKEHOLDER") {
      findCurrentStakeholder();
    }
  }, [location]);

  const stakeholder = reducer.state?.data?.object;

  const findCurrentStakeholder = () => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.stakeholders}/me`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  }

  const logout = () => {
    SecurityProvider.deauthenticate();
    navigate("/login");
  };

  return (
    <>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <Group
            sx={{ height: "100%" }}
            spacing={0}
            className={classes.hiddenMobile}
          >
            <img style={{ height: "30px" }} src={logo} id="logo-desktop" />
          </Group>
          <Group className={classes.hiddenMobile}>
            <Group ml={5} spacing={5} className={classes.links}>
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Group style={{ cursor: 'pointer' }} mx="md">
                    <Avatar radius="xl" />
                    <div style={{ flex: 1 }}>
                      <Text size="sm" weight={500}>
                        {stakeholder?.nome}
                      </Text>
                      <Text color="dimmed" size="xs">
                        {stakeholder?.matricula}
                      </Text>
                    </div>

                  </Group>
                </Menu.Target>
                <Menu.Dropdown>
                  {/*<Menu.Item icon={<IconUserCog size={21} color={theme.colors.dark[1]} />}>
                    <Text color={theme.colors.dark[6]}>Minha Conta</Text>
                  </Menu.Item>
                  <Menu.Divider />*/}
                  <Menu.Item icon={<IconLogout size={21} />} color={theme.colors.dark[1]} onClick={() => logout()}>
                    <Text color={theme.colors.dark[6]}>Logout</Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </Group>
          <>
            <>
              <Burger
                opened={drawerOpened}
                onClick={toggleDrawer}
                className={classes.hiddenDesktop}
              />
              <img className={classes.hiddenDesktop} style={{ height: "30px" }} src={logo} id="logo-mobile" />
            </>
            <Hidden hide={!mobileHeaderActions}>
              <Box className={classes.hiddenDesktop}>
                <Group>
                  <Group ml={5} spacing={5}>
                    <Menu shadow="md" width={200}>
                      <Menu.Target>
                        <IconDotsVertical />
                      </Menu.Target>
                      <Menu.Dropdown>
                        {mobileHeaderActions}
                      </Menu.Dropdown>
                    </Menu>
                  </Group>
                </Group>
              </Box>
            </Hidden>
          </>
        </Group>
      </Header>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        title={<img style={{ height: "30px" }} src={logo} id="logo-mobile-navbar" />}
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <NavBarMobile />
      </Drawer>
    </>
  );
}
