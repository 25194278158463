import {
  AppShell,
  createStyles
} from "@mantine/core";
import { HeaderMegaMenu } from "../header/HeaderBar";
import { HeaderCustom } from "./HeaderCustom";
import { DoubleNavbar } from "../navbar/Menu";
import { useState } from "react";

export const PageLayout = ({ children, headerActions, title, header, mobileHeaderActions }: any) => {

  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      padding="md"
      fixed
      navbarOffsetBreakpoint="sm"
      header={<HeaderMegaMenu mobileHeaderActions={mobileHeaderActions} />}
      navbar={<DoubleNavbar opened={opened} />}
      styles={(theme) => ({
        main: {
          height: "100%",
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      {header && <>{header}</>}
      {!header && <HeaderCustom title={title}>{headerActions}</HeaderCustom>}
      {children}
    </AppShell>
  );
};
