import { ActionIcon, Badge, Group, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconEdit,
  IconEye,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const ContratoPage = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchSingle, update } = useRemoteOperation({ reducer: reducer });
  const { remove, fetchPage } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (
      status?.isUpdated ||
      status?.isSubmitted ||
      status?.isDeleted ||
      status?.isDeleted
    )
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
  }, [status, location?.pathname]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.contratos}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.contratos}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.contratos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const onUpdate = (entity: any) =>
    update({
      endpoint: `${RemoteEndpoints.contratos}/${entity.id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: data,
    });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.contratos}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const showInfo = (company: any) =>
    notifications.show({ message: "Não implementado", color: "red" });

  const editInfo = (company: any) =>
    notifications.show({ message: "Não implementado", color: "red" });

  const deleteCompany = (company: any) =>
    notifications.show({ message: "Não implementado", color: "red" });

  return (
    <PageLayout title="Contratos">
      <DataGridComponent
        fetching={false}
        visible={true}
        onPaginationChange={() => onPaginationChange({ page: 0, size: 10, sort: "desc" })}
        noRecordText="Nenhum contrato encontrado"
        state={reducer.state}
        columns={[
          { accessor: "cnpj", title: "CNPJ", sortable: true },
          { accessor: "nome", title: "Nome" },
          { accessor: "cidade", title: "Cidade" },
          {
            accessor: "modo",
            title: "Modo",
            render: ({ modo }: any) => <Badge>{ /*  FIXME */ }{modo}</Badge>,
          },
          {
            accessor: "actions",
            title: <Text mr="xs">Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <a href={`/contratos/${id}`}>
                  <ActionIcon color="gray">
                    <IconEye size={26} />
                  </ActionIcon>
                </a>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
