import { PaginationProps } from "@mantine/core";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { OrcamentoItemDataGrid } from "partials/datagrids/OrcamentoItem.datagrid";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PageLayout } from "shared/custom/page/PageLayout";


export const ProspeccaoItemViewPage = () => {

    const params = useParams<any>();
    const prospeccaoID = params.id;
    const prospeccaoItemID = params.idItem;
    const location = useLocation();

    const reducerProspeccaoItemOrcamento = useRemoteDataReducer();
    const prospeccaoItemOrcamentoOperations = useRemoteOperation({
        reducer: reducerProspeccaoItemOrcamento
    });

    const reducerItensProspeccao = useRemoteDataReducer();
    const prospeccaoItemOperations = useRemoteOperation(
        { reducer: reducerItensProspeccao }
    );

    const { data, status } = reducerItensProspeccao.state;

    useEffect(() => {
        if (prospeccaoID && !data?.object) {
            reducerItensProspeccao.dispatch({ type: "INITIATING" });
            reducerProspeccaoItemOrcamento.dispatch({ type: "INITIATING" });
        }
    }, [location]);

    useEffect(() => {
        if (status?.isInitiating) {
            findProspeccaoItem(prospeccaoID, prospeccaoItemID);
            findProspeccaoItemOrcamentos(prospeccaoID, prospeccaoItemID);
        }
    }, [status]);

    const findProspeccaoItem = (id: any, idItem: any) => {
        prospeccaoItemOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/itens/${idItem}`,
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccaoItemOrcamentos = (id: any, idItem: any) => {
        prospeccaoItemOrcamentoOperations.fetchPage({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/orcamentos/item/${idItem}`,
            headers: RequestConfigGenerator.build(),
        });
    };

    const onPaginationChange = (id: any, pagination: any) => {
        prospeccaoItemOrcamentoOperations.fetchPage({
          endpoint: `${RemoteEndpoints.ativo}/${id}/itens`,
          requestParameters: {
            page: pagination.page,
            size: pagination.size,
            sort: pagination.sort || "desc",
          },
          headers: RequestConfigGenerator.build(),
        });
      };

    return (
        <PageLayout
            title={`Item: ${data?.object?.descricao || ""}`}
        >
            <OrcamentoItemDataGrid
                reducer={reducerProspeccaoItemOrcamento}
                onPaginationChange={onPaginationChange}
                flags={["view"]}
            />
        </PageLayout>
    );

}