import {
  Paper,
  TextInput,
  PasswordInput,
  Checkbox,
  Button,
  Title,
  Text,
  Anchor,
  Group,
  PinInput,
  Input,
  Badge,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useTokenLogin } from "hooks/useTokenLogin";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { useStyles } from "./Login.style";

export const LoginPage = () => {

  const { classes } = useStyles();
  const navigate = useNavigate();
  const reducer = useRemoteDataReducer();
  const loginHook = useTokenLogin({ reducer: reducer });

  const form = useForm({
    initialValues: {
      username: "",
      password: "",
      mfa_code: "",
      keep_logged_in: false,
    },
    validate: {
      username: (value) => (/^\S+@\S+$/.test(value) ? null : "Email inválido"),
      password: (value) => (value.length > 4 ? null : "Informe uma senha"),
      mfa_code: (value) =>
        value.length > 5 ? null : "Informe um código de autenticação",
    },
  });

  const { data, status } = reducer.state;

  const onSubmit = async (data: any) => loginHook.execute(data);

  const onError = (errors: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário!",
      color: "orange",
    });
  };

  useEffect(() => {
    if (data?.object){ 
      navigate("/dashboard"); 
    } else if (reducer.state.status?.isFailed) {
      notifications.show({
        message:
          reducer.state.data?.error?.message ||
          "Não foi possível logar no momento...",
        color: "red",
      });
    }
  }, [reducer.state.status]);

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.form} radius={0} p={30}>
        <Title order={2} className={classes.title} ta="center" mt="md" mb={50}>
          Bem-vindo ao RedD
          {process.env.REACT_APP_PROFILE !== "PROD" && (
            <Badge ml="sm">{process.env.REACT_APP_PROFILE}</Badge>
          )}
        </Title>
        <form onSubmit={form.onSubmit((a) => onSubmit(a), onError)}>
          <TextInput
            label="Email"
            placeholder="hello@gmail.com"
            size="md"
            {...form.getInputProps("username")}
          />
          <PasswordInput
            label="Senha"
            placeholder="**********"
            mt="md"
            size="md"
            {...form.getInputProps("password")}
          />
          <Input.Wrapper mt="md" label="Token de segurança">
            <Group position="center">
              <PinInput
                length={6}
                mt="sm"
                {...form.getInputProps("mfa_code")}
              />
            </Group>
          </Input.Wrapper>
          <Checkbox
            label="Manter logado"
            mt="xl"
            size="md"
            {...form.getInputProps("keep_logged_in")}
            checked
          />
          <Button
            fullWidth
            mt="xl"
            size="md"
            type="submit"
            disabled={!form.isValid()}
            loading={reducer.state.status?.isSubmitting}
          >
            Login
          </Button>
        </form>
        <Text ta="center" mt="md">
          Problemas para logar?{" "}
          <Anchor<"a"> href="/recover" weight={700}>
            Recupere sua senha
          </Anchor>
        </Text>
      </Paper>
    </div>
  );
};
