import { Grid, Paper, SimpleGrid, Text, createStyles } from "@mantine/core"
import { IconBasket, IconFileInvoice, IconTrophy, IconBox, IconBuilding, IconPaperclip, IconFileCheck, IconFileUpload, IconUserShield, IconKey } from "@tabler/icons-react"
import { EventosProcessosDashboardPanel } from "partials/panels/dashboard/EventosProcessosDashboard.panel"
import { SolicitacoesDashboardPanel } from "partials/panels/dashboard/SolicitacoesDashoard.panel"
import { AccessButton } from "../../component/AccessButton.component"
import { FastAccessButton } from "../../component/FastAccessButton.component"
import { DoughnutChartComponent } from "shared/components/DoughnutChart"
import { LineChartComponent } from "shared/components/LineChart.component"

export const DashboardAdministradorPanel = () => {

    const useStyles = createStyles((theme) => ({
        title: {
            fontWeight: 700,
            textTransform: "uppercase",
        },
    }));

    const { classes } = useStyles();

    return (
        <>
            <SimpleGrid
                cols={2}
                breakpoints={[
                    { maxWidth: 600, cols: 1, spacing: 'sm' }
                ]}
            >
                <Paper withBorder p="md">
                    <Text size="xs" color="dimmed" className={classes.title}>
                        Cadastros de Empresas
                    </Text>
                    <LineChartComponent />
                </Paper>
                <Paper withBorder p="md">
                    <Text size="xs" color="dimmed" className={classes.title}>
                        Composição dos cadastros
                    </Text>
                    <DoughnutChartComponent />
                </Paper>
            </SimpleGrid>
            <Text size="xl" weight={700} style={{ marginBottom: "1rem" }}>
                Acesso Rápido
            </Text>
            <SimpleGrid
                cols={5}
                breakpoints={[
                    { maxWidth: 980, cols: 4, spacing: 'md' },
                    { maxWidth: 755, cols: 3, spacing: 'sm' },
                    { maxWidth: 600, cols: 2, spacing: 'sm' }
                ]}
            >

                <FastAccessButton
                    href="/empresas?action=cadastrar-empresa"
                    icon={<IconBuilding size={32} color="gray" />}
                    label="Cadastrar nova empresa"
                />
                <FastAccessButton
                    href="/administradores?action=cadastrar-administrador"
                    icon={<IconUserShield size={32} color="gray" />}
                    label="Cadastrar novo administrador"
                />
                <FastAccessButton
                    href="/permission?action=cadastrar-permissao"
                    icon={<IconKey size={32} color="gray" />}
                    label="Cadastrar nova permissão"
                />
            </SimpleGrid>
            <Paper withBorder p="md" mb="md">
                {/*<FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    events={[
                        { title: 'event 1', date: '2023-04-01' },
                        { title: 'event 2', date: '2023-04-02' }
                    ]}
                    height={300}
                    viewHeight={300}
                />*/}
            </Paper>
        </>
    )

}