//import { FormMode } from "components/props/FormProps";
import React, { useEffect, useReducer } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRemoteOperation } from "./useRemoteOperation";
import { State } from "./Model";
import { reducer, initialState } from "./useRemoteDataReducer";
import { RequestConfigGenerator } from "hooks";

export function useCRUDOperations({ reducer }: any) {
  const location = useLocation();
  const { remove, fetchPage, fetchSingle, create, update } = useRemoteOperation(
    { reducer: reducer },
  );
  const { status } = reducer.state;

  useEffect(() => {
    if (!status.isInitiating) {
      reducer.dispatch({ type: "INITIATING" });
    }
  }, [location?.pathname]);

  const deleteItem = (endpoint: string, id: number | string) =>
    remove({
      endpoint: endpoint,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const getPaginatedResults = (endpoint: string, { page, size, sort }: any) =>
    fetchPage({
      endpoint: endpoint,
      properties: { reducer: reducer },
      //...onPaginationChange(page, size, sort),
      headers: RequestConfigGenerator.build(),
    });

  const submit = (endpoint: string, data: any, update_mode: boolean) => {
    if (update_mode) {
      update({
        endpoint: endpoint,
        properties: { reducer: reducer },
        headers: RequestConfigGenerator.build(),
        data: data,
      });
    } else {
      create({
        endpoint: endpoint,
        properties: { reducer: reducer },
        headers: RequestConfigGenerator.build(),
        data: data,
      });
    }
  };

  const find = (endpoint: string, id: number | string) =>
    fetchSingle({
      endpoint: endpoint,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const search = (endpoint: string, searchParameters: object) =>
    fetchSingle({
      endpoint: endpoint,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  return [search, find, submit, deleteItem, getPaginatedResults];
}

export const useRemoteDataReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return { state: state as State<any>, dispatch: dispatch };
};
