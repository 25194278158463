import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";

export const useCadastroPagamentoProcesso = (properties: any) => {

  const execute = async (
    processoID: any,
    data: any
  ) => {

    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });
    delete data.id;

    const createResult = await RemoteClient.create(
      `${RemoteEndpoints.processo}/${processoID}/pagamentos`,
      data,
      RequestConfigGenerator.build(),
    );

    if (createResult.data) {
      properties.reducer.dispatch({
        type: "SUBMIT_SUCCESS",
        payload: createResult.data?.pagamento,
      });
    } else {
      properties.reducer.dispatch({
        type: "ERROR",
        payload: createResult.error,
      });
    }
    
  };

  return { execute: execute };

};
