import { Button, Group, PasswordInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

export const RedefinirSenhaForm = ({ data, submiting, onSubmit }: any) => {
  const form = useForm({
    validateInputOnBlur: ["status", "valor"],
    initialValues: {
      id: data?.id,
      password: "",
      password_retype: "",
    },
    validate: {
      password: (value) => (value.length > 1 ? null : "Informe uma senha"),
      password_retype: (value) =>
        value !== "" ? null : "Digite a senha novamente",
    },
  });

  const _onSubmit = async (submitData: any) => {
    onSubmit(submitData);
  };

  const onFormError = (event: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  return (
    <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
      <PasswordInput
        mt="md"
        label="Nova Senha"
        {...form.getInputProps("password")}
        placeholder="******"
      />
      <PasswordInput
        mt="md"
        label="Confirmação Nova Senha"
        {...form.getInputProps("password_retype")}
        placeholder="******"
      />
      <Group position="apart" mt="lg">
        <Button variant="subtle" disabled={submiting}>
          Limpar
        </Button>
        <Button type="submit" loading={submiting}>
          Confirmar
        </Button>
      </Group>
    </form>
  );
};
