import { Group, Box, Divider, Badge, Image, ActionIcon, Tooltip, Text, SimpleGrid } from "@mantine/core"
import { notifications } from "@mantine/notifications";
import { IconExternalLink, IconEye, IconSignature, IconUpload, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { useUploadAssinaturaContrato } from "hooks/uploads/useUploadAssinaturaContrato";
import { useUploadContratoSolicitacao } from "hooks/uploads/useUploadContratoSolicitacao";
import { Contrato } from "models/Contrato.model";
import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { ContratoVisualizacaoAssinaturaUploadPanel } from "./uploads/ContratoVisualizacaoAssinaturaUpload.panel";
import { CustomModal } from "shared/custom/CustomFullModal";
import { ContratoForm } from "partials/forms/Contrato.form";


export interface ContratoOperationProps {
    solicitacao: any;
    contrato?: Contrato;
    onAction: any;
}

export const ContratoOperationsPanel = ({ solicitacao, contrato, onAction }: ContratoOperationProps) => {

    const reducer = useRemoteDataReducer();
    const { data, status } = reducer.state;
    const drawerForm = useDrawerForm({ reducer: reducer });
    const fileUpload = useUploadContratoSolicitacao({ reducer: reducer });
    const assinaturaUpload = useUploadAssinaturaContrato({ reducer: reducer })
    const { fetchSingle } = useRemoteOperation({
        reducer: reducer
    });

    useEffect(() => {
        if (status?.isSubmitted && ["DISPONIVEL", "ASSINADO_LOCADOR", "ASSINADO_LOCATARIO", "ASSINADO"].includes(data?.object?.status)) {
            onAction();
        }
    }, [status]);

    const abrirPainelAssinatura = (id: any) => {
        fetchSingle({
            endpoint: `${RemoteEndpoints.contrato}/${id}`,
            properties: { reducer: reducer },
            headers: RequestConfigGenerator.build(),
        });
        drawerForm.open("contratoAssinaturaModal");
    };

    const onUploadAssinatura = (id: any, file: File) => {
        assinaturaUpload.execute(id, file);
    };

    const onUploadContrato = (file: any) => {
        if (contrato) {
            fileUpload.execute(solicitacao.id, file);
        } else {
            notifications.show({
                message: "Houve um erro nesta página, por favor recarregue-a e tente novamente.",
                color: "teal",
                icon: <IconX />,
            });
        }
    };

    return (
        <Box>
            <CustomDrawer
                drawerFormState={drawerForm}
                tag="enviarNovoContrato"
                title="Enviar Novo Contrato"
            >
                <ContratoForm
                    reducer={reducer}
                    onUploadContrato={(file: any) => onUploadContrato(file)}
                />
            </CustomDrawer>
            <CustomModal
                modalFormState={drawerForm}
                tag="contratoAssinaturaModal"
                title={"Contrato #" + data?.object?.numeroContrato}
                full
            >
                <ContratoVisualizacaoAssinaturaUploadPanel
                    status={reducer.state.status}
                    contrato={reducer.state?.data?.object}
                    onUploadAssinatura={onUploadAssinatura}
                />
            </CustomModal>
            <Group position="apart" noWrap p="md">
                <SimpleGrid cols={5} breakpoints={[{ maxWidth: "sm", cols: 2 }, { maxWidth: "md", cols: 3 }]}>
                    <Group>
                        <Divider size="md" orientation="vertical" />
                        <Box>
                            <Text size="xs" fw={500} color="gray">Número do Contrato</Text>
                            <Text>{contrato?.numeroContrato}</Text>
                        </Box>
                    </Group>
                    <Group>
                        <Divider size="md" orientation="vertical" />
                        <Box>
                            <Text size="xs" fw={500} color="gray">Status</Text>
                            <Badge color="blue">{contrato?.status}</Badge>
                        </Box>
                    </Group>
                    <Group>
                        <Divider size="md" orientation="vertical" />
                        <Box>
                            <Text size="xs" fw={500} color="gray">Fonte</Text>
                            <Badge color="blue">{contrato?.fonte}</Badge>
                        </Box>
                    </Group>
                    <Group>
                        <Divider size="md" orientation="vertical" />
                        <Box>
                            <Text size="xs" fw={500} color="gray">Localização</Text>
                            <Badge color="blue">{contrato?.localizacao}</Badge>
                        </Box>
                    </Group>
                    <Group>
                        <Divider size="md" orientation="vertical" />
                        <Box>
                            <Text size="xs" fw={500} color="gray">Data Limite da Assinatura</Text>
                            <Text size="sm">{moment(contrato?.dataVencimento).format("DD/MM/YYYY")}</Text>
                        </Box>
                    </Group>
                </SimpleGrid>
                <Group spacing={12} position="right" noWrap>
                    <Hidden hide={contrato?.status !== "ENVIO_PENDENTE"}>
                        <Tooltip label="Enviar Documento de Contrato">
                            <ActionIcon color="blue" onClick={() => drawerForm.open("enviarNovoContrato")}>
                                <IconUpload size={26} />
                            </ActionIcon>
                        </Tooltip>
                    </Hidden>
                    <Tooltip label="Visualizar e/ou Assinar">
                        <ActionIcon
                            color="blue"
                            onClick={() => abrirPainelAssinatura(contrato?.id)}
                        >
                            <IconSignature size={26} />
                        </ActionIcon>
                    </Tooltip>

                </Group>
            </Group>
            <Group grow>
                <Hidden hide={contrato?.status !== "ASSINADO_LOCADOR" && contrato?.status !== "ASSINADO_LOCATARIO"}>
                    <Box my="sm">
                        <Divider label="Contratante" />
                        <Hidden hide={!contrato?.comprovanteAssinaturaLocatario?.url}>
                            <Image height={200} fit="contain" src={contrato?.comprovanteAssinaturaLocatario?.url} placeholder="Assinatura indisponível" />
                        </Hidden>
                        <Hidden hide={contrato?.comprovanteAssinaturaLocatario?.url}>
                            <Image height={120} src="#" withPlaceholder placeholder="Assinatura pendente" />
                        </Hidden>
                    </Box>
                    <Box my="sm">
                        <Divider label="Contratado" />
                        <Hidden hide={!contrato?.comprovanteAssinaturaLocador?.url}>
                            <Image height={200} fit="contain" src={contrato?.comprovanteAssinaturaLocador?.url} placeholder="Assinatura indisponível" />
                        </Hidden>
                        <Hidden hide={contrato?.comprovanteAssinaturaLocador?.url}>
                            <Image height={120} src="#" withPlaceholder placeholder="Assinatura pendente" />
                        </Hidden>
                    </Box>
                </Hidden>
            </Group>
        </Box >
    )

}