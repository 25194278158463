import {
  ActionIcon,
  Group,
  Text,
  Tooltip
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconCircleCheck,
  IconCirclePlus,
  IconCircleX,
  IconEye,
  IconMailForward,
  IconMailShare,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import moment from "moment";
import { EmpresaForm } from "partials/forms/Empresa.form";
import { PreCadastroEmpresaView } from "partials/views/PreCadastroEmpresa.view";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const PreCadastroOrganizacaoListPage = () => {
  const reducerCadastroEmpresa = useRemoteDataReducer();
  const operationCadastroEmpresa = useRemoteOperation({
    reducer: reducerCadastroEmpresa,
  });

  const [dadosIniciais, setDadosIniciais] = useState<any>({});
  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { fetchPage, fetchSingle, update } = useRemoteOperation({
    reducer: reducer,
  });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (
      status?.isUpdated ||
      status?.isSubmitted ||
      status?.isDeleted ||
      status?.isDeleted
    )
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
  }, [status, location?.pathname]);

  useEffect(() => {
    if (reducerCadastroEmpresa.state.status?.isSubmitted) {
      onUpdate({ id: dadosIniciais.id, status: "FINALIZADO" });
      drawerForm.close();
    }
  }, [reducerCadastroEmpresa.state.status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.preCadastoEmpresas}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const onUpdate = (entity: any) =>
    update({
      endpoint: `${RemoteEndpoints.preCadastoEmpresa}/${entity.id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.preCadastoEmpresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const deny = (data: any) => { 
    delete data.modificationTime;
    onUpdate({ ...data, status: "NEGADO" });
  }

  const attend = (data: any) => {
    delete data.modificationTime;
    onUpdate({ ...data, status: "ATENDIDO" });
  }

  const approve = (data: any) => {
    delete data.modificationTime;
    onUpdate({ ...data, status: "APROVADO" });
  }

  const view = (entity: any) => {
    findItem(entity.id);
    drawerForm.open("preCadastroDrawer");
  };

  const open = (data: any) => {
    reducerCadastroEmpresa.dispatch({
      type: "SUCCESS_SINGLE",
      payload: {
        id: data.id,
        modo: data.papel,
        cnpj: data.cnpj,
        contato: { email: data.email, telefone: data.telefone },
      }
    })
    drawerForm.open("cadastroEmpresa");
  };

  const onCreate = (entity: any) => {
    operationCadastroEmpresa.create({
      endpoint: RemoteEndpoints.empresas,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  return (
    <PageLayout title="Pré-Cadastros de Empresas">
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="preCadastroDrawer"
        title="Visualizar Pré-Cadastro Empresa"
      >
        <PreCadastroEmpresaView precadastro={reducer.state.data?.object} />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastroEmpresa"
        title=" Cadastrar Empresa"
      >
        <EmpresaForm
          reducer={reducerCadastroEmpresa}
          precadastroMode
          onSubmit={onCreate}
        />
      </CustomDrawer>
      <DataGridComponent
        state={reducer.state}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhum pré-cadastro de empresa encontrada"
        columns={[
          { accessor: "cnpj", title: "CNPJ" },
          {
            accessor: "papel", title: "Modo",
            render: ({ papel }: any) =>
              <CustomBadge value={papel} options={[
                { value: "LOCADOR", text: "Locador", color: "blue" },
                { value: "LOCATARIO", text: "Locatário", color: "blue" },
                { value: "AMBOS", text: "Ambos", color: "blue" }
              ]} />
          },
          { accessor: "email", title: "Email" },
          {
            accessor: "status",
            title: "Status",
            render: ({ status }: any) =>
              <CustomBadge value={status} options={[
                { value: "APROVADO", text: "Aprovado", color: "teal" },
                { value: "ATENDIDO", text: "Atendido", color: "blue" },
                { value: "NEGADO", text: "Negado", color: "red" }
              ]} />
          },
          {
            accessor: "criacao",
            title: "Criação",
            render: ({ creationTime }: any) => (
              <Text size="xs" color="dimmed">
                {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "atualizacao",
            title: "Atualização",
            render: ({ modificationTime }: any) => (
              <Text size="xs" color="dimmed">
                {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (data: any) => (
              <Group spacing={12} position="right" noWrap>
                <Tooltip label="Visualizar">
                  <ActionIcon color="gray" onClick={() => view(data)}>
                    <IconEye size={26} />
                  </ActionIcon>
                </Tooltip>
                <Hidden hide={data.status !== "DOCUMENTADO"}>
                  <Tooltip label="Negar">
                    <ActionIcon color="gray" onClick={() => deny(data)}>
                      <IconCircleX size={26} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Aprovar">
                    <ActionIcon color="gray" onClick={() => approve(data)}>
                      <IconCircleCheck size={26} />
                    </ActionIcon>
                  </Tooltip>
                </Hidden>
                <Hidden hide={data.status !== "CADASTRADO"}>
                  <Tooltip label="Atender">
                    <ActionIcon color="gray" onClick={() => attend(data)}>
                      <IconMailForward size={26} />
                    </ActionIcon>
                  </Tooltip>
                </Hidden>
                <Hidden hide={data.status !== "APROVADO"}>
                  <ActionIcon color="gray" onClick={() => open(data)}>
                    <IconCirclePlus size={26} />
                  </ActionIcon>
                </Hidden>
                <Hidden hide={data.status !== "ATENDIDO"}>
                  <Tooltip label="Enviar novo email para lembrete">
                    <ActionIcon color="gray" onClick={() => attend(data)}>
                      <IconMailShare size={26} />
                    </ActionIcon>
                  </Tooltip>
                </Hidden>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
