import {
  Button,
  Group
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { AtivoDataGrid } from "partials/datagrids/Ativo.datagrid";
import { AtivoForm } from "partials/forms/Ativo.form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const AtivoPage = () => {

  const authority = SecurityProvider.getAuthority();
  const location = useLocation();
  const { t } = useTranslation();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { fetchSingle, update, remove, fetchPage, create } = useRemoteOperation(
    { reducer: reducer },
  );
  const { data, status } = reducer.state;
  const action = new URLSearchParams(useLocation().search).get("action");

  useEffect(() => {
    if (action === "cadastrar-ativo")
      drawerForm.open("cadastrarAtivo")
  }, [action]);

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating)
      onPaginationChange({ page: 0, size: 10, sort: "numeroAtivo,desc" });
    else if (status?.isDeleted)
      notifications.show({
        message: "Ativo removido com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Ativo cadastrado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Ativo atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.ativo}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.ativos}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.ativos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "numeroAtivo,desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const onUpdate = (entity: any, id: any) =>
    update({
      endpoint: `${RemoteEndpoints.ativo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.ativo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onCreate = (entity: any) =>
    create({
      endpoint: RemoteEndpoints.ativos,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });

  const editInfo = (id: any) => {
    findItem(id);
    drawerForm.open("editarAtivo");
  };
  
  return (
    <PageLayout
      title="Portifólio de Ativos"
      headerActions={
        <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
          <Group position="center">
            <Button onClick={() => drawerForm.open("cadastrarAtivo")}>Cadastrar Ativo</Button>
          </Group>
        </Hidden>
      }
    >
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastrarAtivo"
        title="Cadastrar Ativo"
      >
        <AtivoForm
          reducer={reducer}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarAtivo"
        title="Editar Ativo"
      >
        <AtivoForm
          reducer={reducer}
          editMode={true}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
        />
      </CustomDrawer>
      <AtivoDataGrid
        reducer={reducer}
        onPaginationChange={onPaginationChange}
        flags={["view"]}
        onEdit={editInfo}
      />
    </PageLayout >
  );
};
