import {
  Box,
  Button,
  Card,
  Drawer,
  Modal,
  Timeline,
  Text,
  Group,
  Paper,
  Divider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ProcessoPanel } from "partials/views/Processo.view";
import { useState } from "react";
import { PageLayout } from "shared/custom/page/PageLayout";
import "../../styles/styles.css";
import {
  IconGitBranch,
  IconGitCommit,
  IconGitPullRequest,
  IconMessageDots,
} from "@tabler/icons-react";

export const VisualizarContratoPage = () => {
  const drawer = useDisclosure(false);
  const modal = useDisclosure(false);

  const [active, setActive] = useState(1);
  const nextStep = () =>
    setActive((current: any) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current: any) => (current > 0 ? current - 1 : current));

  const ativos = [
    { descricao: "Furadeira de Impacto", marca: "Bosch" },
    { descricao: "Caminhão Baú", marca: "Mercedes" },
  ];

  return (
    <PageLayout title="Contrato #1234">
      <Drawer
        opened={drawer[0]}
        onClose={drawer[1].close}
        position="right"
        size="xl"
        title={
          <Text fz="xl" fw={500}>
            Iniciar Processo
          </Text>
        }
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Divider />
      </Drawer>
      <Modal
        opened={modal[0]}
        onClose={modal[1].close}
        title="Visualizar Contrato"
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <iframe
          frameBorder="0"
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
          height="100%"
          width="100%"
          className="iframe"
          src="https://www.habitusbrasil.com/wp-content/uploads/2016/03/habitus-modelo3-contrato-marcenaria.pdf"
        ></iframe>
      </Modal>

      <Group>
        <Paper withBorder p="md">
          <Timeline active={1} bulletSize={24} lineWidth={2}>
            <Timeline.Item
              bullet={<IconGitBranch size={12} />}
              title="Contrato Assinado"
            >
              <Text color="dimmed" size="sm">
                Assinatura do contrato pelas partes
              </Text>
              <Text size="xs" mt={4}>
                2 hours ago
              </Text>
            </Timeline.Item>

            <Timeline.Item
              bullet={<IconGitCommit size={12} />}
              title="Envio dos Ativos"
            >
              <Text color="dimmed" size="sm">
                Envio dos ativos pelo locador
              </Text>
              <Text size="xs" mt={4}>
                52 minutes ago
              </Text>
            </Timeline.Item>

            <Timeline.Item
              title="Inspeção Programada"
              bullet={<IconGitPullRequest size={12} />}
              lineVariant="dashed"
            >
              <Text color="dimmed" size="sm">
                Inspeção solicitada pelo locador via contrato
              </Text>
              <Text size="xs" mt={4}>
                Em 05/07/2023
              </Text>
            </Timeline.Item>

            <Timeline.Item
              title="Devolução dos ativos"
              bullet={<IconGitPullRequest size={12} />}
              lineVariant="dashed"
            >
              <Text color="dimmed" size="sm">
                Retorno dos ativo para o locador
              </Text>
              <Text size="xs" mt={4}>
                Em 30/10/2024
              </Text>
            </Timeline.Item>

            <Timeline.Item
              title="Encerramento do Contrato"
              bullet={<IconMessageDots size={12} />}
            >
              <Text color="dimmed" size="sm">
                Finalização prevista pelo contrato
              </Text>
              <Text size="xs" mt={4}>
                Em 31/12/2023
              </Text>
            </Timeline.Item>
          </Timeline>
        </Paper>
        <Box>
          <Box mt="md">
            <ProcessoPanel />
          </Box>
          <Button onClick={modal[1].open}>Exibir Contrato</Button>
          <Paper withBorder p="md">
            eae
          </Paper>
        </Box>
      </Group>
    </PageLayout>
  );
};
