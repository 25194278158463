import { notifications } from "@mantine/notifications";
import {
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { OrcamentoDataGrid } from "partials/datagrids/Orcamento.datagrid";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";

export const OrcamentoListPage = () => {

  const location = useLocation();
  const { t } = useTranslation();
  const reducer = useRemoteDataReducer();
  const { fetchPage } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;
 
  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating)
      onPaginationChange({ page: 0, size: 10, sort: "numeroOrcamento,desc" });
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.orcamentos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "numeroOrcamento,desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <PageLayout
      title="Orçamentos"
    >
      <OrcamentoDataGrid
        reducer={reducer}
        onPaginationChange={onPaginationChange}
        flags={["view"]}
      />
    </PageLayout >
  );

};

