import {
  Button,
  Group,
  Title
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { useUploadAssinaturaContrato } from "hooks/uploads/useUploadAssinaturaContrato";
import { useUploadContratoProcesso } from "hooks/uploads/useUploadContratoProcesso";
import { ContratoDataGrid } from "partials/datagrids/Contrato.datagrid";
import { useEffect } from "react";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { ContratoVisualizacaoAssinaturaUploadPanel } from "partials/panels/uploads/ContratoVisualizacaoAssinaturaUpload.panel";
import { CustomModal } from "shared/custom/CustomFullModal";
import { ContratoForm } from "partials/forms/Contrato.form";

export const ProcessoContratosTab = ({ processoID, locadorID }: any) => {

  const authority = SecurityProvider.getAuthority();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer });
  const assinaturaUpload = useUploadAssinaturaContrato({ reducer: reducer });
  const { fetchPage, fetchSingle, create } = useRemoteOperation({
    reducer: reducer,
  });
  const fileUpload = useUploadContratoProcesso({ reducer: reducer });
  const { data, status } = reducer.state;

  const onPaginationChange = (id: any, pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.processo}/${id}/contratos`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  useEffect(() => {
    if (processoID && !data?.object) {
      reducer.dispatch({ type: "INITIATING" });
    }
  }, [processoID]);

  useEffect(() => {
    if (status?.isInitiating) {
      onPaginationChange(processoID, { page: 0, size: 10, sort: "desc" });
    } else if (status?.isSubmitted) {
      notifications.show({ message: "Enviado com sucesso!" });
      close();
    }
  }, [status]);

  const onUploadContrato = (file: any) => fileUpload.execute(processoID, file);

  const abrirPainelAssinatura = (id: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.contrato}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
    drawerForm.open("contratoAssinaturaModal");
  };

  const onUploadAssinatura = (id: any, file: File) => assinaturaUpload.execute(id, file)

  return (
    <>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="enviarNovoContrato"
        title="Enviar contrato"
      >
        <ContratoForm
          reducer={reducer}
          onUploadContrato={(file: any) => onUploadContrato(file)}
        />
      </CustomDrawer>
      <CustomModal
        modalFormState={drawerForm}
        tag="contratoAssinaturaModal"
        title={"Contrato #" + data?.object?.numeroContrato}
        full
      >
        <ContratoVisualizacaoAssinaturaUploadPanel
          status={reducer.state.status}
          contrato={reducer.state?.data?.object}
          onUploadAssinatura={onUploadAssinatura}
        />
      </CustomModal>
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Contratos</Title>
        <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
          <Button onClick={() => drawerForm.open("enviarNovoContrato")}>Enviar Contrato</Button>
        </Hidden>
      </Group>
      <ContratoDataGrid
        reducer={reducer}
        onSign={(id: any) => abrirPainelAssinatura(id)}
        onPaginationChange={(pagination: PaginationProps) =>
          onPaginationChange(processoID, {
            page: pagination.page,
            size: pagination.size,
            sort: "desc",
          })}
      />
    </>
  );
};
