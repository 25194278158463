import { ActionIcon, Badge } from "@mantine/core";
import { IconFileDollar } from "@tabler/icons-react";


export const CustomBadge = ({ value, size, options }: any) => {

    let result = options.filter((objeto: any) => objeto.value === value);
    if(result.length == 0){
        result = { text: value, color: "blue" }
    } else {
        result = result[0];
    }

    const icon = (
        <ActionIcon size={size || "xs"} color="blue" radius="xl" variant="transparent">
            {result.icon}
        </ActionIcon>
      );

    return (
        <> { result.icon ? <Badge leftSection={icon} color={result.color}>{result.text}</Badge> : <Badge color={result.color}>{result.text}</Badge> }</>
    )

}
