import { Avatar, Badge, Box, Group, Paper, SimpleGrid, Text } from "@mantine/core";
import moment from "moment";
import { InfoUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";


export const SolicitacaoOrcamentoPanel = ({ solicitacaoOrcamento }: any) => {

    return (
        <Paper withBorder p="md">
            <SimpleGrid
                cols={1}
                breakpoints={[
                    { minWidth: "xl", cols: 4 },
                    { minWidth: "lg", cols: 3 },
                    { minWidth: "md", cols: 2 },
                    { minWidth: "sm", cols: 2 },
                ]}
            >
                <Box>
                    <Text size="xs" fw={500}>Quantidade</Text>
                    <Text size="sm">{solicitacaoOrcamento?.quantidade} und.</Text>
                </Box>
                <Box>
                    <Text size="xs" fw={500}>Modo de Pagamento</Text>
                    <Badge size="xs" mr="xs">
                        {solicitacaoOrcamento?.modoPagamento}
                    </Badge>
                </Box>
                <Box>
                    <Text size="xs" fw={500}>Data de Retirada</Text>
                    <Text size="sm">{moment(solicitacaoOrcamento?.dataInicioLocacao).format("DD/MM/YYYY").toString()}</Text>
                </Box>
                <Box>
                    <Text size="xs" fw={500}>Data de Devolução</Text>
                    <Text size="sm">{moment(solicitacaoOrcamento?.dataFinalLocacao).format("DD/MM/YYYY").toString()}</Text>
                </Box>
            </SimpleGrid>
        </Paper>
    );

}