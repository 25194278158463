import {
  Button,
  Checkbox,
  Group,
  Input,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Text,
  TextInput,
  Textarea
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { NumberUtils, ObjectUtils } from "shared/Utils";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import { Hidden } from "shared/custom/Hidden";
import SecurityProvider from "shared/providers/security/SecurityProvider";
  
  export const AtivoItemForm = ({
    reducer,
    editMode,
    onSubmit,
  }: any) => {
  
    const { data, status } = reducer.state;
  
    const form = useForm({
      validateInputOnBlur: [
        "detalhes",
        "estado",
        "valorCompra",
        "batnaMin",
        "batnaMax",
        "valorLocacao",
      ],
      initialValues: {
        lote: false,
        qualidadeLote: "",
        numeroSeriePatrimonio: "",
        detalhes: "",
        estado: "",
        valorCompra: "",
        batnaMin: "",
        batnaMax: "",
        valorLocacao: "",
      },
      validate: {
        detalhes: (value) =>
          value !== "" ? null : "Informe detalhes do produto",
        estado: (value) => (value !== "" ? null : "Informe o estado do ativo"),
        valorCompra: (value) =>
          value !== "" ? null : "Informe o valor de compra do ativo",
        valorLocacao: (value) =>
          value !== "" ? null : "Informe o valor de locação",
      },
    });
  
    const _onSubmit = async (submitData: any) => {
      let cleanData = ObjectUtils.clone(submitData);
  
      cleanData.batnaMin = NumberUtils.valorToNumber(cleanData.batnaMin);
      cleanData.batnaMax = NumberUtils.valorToNumber(cleanData?.batnaMax);
      cleanData.valorLocacao = NumberUtils.valorToNumber(cleanData?.valorLocacao);
      cleanData.valorCompra = NumberUtils.valorToNumber(cleanData?.valorCompra);
  
      cleanData = ObjectUtils.cleanNulls(cleanData);
  
      if(editMode) {
        delete cleanData.id;
        delete cleanData.images;
        delete cleanData.emUso;
        delete cleanData.locatario;
        delete cleanData.creationTime;
        delete cleanData.modificationTime;
        delete cleanData.ativo;
      }
  
      onSubmit(cleanData, submitData?.id);
    };
  
    const onFormError = (event: any) => {
      notifications.show({
        message: "Há algum campo incorreto no formulário",
        color: "red",
      });
    };
  
    useEffect(() => {
      if(editMode && data?.object){
        form.setValues({ ...data?.object });
      }
    }, [data]);
  
    return (
      <>
        <LoadingOverlay visible={!!status?.isFetching} overlayBlur={2} />
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
          <Text fz="md" mt="md" fw={500}>
            Dados do Ativo
          </Text>
          <Text color="gray" size="xs">
            Estas informações devem ajudar a indentificar o item no seu estoque
          </Text>
          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Checkbox
              {...form.getInputProps("lote", { type: "checkbox" })}
              label="É um lote de itens para aluguel?"
              radius="xl"
            />
            <Hidden hide={!form.values.lote}>
              <TextInput
                {...form.getInputProps("qualidadeLote")}
                label="Quantidade de itens nesse lote"
                placeholder="123456"
              />
            </Hidden>
          </SimpleGrid>
          <TextInput
            {...form.getInputProps("numeroSeriePatrimonio")}
            mt="md"
            label="Número de série ou patromônio"
            placeholder="123456"
            description="Se houver, informe este dado, ele identificará cada unidade"
          />
          <Textarea
            {...form.getInputProps("detalhes")}
            placeholder="Ex: 127V, Falta uma broca"
            description="Ajude seu cliente a identificar melhor este ativo"
            label="Detalhes"
            mt="md"
          />
          <Select
            {...form.getInputProps("estado")}
            label="Estado do Ativo"
            placeholder="Estado do Ativo"
            mt="md"
            data={[
              { label: "Excelente", value: "EXCELENTE" },
              { label: "Bom", value: "BOM" },
              { label: "Razoável", value: "RAZOAVEL" },
              { label: "Ruim", value: "RUIM" },
            ]}
          />
          <Input.Wrapper
            mt="md"
            label="Valor do Item"
            description="Será incluído no contrato caso o item seja danificado"
            required
            error={form.errors?.valorCompra}
          >
            <ConcurrencyInput
              label="Valores financeiros"
              {...form.getInputProps("valorCompra")}
              placeholder="R$ 250,00"
            />
          </Input.Wrapper>
          <Input.Wrapper
            mt="md"
            label="Valor de locação do item"
            description="Valor cobrado do locatário"
            required
            error={form.errors?.valorLocacao}
          >
            <ConcurrencyInput
              label="Valores de locação"
              {...form.getInputProps("valorLocacao")}
              placeholder="R$ 250,00"
            />
          </Input.Wrapper>
          <Text fz="md" mt="md" fw={500}>
            Orçamento
          </Text>
          <Text color="gray" size="xs">
            Estas informações não são obrigatórias, mas agilizam e facilitam o
            sistema para encontrar as melhores possibilidades de negociação
          </Text>
          <SimpleGrid
            cols={2}
            mt="md"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Input.Wrapper label="Valor (mensal) Mínimo para locação">
              <ConcurrencyInput
                {...form.getInputProps("batnaMin")}
                placeholder="R$ 250,00"
              />
            </Input.Wrapper>
            <Input.Wrapper label="Valor (mensal) Máximo para locação">
              <ConcurrencyInput
                {...form.getInputProps("batnaMax")}
                placeholder="R$ 250,00"
              />
            </Input.Wrapper>
          </SimpleGrid>
          <Group position="apart" mt="lg">
            <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isUpdating || !!status?.isSubmitting}>
              Limpar
            </Button>
            <Button type="submit" loading={!!status?.isFetching || !!status?.isUpdating || !!status?.isSubmitting}>
              Confirmar
            </Button>
          </Group>
        </form>
      </>
    );
  };
  