import { Title } from "@mantine/core";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { OrcamentoDataGrid } from "partials/datagrids/Orcamento.datagrid";
import { useEffect } from "react";
import { PaginationProps } from "shared/models/Pagination.props";

export const ProspeccaoOrcamentoTab = ({ prospeccaoID }: any) => {

    const reducer = useRemoteDataReducer();
    const { fetchPage } = useRemoteOperation({
        reducer: reducer
    });
    const { data } = reducer.state;

    useEffect(() => {
        if (prospeccaoID && !data?.object) {
            reducer.dispatch({ type: "INITIATING" });
            onPaginationChange(prospeccaoID, { page: 0, size: 10, sort: "desc" });
        }
    }, [prospeccaoID]);

    const onPaginationChange = (id: any, pagination: PaginationProps) =>
        fetchPage({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/orcamentos`,
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            properties: { reducer: reducer },
            headers: RequestConfigGenerator.build(),
        });


    return (
        <>
            <Title mt="md" mb="md" order={3}>
                Orçamentos
            </Title>
            <OrcamentoDataGrid
                reducer={reducer}
                onPaginationChange={onPaginationChange}
                flags={["view"]}
            />
        </>
    )

}