import { Input } from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import React, { useState } from "react";
import NumberFormat, {
  NumberFormatBase,
  NumberFormatValues,
  NumericFormat,
} from "react-number-format";

export const ConcurrencyInput = ({
  value,
  onChange,
  defaultValue,
  ...rest
}: any) => {
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });

  return (
    <Input<any>
      component={NumericFormat}
      value={_value}
      onChange={(event: any) => handleChange(event.currentTarget.value)}
      decimalScale={2}
      prefix={"R$ "}
      fixedDecimalScale
      thousandSeparator="."
      decimalSeparator=","
      {...rest}
    />
  );
};
