import {
  Button,
  Group,
  Loader,
  LoadingOverlay,
  MultiSelect,
  PasswordInput,
  Select,
  SimpleGrid,
  TextInput
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { Hidden } from "shared/custom/Hidden";

export const StakeholderForm = ({
  reducer,
  empresa,
  stakeholder,
  onSubmit,
  editMode
}: any) => {

  const accountReducer = useRemoteDataReducer();
  const accountOperations = useRemoteOperation({ reducer: accountReducer });
  const permissionsReducer = useRemoteDataReducer();
  const permissionsOperations = useRemoteOperation({
    reducer: permissionsReducer
  });

  const { data, status } = reducer.state;

  const form = useForm({
    validateInputOnBlur: [
      "nome",
      "matricula",
      "empresa",
      "permissions",
      "papel",
    ],
    initialValues: {
      nome: "",
      matricula: "",
      account: {
        email: "",
        password: "",
        password2: "",
        permissions: [],
      },
      empresa: empresa,
      papel: "",
    },
    validate: {
      nome: (value) => (value.length > 1 ? null : "Informe um nome"),
      papel: (value) =>
        value !== "" ? null : "Informe um panel do interessado",
      empresa: (value) => (value !== "" ? null : "Informe uma empresa"),
      matricula: (value) => (value !== "" ? null : "Informe uma matrícula"),
      //account: (value) => (value.email !== "" ? null : "Informe um email"),
    },
  });

  useEffect(() => {
    if (stakeholder) {
      accountOperations.fetchSingle({
        endpoint: `${RemoteEndpoints.permissoes}/list?type=STAKEHOLDER`,
        headers: RequestConfigGenerator.build(),
      });
    }
  }, [stakeholder]);

  useEffect(() => {
    permissionsOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.permissoes}/list?type=STAKEHOLDER`,
      headers: RequestConfigGenerator.build(),
    });
  }, []);

  useEffect(() => {
    if (editMode) {
      form.setValues({ ...data?.object });
    }
  }, [data]);

  const onSubmit_ = async (data: any) => {

    if(data.account.email === "" && !editMode){
      form.setFieldError("account.email", "Informe um email");
    }

    if(data.account.password !== data.account.password2){
      form.setFieldError("account.password", "As senhas não conferem");
      form.setFieldError("account.password2", "As senhas não conferem");
      onFormError({});
      return;
    }

    delete data.account.password2;
    delete data.razao;
    
    if(editMode){
      const id = data?.id;
      delete data.id;
      delete data.account;
      delete data.status;
      onSubmit(data, id);
    }else{
      onSubmit(data, undefined);
    }
  };

  const onFormError = (event: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  const processPermissions = (data: any) => {
    const permissions = data?.object;
    if (!permissions || permissions.length < 1) {
      return [];
    }
    return permissions?.map((p: any) => {
      return { value: p.id, label: `${p.description}` };
    });
  };

  return (
    <>
      <LoadingOverlay visible={!!status?.isFetching} overlayBlur={2} />
      <form onSubmit={form.onSubmit((a) => onSubmit_(a), onFormError)}>
        <TextInput
          mt="md"
          label="Nome"
          placeholder="Paola B"
          required
          {...form.getInputProps("nome")}
        />
        <TextInput
          mt="md"
          label="Email"
          autoComplete="new-password"
          placeholder="paola@usurpadora.com.mx"
          required
          {...form.getInputProps("account.email")}
          disabled={accountReducer.state.status?.isFetching || editMode}
          rightSection={
            accountReducer.state.status?.isFetching ? (
              <Loader size="xs" />
            ) : (
              <></>
            )
          }
        />
        <Hidden hide={editMode}>
          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <PasswordInput
              label="Senha"
              placeholder="**********"
              autoComplete="new-password"
              {...form.getInputProps("account.password")}
            />
            <PasswordInput
              label="Confirme sua senha"
              placeholder="**********"
              {...form.getInputProps("account.password2")}
            />
          </SimpleGrid>
        </Hidden>
        <TextInput
          mt="md"
          label="Matrícula"
          placeholder="01457"
          description="Caso sua empresa identifique os colaboradores"
          {...form.getInputProps("matricula")}
        />
        <Select
          label="Papel (Cargo ou Setor)"
          placeholder="Escolha uma forma"
          description="Escolha o mais próximo cargos listado abaixo"
          data={[
            { value: "GESTAO", label: "Gestão" },
            { value: "JURIDICO", label: "Jurídico" },
            { value: "TECNICO", label: "Técnico" },
            { value: "OUTROS", label: "Outros" },
          ]}
          mt="md"
          {...form.getInputProps("papel")}
        />
        <MultiSelect
          mt="md"
          disabled={permissionsReducer.state.status?.isFetching || editMode}
          rightSection={
            permissionsReducer.state.status?.isFetching ? (
              <Loader size="xs" />
            ) : (
              <></>
            )
          }
          data={processPermissions(permissionsReducer.state?.data)}
          label="Autorização de Acesso"
          placeholder="Ex: Contratos, Processos, Stakeholders"
          description="Escolha as funcionalidades que este stakeholder pode ter acesso"
          {...form.getInputProps("account.permissions")}
        />
        <Group position="apart" mt="lg">
          <Button
            variant="subtle"
            disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}
          >
            Limpar
          </Button>
          <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Confirmar
          </Button>
        </Group>
      </form>
    </>
  );
};
