import {
  Avatar,
  Badge,
  Box,
  Divider,
  Grid,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Skeleton,
  Text,
} from "@mantine/core";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { useEffect } from "react";
import { Hidden } from "shared/custom/Hidden";

export const AtivoPanel = ({ loading, ativo }: any) => {

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />

      <SimpleGrid
        cols={1}
        breakpoints={[
          { minWidth: "xl", cols: 4 },
          { minWidth: "lg", cols: 3 },
          { minWidth: "md", cols: 2 },
          { minWidth: "sm", cols: 2 },
        ]}
      >
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Empresa</Text>
            <EmpresaChip empresa={ativo?.empresa} />
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Descrição do Produto</Text>
            <Hidden hide={!loading}>
              <Skeleton height={8} radius="xl" />
            </Hidden>
            <Hidden hide={loading}>
              <Text size="sm">{ativo?.produto?.descricao}</Text>
            </Hidden>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Detalhes do produto</Text>
            <Hidden hide={!loading}>
              <Skeleton height={8} radius="xl" />
            </Hidden>
            <Hidden hide={loading}>
              <Text size="sm">{ativo?.detalhes}</Text>
            </Hidden>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Marca</Text>
            <Group noWrap>
              <Avatar size="md" src={ativo?.produto?.marca?.logoURL} />
              <div>
                <Text size="xs">{ativo?.produto?.marca?.nome}</Text>
              </div>
            </Group>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Classificação</Text>
            <Hidden hide={!loading}>
              <Skeleton height={8} radius="xl" />
            </Hidden>
            <Hidden hide={loading}>
              <Badge size="sm">{ativo?.produto?.classe?.titulo}</Badge>
              <Badge size="sm">{ativo?.produto?.subclasse?.titulo}</Badge>
              <Badge size="sm">{ativo?.produto?.grupo?.titulo}</Badge>
              <Badge size="sm">{ativo?.produto?.familia?.titulo}</Badge>
            </Hidden>
          </Box>
        </Group>
      </SimpleGrid>

    </>
  );
};
