import {
  Avatar,
  Box,
  Group,
  Navbar,
  ScrollArea,
  Text
} from "@mantine/core";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { Hidden } from "../Hidden";
import { data } from "./Menu.data";
import { useStyles } from "./Menu.style";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";

export function NavBarMobile() {

  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");

  const navigate = useNavigate();

  const links = data.map((item, index) => (
    <Hidden
      hide={
        item.authorities &&
        !item.authorities?.includes(SecurityProvider.getAuthority())
      }
      key={index}
    >
      <Link
        className={cx(classes.link, {
          [classes.linkActive]: item.label === active,
        })}
        to={item.link}
        key={item.label}
      >
        {item.icon && <item.icon className={classes.linkIcon} stroke={1.5} />}
        <span>{item.label}</span>
      </Link>
    </Hidden>
  ));

  return (
    <Navbar width={{ sm: 300 }} p="md">
      <Navbar.Section grow>{links}</Navbar.Section>
      {/*<Navbar.Section className={classes.footer} component={ScrollArea}>
        <Link
          to="/configuracoes"
          className={classes.link}
          onClick={(event) => event.preventDefault()}
        >
          <IconUserCog className={classes.linkIcon} stroke={1.5} />
          <span>Conta</span>
        </Link>

        <Link to="#" className={classes.link} onClick={(event) => logout()}>
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Sair</span>
        </Link>
      </Navbar.Section>*/}
    </Navbar>
  );
}

export function DoubleNavbar({ opened }: any) {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Billing");

  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });

  useEffect(() => {
    const authority = SecurityProvider.getAuthority();
    reducer.dispatch({ type: "INITIATING" });
    if (authority === "STAKEHOLDER") {
      const authorizationToken = SecurityProvider.getAuthorizationToken();
      findEmpresa(authorizationToken.resource_group);
    }
  }, [location]);

  const empresa = reducer.state?.data?.object;

  const findEmpresa = (id: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.empresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  }


  /* onClick={(event) => {
            event.preventDefault();
            setActive(item.label);
          }}*/

  const links = data.map((item, index) => (
    <Hidden
      hide={
        item.authorities &&
        !item.authorities?.includes(SecurityProvider.getAuthority())
      }
      key={index}
    >
      <Link
        className={cx(classes.link, {
          [classes.linkActive]: item.label === active,
        })}
        to={item.link}
        key={item.label}
      >
        {item.icon && <item.icon className={classes.linkIcon} stroke={1.5} />}
        <span>{item.label}</span>
      </Link>
    </Hidden>
  ));

  return (
    <Navbar
      hidden={!opened}
      width={{ md: 250, lg: 300 }}
      hiddenBreakpoint="md"
      p="md"
      height="92vh"
      style={{ paddingTop: -70 }}
    >
      <Navbar.Section grow>
        <ScrollArea>
          <Box>
            {links}
          </Box>
        </ScrollArea>
      </Navbar.Section>
      <Hidden hide={["ADMINISTRATOR"].includes(SecurityProvider.getAuthority())}>
        <Navbar.Section className={classes.footer}>
          <Group>
            <Avatar size="md" src={encontrarMenorUrl(empresa?.logo?.thumbnailSourceSet)} radius="xl" />
            <div style={{ flex: 1 }}>
              <Text size="sm" weight={500}>
                {empresa?.nomeFantasia}
              </Text>
              <Text color="dimmed" size="xs">
                {empresa?.cnpj}
              </Text>
            </div>
          </Group>
        </Navbar.Section>
      </Hidden>
    </Navbar>
  );

  function encontrarMenorUrl(str: string) {
    if (!str) {
      return "";
    }
    const pares = str.split(', ');
    let menorUrl = null;
    let menorTamanho = Infinity;
    pares.forEach((par: string) => {
      const [url, tamanhoStr] = par.split(' ');
      const tamanho = parseInt(tamanhoStr);
      if (tamanho < menorTamanho) {
        menorTamanho = tamanho;
        menorUrl = url;
      }
    });
    return menorUrl;
  }

}

/*

const mainLinksMockdata = [
  { icon: IconHome2, label: 'Home' },
  { icon: IconGauge, label: 'Dashboard' },
  { icon: IconDeviceDesktopAnalytics, label: 'Analytics' },
  { icon: IconCalendarStats, label: 'Releases' },
  { icon: IconUser, label: 'Account' },
  { icon: IconFingerprint, label: 'Security' },
  { icon: IconSettings, label: 'Settings' },
];

interface MenuItem {
  link: string,
  title: string;
  authorities?: string[];
}

export function DoubleNavbar() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState('Releases');
  const [activeLink, setActiveLink] = useState<MenuItem | null>(null);

  const mainLinks = mainLinksMockdata.map((link) => (
    <Tooltip
      label={link.label}
      position="right"
      withArrow
      transitionProps={{ duration: 0 }}
      key={link.label}
    >
      <UnstyledButton
        onClick={() => setActive(link.label)}
        className={cx(classes.mainLink, { [classes.mainLinkActive]: link.label === active })}
      >
        <link.icon size="1.4rem" stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  ));

  const links = menu.map((item,  index) => (
    <Link
      className={cx(classes.link, { [classes.linkActive]: activeLink === item })}
      to={item.link}
      key={index}
    >
      {item.title}
    </Link>
  ));

  return (
    <Navbar  width={{ sm: 300 }}>
      <Navbar.Section grow className={classes.wrapper}>
        <div className={classes.aside}>
          <div className={classes.logo}>
            Logo
          </div>
          {mainLinks}
        </div>
        <div className={classes.main}>
          <Title order={4} className={classes.title}>
            {active}
          </Title>
          {links}
        </div>
      </Navbar.Section>
    </Navbar>
  );
}*/
