import { TextInput, Textarea, Text, Paper, Group, CloseButton, Button, Box } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconFile } from "@tabler/icons-react";
import { UploadDropZone } from "shared/components/UploadDropZone.component";
import { Hidden } from "shared/custom/Hidden";

export const EnviarDocumentoAtivoForm = ({
    reducer,
    editMode,
    onSubmit,
}: any) => {

    const form = useForm({
        validateInputOnBlur: ["titulo", "documento", "description"],
        initialValues: {
            titulo: "",
            description: "",
            documento: new File([], "", undefined),
        },
        validate: {
            titulo: (value) =>
                value !== "" ? null : "Informe o titulo do documento",
            description: (value) =>
                value !== ""
                    ? null
                    : "Informe uma descrição mais detalhada sobre o documento",
        },
    });

    const _onSubmit = (submitData: any) => {
        if (form.values.documento?.name) {
            onSubmit(form.values);
        }
    };

    const onFormError = (e: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <Text color="gray" size="xs" mt="md">
                Informe quaisquer documentos necessários do ativo como: laudos
                técnicos, notas fiscais, relatórios de manutenção, certificados,
                documentos de licenciamento, e etc. Para alguns ativos os documentos
                são obrigatórios, como veiculos terrestres, aeronaves. Estes
                documentos serão disponibilizados para os locatários ao concluir a
                contratação do aluguel do ativo.
            </Text>
            <TextInput
                {...form.getInputProps("titulo")}
                mt="md"
                label="Titulo"
                maxLength={255}
                placeholder="Ex: Laudo técnico manutenção Junho/2023"
                description="Informe o titulo do documento ou uma descrição curta que explique o documento"
            />
            <Textarea
                {...form.getInputProps("description")}
                description="Adicione mais detalhes sobre o documento"
                maxLength={250}
                label="Detalhes"
                mt="md"
            />
            <Hidden hide={form.values.documento?.name}>
                <UploadDropZone
                    mt="md"
                    multiple={false}
                    onDrop={(files) => form.setValues({ documento: files[0] })}
                    accept={["application/pdf"]}
                />
            </Hidden>
            <Hidden hide={!form.values.documento?.name}>
                <Paper withBorder p="xs" mt="md">
                    <Group position="apart" noWrap spacing="xl">
                        <Group noWrap>
                            <IconFile size={32} color="gray" />
                            <div>
                                <Text size="sm">{form.values.documento.name}</Text>
                            </div>
                        </Group>
                        <CloseButton
                            onClick={() =>
                                form.setValues({ documento: new File([], "", undefined) })
                            }
                            aria-label="Remover item selecionado"
                        />
                    </Group>
                </Paper>
            </Hidden>
            <Group position="apart" mt="lg">
                <Box></Box>
                <Button
                    type="submit"
                    loading={reducer?.state?.status?.isSubmitting}
                >
                    Confirmar
                </Button>
            </Group>
        </form>
    );

}