import { Badge, Grid, Skeleton, Text } from "@mantine/core";
import { Hidden } from "shared/custom/Hidden";

export const StakeholderPanel = ({ loading, data }: any) => {
  return (
    <Grid>
      <Grid.Col span={4}>
        <Text size="xs" fw={500}>Nome</Text>
        <Hidden hide={!loading}>
          <Skeleton height={8} radius="xl" />
        </Hidden>
        <Hidden hide={loading}>
          <Text size="sm">{data?.nome}</Text>
        </Hidden>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text size="xs" fw={500}>Matrícula</Text>
        <Hidden hide={!loading}>
          <Skeleton height={8} radius="xl" />
        </Hidden>
        <Hidden hide={loading}>
          <Text size="sm">{data?.matricula}</Text>
        </Hidden>
      </Grid.Col>
      <Grid.Col span={4}>
        <Text size="xs" fw={500}>Papel</Text>
        <Hidden hide={!loading}>
          <Skeleton height={8} radius="xl" />
        </Hidden>
        <Hidden hide={loading}>
          <Badge color="blue">{data?.papel}</Badge>
        </Hidden>
      </Grid.Col>
    </Grid>
  );
};
