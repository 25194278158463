import { Group, Select, SimpleGrid, TextInput } from "@mantine/core";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";

export const ProdutoForm = ({
  form,
  error,
  value,
  onChange,
  selectControl,
}: any) => {
  const reducerClasse = useRemoteDataReducer();
  const reducerSubclasse = useRemoteDataReducer();
  const reducerGrupo = useRemoteDataReducer();
  const reducerFamilia = useRemoteDataReducer();
  const reducerMarca = useRemoteDataReducer();

  const classeOperations = useRemoteOperation({ reducer: reducerClasse });
  const subclasseOperations = useRemoteOperation({ reducer: reducerSubclasse });
  const grupoOperations = useRemoteOperation({ reducer: reducerGrupo });
  const familiaOperations = useRemoteOperation({ reducer: reducerFamilia });
  const marcaOperations = useRemoteOperation({ reducer: reducerMarca });

  const classe = reducerClasse.state.data?.object?.map((result: any) => {
    return { value: result.valor, label: result.titulo };
  });
  const subclasse = reducerSubclasse.state.data?.object?.map((result: any) => {
    return { value: result.valor, label: result.titulo };
  });
  const familia = reducerFamilia.state.data?.object?.map((result: any) => {
    return { value: result.valor, label: result.titulo };
  });
  const grupo = reducerGrupo.state.data?.object?.map((result: any) => {
    return { value: result.valor, label: result.titulo };
  });
  const marcas = reducerMarca.state.data?.object?.map((result: any) => {
    return { value: result.id, label: result.nome };
  });

  useEffect(() => {
    if (!selectControl) {
      if (value?.marca === "") {
        marcaOperations.fetchSingle({
          endpoint: `${RemoteEndpoints.marcas}/listar`,
          properties: { reducer: reducerMarca },
          headers: RequestConfigGenerator.build(),
        });
      }
      if (value?.classe === "") {
        classeOperations.fetchSingle({
          endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=CLASS`,
          properties: { reducer: reducerClasse },
          headers: RequestConfigGenerator.build(),
        });
      }
      if (value?.classe !== "") {
        if (
          reducerClasse?.state?.data?.object &&
          reducerClasse?.state?.data?.object.length > 0
        ) {
          const classId = reducerClasse?.state?.data?.object[0]?.id;
          subclasseOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=SUBCLASS&parent=${classId}`,
            headers: RequestConfigGenerator.build(),
          });
        }
      }
      if (value?.subclasse !== "") {
        if (
          reducerSubclasse?.state?.data?.object &&
          reducerSubclasse?.state?.data?.object.length > 0
        ) {
          const subclassId = reducerSubclasse?.state?.data?.object[0]?.id;
          grupoOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=GROUP&parent=${subclassId}`,
            headers: RequestConfigGenerator.build(),
          });
        }
      }
      if (value?.grupo !== "") {
        if (
          reducerGrupo?.state?.data?.object &&
          reducerGrupo?.state?.data?.object.length > 0
        ) {
          const groupId = reducerGrupo?.state?.data?.object[0]?.id;
          familiaOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=FAMILY&parent=${groupId}`,
            headers: RequestConfigGenerator.build(),
          });
        }
      }
    }
  }, [selectControl, value?.classe, value?.subclasse, value?.grupo]);

  return (
    <>
      <SimpleGrid cols={2} mt="xl" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Select
          {...form.getInputProps("dadosIdentificacaoProduto.classe")}
          label="Classe do Ativo"
          placeholder="Escolha um tipo de ativo"
          description="O que é esse ativo?"
          data={classe || []}
          disabled={reducerClasse.state.status?.isFetching}
        />
        <Select
          {...form.getInputProps("dadosIdentificacaoProduto.subclasse")}
          label="Subclasse do Ativo"
          placeholder="Escolha um tipo de ativo"
          description="O que é esse ativo?"
          data={subclasse || []}
          disabled={value?.classe === ""}
        />
      </SimpleGrid>
      <SimpleGrid cols={2} mt="md" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <Select
          {...form.getInputProps("dadosIdentificacaoProduto.grupo")}
          label="Grupo do Ativo"
          placeholder="Escolha um tipo de ativo"
          description="O que é esse ativo?"
          data={grupo || []}
          disabled={value?.subclasse === ""}
        />
        <Select
          {...form.getInputProps("dadosIdentificacaoProduto.familia")}
          label="Familia do Ativo"
          placeholder="Escolha um tipo de ativo"
          description="O que é esse ativo?"
          data={familia || []}
          disabled={value?.grupo === ""}
        />
      </SimpleGrid>
      <TextInput
        {...form.getInputProps("dadosIdentificacaoProduto.descricao")}
        mt="md"
        label="Descrição"
        placeholder="Furadeira de Impacto"
      />
      <SimpleGrid cols={2} mt="md" breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
        <TextInput
          {...form.getInputProps("dadosIdentificacaoProduto.modelo")}
          label="Modelo"
          placeholder="Gsb 13 Re 650W"
        />
        <Select
          {...form.getInputProps("dadosIdentificacaoProduto.marca")}
          label="Marca"
          placeholder="Bosch"
          data={marcas || []}
        />
      </SimpleGrid>
    </>
  );
  
};
