import {
  ActionIcon,
  Badge,
  Box,
  Divider,
  Drawer,
  Group,
  Progress,
  Stack,
  Text,
  Tooltip
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconSquareAsterisk,
  IconUser,
  IconUserOff
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { RedefinirSenhaForm } from "partials/forms/RedefinirSenha.form";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";

export const AccountListPage = () => {

  const [permissoesAtivas, setPermissoesAtivas] = useState<any>([]);
  const [conta, setConta] = useState<any>({});
  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer });
  const { fetchPage, update, patch } = useRemoteOperation({ reducer: reducer });
  const permissionReducer = useRemoteDataReducer();
  const permissionOperations = useRemoteOperation({
    reducer: permissionReducer
  });
  const accountPermissionReducer = useRemoteDataReducer();
  const accountPermissionOperations = useRemoteOperation({
    reducer: accountPermissionReducer
  });

  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed) notifications.show({ message: data?.error?.message });
    else if (
      status?.isUpdated ||
      status?.isSubmitted ||
      status?.isDeleted ||
      status?.isDeleted
    )
      notifications.show({ message: "Operação realizada com sucesso!" });
    else if (status?.isInitiating)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
  }, [status, location?.pathname]);

  useEffect(() => {
    if (
      accountPermissionReducer.state.data?.object &&
      permissionReducer.state.data?.object
    ) {
      let permissoes: any[] = [];

      permissionReducer.state.data?.object?.map((item: any) =>
        permissoes.push({
          permission: item.permission,
          description: item.description,
          active: accountPermissionReducer.state.data?.object?.includes(item),
        }),
      );

      setPermissoesAtivas(permissoes);
    }
  }, [accountPermissionReducer.state, permissionReducer.state]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.accounts}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const desativarConta = (data: any) => {
    update({
      endpoint: RemoteEndpoints.accounts + "/account/" + data?.id + "/disable",
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const ativarConta = (data: any) => {
    update({
      endpoint: RemoteEndpoints.accounts + "/account/" + data?.id + "/enable",
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const abrirDrawerRedefinirSenha = (data: any) => {
    setConta(data);
    drawerForm.open("redefinirSenha");
  };

  const actionRedefinirSenha = (data: any) => {
    patch({
      endpoint:
        RemoteEndpoints.accounts +
        "/account/" +
        data?.id +
        "/change-account-password",
      properties: { reducer: reducer },
      data: {
        newPassword: data?.password,
      },
      headers: RequestConfigGenerator.build(),
    });
    setConta(null);
  };

  return (
    <PageLayout title="Contas de Usuários">
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="redefinirSenha"
        title="Redefinir Senha Usuário"
      >
        <RedefinirSenhaForm
          onSubmit={actionRedefinirSenha}
          data={conta}
          submiting={reducer.state.status?.isSubmitting}
        />
      </CustomDrawer>
      <DataGridComponent
        state={reducer.state}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhuma conta encontrada"
        columns={[
          {
            accessor: "email",
            title: "Email / ID",
            render: ({ email, id }: any) => (
              <Stack>
                <Box>{email}</Box>
                <Text size="xs" color="dimmed">
                  {id}
                </Text>
              </Stack>
            ),
          },
          {
            accessor: "type",
            title: "Tipo",
            render: ({ type }: any) => <Badge>{type}</Badge>,
          },
          {
            accessor: "enabled",
            title: "Habilitado",
            render: ({ enabled }: any) => (
              <Badge color={enabled ? "teal" : "orange"}>{enabled ? "Ativo" : "Inativo"}</Badge>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (data: any) => (
              <Group spacing={12} position="right" noWrap>
                <Hidden hide={data.enabled}>
                  <Hidden hide={reducer.state.status?.isSubmitting}>
                    <Tooltip label="Ativar">
                      <ActionIcon
                        color="gray"
                        onClick={() => ativarConta(data)}
                      >
                        <IconUser size={26} />
                      </ActionIcon>
                    </Tooltip>
                  </Hidden>
                  <Hidden hide={!reducer.state.status?.isSubmitting}>
                    <Progress />
                  </Hidden>
                </Hidden>
                <Hidden hide={!data.enabled}>
                  <Hidden hide={reducer.state.status?.isSubmitting}>
                    <Tooltip label="Desativar">
                      <ActionIcon
                        color="gray"
                        onClick={() => desativarConta(data)}
                      >
                        <IconUserOff size={26} />
                      </ActionIcon>
                    </Tooltip>
                  </Hidden>
                  <Hidden hide={!reducer.state.status?.isSubmitting}>
                    <Progress />
                  </Hidden>
                </Hidden>
                <Tooltip label="Trocar Senha">
                  <ActionIcon
                    color="gray"
                    onClick={() => abrirDrawerRedefinirSenha(data)}
                  >
                    <IconSquareAsterisk size={26} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
