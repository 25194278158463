import { Avatar, Badge, Box, Card, Divider, Grid, Group, LoadingOverlay, Paper, SimpleGrid, Text } from "@mantine/core";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { InfoUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";


/*
<a href={`/empresas/${empresa?.id}`}>
                  <Tooltip label="Visualizar Empresa">
                    <ActionIcon color="gray">
                      <IconBuilding size={26} />
                    </ActionIcon>
                  </Tooltip>
                </a>
*/

export const SolicitacaoPanel = ({ solicitacao, loading }: any) => {
  return (
    <Paper withBorder p="md">
      <LoadingOverlay visible={loading} />
      <SimpleGrid
        cols={1}
        breakpoints={[
          { minWidth: "xl", cols: 4 },
          { minWidth: "lg", cols: 3 },
          { minWidth: "md", cols: 2 },
          { minWidth: "sm", cols: 2 },
        ]}
      >
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Solicitante</Text>
            <EmpresaChip empresa={solicitacao?.empresaSolicitante} />
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Status</Text>
            <Badge color="blue">{solicitacao?.status}</Badge>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Modo</Text>
            <Badge color="blue">{solicitacao?.modo}</Badge>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Canal</Text>
            <Badge color="blue">{solicitacao?.canal}</Badge>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Stakeholder Solicitante</Text>
            <Text size="sm">{solicitacao?.stakeholderSolicitante?.nome}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Stakeholder Aprovação/Reprovação</Text>
            <Text size="sm">{solicitacao?.stakeholderSolicitado?.nome}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Data/Hora Solicitação</Text>
            <Text size="sm">{moment(solicitacao?.horaDataSolicitacao).format("DD/MM/YYYY [as] HH:mm")}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Data/Hora Aprovação</Text>
            <Text size="sm">{moment(solicitacao?.horaDataAprovacao).format("DD/MM/YYYY [as] HH:mm")}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Valor total</Text>
            <>{InfoUtils.formatCurrency(solicitacao?.valorTotal) ? InfoUtils.formatCurrency(solicitacao?.valorTotal): <Badge color="gray">A definir</Badge>}</>   
          </Box>
        </Group>
      </SimpleGrid>
    </Paper>
  );
};
