import {
  Autocomplete,
  Loader,
  Card,
  Group,
  CloseButton,
  Text,
  Input,
  Box,
  Paper,
  Avatar,
  TextInput,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { FormEvent, forwardRef, useEffect, useState } from "react";
import { Hidden } from "shared/custom/Hidden";

export const EmpresaSelectForm = ({
  error,
  value,
  onChange,
  checked,
  onFocus,
  onBlur,
  label,
  description,
  placeholder,
  dataTestId
}: any) => {
  const reducer = useRemoteDataReducer();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  const [currentInput, setCurrentInput] = useDebouncedState("", 750);
  const [currentValue, setCurrentValue] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<any>([]);

  useEffect(() => {
    if (status?.isSuccessful && data) setSearchResults([data?.object]);
  }, [status]);

  const onSearch = (cnpj: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.empresas}/buscar/cnpj?term=${cnpj.replace(
        /[^a-zA-Z0-9]/g,
        "",
      )}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onSelect = (item: any) => {
    setCurrentValue(item);
    onChange(item?.id);
  };

  const onType = (event: FormEvent<HTMLInputElement>) => {
    let term = event.currentTarget.value;
    term = term.replace(/[^a-z0-9]/gi, "");
    setSearchResults([]);
    if (term.length > 5) {
      onSearch(term);
    }
  };

  const reset = () => {
    onChange(null);
    setCurrentValue(null);
  };

  const AutoCompleteItem = ({
    nomeFantasia,
    razaoSocial,
    value,
    logo,
    ...rest
  }: any) => (
    <Group noWrap {...rest}>
      <Avatar size="lg" src={logo?.thumbnailSourceSet} />
      <div>
        <Text>{nomeFantasia}</Text>
        <Text size="xs" color="dimmed">
          {razaoSocial}
        </Text>
      </div>
    </Group>
  );

  const resultData = searchResults?.map((item: any) => ({
    ...item,
    value: item?.id,
  }));

  return (
    <Box>
      <Hidden hide={currentValue}>
        <Box style={{ maxWidth: "100%" }}>
          <TextInput
            data-testid={dataTestId}
            label={label}
            description={description}
            placeholder={placeholder}
            onInput={onType}
            rightSection={status?.isFetching ? <Loader size="xs" /> : <></>}
            error={error}
          />
          {resultData && resultData.length > 0 && (
            <Paper
              shadow="xs"
              pos={"absolute"}
              p="xs"
              style={{
                maxHeight: "200px",
                width: "96%",
                overflowY: "auto",
                zIndex: 10,
              }}
            >
              {resultData.map((result: any, z: number) => (
                <AutoCompleteItem
                  style={{ cursor: "pointer" }}
                  onClick={() => onSelect(result)}
                  razaoSocial={result.razaoSocial}
                  nomeFantasia={result.nomeFantasia}
                  logo={result.logo}
                />
              ))}
            </Paper>
          )}
        </Box>
      </Hidden>
      <Hidden hide={!currentValue}>
        <Input.Wrapper label="Empresa">
          <Paper withBorder p="xs">
            <Group position="apart" noWrap spacing="xl">
              <Group noWrap>
                <Avatar
                  size="xl"
                  src={currentValue?.logo?.thumbnailSourceSet}
                />
                <div>
                  <Text>{currentValue?.nomeFantasia}</Text>
                  <Text size="xs" color="dimmed">
                    {currentValue?.razaoSocial}
                  </Text>
                </div>
              </Group>
              <CloseButton
                onClick={() => reset()}
                aria-label="Remover item selecionado"
              />
            </Group>
          </Paper>
        </Input.Wrapper>
      </Hidden>
    </Box>
  );
};
