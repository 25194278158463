import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";
import { ObjectUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";

export const DadosBancariosForm = ({
  reducer,
  editMode,
  onSubmit,
  empresa,
}: any) => {

  const { data, status } = reducer.state;

  const form = useForm({
    validateInputOnBlur: [
      "empresa",
      "tipoDado",
      "chavePix",
      "beneficiadoPix",
      "nomeBanco",
      "numeroConta",
      "numeroAgencia",
      "numeroReferencia",
      "outrasInformacoes",
    ],
    initialValues: {
      id: "",
      empresa: empresa,
      tipoDado: "",
      chavePix: "",
      beneficiadoPix: "",
      nomeBanco: "",
      numeroConta: "",
      numeroAgencia: "",
      numeroReferencia: "",
      outrasInformacoes: ""
    },
    validate: {
      tipoDado: (value: string) =>
        value.length > 1 ? null : "Informe um Tipo",
    },
  });

  useEffect(() => {
    if (data.object && editMode) {
      let finalData = data.object;
      form.setValues(finalData);
    }
  }, [data]);

  const _onSubmit = async (submitData: any) => {
    const id = submitData.id;
    delete submitData.id;
    const finalSubmitData = ObjectUtils.cleanNulls(submitData);
    onSubmit(finalSubmitData, id);
  }

  const onFormError = (event: any) =>
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });

  return (
    <>
      <LoadingOverlay visible={!!status?.isFetching} overlayBlur={2} />
      <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
        <Select
          label="Modo"
          placeholder="Escolha um tipo"
          mt="md"
          data={[
            { value: "PIX", label: "Pix" },
            { value: "CONTA_BANCARIA", label: "Conta bancária" },
          ]}
          {...form.getInputProps("tipoDado")}
        />
        <Hidden hide={form.values.tipoDado != "PIX"}>
          <Text fz="md" mt="md" fw={500}>
            Chave Pix
          </Text>
          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <TextInput
              label="Chave Pix"
              placeholder="Chave Pix"
              {...form.getInputProps("chavePix")}
            />
            <TextInput
              label="Beneficiado Pix"
              placeholder="Beneficiado Pix"
              {...form.getInputProps("beneficiadoPix")}
            />
          </SimpleGrid>
        </Hidden>
        <Hidden hide={form.values.tipoDado != "CONTA_BANCARIA"}>
          <Text fz="md" mt="md" fw={500}>
            Conta de Banco
          </Text>
          <TextInput
            mt="md"
            label="Nome Banco"
            placeholder="Nome Banco"
            {...form.getInputProps("nomeBanco")}
          />
          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <TextInput
              label="Número Conta"
              placeholder="Número Conta"
              {...form.getInputProps("numeroConta")}
            />
            <TextInput
              label="Número Agência"
              placeholder="Número Agência"
              {...form.getInputProps("numeroAgencia")}
            />
          </SimpleGrid>
          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <TextInput
              label="Número Referencia"
              placeholder="Número Referencia"
              {...form.getInputProps("numeroReferencia")}
            />
            <TextInput
              label="Outras Informações"
              placeholder="Outras Informações"
              {...form.getInputProps("outrasInformacoes")}
            />
          </SimpleGrid>
        </Hidden>
        <Group position="apart" mt="lg">
          <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Limpar
          </Button>
          <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Confirmar
          </Button>
        </Group>
      </form>
    </>
  );
};
