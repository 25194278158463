import {
  Card,
  LoadingOverlay,
  Timeline,
  Button,
  Text,
  Title,
  Box,
} from "@mantine/core";
import { IconDots, IconMessageDots } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden";
import { PaginationProps } from "shared/models/Pagination.props";

export const EventosProcessosDashboardPanel = () => {
  const reducerEventosProcesso = useRemoteDataReducer();
  const remoteEventosOperations = useRemoteOperation({
    reducer: reducerEventosProcesso,
  });
  const location = useLocation();
  const { data, status } = reducerEventosProcesso.state;

  const findEventosProcesso = (pagination: PaginationProps) =>
    remoteEventosOperations.fetchPage({
      endpoint: `${RemoteEndpoints.eventosRecentes}`,
      properties: { reducer: reducerEventosProcesso },
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  useEffect(() => {
    if (!data?.object) {
      reducerEventosProcesso.dispatch({ type: "INITIATING" });
      findEventosProcesso({ page: 0, size: 5, sort: "desc" });
    }
  }, [location]);

  function converterData(data: any) {
    const dataMoment = moment(data);
    const diferenca = moment().diff(dataMoment);

    const duracao = moment.duration(diferenca);
    const anos = duracao.years();
    const meses = duracao.months();
    const dias = duracao.days();

    if (diferenca < 0) {
      if (anos > 0) {
        return `Daqui ${anos} ano${anos > 1 ? "s" : ""}`;
      } else if (meses > 0) {
        return `Daqui ${meses} mês${meses > 1 ? "es" : ""}`;
      } else {
        return `Daqui ${dias} dia${dias > 1 ? "s" : ""}`;
      }
    } else {
      if (anos > 0) {
        return `Há ${anos} ano${anos > 1 ? "s" : ""}`;
      } else if (meses > 0) {
        return `Há ${meses} mês${meses > 1 ? "es" : ""}`;
      } else {
        return `Há ${dias} dia${dias > 1 ? "s" : ""}`;
      }
    }
  }

  return (
    <>
      <Title mt="md" mb="md" order={3}>
        Eventos
      </Title>
      <Card withBorder mt="md">
        <LoadingOverlay
          visible={reducerEventosProcesso.state?.status?.isFetching!}
        />
        <Hidden hide={reducerEventosProcesso?.state?.data?.page?.empty || reducerEventosProcesso?.state?.status?.isFailed}>
          <Timeline active={1} bulletSize={24} lineWidth={2} mt="md" ml="md">
            {!reducerEventosProcesso?.state.data?.page?.first && (
              <Timeline.Item>
                <Button
                  size="xs"
                  compact
                  onClick={() =>
                    findEventosProcesso({
                      page: reducerEventosProcesso.state?.data?.page?.number! - 1,
                      size: 5,
                      sort: "desc"
                    })
                  }
                  leftIcon={<IconDots size="1rem" />}
                >
                  Mais Novas
                </Button>
              </Timeline.Item>
            )}
            {reducerEventosProcesso?.state.data?.page?.content?.map(
              (item: any) => (
                <Timeline.Item
                  radius="md"
                  title={item.titulo}
                  bullet={<IconMessageDots size={12} />}
                >
                  <Text color="dimmed" size="sm">
                    {item.descricao}
                  </Text>
                  <Text size="xs" mt={4}>
                    {converterData(item.dataHoraAlvo)}
                  </Text>
                </Timeline.Item>
              ),
            )}
            {!reducerEventosProcesso?.state.data?.page?.last && (
              <Timeline.Item>
                <Button
                  size="xs"
                  compact
                  onClick={() =>
                    findEventosProcesso({
                      page:
                        (reducerEventosProcesso.state?.data?.page?.number || 0) +
                        1,
                      size: 5,
                      sort: "desc"
                    })
                  }
                  leftIcon={<IconDots size="1rem" />}
                >
                  Mais Antigas
                </Button>
              </Timeline.Item>
            )}
          </Timeline>
        </Hidden>
        <Hidden hide={!reducerEventosProcesso?.state?.data?.page?.empty && !reducerEventosProcesso?.state?.status?.isFailed}>
          <Box>
            <Text>
              Nenhum evento no momento.
            </Text>
          </Box>
        </Hidden>
      </Card>
    </>
  );
};
