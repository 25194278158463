import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";
import { FileUtils } from "shared/Utils";
import { BlobStorageService } from "shared/azurestorage/BlobStorageService.proto";

export const useUploadAtivoDocument = (properties: any) => {

  const execute = async (data: any) => {
    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });

    const file = data.documento as File;

    const hash = await FileUtils.getFileMD5(file);
    const documentData = {
      title: data.titulo,
      file: {
        filename: file.name,
        contentType: file.type,
        size: file.size,
        hash: hash,
      },
    };

    const result = await RemoteClient.create(
      `${RemoteEndpoints.ativoitem}/${data?.ativoId}/documents`,
      documentData,
      RequestConfigGenerator.build(),
    );
    if (result.data) {
      try {
        var sas = result.data.documento?.url + '?' + result.data.documento?.sasToken;
        await BlobStorageService.uploadFiles([file], sas);
        properties.reducer.dispatch({
          type: "SUBMIT_SUCCESS",
          payload: result.data?.documento,
        });
      } catch (e) {
        console.error(e)
        properties.reducer.dispatch({
          type: "ERROR",
          payload: result.error || "Erro ao enviar arquivo",
        });
      }
    } else {
      properties.reducer.dispatch({ type: "ERROR", payload: result.error });
    }
  };

  return { execute: execute };
};
