import { Group, ActionIcon, Text, Tooltip, Avatar, Badge } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconScanEye, IconEye, IconEdit, IconBox, IconUpload, IconTrash, IconCamera } from "@tabler/icons-react";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { Link } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const ExemplarAtivoDataGrid = ({ reducer, onPaginationChange, onView, onEdit, onDelete, flags, onImageView }: any) => {

    const authority = SecurityProvider.getAuthority();

    const openDeleteConfirmModal = (id: any) => modals.openConfirmModal({
        centered: true,
        title: 'Tem certeza?',
        children: (
            <Text size="sm">
                Tem certeza que deseja remover este ativo da lista?
            </Text>
        ),
        labels: { confirm: 'Sim', cancel: 'Não' },
        onConfirm: () => onDelete(id),
    });

    const _onImageView = (images: any) => onImageView(images);

    return (
        <DataGridComponent
            visible={true}
            fetching={reducer.state.status?.isFetching}
            state={reducer.state}
            noRecordText="Nenhum ativo encontrado"
            onPaginationChange={onPaginationChange}
            columns={[
                {
                    accessor: "imagem",
                    title: "Imagem",
                    textAlignment: "center",
                    render: ({ images }: any) => (
                        <Tooltip label="Visualizar Imagens do Ativo">
                            <ActionIcon color="blue" onClick={() => _onImageView(images)}>
                                <IconCamera size={26} />
                            </ActionIcon>
                        </Tooltip>
                    ),
                },
                {
                    accessor: "numeroSeriePatrimonio",
                    title: "Série/Número Patrimonio",
                },
                {
                    hidden: !["ADMINISTRATOR"].includes(SecurityProvider.getAuthority()),
                    accessor: "empresa",
                    title: "Empresa",
                    width: '300px',
                    render: ({ empresa }: any) => <EmpresaChip empresa={empresa} />

                },
                {
                    title: "Ativo",
                    render: ({ ativo }: any) => (
                        <>{ativo?.detalhes}</>
                    ),
                },
                {
                    accessor: "detalhes",
                    title: "Detalhes",
                    render: ({ detalhes }: any) => (
                        <>{detalhes}</>
                    ),
                },
                {
                    accessor: "estado",
                    title: "Estado",
                    render: ({ estado }: any) => {
                        <>{estado}</>
                    },
                },
                {
                    accessor: "criacao",
                    title: "Criação",
                    render: ({ creationTime }: any) =>
                        <Text size="xs" color="dimmed">
                            {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                },
                {
                    accessor: "atualizacao",
                    title: "Atualização",
                    render: ({ modificationTime }: any) =>
                        <Text size="xs" color="dimmed">
                            {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ id }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            <Tooltip label="Visualizar Ativo">
                                <Link to={`/ativo-item/${id}`}>
                                    <ActionIcon color="gray">
                                        <IconEye size={26} />
                                    </ActionIcon>
                                </Link>
                            </Tooltip>
                            <Link to={`/ativo-locacao/${id}`}>
                                <ActionIcon color="gray">
                                    <IconScanEye size={26} />
                                </ActionIcon>
                            </Link>
                            <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
                                <Tooltip label="Enviar documentos e fotos">
                                    <Link to={`/ativos/${id}?action=enviar-documento`}>
                                        <ActionIcon color="blue">
                                            <IconUpload size={26} />
                                        </ActionIcon>
                                    </Link>
                                </Tooltip>
                                <Tooltip label="Editar Ativo">
                                    <ActionIcon color="blue" onClick={() => onEdit(id)}>
                                        <IconEdit size={26} />
                                    </ActionIcon>
                                </Tooltip>
                                <ActionIcon color="blue" onClick={() => openDeleteConfirmModal(id)}>
                                    <IconTrash size={26} />
                                </ActionIcon>
                            </Hidden>
                        </Group>
                    ),
                },
            ]}
        />
    );

}
