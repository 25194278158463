import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import {
  Paper,
  useMantineTheme,
  rem,
} from "@mantine/core";

interface CardProps {
  url: any
  thumbnailSourceSet: any;
  imageSourceSet: any;
}

function ImageCard(image: CardProps) {
  return (
    <Paper
      p="md"
      radius="md"
      style={{
        maxWidth: rem(256),
        maxHeight: rem(256),
        height: rem(256),
        display: "flex",
        flexDirection: "column",
      }}
      sx={{
        backgroundImage: `url(${image?.url})`,
        backgroundSize: "cover",
      }}
    />
  );
}

export function CardsCarousel({ imagens, loading }: any) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const slides = imagens.map((item: any, index: number) => (
    <Carousel.Slide key={index}>
      <ImageCard {...item} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      slideSize="20%"
      mt="md"
      mb="md"
      breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: rem(2) }]}
      align="start"
      onClick={() => {}}
      slidesToScroll={mobile ? 1 : 2}
    >
      {slides}
    </Carousel>
  );
}
