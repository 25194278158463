import { Group, Box, Text, Tooltip, ActionIcon } from "@mantine/core";
import { IconEdit, IconEye, IconFileDollar } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useUploadPagamentoAnexo } from "hooks/uploads/useUploadPagamentoAnexo";
import { useDrawerForm } from "hooks/useDrawerForm";
import { Pagamento } from "models/Pagamento.model";
import { PagamentoForm } from "partials/forms/Pagamento.form";
import { PagamentoView } from "partials/views/Pagamento.view";
import { useEffect } from "react";
import { CustomDrawer } from "shared/custom/CustomDrawer";

export interface PagamentoPanelProps {
    pagamento?: Pagamento;
    title: string;
    onAction: any;
}

export const PagamentoPanel = ({ pagamento, title, onAction }: PagamentoPanelProps) => {

    const reducer = useRemoteDataReducer();
    const drawerForm = useDrawerForm({ reducer: reducer });

    const findItem = (id: any) =>
        fetchSingle({
            endpoint: `${RemoteEndpoints.pagamento}/${id}`,
            properties: { reducer: reducer },
            headers: RequestConfigGenerator.build(),
        });

    const uploadAnexoPagamento = useUploadPagamentoAnexo({ reducer: reducer });

    const { patch, fetchSingle } =
        useRemoteOperation({ reducer: reducer });

    const { data, status } = reducer.state;

    useEffect(() => {
        if (status?.isUpdated && data?.object?.status === "CONFIRMED") {
            onAction();
        }
    }, [status]);

    const editarPagamento = async (formData: any, idPagamento: any) => {
        patch({
            endpoint: `${RemoteEndpoints.pagamento}/${idPagamento}`,
            properties: { reducer: reducer },
            data: formData,
            headers: RequestConfigGenerator.build(),
        });
    };

    const visualizarPagamento = (id?: string) => {
        if (id) {
            findItem(id);
            drawerForm.open("visualizarPagamento");
        }
    }

    const editarPagamentoAction = (id: any) => {
        fetchSingle({
            endpoint: `${RemoteEndpoints.pagamento}/${id}`,
            properties: { reducer: reducer },
            headers: RequestConfigGenerator.build(),
        });
        drawerForm.open("pagamentoEditarDrawer");
    };

    return (
        <>
            <CustomDrawer
                drawerFormState={drawerForm}
                tag="visualizarPagamento"
                title="Visualizar Pagamento"
            >
                <PagamentoView
                    reducer={reducer}
                    onDocumentoCobrancaUpload={(file: File, id: any) =>
                        uploadAnexoPagamento.execute(file, "DOCUMENTO_COBRANCA", id)
                    }
                    onComprovanteUpload={(file: File, id: any) =>
                        uploadAnexoPagamento.execute(file, "COMPROVANTE", id)
                    }
                    onReciboUpload={(file: File, id: any) =>
                        uploadAnexoPagamento.execute(file, "RECIBO_NOTA", id)
                    }
                />
            </CustomDrawer>
            <CustomDrawer
                drawerFormState={drawerForm}
                tag="pagamentoEditarDrawer"
                title="Editar pagamento"
            >
                <PagamentoForm
                    reducer={reducer}
                    onSubmit={(entity: any, id: any) => editarPagamento(entity, id)}
                    editMode={true}
                />
            </CustomDrawer>
            <Group position="apart" noWrap spacing="xl">
                <Group noWrap>
                    <IconFileDollar />
                    <Box>
                        <Text fw={500}>{title}</Text>
                        <Text size="xs" color="dimmed">
                            {pagamento?.descricao}
                        </Text>
                        <Text size="xs" color="dimmed">
                            Valor: R$ {pagamento?.valor}
                        </Text>
                    </Box>
                </Group>
                <Group noWrap>
                    <Tooltip label="Editar pagamento">
                        <ActionIcon
                            color="gray"
                            onClick={() => editarPagamentoAction(pagamento?.id)}
                        >
                            <IconEdit size={26} />
                        </ActionIcon>
                    </Tooltip>
                    <Tooltip label="Visualizar pagamento">
                        <ActionIcon
                            color="gray"
                            onClick={() => visualizarPagamento(pagamento?.id)}
                        >
                            <IconEye size={26} />
                        </ActionIcon>
                    </Tooltip>
                </Group>
            </Group>
        </>
    )

}