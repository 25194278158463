import { Accordion, Box, LoadingOverlay, ThemeIcon } from "@mantine/core";
import { IconCircleCheck, IconFileUpload } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { UploadDropZone } from "shared/components/UploadDropZone.component";

export const UploadDocumentosEmpresa = ({
  empresaID,
  files,
  loading,
  onFileDrop,
  current,
}: any) => {
  const openRefCNPJ = useRef<() => void>(() => {});
  const openRefCB = useRef<() => void>(() => {});
  const openRefCP = useRef<() => void>(() => {});

  const [_current, setCurrent] = useState<string>(current);

  const _onFileDrop = (tag: string, file: any) => {
    setCurrent("");
    onFileDrop(tag, file);
  };

  return (
    <Box pos="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Accordion
        variant="contained"
        disableChevronRotation
        value={_current}
        onChange={(e) => setCurrent(`${e}`)}
      >
        <Accordion.Item value="cartaoCNPJ">
          <Accordion.Control
            icon={
              <ThemeIcon
                color={files?.cartaoCNPJ ? "teal" : "blue"}
                size={24}
                radius="xl"
              >
                {files?.cartaoCNPJ ? (
                  <IconCircleCheck size="1rem" />
                ) : (
                  <IconFileUpload size="0.9rem" />
                )}
              </ThemeIcon>
            }
            disabled={files?.cartaoCNPJ}
          >
            Cartão CNPJ
          </Accordion.Control>
          <Accordion.Panel>
            <UploadDropZone
              accept={["application/pdf", "image/x-png", "image/jpeg"]}
              multiple={false}
              openRef={openRefCNPJ}
              onDrop={(file) => _onFileDrop("CARTAO_CNPJ", file)}
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="balancoFinanceiro">
          <Accordion.Control
            icon={
              <ThemeIcon
                color={files?.balancoFinanceiro ? "teal" : "blue"}
                size={24}
                radius="xl"
              >
                {files?.balancoFinanceiro ? (
                  <IconCircleCheck size="1rem" />
                ) : (
                  <IconFileUpload size="0.9rem" />
                )}
              </ThemeIcon>
            }
            disabled={files?.balancoFinanceiro}
          >
            Balanço Financeiro do ano anterior
          </Accordion.Control>
          <Accordion.Panel>
            <UploadDropZone
              accept={[
                "application/pdf",
                "image/x-png",
                "image/jpeg",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
              multiple={false}
              openRef={openRefCB}
              onDrop={(file) => _onFileDrop("BALANCO_FINANCEIRO", file)}
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="comprovantePagamento">
          <Accordion.Control
            icon={
              <ThemeIcon
                color={files?.comprovantePagamento ? "teal" : "blue"}
                size={24}
                radius="xl"
              >
                {files?.comprovantePagamento ? (
                  <IconCircleCheck size="1rem" />
                ) : (
                  <IconFileUpload size="0.9rem" />
                )}
              </ThemeIcon>
            }
            disabled={files?.comprovantePagamento}
          >
            Comprovante de pagamento
          </Accordion.Control>
          <Accordion.Panel>
            <UploadDropZone
              multiple={false}
              openRef={openRefCP}
              onDrop={(file) => _onFileDrop("COMPROVANTE_PAGAMENTO", file)}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Box>
  );
};
