import { Text, Divider, Modal } from "@mantine/core"


export const CustomModal = ({ tag, modalFormState, title, children, full }: any) => {

    return (
        <Modal
            opened={modalFormState.isOpened() && modalFormState.parameters.tag === tag}
            onClose={modalFormState.close}
            fullScreen={full}
            title={
                <Text fz="xl" fw={500}>
                    { title }
                </Text>
            }
            overlayProps={{ opacity: 0.5, blur: 4 }}
            transitionProps={{ transition: "pop"  }}
        >
            <Divider />
            { children }
        </Modal>
    );

}