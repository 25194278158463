import {
  ActionIcon,
  Group,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconEye, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const ProspeccaoListPage = () => {

  const { t } = useTranslation();
  const location = useLocation();
  const authority = SecurityProvider.getAuthority();
  const reducer = useRemoteDataReducer();
  const { fetchPage } = useRemoteOperation(
    { reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed) {
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    } else if (status?.isInitiating) {
      onPaginationChange({ page: 0, size: 10, sort: "numeroProspeccao,desc" });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.prospeccoes}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => 
    fetchPage({
      endpoint: `${RemoteEndpoints.prospeccoes}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "numeroProspeccao,desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <PageLayout
      title="Prospecções"
    >
      <DataGridComponent
        visible={true}
        state={reducer.state}
        noRecordText="Nenhuma prospecção encontrada"
        onPaginationChange={onPaginationChange}
        columns={[
          { 
            accessor: "numeroProspeccao", 
            title: "Número",
            render: ({ numeroProspeccao }: any) => (
              <Text>
                {numeroProspeccao ? numeroProspeccao : "-"}
              </Text>
            ), 
          },
          {
            hidden: !["ADMINISTRATOR"].includes(authority),
            accessor: "empresa",
            title: "Empresa",
            render: ({ empresa }: any) =>
              <EmpresaChip empresa={empresa} />
          },
          { accessor: "objetivo", title: "Objetivo", render: ({ objetivo }: any) => <>{InfoUtils.limitText(objetivo, 20)}</> },
          {
            accessor: "status", title: "Status", hidden: false,
            render: ({ status }: any) => (
              <CustomBadge value={status} options={[
                { value: "INICIADO", text: "Iniciado", color: "blue" },
                { value: "FINALIZADO", text: "Finalizado", color: "gray" },
              ]} />
            ),  
          },
          {
            accessor: "iniciadoEm",
            title: "Iniciado em",
            render: ({ iniciadoEm }: any) =>
              <Text size="xs" color="dimmed">
                {moment(iniciadoEm, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
          },
          { accessor: "finalizadoEm", title: "Finalizado em" },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <Link to={`/prospeccoes/${id}`}>
                  <ActionIcon
                    color="blue"
                  >
                    <IconEye size={26} />
                  </ActionIcon>
                </Link>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );

};
