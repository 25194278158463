import { LoadingOverlay } from "@mantine/core";
import { useCheckSecurityStatus } from "hooks/useCheckSecurityStatus";
import { useEffect } from "react";

export const TokenChecker = ({ children }: any) => {

    const { check, reducer } = useCheckSecurityStatus();

    useEffect(() => {
        check();
        const timer = setInterval(() => {
            check();
        }, 60000);
        return () => clearTimeout(timer);
    });

    return (
        <>
            {
                reducer.state.status?.isFetching &&  <LoadingOverlay visible={true} />
            }
            <>
                { children }
            </>
        </>
    )

}