import {
  ActionIcon,
  Avatar,
  Badge,
  Box,
  Divider,
  Group,
  Paper,
  Progress,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconArrowRight,
  IconBusinessplan,
  IconCoin,
  IconStar,
} from "@tabler/icons-react";
import { PageLayout } from "shared/custom/page/PageLayout";

export const RecomendacaoProcessosPage = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const processos = [
    {
      id: "8aeba8f2-08fe-484f-acbc-d995a1763ce7",
      nomeFantasia: "Petróleo Brasileiro S.A. - Petrobrás",
      dataLimite: "01/01/2024",
      totalItens: 200,
      numeroProcesso: 457512,
      categorias: ["Caminhões Tanque"],
    },
    {
      id: "8aeba8f2-08fe-484f-acbc-d995a1763ce7",
      nomeFantasia: "Vale S.A.",
      dataLimite: "03/03/2024",
      numeroProcesso: 98625,
      totalItens: 50,
      categorias: ["Escavadeiras", "Caçambas"],
    },
    {
      id: "8aeba8f2-08fe-484f-acbc-d995a1763ce7",
      nomeFantasia: "Construtora Tenda",
      totalItens: 7800,
      numeroProcesso: 15442,
      categorias: ["Ferramentas", "Betoneiras", "Galpões"],
    },
    {
      id: "8aeba8f2-08fe-484f-acbc-d995a1763ce7",
      nomeFantasia: "Grupo São Geraldo",
      numeroProcesso: 958751,
      dataLimite: "03/03/2025",
      totalItens: 70,
      categorias: ["Empilhadeiras", "Pallets"],
    },
  ];

  return (
    <PageLayout title="Licitações Ativas">
      {processos.map((item) => (
        <Paper withBorder my="xs" p="md">
          <Group position="apart" spacing="xl" noWrap>
            <Box style={{ width: "100%" }}>
              <Group position="apart" noWrap spacing="xl">
                <Group noWrap>
                  <Avatar size="lg" src="https://placehold.co/400" />
                  <div>
                    <Text fw={500}>{item.nomeFantasia}</Text>
                    <Text size="xs" color="dimmed">
                      Processo: {item.numeroProcesso}
                    </Text>
                    <Text size="xs" color="dimmed">
                      Disponível até {item.dataLimite}
                    </Text>
                  </div>
                </Group>
                <Group position="apart" noWrap spacing="xl">
                  <Box>
                    <Group spacing="md">
                      <Tooltip label="Bom pagador">
                        <ThemeIcon color="red.8" size={24} radius="xl">
                          <IconCoin size="1rem" />
                        </ThemeIcon>
                      </Tooltip>
                      <Tooltip label="Faturamento alto">
                        <ThemeIcon color="indigo.9" size={24} radius="xl">
                          <IconBusinessplan size="1rem" />
                        </ThemeIcon>
                      </Tooltip>
                      <Tooltip label="Excelente Classificação">
                        <ThemeIcon color="teal" size={24} radius="xl">
                          <IconStar size="1rem" />
                        </ThemeIcon>
                      </Tooltip>
                    </Group>
                    <Progress
                      value={75}
                      label="75%"
                      size="lg"
                      radius="xl"
                      my="md"
                    />
                  </Box>
                </Group>
              </Group>
              <Divider mt="xs" size="xs" />
              <Group position="apart" noWrap spacing="xl">
                <Box mt="sm">
                  {item.categorias.map((categoria) => (
                    <Badge size="md" mr="xs">
                      {categoria}
                    </Badge>
                  ))}
                  <Badge size="md" mr="xs" color="gray">
                    + {item.totalItens} itens
                  </Badge>
                </Box>
              </Group>
            </Box>
            <div>
              <a href={`/processos/${item.id}`}>
                <Tooltip label="Visualizar Processo">
                  <ActionIcon size="xl" radius="md" variant="light">
                    <IconArrowRight size="1.5rem" />
                  </ActionIcon>
                </Tooltip>
              </a>
            </div>
          </Group>
        </Paper>
      ))}
    </PageLayout>
  );
};
