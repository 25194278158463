import {
    Avatar,
    Badge,
    Box,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    SimpleGrid,
    Skeleton,
    Text,
} from "@mantine/core";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { Hidden } from "shared/custom/Hidden";

export const ExemplarPanel = ({ loading, exemplar }: any) => {

    return (
        <>
            <LoadingOverlay visible={loading} overlayBlur={2} />
            <SimpleGrid
                cols={1}
                breakpoints={[
                    { minWidth: "xl", cols: 4 },
                    { minWidth: "lg", cols: 3 },
                    { minWidth: "md", cols: 2 },
                    { minWidth: "sm", cols: 2 },
                ]}
            >
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Locatário</Text>
                        <EmpresaChip empresa={exemplar?.locatario} />
                    </Box>
                </Group>
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Número de Série/Partimônio</Text>
                        <Hidden hide={!loading}>
                            <Skeleton height={8} radius="xl" />
                        </Hidden>
                        <Hidden hide={loading}>
                            <Text size="sm">{exemplar?.numeroSeriePatrimonio}</Text>
                        </Hidden>
                    </Box>
                </Group>
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Detalhes do produto</Text>
                        <Hidden hide={!loading}>
                            <Skeleton height={8} radius="xl" />
                        </Hidden>
                        <Hidden hide={loading}>
                            <Text size="sm">{exemplar?.detalhes}</Text>
                        </Hidden>
                    </Box>
                </Group>
                <Group>
                    <Divider size="md" orientation="vertical" />
                    <Box>
                        <Text size="xs" fw={500} color="gray">Classificação</Text>
                        <Hidden hide={!loading}>
                            <Skeleton height={8} radius="xl" />
                        </Hidden>
                        <Hidden hide={loading}>
                            <Badge size="sm">{exemplar?.estado}</Badge>
                        </Hidden>
                    </Box>
                </Group>
            </SimpleGrid>
        </>
    );
};
