import { ActionIcon, Badge, Grid, Group, Paper, Skeleton, Text } from "@mantine/core";
import { IconFile, IconEye } from "@tabler/icons-react";
import { Hidden } from "shared/custom/Hidden";

export const PreCadastroEmpresaView = ({ precadastro }: any) => {
  return (
    <>
      <Text fz="md" mt="md" mb="md" fw={500}>
        Informações Gerais da Empresa
      </Text>
      <Grid>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Papel</Text>
          <Badge color="blue">{precadastro?.papel}</Badge>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>CNPJ</Text>
          <Text size="sm">{precadastro?.cnpj}</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Nome</Text>
          <Text size="sm">{precadastro?.nome}</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Email</Text>
          <Text size="sm">{precadastro?.email}</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Telefone</Text>
          <Text size="sm">{precadastro?.telefone}</Text>
        </Grid.Col>
      </Grid>
      <Hidden hide={!precadastro?.empresaReferencia1 && (["CADASTRADO", "ATENDIDO"].includes(precadastro?.status))}>
        <Text fz="md" mt="md" fw={500}>
          Referências Informadas
        </Text>
        <Grid>
          <Grid.Col span={4}>
            <Text size="xs" fw={500}>Empresa 1</Text>
            <Text size="sm">{precadastro?.empresaReferencia1}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text size="xs" fw={500}>Telefone Empresa 2</Text>
            <Text size="sm">{precadastro?.telefoneReferencia1}</Text>
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <Text size="xs" fw={500}>Empresa 2</Text>
            <Text size="sm">{precadastro?.empresaReferencia2}</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text size="xs" fw={500}>Telefone Empresa 2</Text>
            <Text size="sm">{precadastro?.telefoneReferencia2}</Text>
          </Grid.Col>
        </Grid>
      </Hidden>
      <Hidden hide={(["CADASTRADO", "ATENDIDO"].includes(precadastro?.status))}>
        <Text fz="md" mt="md" fw={500}>
          Documentos Anexados
        </Text>
        <Hidden hide={!precadastro?.cartaoCNPJ?.url}>
          <Paper withBorder p="xs" mt="sm">
            <Group position="apart" noWrap spacing="xl">
              <Group noWrap>
                <IconFile size={32} color="gray" />
                <div>
                  <Text
                    component="a"
                    href={precadastro?.cartaoCNPJ?.url}
                    size="sm"
                  >
                    Cartão CNPJ
                  </Text>
                </div>
              </Group>
              <ActionIcon component="a" href={precadastro?.cartaoCNPJ?.url}>
                <IconEye />
              </ActionIcon>
            </Group>
          </Paper>
        </Hidden>
        <Hidden hide={!precadastro?.balancoFinanceiro?.url}>
          <Paper withBorder p="xs" mt="sm">
            <Group position="apart" noWrap spacing="xl">
              <Group noWrap>
                <IconFile size={32} color="gray" />
                <div>
                  <Text
                    component="a"
                    href={precadastro?.balancoFinanceiro?.url}
                    size="sm"
                  >
                    Balanço Financeiro
                  </Text>
                </div>
              </Group>
              <ActionIcon
                component="a"
                href={precadastro?.balancoFinanceiro?.url}
              >
                <IconEye />
              </ActionIcon>
            </Group>
          </Paper>
        </Hidden>
        <Hidden hide={!precadastro?.comprovantePagamento?.url}>
          <Paper withBorder p="xs" mt="sm">
            <Group position="apart" noWrap spacing="xl">
              <Group noWrap>
                <IconFile size={32} color="gray" />
                <div>
                  <Text
                    component="a"
                    href={precadastro?.comprovantePagamento?.url}
                    size="sm"
                  >
                    Comprovante de Pagamento
                  </Text>
                </div>
              </Group>
              <ActionIcon
                component="a"
                href={precadastro?.comprovantePagamento?.url}
              >
                <IconEye />
              </ActionIcon>
            </Group>
          </Paper>
        </Hidden>
      </Hidden>
    </>
  );
};
