import {
  Box,
  Card,
  Container,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconBarcode,
  IconPointerDollar,
  IconQrcode,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PageLayout } from "shared/custom/page/PageLayout";
import Swal from "sweetalert2";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { AtivoLocacaoPanel } from "partials/panels/AtivoLocacao.panel";
import { Hidden } from "shared/custom/Hidden";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const AtivoLocacaoViewPage = () => {

  const reducer = useRemoteDataReducer();
  const solicitacaoReducer = useRemoteDataReducer();
  const reducerEmpresa = useRemoteDataReducer();
  const parametroLojaReducer = useRemoteDataReducer();

  const authorizationToken = SecurityProvider.getAuthorizationToken();
  const resourceGroup = authorizationToken.resource_group;
  const navigate = useNavigate();
  const params = useParams<any>();
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });
  const solicitacaoOperations = useRemoteOperation({
    reducer: solicitacaoReducer,
  });

  const parametrosOperations = useRemoteOperation({
    reducer: parametroLojaReducer,
  });

  const empresaOperations = useRemoteOperation({ reducer: reducerEmpresa });
  const { data, status } = reducer.state;
  const ativo = reducer.state?.data?.object;
  const loadingState = !!parametroLojaReducer.state.status?.isFetching || !!reducer.state.status?.isFetching;

  const context = new URLSearchParams(useLocation().search).get("context");
  const prospectionID = new URLSearchParams(useLocation().search).get("prospectionID");
  const prospectionItemID = new URLSearchParams(useLocation().search).get("prospectionItemID");

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isSuccessful && data?.object) {
      findEmpresa(data?.object?.empresa?.id);
      findParametrosAtivo(data?.object?.empresa?.id);
    }
  }, [status]);

  useEffect(() => {
    if (solicitacaoReducer.state.status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (solicitacaoReducer.state.status?.isSubmitted) {
      Swal.fire({
        title: "Tudo certo",
        text: "A sua solicitação foi enviada para a locadora.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(
            `/solicitacoes/${solicitacaoReducer.state.data?.object?.id}`,
          );
        }
      });
    }
  }, [solicitacaoReducer.state.status]);

  useEffect(() => {
    findAtivo(params.id);
  }, [params.id]);

  const enviarSolicitacao = () => {
    const solicitacao = {
      canal: "LOJA",
      modo: "SOLICITACAO",
      ativos: [ativo],
    };
    solicitacaoOperations.create({
      endpoint: RemoteEndpoints.solicitacoes,
      properties: { reducer: solicitacaoReducer },
      data: solicitacao,
      headers: RequestConfigGenerator.build(),
    });
  };

  const findEmpresa = (id: any) =>
    empresaOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.empresa}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const findAtivo = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.ativo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const findParametrosAtivo = (id: any) =>
    parametrosOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.lojas}/loja/${id}/parametros`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <PageLayout>
      <Container size="xl">
        <Card withBorder radius="md">
          <LoadingOverlay visible={loadingState} />
          <AtivoLocacaoPanel
            images={reducer?.state?.data?.object?.images}
            ativo={ativo}
            context={context}
            prospectionID={prospectionID}
            itemID={prospectionItemID}
            hideSolicitacaoButton={context === "prospection" || ativo?.empresa?.id === resourceGroup}
            hideOrcamentoButton={ativo?.empresa?.id === resourceGroup}
            hideAddProspeccaoButton={context === "prospection" || ativo?.empresa?.id === resourceGroup}
            onAddSolicitacao={`/solicitar-locacao/${ativo?.id}?context=prospection&prospectionID=${prospectionID}&prospectionItemID=${prospectionItemID}`}
            onAddOrcamento={`/solicitar-orcamento/${ativo?.id}?context=prospection&prospectionID=${prospectionID}&prospectionItemID=${prospectionItemID}`}
          />
          <Grid gutter="md">
            <Grid.Col span={7}>
              <Box mt="md">
                <Text size="lg" fw={500} mb="md">
                  Detalhes sobre o ativo
                </Text>
                <Paper withBorder p="md">
                  <Text size="sm">{ativo?.detalhes}</Text>
                </Paper>
              </Box>
            </Grid.Col>
            <Grid.Col span={5}>
              <Box mt="md">
                <Text size="lg" fw={500} mb="md">
                  Detalhes sobre a empresa
                </Text>
                <Paper withBorder p="md">
                  <Text size="sm" color="dimmed">
                    Disponibilizado por
                  </Text>
                  <Box mt="sm">
                    <EmpresaChip empresa={ativo?.empresa} />
                  </Box>
                </Paper>
              </Box>
              <Hidden hide={!parametroLojaReducer.state.data?.object?.metodosPagamento}>
                <Paper withBorder p="md" mt="md">
                  <Text size="sm" color="dimmed">
                    Formas de Pagamento
                  </Text>
                  <Group mt="md">
                    {parametroLojaReducer.state.data?.object?.metodosPagamento?.map((item: any) =>
                      <Box>
                        <CustomBadge value={item} options={[
                          { value: "PIX", text: "Pix", color: "blue", icon: <IconQrcode size="xs" /> },
                          { value: "BOLETO_BANCARIO", text: "Depósito Bancário", color: "blue", icon: <IconBarcode size="xs" /> },
                          { value: "DEPOSITO_BANCARIO", text: "Depósito Bancário", color: "blue", icon: <IconPointerDollar size="xs" /> },
                        ]} />
                      </Box>)
                    }
                  </Group>
                </Paper>
              </Hidden>
            </Grid.Col>
          </Grid>
        </Card>
      </Container>
    </PageLayout>
  );

};
