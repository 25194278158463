import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import moment from "moment";
import "moment/locale/pt-br";
import ReactDOM from "react-dom/client";
import RouteListComponent from "routes";
import reportWebVitals from "./reportWebVitals";
import "yet-another-react-lightbox/styles.css";

moment.locale();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <MantineProvider withNormalizeCSS withGlobalStyles>
    <Notifications />
    <ModalsProvider>
      <RouteListComponent />
    </ModalsProvider>
  </MantineProvider>
);

reportWebVitals(console.log);
