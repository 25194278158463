import {
  Button,
  Group,
  Title
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { useUploadDocumentoProcesso } from "hooks/uploads/useUploadDocumentoProcesso";
import { DocumentoDataGrid } from "partials/datagrids/Documento.datagrid";
import { DocumentoProcessoPanel } from "partials/panels/uploads/DocumentoProcesso.panel";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { IconCheck, IconX } from "@tabler/icons-react";

export const ProcessoDocumentosTab = ({ processoID, locadorID }: any) => {

  const authority = SecurityProvider.getAuthority();
  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const uploadDocumentProcesso = useUploadDocumentoProcesso({
    reducer: reducer,
  });
  const drawerForm = useDrawerForm({ reducer: reducer });
  const { fetchPage, create } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (processoID && !data?.object) {
      reducer.dispatch({ type: "INITIATING" });
      onPaginationChange(processoID, { page: 0, size: 10, sort: "desc" });
    }
  }, [location]);

  useEffect(() => {
    if (status?.isSubmitted) {
      notifications.show({
        message: "Documento enviado com sucesso.",
        color: "teal",
        icon: <IconCheck />,
      });
    }
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
  }, [status]);

  const onPaginationChange = (id: any, pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.processo}/${id}/documentos`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onSelectDocument = (data: any) => {
    create({
      endpoint: `${RemoteEndpoints.processo}/${processoID}/documentos/associar`,
      data: {
        documento: data.code,
      },
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUploadDocument = (formData: any) => {
    const submitData = {
      title: formData?.title,
      description: formData?.description,
    };
    uploadDocumentProcesso.execute(processoID, submitData, formData?.file);
  };

  return (
    <>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="enviarSelecionarDocumentoProcesso"
        title="Enviar/Selecionar Documento do Processo"
      >
        <DocumentoProcessoPanel
          processoID={processoID}
          locatarioID={data?.object?.locatario?.id}
          onSelectDocument={onSelectDocument}
          onUploadDocument={onUploadDocument}
          reducer={reducer}
        />
      </CustomDrawer>
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Documentos</Title>
        <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
          <Button onClick={() => drawerForm.open("enviarSelecionarDocumentoProcesso")}>Enviar documentos</Button>
        </Hidden>
      </Group>
      <DocumentoDataGrid
        reducer={reducer}
        onPaginationChange={(pagination: PaginationProps) => onPaginationChange(processoID, pagination)}
      />
    </>
  );
};
