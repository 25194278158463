import decode from "jwt-decode";

export default class SecurityProvider {
  static authenticate(token: any, refreshToken: any) {
    sessionStorage.setItem("AuthToken", token);
    sessionStorage.setItem("RefreshToken", refreshToken);
  }

  static getRefreshToken() {
    return sessionStorage.getItem("RefreshToken") || "";
  }

  static deauthenticate() {
    try {
      sessionStorage.setItem("AuthToken", "");
      sessionStorage.setItem("RefreshToken", "");
    } catch (e) {
      console.error(e);
    }
  }

  static isAuthenticated(): boolean {
    try {
      const token = sessionStorage.getItem("AuthToken");
      const hasToken = token !== null && token !== "" && token !== undefined;
      return hasToken;
    } catch (e) {
      return false;
    }
  }

  static getAuthorizationToken(): any {
    try {
      const token = sessionStorage.getItem("AuthToken") || "";
      const decoded = decode(token);
      return decoded;
    } catch (e) {
      return null;
    }
  }

  static getAuthorizationStringToken(): any {
    return sessionStorage.getItem("AuthToken") || "";
  }

  static getAuthority() {
    const authorities = this.getAuthorizationToken()?.authorities;
    return authorities[0];
  }

  static checkTokenExpired(): boolean {
    const decodedToken = SecurityProvider.getAuthorizationToken();
    return new Date().getTime() / 1000 > decodedToken.exp;
  }
}
