import {
  ActionIcon,
  Box,
  Button,
  Card,
  Drawer,
  Group,
  Image,
  Stack,
  Skeleton,
  Stepper,
  Text,
  TextInput,
  ThemeIcon,
  Title,
  createStyles,
  rem,
  Paper,
  Divider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCheck, IconPlus, IconX } from "@tabler/icons-react";
import "../styles/styles.css";
import { useState } from "react";
import { CondicoesTab } from "pages/empresa/minha-empresa/tabs/Condicoes.tab";
import { AtivoForm } from "partials/forms/Ativo.form";

const ICON_SIZE = rem(120);

const useStyles = createStyles((theme) => ({
  icon: {
    position: "relative",
    top: `calc(-${ICON_SIZE} / 3)`,
    left: `calc(50% - ${ICON_SIZE} / 2)`,
  },
}));

export const PropostasLocadoresPanel = ({ data }: any) => {
  const drawer = useDisclosure(false);

  const [active, setActive] = useState(1);
  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const { classes } = useStyles();

  return (
    <>
      <Drawer
        opened={drawer[0]}
        onClose={drawer[1].close}
        position="right"
        size="xl"
        title={
          <Text fz="xl" fw={500}>
            Enviar proposta
          </Text>
        }
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Divider />
        <Stepper active={active} onStepClick={setActive} breakpoint="sm">
          <Stepper.Step label="Primeiro Passo" description="Definir Ativo">
            <TextInput
              mt="md"
              mb="md"
              description="Insira um código de barras ou"
              label="Termo de busca"
              placeholder="7890000000000"
            />
            <Box p="xl">
              <Stack>
                <Button variant="light">Propor um item já cadastrado</Button>
                <Text align="center">ou</Text>
                <Button variant="light">
                  Cadastrar um item para enviar proposta
                </Button>
              </Stack>
            </Box>
            <AtivoForm />
          </Stepper.Step>
          <Stepper.Step label="Segundo Passo" description="Definir Condições">
            <Box p="xl">
              <Stack>
                <Button variant="light" onClick={() => setActive(3)}>
                  Usar condições já definidas da sua empresa
                </Button>
                <Text align="center">ou</Text>
                <Button variant="light">Usar condições personalizadas</Button>
              </Stack>
            </Box>
            <CondicoesTab />
          </Stepper.Step>
          <Stepper.Completed>
            <ThemeIcon
              className={classes.icon}
              size={ICON_SIZE}
              radius={ICON_SIZE}
            >
              <IconCheck size={ICON_SIZE} />
            </ThemeIcon>

            <Text>Completed, click back button to get to previous step</Text>
            <Button variant="light">Tudo joia, pode fechar essa janela</Button>
          </Stepper.Completed>
        </Stepper>
      </Drawer>
      <Group position="apart" noWrap spacing="xl" mt="md" mb="xl">
        <div>
          <Title order={3}>Propostas de locadores</Title>
        </div>
      </Group>
      {data.map((item: any) => (
        <Paper withBorder my={"xs"} p="md">
          <Group position="apart" noWrap spacing="xl">
            <div>
              <Text>{item.item.descricao}</Text>
              <Text size="xs" color="dimmed">
                {item.item.marca}
              </Text>
              <Text size="xs" color="dimmed">
                {item.item.dataInicio} - {item.item.periodo}
              </Text>
            </div>
            <Button
              leftIcon={<IconPlus />}
              size="xs"
              onClick={drawer[1].open}
              variant="white"
              compact
            >
              Enviar proposta
            </Button>
          </Group>
          {item.propostas.map((proposta: any) => (
            <Paper withBorder p="md">
              <Group position="apart" noWrap spacing="xl">
                <div>
                  <Image
                    width={80}
                    height={80}
                    src={null}
                    alt="With default placeholder"
                    withPlaceholder
                  />
                </div>
                <div>
                  <Skeleton height={8} radius="xl" />
                  <Text>{proposta.empresa.nomeFantasia}</Text>
                  <Text size="xs" color="dimmed">
                    {proposta.item.descricao} - {proposta.item.marca} -{" "}
                    {proposta.item.modelo}
                  </Text>
                  <a href={`/ativos/${proposta.item.id}`}>
                    <Button>Visulizar detalhes do ativo</Button>
                  </a>
                  <Text>Frete: {proposta.condicoes.frete}</Text>
                </div>
                <Group>
                  <ActionIcon variant="default" radius="md" size={36}>
                    <IconCheck size="1.1rem" stroke={1.5} />
                  </ActionIcon>
                  <ActionIcon variant="default" radius="md" size={36}>
                    <IconX size="1.1rem" stroke={1.5} />
                  </ActionIcon>
                </Group>
              </Group>
            </Paper>
          ))}
          {/**/}
        </Paper>
      ))}
    </>
  );
};
