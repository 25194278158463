import {
  ActionIcon,
  Box,
  Button,
  CloseButton,
  Group,
  LoadingOverlay,
  Paper,
  Text,
  TextInput,
  Textarea,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconFile,
  IconFileSearch,
  IconFileUpload,
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect, useState } from "react";
import { UploadDropZone } from "shared/components/UploadDropZone.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PaginationProps } from "shared/models/Pagination.props";

const useStyles = createStyles((theme) => ({
  button: {
    display: "flex",
    width: "100%",
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.lg,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[0],
    },
  },
}));

export const DocumentoProcessoPanel = ({
  reducer,
  processoID,
  onUploadDocument,
  onSelectDocument,
  locatarioID,
}: any) => {

  const documentosReducer = useRemoteDataReducer();
  const remoteDocumentosOperations = useRemoteOperation({
    reducer: documentosReducer,
  });
  const [modoSelecao, setModoSelecao] = useState("");
  const { classes, cx } = useStyles();
  const { status, data } = documentosReducer.state;

  const form = useForm({
    validateInputOnBlur: ["file", "title", "description"],
    initialValues: {
      file: new File([], "", undefined),
      title: "",
      description: "",
    },
    validate: {
      title: (value) => (value !== "" ? null : "Informe o titulo do documento"),
      description: (value) =>
        value !== "" ? null : "Informe a descrição do documento",
      file: (value) =>
        value.name !== "" ? null : "Informe o arquivo do documento",
    },
  });

  useEffect(() => {
    if (status?.isSubmitted && data) {
      onUploadDocument(data?.object);
    }
  }, [status]);

  useEffect(() => {
    if (modoSelecao === "selecionar") {
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    }
  }, [modoSelecao]);

  const onPaginationChange = (pagination: PaginationProps) => {
    remoteDocumentosOperations.fetchPage({
      endpoint: `${RemoteEndpoints.documentos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      properties: { reducer: remoteDocumentosOperations },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onSubmit = async (e: any) => {
    if (form.values.file?.name) {
      onUploadDocument(form.values);
    }
  };

  const onFormError = (e: any) =>
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });

  return (
    <>
      <Hidden hide={modoSelecao}>
        <UnstyledButton
          mt="md"
          onClick={() => setModoSelecao("enviar")}
          className={cx(classes.button)}
        >
          <Box mt="xs">
            <IconFileUpload size={32} color="gray" />
          </Box>
          <Box ml="md">
            <Text fw={500} mb={7} sx={{ lineHeight: 1 }}>
              Enviar documento
            </Text>
            <Text fz="sm" c="dimmed">
              Escolha um novo documento do seu computador
            </Text>
          </Box>
        </UnstyledButton>
        <UnstyledButton
          mt="md"
          onClick={() => setModoSelecao("selecionar")}
          className={cx(classes.button)}
        >
          <Box mt="xs">
            <IconFileSearch size={32} color="gray" />
          </Box>
          <Box ml="md">
            <Text fw={500} mb={7} sx={{ lineHeight: 1 }}>
              Selecionar documento
            </Text>
            <Text fz="sm" c="dimmed">
              Selecione um documento já enviado anteriomente
            </Text>
          </Box>
        </UnstyledButton>
      </Hidden>
      <Hidden hide={!modoSelecao}>
        <Hidden hide={modoSelecao !== "enviar"}>
          <form onSubmit={form.onSubmit((a) => onSubmit(a), onFormError)}>
            <TextInput
              {...form.getInputProps("title")}
              mt="md"
              label="Título"
              maxLength={100}
              placeholder="Título curto do documento"
              description="Máximo de 100 caracteres"
            />
            <Textarea
              mt="md"
              placeholder="Descrição"
              label="Descrição"
              maxLength={1000}
              {...form.getInputProps("description")}
            />
            <Hidden hide={form.values.file?.name}>
              <UploadDropZone
                mt="md"
                multiple={false}
                onDrop={(files) => form.setValues({ file: files[0] })}
                accept={["application/pdf"]}
              />
            </Hidden>
            <Hidden hide={!form.values.file?.name}>
              <Paper withBorder p="xs" mt="md">
                <Group position="apart" noWrap spacing="xl">
                  <Group noWrap>
                    <IconFile size={32} color="gray" />
                    <div>
                      <Text size="sm">{form.values.file.name}</Text>
                    </div>
                  </Group>
                  <CloseButton
                    onClick={() =>
                      form.setValues({ file: new File([], "", undefined) })
                    }
                    aria-label="Remover item selecionado"
                  />
                </Group>
              </Paper>
            </Hidden>
            <Group position="apart" mt="lg">
              <Box></Box>
              <Button
                type="submit"
                loading={reducer?.state?.status?.isSubmitting}
              >
                Confirmar
              </Button>
            </Group>
          </form>
        </Hidden>
        <Hidden hide={modoSelecao !== "selecionar"}>
          <Box mt="md">
            <LoadingOverlay visible={reducer?.state?.status?.isSubmitting } overlayBlur={2} />
            <DataGridComponent
              visible={true}
              state={documentosReducer.state}
              noRecordText="Nenhum documento encontrado"
              onPaginationChange={onPaginationChange}
              columns={[
                { accessor: "title", title: "Título" },
                { accessor: "description", title: "Descrição" },
                { accessor: "creationTime", title: "Hora/Data Criação" },
                {
                  accessor: "actions",
                  textAlignment: "right",
                  render: (documento: any) => (
                    <Group spacing={12} position="right" noWrap>
                      <ActionIcon
                        color="blue"
                        onClick={() => onSelectDocument(documento)}
                      >
                        <IconCheck size={26} />
                      </ActionIcon>
                    </Group>
                  ),
                },
              ]}
            />
          </Box>
        </Hidden>
      </Hidden>
    </>
  );
};
