import {
  Box,
  Button,
  Card,
  Divider,
  Group,
  Modal,
  Paper,
  Stepper,
  Text,
  Title,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AtivosALocarPanel } from "partials/panels/AtivosALocar.panel";
import { ProcessoPanel } from "partials/views/Processo.view";
import { useState } from "react";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import "../../styles/styles.css";
import { NegociacaoContratoPanel } from "partials/panels/NegociacaoContrato.panel";
import { PropostasLocadoresPanel } from "deadcode/PropostasLocadores.panel";

export const NegociacaoLicitacaoPage = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const contrato = true;
  const [active, setActive] = useState(0);

  const nextStep = () =>
    setActive((current: any) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current: any) => (current > 0 ? current - 1 : current));

  const itemsProcesso = [
    {
      descricao: "Furadeira de Impacto",
      marca: "Bosch",
      dataInicio: "30/09/2023",
      periodo: "Quinzenal",
    },
    {
      descricao: "Bomba submersa 762",
      marca: "WEG",
      dataInicio: "01/05/2023",
      periodo: "Mensal",
    },
    {
      descricao: "Caminhão Baú",
      marca: "Mercedes",
      dataInicio: "15/08/2023",
      periodo: "Diário",
    },
  ];

  const propostasItemProcesso = [
    {
      item: itemsProcesso[0],
      propostas: [
        {
          empresa: {
            nomeFantasia: "AVS Manutenções",
          },
          item: {
            id: "19ab4a00-00b4-4e7c-bfe4-b7f774ac9ae5",
            descricao: "Furadeira de Impacto",
            marca: "Bosch",
            modelo: "750w",
          },
          condicoes: {},
        },
      ],
    },
    {
      item: itemsProcesso[1],
      propostas: [
        {
          empresa: {
            nomeFantasia: "AVS Manutenções",
          },
          item: {
            id: "19ab4a00-00b4-4e7c-bfe4-b7f774ac9ae5",
            descricao: "Furadeira de Impacto",
            marca: "Bosch",
            modelo: "750w",
          },
          condicoes: {},
        },
      ],
    },
    {
      item: itemsProcesso[2],
      propostas: [
        {
          empresa: { nomeFantasia: "AVS Manutenções" },
          item: {
            id: "19ab4a00-00b4-4e7c-bfe4-b7f774ac9ae5",
            descricao: "Furadeira de Impacto",
            marca: "Bosch",
            modelo: "750w",
          },
          condicoes: {
            frete: "Envio gratuito",
          },
        },
      ],
    },
  ];

  return (
    <PageLayout
      title="Processo #1234"
      headerActions={
        <Group position="center">
          <Hidden hide={active !== 0}>
            <Button
              disabled={itemsProcesso.length < 1}
              onClick={() => setActive(active + 1)}
            >
              Concluir Definições
            </Button>
          </Hidden>
          <Hidden hide={active !== 1}>
            <Button onClick={() => setActive(active + 1)}>
              Concluir Negociações
            </Button>
          </Hidden>
          <Hidden hide={active !== 2}>
            <Button onClick={() => setActive(active + 1)}>
              Concluir Contrato
            </Button>
          </Hidden>
          <Hidden hide={active !== 3}>
            <Button onClick={() => setActive(active + 1)}>
              Concluir Processo
            </Button>
          </Hidden>
        </Group>
      }
    >
      <Modal
        opened={opened}
        onClose={close}
        title="Visualizar Contrato"
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <iframe
          height="100%"
          width="100%"
          className="iframe"
          src="https://www.habitusbrasil.com/wp-content/uploads/2016/03/habitus-modelo3-contrato-marcenaria.pdf"
        ></iframe>
      </Modal>
      <Paper withBorder p="xl" px="xl">
        <Stepper active={active} size="xs">
          <Stepper.Step
            label="Definição"
            description="Definição de ativos"
          ></Stepper.Step>
          <Stepper.Step
            label="Negociação"
            description="Negociação de ativos"
          ></Stepper.Step>
          {contrato && (
            <Stepper.Step
              label="Formalização"
              description="Definição do contrato"
            ></Stepper.Step>
          )}
          {contrato && (
            <Stepper.Step
              label="Contratação"
              description="Assinatura de contrato"
            ></Stepper.Step>
          )}
          {!contrato && (
            <Stepper.Step
              label="Aprovação do termo"
              description="Aprovação do termo"
            ></Stepper.Step>
          )}
        </Stepper>
      </Paper>
      <Hidden hide={active !== 0}>
        <Box mt="md">
          <ProcessoPanel />
        </Box>
        <AtivosALocarPanel data={itemsProcesso} />
      </Hidden>
      <Hidden hide={active !== 1}>
        <PropostasLocadoresPanel data={propostasItemProcesso} />
      </Hidden>
      <Hidden hide={active !== 2 || !contrato}>
        <NegociacaoContratoPanel />
      </Hidden>
      <Hidden hide={active !== 2 || contrato}>Termo</Hidden>
      <Hidden hide={active !== 3 || !contrato}>
        <Title order={3}>Contrato de Locação</Title>
        <Paper withBorder my="md">
          <Group position="apart" noWrap spacing="xl">
            <div>
              <Text>Contrato de Locação de Ativos</Text>
              <Text size="xs" color="dimmed">
                Hash:
                b7508b1c59a0c877316f47cc3fb26ba0d2430ace544d7ec7689bbe4aa37ae75a
              </Text>
            </div>
            <Button onClick={open}>Exibir Contrato</Button>
          </Group>
        </Paper>
        <Title order={3}>Envio de Assinaturas</Title>
      </Hidden>
    </PageLayout>
  );
};
