import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Group,
  Text,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconEdit, IconTrash, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { DadosBancariosForm } from "partials/forms/DadosBancarios.form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const FinancialListPanel = ({ empresaID }: any) => {
  
  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchSingle, update, create } = useRemoteOperation({
    reducer: reducer,
  });
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { remove, fetchPage } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) onFindItem(params.id);
    else if (status?.isUpdated || status?.isSubmitted) {
      notifications.show({
        message: "Dados bancários salvos com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } 
  }, [status, location?.pathname]);

  useEffect(() => {
    if (empresaID) reducer.dispatch({ type: "INITIATING" });
  }, [empresaID]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.dadoBancario}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.dadosBancarios}`,
      requestParameters: {
        empresa: empresaID,
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    if (empresaID) {
      fetchPage({
        endpoint: `${RemoteEndpoints.dadosBancarios}`,
        requestParameters: {
          empresa: empresaID,
          page: pagination.page,
          size: pagination.size,
          sort: pagination.sort || "desc",
        },
        headers: RequestConfigGenerator.build(),
      });
    }
  };

  const onCreate = (entity: any) => {
    const authority = SecurityProvider.getAuthority();
    if (authority === "ADMINISTRATOR") {
      entity.empresa = empresaID;
    }

    create({
      endpoint: RemoteEndpoints.dadosBancarios,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) =>
    update({
      endpoint: `${RemoteEndpoints.dadoBancario}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });

  const onFindItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.dadosBancarios}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.dadoBancario}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const actionEditar = (id: any) => {
    findItem(id);
    drawerForm.open("editarDadosBancarios");
  }

  return (
    <>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastrarDadosBancarios"
        title="Cadastrar Informações Bancárias"
      >
        <DadosBancariosForm
          reducer={reducer}
          empresa={empresaID}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarDadosBancarios"
        title="Editar Informações Bancárias"
      >
        <DadosBancariosForm
          reducer={reducer}
          empresa={empresaID}
          editMode={true}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
        />
      </CustomDrawer>
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title mb="md" order={3}>
          Dados bancários
        </Title>
        <Button onClick={() => drawerForm.open("cadastrarDadosBancarios")}>Cadastrar Dados Bancários</Button>
      </Group>
      <DataGridComponent
        state={reducer.state}
        visible={true}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhum dado bancário encontrado"
        columns={[
          {
            accessor: "tipoDado",
            title: "Tipo",
            render: ({ tipoDado }: any) =>
              <CustomBadge value={tipoDado} options={[
                { value: "PIX", text: "Pix", color: "blue" },
                { value: "CONTA_BANCARIA", text: "Conta Bancária", color: "blue" },
              ]} />
          },
          {
            accessor: "dados",
            title: "Dados",
            render: (dados: any) => (
              <>
                {dados.tipoDado === "PIX" && (
                  <>
                    {dados.chavePix} - {dados.beneficiadoPix}
                  </>
                )}
                {dados.tipoDado === "CONTA_BANCARIA" && (
                  <>
                    {dados.nomeBanco} - {dados.numeroConta} -{" "}
                    {dados.numeroAgencia} - {dados.numeroReferencia} -{" "}
                    {dados.outrasInformacoes}
                  </>
                )}
              </>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (company: any) => (
              <Group spacing={12} position="right" noWrap>
                <ActionIcon color="blue" onClick={() => actionEditar(company.id)}>
                  <IconEdit size={26} />
                </ActionIcon>
                <ActionIcon
                  color="red"
                  onClick={() => onDelete({ id: company.id })}
                >
                  <IconTrash size={26} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
      />
    </>
  );
};
