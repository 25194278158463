import { Avatar, Box, Button, Card, Center, Container, Image, Divider, Grid, Group, LoadingOverlay, Pagination, Paper, SimpleGrid, Stepper, Text, Title, UnstyledButton, Checkbox, createStyles, rem, Badge } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCircleCheck, IconExclamationMark, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { ProspeccaoPanelComponent } from "partials/panels/ProspeccaoPanel.panel";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { SolicitacaoLocacaoForm } from "partials/forms/SolicitacaoLocacao.form";
import { SolicitacaoOrcamentoForm } from "partials/forms/SolicitacaoOrcamento.form";
import { ProspeccaoItemPanel } from "partials/views/ProspeccaoItem.view";
import { SolicitacaoOrcamentoPanel } from "partials/panels/SolicitacaoOrcamento.panel";
import { ProspeccaoPanel } from "partials/views/Prospeccao.view";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { InfoUtils } from "shared/Utils";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";
import Swal from "sweetalert2";


const useStyles = createStyles((theme) => ({
    button: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        borderRadius: theme.radius.sm,
        padding: theme.spacing.sm,
        border: `1px solid ${theme.colorScheme === "light"
            ? theme.colors.gray[2]
            : theme.colors.gray[8]
            }`,
        backgroundColor: `light-dark(${theme.colors.white}, ${theme.colors.dark[8]})`,
        '&[data-checked]': {
            borderColor: theme.colors.blueFilled,
            backgroundColor: theme.colors.blueLight,
        },
    },

    body: {
        flex: 1,
        marginLeft: theme.spacing.md,
    },

}));

export const SolicitarOrcamentoPage = () => {

    const navigate = useNavigate();
    const params = useParams<any>();
    const { classes } = useStyles();

    const context = new URLSearchParams(useLocation().search).get("context");
    const prospectionID = new URLSearchParams(useLocation().search).get("prospectionID");
    const prospectionItemID = new URLSearchParams(useLocation().search).get("prospectionItemID");

    const reducerAtivo = useRemoteDataReducer();
    const reducerAtivoItem = useRemoteDataReducer();
    const reducerParametrosLoja = useRemoteDataReducer();
    const reducerSolicitacaoOrcamento = useRemoteDataReducer();
    const reducerEmpresa = useRemoteDataReducer();
    const prospeccaoReducer = useRemoteDataReducer();
    const prospeccaoItemReducer = useRemoteDataReducer();

    const empresaOperations = useRemoteOperation({ reducer: reducerEmpresa });

    const ativoOperations = useRemoteOperation({ reducer: reducerAtivo });

    const ativoItemOperations = useRemoteOperation({
        reducer: reducerAtivoItem
    });

    const solicitacaoOperations = useRemoteOperation({
        reducer: reducerSolicitacaoOrcamento
    });

    const parametrosOperations = useRemoteOperation({
        reducer: reducerParametrosLoja
    });

    const prospeccaoOperations = useRemoteOperation(
        { reducer: prospeccaoReducer }
    );

    const prospeccaoItemOperations = useRemoteOperation({
        reducer: prospeccaoItemReducer
    });

    const [active, setActive] = useState(0);
    const [selectedItens, setSelectedItens] = useState<any[]>([]);
    const [dadosSolicitacao, setDadosSolicitacao] = useState<any>({});

    const [value, setValue] = useState<any>({
        prospeccao: null as any,
        prospeccaoItem: null as any
    });

    /* Status */
    const ativoStatus = reducerAtivo.state.status;
    const empresaStatus = reducerEmpresa.state.status;
    const prospeccaoStatus = prospeccaoReducer.state.status;
    const solicitacaoOrcamentoStatus = reducerSolicitacaoOrcamento.state.status;

    /* Dados */
    const ativoData = reducerAtivo.state?.data?.object;
    const ativoItemData = reducerAtivoItem.state.data?.page;
    const empresaData = reducerEmpresa?.state.data?.object;
    const parametrosLojaData = reducerParametrosLoja.state.data?.object;
    const solicitacaoOrcamentoData = reducerSolicitacaoOrcamento.state.data?.object;

    /*
        Caso não tenha parametros de loja encontrados, avisa o usuario bloqueando 
    */

    useEffect(() => {
        prospeccaoReducer.dispatch({ type: "INITIATING" });
        prospeccaoItemReducer.dispatch({ type: "INITIATING" });
    }, [location]);

    useEffect(() => {
        /* Um id de prospecção for informado */
        if (prospeccaoStatus?.isInitiating && prospectionID && !prospeccaoReducer.state.data?.object) {
            findProspeccao(prospectionID);
        }
        /* Quando a nova prospeccao for cadastrada, ou quando a busca por prospecção por ID retornar dados ela será selecionada automaticamente */
        if ((prospeccaoStatus?.isSubmitted || prospeccaoStatus?.isSuccessful) && prospeccaoReducer.state.data?.object) {
            setValue({ prospeccao: prospeccaoReducer.state.data?.object })
        }
        /* Um id de prospecção não for informado, busca uma página de prospecções  */
        if (prospeccaoStatus?.isInitiating && !prospectionID && !prospeccaoReducer.state.data?.object) {
            findProspeccoes({ page: 0, size: 5, sort: "desc" });
        }
    }, [prospeccaoStatus]);

    useEffect(() => {
        /* Um id de item de prospecção for informado */
        if (prospeccaoItemReducer.state.status?.isInitiating && prospectionItemID && !prospeccaoItemReducer.state.data?.object && value.prospeccao?.id) {
            findProspeccaoItem(value.prospeccao?.id, prospectionItemID);
        }
        /* Quando o novo item de prospeccao for cadastrado, ou quando a busca por item de prospecção por ID retornar dados ela será selecionada automaticamente */
        if ((prospeccaoItemReducer.state.status?.isSubmitted || prospeccaoItemReducer.state.status?.isSuccessful) && prospeccaoItemReducer.state.data?.object) {
            setValue({ ...value, prospeccaoItem: prospeccaoItemReducer.state.data?.object })
        }
        /* Um id de item de prospecção não for informado, busca uma página de item de  prospecções  */
        if (value.prospeccao && prospeccaoItemReducer.state.status?.isInitiating && !prospectionItemID && !prospeccaoItemReducer.state.data?.object) {
            findProspeccaoItens(value.prospeccao.id, { page: 0, size: 10, sort: "desc" });
        }
    }, [prospeccaoItemReducer.state.status, value.prospeccao]);

    useEffect(() => {
        if (ativoStatus?.isFailed)
            notifications.show({
                message: reducerAtivo.state?.data?.error?.message,
                color: "red",
                icon: <IconX />,
            });
        else if (ativoStatus?.isSuccessful && ativoData) {
            findEmpresa(ativoData?.empresa?.id);
            findParametrosEmpresa(ativoData?.empresa?.id);
            findAtivoItens(ativoData?.id, { page: 0, size: 10, sort: "desc" });
        }
    }, [ativoStatus]);

    useEffect(() => {
        if (solicitacaoOrcamentoStatus?.isFailed)
            notifications.show({
                message: reducerSolicitacaoOrcamento.state?.data?.error?.message,
                color: "red",
                icon: <IconX />,
            });
        else if (solicitacaoOrcamentoStatus?.isSuccessful) {
            setActive(5);
        }
    }, [solicitacaoOrcamentoStatus]);

    /*useEffect(() => {
        if (solicitacaoReducer.state.status?.isFailed)
            notifications.show({
                message: data?.error?.message,
                color: "red",
                icon: <IconX />,
            });
        else if (solicitacaoReducer.state.status?.isSubmitted) {
            Swal.fire({
                title: "Tudo certo",
                text: "A sua solicitação foi enviada para a locadora.",
                icon: "success",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(
                        `/solicitacoes/${solicitacaoReducer.state.data?.object?.id}`,
                    );
                }
            });
        }
    }, [solicitacaoReducer.state.status]);*/

    useEffect(() => {
        findAtivo(params.id);
    }, [params.id]);

    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const findProspeccoes = (pagination: PaginationProps) => {
        prospeccaoOperations.fetchPage({
            endpoint: `${RemoteEndpoints.prospeccoes}`,
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccao = (id: any) => {
        prospeccaoOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}`,
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccaoItem = (id: any, idItem: any) => {
        prospeccaoItemOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/itens/${idItem}`,
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccaoItens = (id: any, pagination: PaginationProps) => {
        prospeccaoItemOperations.fetchPage({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/itens`,
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            headers: RequestConfigGenerator.build(),
        });
    };

    const findEmpresa = (id: any) =>
        empresaOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.empresa}/${id}`,
            properties: { reducer: reducerEmpresa },
            headers: RequestConfigGenerator.build(),
        });

    const findAtivo = (id: any) =>
        ativoOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.ativo}/${id}`,
            properties: { reducer: reducerAtivo },
            headers: RequestConfigGenerator.build(),
        });

    const findAtivoItens = (id: any, pagination: PaginationProps) =>
        ativoItemOperations.fetchPage({
            endpoint: `${RemoteEndpoints.ativo}/${id}/itens`,
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            headers: RequestConfigGenerator.build(),
        });


    const findParametrosEmpresa = (id: any) =>
        parametrosOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.lojas}/loja/${id}/parametros`,
            properties: { reducer: reducerParametrosLoja },
            headers: RequestConfigGenerator.build(),
        });

    const enviarSolicitacao = () => {

        const solicitacao = {
            itens: selectedItens,
            dataHoraRetirada: dadosSolicitacao?.dataHoraRetirada,
            dataHoraDevolucao: dadosSolicitacao?.dataHoraDevolucao,
            prospeccao: value?.prospeccao?.id,
            prospeccaoItem: value?.prospeccaoItem?.id
        };

        solicitacaoOperations.create({
            endpoint: RemoteEndpoints.orcamentos,
            properties: { reducer: reducerSolicitacaoOrcamento },
            data: solicitacao,
            headers: RequestConfigGenerator.build(),
        });
    };

    const onCheckItem = (id: any) => {
        if (selectedItens.includes(id)) {
            const newSelectedItens = [...selectedItens];
            var index = newSelectedItens.indexOf(id);
            if (index !== -1) {
                newSelectedItens.splice(index, 1);
            }
            setSelectedItens(newSelectedItens);
        } else {
            setSelectedItens([...selectedItens, id]);
        }
    }

    return (
        <PageLayout>
            <Container size="xl">
                <Card withBorder radius="md" style={{ minHeight: "70vh" }}>
                    <LoadingOverlay visible={false} />
                    <Grid gutter="md">
                        <Grid.Col span={8}>
                            <Stepper active={active} onStepClick={setActive} breakpoint="sm" mt="lg" mx="lg">
                                <Stepper.Step label="Prospecção" description="Informações de Prospecção">
                                    <Divider my="sm" />
                                    <Paper withBorder p="sm" my="md">
                                        <ProspeccaoPanelComponent
                                            parameters={{ prospectionID: prospectionID, prospectionItemID: prospectionItemID }}
                                            value={value}
                                            hideAdd={true}
                                            setValue={(result: any) => setValue(result)}
                                        />
                                    </Paper>
                                    <Group position="center" mt="xl">
                                        <Button onClick={nextStep}>Confirmar dados</Button>
                                        <Button disabled={!value.prospeccao && !value.prospeccaoItem} onClick={nextStep}>Proximo passo</Button>
                                    </Group>
                                </Stepper.Step>
                                <Stepper.Step label="Itens" description="Seleção de Itens para Locação">
                                    <Divider my="sm" />
                                    <>
                                        {ativoItemData?.content?.map((item: any, index: any) => (
                                            <UnstyledButton
                                                data-checked={selectedItens.includes(item.id) || undefined}
                                                onClick={() => onCheckItem(item?.id)}
                                                key={index}
                                                disabled={item.emUso}
                                                className={classes.button}
                                            >
                                                <Checkbox
                                                    checked={selectedItens.includes(item?.id)}
                                                    onChange={() => onCheckItem(item?.id)}
                                                    tabIndex={-1}
                                                    styles={{ input: { cursor: 'pointer' } }}
                                                />
                                                <Hidden hide={item.images}>
                                                    <Image p="lg" src={item.images[0]} alt="Imagem do Ativo" width={40} height={40} />
                                                </Hidden>
                                                <Box className={classes.body} ml="xl">
                                                    <Group noWrap>
                                                        <Badge>{item.estado}</Badge>
                                                        <Hidden hide={!item.lote}>
                                                            <Badge>Lote</Badge>
                                                            <Text size="sm">{item.quantidadeLote || 0} unidades</Text>
                                                        </Hidden>
                                                    </Group>
                                                    <Text c="dimmed" size="sm" mt="xs" lh={1} mb={5}>
                                                        {item.detalhes}
                                                    </Text>
                                                </Box>
                                            </UnstyledButton>
                                        ))}
                                        <Center>
                                            <Divider />
                                            <Pagination
                                                mt="md"
                                                mb="xl"
                                                total={reducerAtivoItem?.state.data?.page?.totalPages || 0}
                                                onChange={(page: number) =>
                                                    findAtivoItens(ativoData?.id, { page: page, size: 10, sort: "desc" })
                                                }
                                            />
                                        </Center>
                                    </>
                                    <Group position="center" mt="xl">
                                        <Button variant="default" onClick={prevStep}>Back</Button>
                                        <Button disabled={selectedItens.length == 0} onClick={nextStep}>Next step</Button>
                                    </Group>
                                </Stepper.Step>
                                <Stepper.Step label="Informações" description="Informações de Locação">
                                    <Divider my="sm" />
                                    <SolicitacaoOrcamentoForm
                                        dadosSolicitacao={dadosSolicitacao}
                                        prospeccao={value.prospeccao}
                                        ativo={ativoData}
                                        formasPagamento={parametrosLojaData?.metodosPagamento || []}
                                        onChange={(entity: any) => setDadosSolicitacao(entity)}
                                    />
                                    <Group position="center" mt="xl">
                                        <Button variant="default" onClick={prevStep}>Back</Button>
                                        {/* disabled={!dadosSolicitacao.modoPagamento} */}
                                        <Button  onClick={nextStep}>Next step</Button>
                                    </Group>
                                </Stepper.Step>
                                <Stepper.Step label="Verificação" description="Verificar informações">
                                    <Divider my="sm" />
                                    <Box pos="relative" mt="lg" mb="lg">
                                        <LoadingOverlay visible={!!solicitacaoOrcamentoStatus?.isSubmitting} overlayBlur={2} />
                                        <Text my="sm" size="sm" fw={500}>Dados da Solicitação para Orçamento</Text>
                                        <SolicitacaoOrcamentoPanel solicitacaoOrcamento={dadosSolicitacao} />
                                        <Text my="sm" size="sm" fw={500}>Dados da Prospecção</Text>
                                        <ProspeccaoPanel prospeccao={value?.prospeccao} />
                                        <Text my="sm" size="sm" fw={500}>Dados do Item da Prospecção</Text>
                                        <ProspeccaoItemPanel prospectionItem={value?.prospeccaoItem} />
                                        <Group position="center" mt="xl">
                                            <Button variant="default" onClick={prevStep}>Back</Button>
                                            <Button onClick={enviarSolicitacao}>Confirmar e Solicitar</Button>
                                        </Group>
                                    </Box>
                                </Stepper.Step>
                                <Stepper.Completed>
                                    <Divider my="sm" />
                                    <Box mt="xl">
                                        <Title align="center">
                                            Sucesso!
                                        </Title>
                                        <Center m="xl">
                                            <IconCircleCheck
                                                color="green"
                                                size={128}
                                                style={{ marginRight: "1rem" }}
                                            />
                                        </Center>
                                        <Center>
                                            <Text size="md">
                                                Sua solicitação de orçamento foi enviada para a locadora.
                                            </Text>
                                        </Center>
                                        <Center>
                                            <Button
                                                component={Link}
                                                to={`/orcamentos/${solicitacaoOrcamentoData?.id}`}
                                                m="xl"
                                            >
                                                Acompanhar Orçamento
                                            </Button>
                                            <Hidden hide={context !== "prospection"}>
                                                <Button
                                                    component={Link}
                                                    to={`/portal/prospeccao?prospectionID=${prospectionID}&prospectionItemID=${prospectionItemID}`}
                                                    m="xl"
                                                >
                                                    Retonar à prospecção
                                                </Button>
                                            </Hidden>
                                        </Center>
                                    </Box>
                                </Stepper.Completed>
                            </Stepper>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <Box mt="md">
                                <Group noWrap>
                                    <Avatar
                                        src={(empresaData?.images && empresaData?.images?.length > 0) ? empresaData?.images[0]?.thumbnailSourceSet : ""}
                                        size={94}
                                        radius="md"
                                    />
                                    <div>
                                        <Text fz="lg" fw={500} >
                                            {ativoData?.produto?.descricao || ativoData?.dadosIdentificacaoProduto?.descricao}
                                        </Text>
                                        <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
                                            {ativoData?.empresa?.nomeFantasia}
                                        </Text>
                                    </div>
                                </Group>
                                <Divider my="sm" />
                                <Text size="sm" color="dimmed">
                                    Disponibilizado por
                                </Text>
                                <Box mt="sm">
                                    <EmpresaChip empresa={empresaData} />
                                </Box>
                            </Box>
                        </Grid.Col>
                    </Grid>
                </Card>
            </Container>
        </PageLayout>
    );

}