import {
  Box,
  Button,
  Group,
  Input,
  LoadingOverlay,
  SimpleGrid,
  Text,
  Textarea
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { CadastroSelecaoProdutoPanel } from "partials/panels/CadastroSelecaoProduto.panel";
import { EmpresaSelectForm } from "partials/selectforms/Empresa.selectform";
import { useEffect, useState } from "react";
import { NumberUtils, ObjectUtils } from "shared/Utils";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import { Hidden } from "shared/custom/Hidden";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const AtivoForm = ({
  reducer,
  editMode,
  onSubmit,
}: any) => {

  const { data, status } = reducer.state;
  const authority = SecurityProvider.getAuthority();
  const [selectControl, onSelectControl] = useState<boolean>(true);

  const form = useForm({
    validateInputOnBlur: [
      "produto.marca",
      "produto.produto",
      "produto.classe",
      "produto.subclasse",
      "produto.grupo",
      "produto.familia",
      "detalhes",
      "valorCompra",
      "batnaMinBase",
      "batnaMaxBase",
      "empresa",
      "valorLocacaoBase",
    ],
    initialValues: {
      produto: "",
      dadosIdentificacaoProduto: {
        descricao: "",
        marca: "",
        classe: "",
        subclasse: "",
        grupo: "",
        familia: "",
      },
      detalhes: "",
      valorCompraBase: "",
      batnaMinBase: "",
      batnaMaxBase: "",
      empresa: "",
      valorLocacaoBase: "",
    },
    validate: {
      produto: (value) =>
        selectControl && value === ""
          ? "Informe o produto correspondente ao ativo"
          : null,
      dadosIdentificacaoProduto: {
        marca: (value) =>
          !selectControl && !editMode && value === ""
            ? "Informe uma marca"
            : null,
        classe: (value) =>
          !selectControl && !editMode && value == ""
            ? "Informe uma classe"
            : null,
        subclasse: (value) =>
          !selectControl && !editMode && value == ""
            ? "Informe uma subclasse"
            : null,
        grupo: (value) =>
          !selectControl && !editMode && value == ""
            ? "Informe um grupo"
            : null,
        familia: (value) =>
          !selectControl && !editMode && value == ""
            ? "Informe uma familia"
            : null,
      },
      empresa: (value) =>
        authority === "ADMINISTRATOR" && !editMode && value === ""
          ? "Informe a empresa locadora do ativo"
          : null,
      detalhes: (value) =>
        value !== "" ? null : "Informe detalhes do produto",
      valorCompraBase: (value) =>
        value !== "" ? null : "Informe o valor de compra do ativo",
      valorLocacaoBase: (value) =>
        value !== "" ? null : "Informe o valor de locação",
    },
  });

  const _onSubmit = async (submitData: any) => {
    let cleanData = ObjectUtils.clone(submitData);

    cleanData.batnaMinBase = NumberUtils.valorToNumber(cleanData.batnaMinBase);
    cleanData.batnaMaxBase = NumberUtils.valorToNumber(cleanData?.batnaMaxBase);
    cleanData.valorLocacaoBase = NumberUtils.valorToNumber(cleanData?.valorLocacaoBase);
    cleanData.valorCompraBase = NumberUtils.valorToNumber(cleanData?.valorCompraBase);

    if (cleanData.dadosIdentificacaoProduto.classe === "") {
      delete cleanData.dadosIdentificacaoProduto;
    }

    cleanData = ObjectUtils.cleanNulls(cleanData);

    if (editMode) {
      delete cleanData.id;
      delete cleanData.produto;
      delete cleanData.empresa;
      delete cleanData.images;
      delete cleanData.locatario;
      delete cleanData.creationTime;
      delete cleanData.modificationTime;
    }

    delete cleanData.numeroAtivo;

    onSubmit(cleanData, submitData?.id);
  };

  const onFormError = (event: any) => {
    console.error(event)
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  const onChangeProduct = (data: any) => {
    form.setFieldValue(
      selectControl ? "produto" : "dadosIdentificacaoProduto",
      data,
    );
  };

  useEffect(() => {
    if (editMode) {
      form.setValues({ ...data?.object });
    }
  }, [data]);

  return (
    <>
      <LoadingOverlay visible={!!status?.isFetching} overlayBlur={2} />
      <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
        <Box>
          <Hidden hide={authority !== "ADMINISTRATOR" || editMode}>
            <Text mt="md" fz="md" fw={500}>
              Empresa
            </Text>
            <EmpresaSelectForm
              label="Empresa"
              dataTestId="empresa"
              description="Empresa que possui esse ativo"
              placeholder="Nome ou CNPJ"
              onChange={(result: any) => form.setFieldValue("empresa", result)}
              value={form.values?.empresa}
              error={form.errors?.empresa}
            />
          </Hidden>
          <Hidden hide={editMode}>
            <Text fz="md" mt="md" fw={500}>
              Produto
            </Text>
          </Hidden>
          <Hidden hide={editMode}>
            <CadastroSelecaoProdutoPanel
              form={form}
              dataTestId="produto"
              error={form.errors?.produto}
              value={
                selectControl
                  ? form.values?.produto
                  : form.values?.dadosIdentificacaoProduto
              }
              onChange={onChangeProduct}
              selectControl={selectControl}
              onSelectControl={onSelectControl}
            />
          </Hidden>
        </Box>
        <Text fz="md" mt="md" fw={500}>
          Dados do Ativo
        </Text>
        <Text color="gray" size="xs">
          Estas informações devem ajudar a indentificar o item no seu estoque
        </Text>
        <Textarea
          {...form.getInputProps("detalhes")}
          placeholder="Ex: 127V, Falta uma broca"
          description="Ajude seu cliente a identificar melhor este ativo"
          label="Detalhes"
          data-testid="detalhes"
          mt="md"
        />
        <Input.Wrapper
          mt="md"
          label="Valor do Item"
          data-testid="valorCompraBase"
          description="Será incluído no contrato caso o item seja danificado"
          required
          error={form.errors?.valorCompraBase}
        >
          <ConcurrencyInput
            label="Valores financeiros"
            {...form.getInputProps("valorCompraBase")}
            placeholder="R$ 250,00"
          />
        </Input.Wrapper>
        <Input.Wrapper
          mt="md"
          label="Valor de locação do item"
          description="Valor cobrado do locatário"
          data-testid="valorLocacaoBaseBase"
          required
          error={form.errors?.valorLocacaoBaseBase}
        >
          <ConcurrencyInput
            label="Valores de locação"
            {...form.getInputProps("valorLocacaoBase")}
            placeholder="R$ 250,00"
            data-testid="valorLocacaoBase"
          />
        </Input.Wrapper>
        <Text fz="md" mt="md" fw={500}>
          Orçamento
        </Text>
        <Text color="gray" size="xs">
          Estas informações não são obrigatórias, mas agilizam e facilitam o
          sistema para encontrar as melhores possibilidades de negociação
        </Text>
        <SimpleGrid
          cols={2}
          mt="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Input.Wrapper label="Valor (mensal) Mínimo para locação">
            <ConcurrencyInput
              {...form.getInputProps("batnaMinBase")}
              placeholder="R$ 250,00"
              data-testid="batnaMinBase"
            />
          </Input.Wrapper>
          <Input.Wrapper label="Valor (mensal) Máximo para locação">
            <ConcurrencyInput
              {...form.getInputProps("batnaMaxBase")}
              placeholder="R$ 250,00"
              data-testid="batnaMaxBase"
            />
          </Input.Wrapper>
        </SimpleGrid>
        <Group position="apart" mt="lg">
          <Button data-testid="cancel" variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Limpar
          </Button>
          <Button data-testid="submit" type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Confirmar
          </Button>
        </Group>
      </form>
    </>
  );
};
