import { Carousel } from "@mantine/carousel";
import {
  Avatar,
  Badge,
  Card,
  Group,
  Image,
  Skeleton,
  Text,
  createStyles,
  getStylesRef,
  rem,
} from "@mantine/core";
import { Hidden } from "shared/custom/Hidden";

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  footer: {
    padding: `${theme.spacing.xs} ${theme.spacing.lg}`,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  price: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },

  carousel: {
    "&:hover": {
      [`& .${getStylesRef("carouselControls")}`]: {
        opacity: 1,
      },
    },
  },

  carouselControls: {
    ref: getStylesRef("carouselControls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  image: {
    objectFit: 'contain',
  },

  carouselIndicator: {
    width: rem(4),
    height: rem(4),
    transition: "width 250ms ease",

    "&[data-active]": {
      width: rem(16),
    },
  },
}));

export function ProdutoCard({ ativo, url }: any) {
  const { classes, theme } = useStyles();

  const slides = ativo?.images?.map((image: any) => (
    <Carousel.Slide key={image}>
      <Image
        className={classes.image}
        src={image.thumbnailSourceSet}
        height={200}
        withPlaceholder
        placeholder={
          <Text align="center" size="sm">
            Imagem indisponível
          </Text>
        }
        alt={ativo?.produto?.descricao}
      />
    </Carousel.Slide>
  ));

  return (
    <Card withBorder padding="lg" radius="md" className={classes.card}>
      <Card.Section mb="sm">
        <Hidden hide={!ativo?.images || ativo?.images?.length === 0}>
          <Carousel
            withIndicators
            loop
            classNames={{
              root: classes.carousel,
              controls: classes.carouselControls,
              indicator: classes.carouselIndicator,
            }}
          >
            {slides}
          </Carousel>
        </Hidden>
        <Hidden hide={ativo?.images && ativo?.images?.length > 0}>
          <Image
            src="#"
            height={200}
            withPlaceholder
            placeholder={
              <Text align="center" size="sm">
                Item sem imagem disponível
              </Text>
            }
            alt={ativo?.produto?.descricao}
          />
        </Hidden>
      </Card.Section>
      <Text
        component="a"
        href={url}
        fw={700}
        className={classes.title}
        mt="xs"
      >
        {ativo?.produto?.descricao || ativo?.dadosIdentificacaoProduto?.descricao }
      </Text>
      <Group mt="xs">
        <Hidden hide={!ativo?.empresa?.logo?.imageSourceSet}>
          <Avatar src={ativo?.empresa?.logo?.imageSourceSet} radius="sm" />
        </Hidden>
        <div>
          <Hidden hide={!ativo?.empresa?.nomeFantasia}>
            <Text
              component="a"
              href={`/loja-ativos/${ativo?.empresa?.id}`}
              fw={500}
            >
              {ativo?.empresa?.nomeFantasia}
            </Text>
          </Hidden>
          <Hidden hide={ativo?.empresa?.nomeFantasia}>
            <Skeleton height={8} mt={6} radius="xl" />
            <Text fz="xs" c="dimmed">
              {ativo?.empresa?.description}
            </Text>
          </Hidden>
        </div>
      </Group>
      <Card.Section className={classes.footer}>
        <Group position="apart">
          <Text fz="xs" c="dimmed">
            <Badge>{ativo?.estado}</Badge>
          </Text>
          {/* 
                    <Group spacing={0}>
                        <QuantityCounter
                            quantity={quantity}
                            setQuantity={setQuantity}
                            color="blue"
                        />
                    </Group>*/}
        </Group>
      </Card.Section>
    </Card>
  );
}
