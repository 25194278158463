import {
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Paper,
  Select,
  SimpleGrid,
  Text,
  Title
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconPercentage, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ObjectUtils } from "shared/Utils";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const ParametrizacaoEmpresaPanel = ({ empresaID }: any) => {

  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const dadosBancariosReducer = useRemoteDataReducer();
  const dadosBancariosOperations = useRemoteOperation({
    reducer: dadosBancariosReducer
  });

  const { fetchSingle, create } = useRemoteOperation({
    reducer: reducer
  });

  const { data, status } = reducer.state;

  const form = useForm({
    validateInputOnBlur: [
      "canais",
      "porcentagemLuva",
      "modoOrcamento",
      "metodosPagamento",
      "envioDocumentoBoleto",
      "modoPagamentoPadrao",
      "contratoFonte",
      "limiteDiasAssinaturaContrato",
      "suprimirAprovacaoSolicitacao",
      "luvaHabilitada"
    ],
    initialValues: {
      canais: data?.object?.canais || [],
      modoOrcamento: data?.object?.modoOrcamento || "",
      metodosPagamento: data?.object?.metodosPagamento || [],
      envioDocumentoBoleto: data?.object?.envioDocumentoBoleto || false,
      modoPagamentoPadrao: data?.object?.modoPagamentoPadrao || "",
      lojaPrivada: data?.object?.lojaPrivada || false,
      contratoFonte: data?.object?.contratoFonte || "",
      luvaHabilitada: (data?.object?.porcentagemLuva !== null && data?.object?.porcentagemLuva > 0) || false,
      porcentagemLuva: data?.object?.porcentagemLuva || "",
      limiteDiasAssinaturaContrato:
        data?.object?.limiteDiasAssinaturaContrato || "",
      suprimirAprovacaoSolicitacao:
        data?.object?.suprimirAprovacaoSolicitacao || false,
    },
    validate: {
      canais: (value) => (value.length > 0) ? null : 'Informe algum canal de vendas',
      modoOrcamento: (value) => value !== "" ? null : "Informe um modo de orçamento"
    },
  });

  useEffect(() => {
    if (empresaID) reducer.dispatch({ type: "INITIATING" });
  }, [empresaID]);

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (
      status?.isUpdated ||
      status?.isSubmitted ||
      status?.isDeleted ||
      status?.isDeleted
    )
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isInitiating) {
      onFindItem(empresaID);
      onFindDadosBancarios(empresaID);
    } else if (status?.isSuccessful) {
      form.setValues(data?.object);
    }
  }, [status, location?.pathname]);

  const gerarEmpresaID = () => {
    const authority = SecurityProvider.getAuthority();
    return authority !== "ADMINISTRATOR" ? "" : `?empresa=${empresaID}`;
  };

  const onFindItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.parametrizacaoEmpresa}${gerarEmpresaID()}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onFindDadosBancarios = (id: any) =>
    dadosBancariosOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.dadosBancarios}${gerarEmpresaID()}`,
      properties: { reducer: dadosBancariosReducer },
      headers: RequestConfigGenerator.build(),
    });

  const onSubmit = async (data: any) => {
    delete data?.luvaHabilitada;
    const finalData = ObjectUtils.cleanNulls(data);
    create({
      endpoint: `${RemoteEndpoints.parametrizacaoEmpresa}${gerarEmpresaID()}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: finalData,
    });
  };

  const onFormError = (event: any) =>
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });

  const formatDadosbancarios = (result: any) => {
    const dados = result?.data?.object?.content;
    if (dados) {
      return dados?.map((item: any) => {
        if (item?.tipoDado === "PIX") {
          return {
            value: item?.id,
            label: `${item?.tipoDado}:  ${item?.chavePix}`,
          };
        } else if (item?.tipoDado === "CONTA_BANCARIA") {
          return {
            value: item?.id,
            label: `${item?.tipoDado}: ${item?.nomeBanco} - ${item?.numeroAgencia} - ${item?.numeroConta}`,
          };
        }
        {
          return { value: item?.id, label: `${item?.tipoDado}` };
        }
      });
    } else {
      return [];
    }
  };

  const habilitaBotaoLojaPrivada = () => {
    if (!form.values || !form.values.canais || !Array.isArray(form.values.canais)) {
      return false;
    }
    return !form.values.canais.includes("LOJA");
  }

  return (
    <>
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Parametrização</Title>
      </Group>
      <Paper withBorder p="md" radius="md">
        <form onSubmit={form.onSubmit((a) => onSubmit(a), onFormError)}>
          <LoadingOverlay
            visible={status?.isFetching || false}
            overlayBlur={2}
          />
          <Divider label={<Text size="sm" fw={500}>Canais, Negociações & Condições</Text>} mb="xs" />
          <SimpleGrid
            cols={3}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <MultiSelect
              data={[
                { value: "ORCAMENTO", label: "Orçamento" },
                //{ value: "LICITACAO", label: "Licitação" },
              ]}
              required
              {...form.getInputProps("canais")}
              label="Canais"
              placeholder="Canais de negociação"
            />
            {/*<NumberInput
              label="Raio de Atendimento da plataforma"
              placeholder="Raio em Km2"
              {...form.getInputProps("raioAtendimentoPlataforma")}
            />*/}
            <Select
              label="Modo Orçamento"
              placeholder="Selecione um modo de orçamento"
              required
              data={[
                { value: "SEM_ORCAMENTO", label: "Orçamento desabilitado" },
                { value: "ORCAMENTO_HABILITADO", label: "Orçamento habilitado (com outros canais)" },
                { value: "SOMENTE_ORCAMENTO", label: "Somente orçamento (sem outros canais)" },
              ]}
              {...form.getInputProps("modoOrcamento")}
            />
            <Group noWrap>
              <Checkbox
                {...form.getInputProps("luvaHabilitada", {
                  type: "checkbox",
                })}
              />
              <NumberInput
                label="Porcentagem luva"
                description="Detalhamento automático de 3 casas decimais. Ex: 1,500 = 1.5%"
                disabled={form.values.luvaHabilitada === false}
                decimalSeparator=","
                thousandsSeparator="."
                precision={3}
                step={0.5}
                placeholder="Porcentagem luva"
                icon={<IconPercentage size="1rem" />}
                {...form.getInputProps("porcentagemLuva")}
              />
            </Group>
            <Checkbox
              mt="md"
              placeholder="Sim"
              label="Loja Privada?"
              disabled={habilitaBotaoLojaPrivada()}
              {...form.getInputProps("lojaPrivada", {
                type: "checkbox",
              })}
            />
            <Checkbox
              mt="md"
              placeholder="Sim"
              label="Pagamento Adiantado?"
              disabled={habilitaBotaoLojaPrivada()}
              {...form.getInputProps("pagamentoAdiantado", {
                type: "checkbox",
              })}
            />
          </SimpleGrid>
          <Divider label={<Text size="sm" fw={500}>Pagamentos</Text>} mt="xl" mb="xs" />
          <SimpleGrid
            cols={3}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <MultiSelect
              data={[
                { value: "NONE", label: "Nenhnum" },
                { value: "BOLETO", label: "Boleto" },
                { value: "DEPOSITO_BANCARIO", label: "Depósito Bancário" },
                { value: "PIX", label: "Pix" },
              ]}
              {...form.getInputProps("metodosPagamento")}
              label="Métodos de Pagamento"
              placeholder="Métodos de Pagamento"
            />
            <Select
              label="Dados Bancários Padrão"
              placeholder={dadosBancariosReducer.state?.data?.object ? "Dados Bancários" : "Nenhum dado bancário disponível"}
              disabled={!dadosBancariosReducer.state?.data?.object}
              data={formatDadosbancarios(dadosBancariosReducer.state)}
              {...form.getInputProps("modoPagamentoPadrao")}
            />
            <Checkbox
              mt="md"
              label="Envio de documento de pagamento?"
              {...form.getInputProps("envioDocumentoBoleto", {
                type: "checkbox",
              })}
            />
          </SimpleGrid>
          <Divider label={<Text size="sm" fw={500}>Contratos</Text>} mt="xl" mb="xs" />
          <SimpleGrid
            cols={3}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <Select
              disabled
              label="Fonte do contrato"
              placeholder="Selecione uma fonte de contrato"
              data={[
                {
                  value: "GERADO",
                  label: "Gerado (automaticamente pelo sistema)",
                  disabled: true
                },
                {
                  value: "ENVIADO",
                  label: "Enviado (manualmente pela empresa)",
                },
              ]}
              {...form.getInputProps("contratoFonte")}
            />
            <NumberInput
              label="Dias para assinatura de contrato"
              placeholder="Número de dias"
              {...form.getInputProps("limiteDiasAssinaturaContrato")}
            />
            {/*<Checkbox
              mt="md"
              label="Suprimir a aprovacao solicitacao?"
              {...form.getInputProps("suprimirAprovacaoSolicitacao", {
                type: "checkbox",
              })}
            />*/}
          </SimpleGrid>
          <Group position="apart" mt="md">
            <Box></Box>
            <Button type="submit" loading={reducer.state.status?.isSubmitting}>
              Confirmar
            </Button>
          </Group>
        </form>
      </Paper>
    </>
  );
};
