import {
  ActionIcon,
  Group,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconEye, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const SolicitacaoListPage = () => {

  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchSingle, update, fetchPage } = 
    useRemoteOperation({ reducer: reducer });
  const drawerForm = useDrawerForm({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
    else if (status?.isUpdated) {
      notifications.show({
        message: "Solicitação atualizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.solicitacoes}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.solicitacoes}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    delete entity.id;
    update({
      endpoint: `${RemoteEndpoints.solicitacao}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  };

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.solicitacao}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const editInfo = (id: any) => {
    findItem(id);
  };

  return (
    <PageLayout title="Solicitações">
      <DataGridComponent
        visible={true}
        state={reducer.state}
        noRecordText="Nenhuma solicitação encontrada"
        onPaginationChange={onPaginationChange}
        columns={[
          { accessor: "numeroSolicitacao", title: "Número", sortable: true },
          {
            accessor: "empresaSolicitante",
            title: "Solicitante",
            render: ({ empresaSolicitante }: any) => 
              <EmpresaChip empresa={empresaSolicitante} />
          },
          {
            accessor: "canal",
            title: "Canal",
            render: ({ canal }: any) =>
              <CustomBadge value={canal} options={[
                { value: "LOJA", text: "Loja", color: "blue" },
                { value: "OTIMO", text: "Ótimo", color: "teal" },
                { value: "BOM", text: "Bom", color: "gray" },
              ]} />
          },
          {
            accessor: "modo",
            title: "Modo",
            render: ({ modo }: any) =>
              <CustomBadge value={modo} options={[
                { value: "SOLICITACAO", text: "Solicitação", color: "blue" },
              ]} />
          },
          {
            accessor: "status", 
            title: "Status",
            render: ({ status }: any) =>
              <CustomBadge value={status} options={[
                { value: "REPROVADO", text: "Reprovado", color: "red" },
                { value: "APROVADO", text: "Aprovado", color: "teal" },
                { value: "SOLICITADO", text: "Solicitado", color: "blue" },
              ]} />
          },
          {
            accessor: "criacao",
            title: "Criação",
            render: ({ creationTime }: any) => (
              <Text size="xs" color="dimmed">
                {" "}
                {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "atualizacao",
            title: "Atualização",
            render: ({ modificationTime }: any) => (
              <Text size="xs" color="dimmed">
                {" "}
                {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <a href={`/solicitacoes/${id}`}>
                  <ActionIcon color="gray">
                    <IconEye size={26} />
                  </ActionIcon>
                </a>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
