import { Title } from "@mantine/core"
import { ProspeccaoPanel } from "partials/views/Prospeccao.view"

export const ProspeccaoInfoTab = ({ prospeccao }: any) => {

  return (
    <>
      <Title mt="md" mb="md" order={3}>
        Informações da prospecção
      </Title>
      <ProspeccaoPanel prospeccao={prospeccao} />
    </>
  )

}