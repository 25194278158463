import { TextInput, Textarea, MultiSelect, Group, Button, Checkbox, Input } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import moment from "moment";
import { NumberUtils, ObjectUtils } from "shared/Utils";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import { Hidden } from "shared/custom/Hidden";


export const OrcamentoItemRespostaForm = ({
    reducer,
    onSubmit,
    ativo,
    orcamento
}: any) => {

    const { data, status } = reducer.state;

    const form = useForm({
        validateInputOnBlur: [],
        initialValues: {
            recusado: false,
            valorTotal: "",
            observacoes: "",
        },
    });

    const _onSubmit = async (submitData: any) => {
        if(!form.values.recusado && !form.values.valorTotal) {
            form.setErrors({ valorTotal : "Informe o valor total" })
            onFormError({});
            return;
        }

        let cleanData = ObjectUtils.clone(submitData);
        cleanData = ObjectUtils.cleanNulls(cleanData);
        cleanData.valorTotal = NumberUtils.valorToNumber(cleanData?.valorTotal);
        onSubmit(cleanData);
    };

    const onFormError = (event: any) => {

        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });
    };

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <Checkbox
                mt="md"
                {...form.getInputProps("recusado", { type: "checkbox" })}
                label="Item Recusado"
                description="Caso o ativo não esteja disponível na data ou na quantidade solicitada ou qualquer outra razão"
                radius="xl"
            />
            <Hidden hide={form.values.recusado}>
                <Input.Wrapper
                    mt="md"
                    label="Valor Total"
                    description="Considere o valor total para este ativo, na quantidade de itens solicitados pelo solicitante"
                    required
                    error={form.errors?.valorTotal}
                >
                    <ConcurrencyInput
                        label="Valores financeiros"
                        {...form.getInputProps("valorTotal")}
                        placeholder="R$ 250,00"
                    />
                </Input.Wrapper>
            </Hidden>
            <Textarea
                mt="md"
                placeholder="Observações"
                label="Observações"
                {...form.getInputProps("observacoes")}
            />
            <Group position="apart" mt="lg">
                <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
                    Limpar
                </Button>
                <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
                    Confirmar
                </Button>
            </Group>
        </form>
    );

}