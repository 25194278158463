import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  Group,
  Input,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconCopy,
  IconEdit,
  IconEye,
  IconTrash,
  IconUpload,
  IconX,
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { AtivoForm } from "partials/forms/Ativo.form";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import moment, { duration } from "moment";
import { Hidden } from "shared/custom/Hidden";
import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import { MapGeolocationComponent } from "shared/components/MapGeolocation.component";
import { PercentageInput } from "shared/components/PercentageInput";
import { PaginationProps } from "shared/models/Pagination.props";

export const ComponentTest = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchSingle, update, remove, fetchPage, create } = useRemoteOperation(
    { reducer: reducer },
  );
  const { data, status } = reducer.state;
  const action = new URLSearchParams(useLocation().search).get("action");

  useEffect(() => {
    if (action === "cadastrar-ativo") {
      
    }
  }, [action]);

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
    else if (status?.isDeleted)
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Empresa cadastrada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Empresa atualizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } //else if (status?.isSuccessful && data?.object) 
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.ativo}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.ativos}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.ativos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    delete entity.id;
    update({
      endpoint: `${RemoteEndpoints.ativo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  };

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.ativo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onCreate = (entity: any) => {
    delete entity.id;
    create({
      endpoint: RemoteEndpoints.ativos,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  const BadgeEstado = (estado: any) => {
    let color;
    let texto;

    if (estado === "EXCELENTE") {
      color = "teal";
      texto = "Excelente";
    } else if (estado === "OTIMO") {
      color = "teal";
      texto = "Ótimo";
    } else if (estado === "BOM") {
      color = "gray";
      texto = "Bom";
    } else {
      texto = estado;
    }

    return <Badge color={color}>{texto}</Badge>;
  };

  const form = useForm({
    validateInputOnBlur: ["status", "valor", "descricao", "dataVencimento"],
    initialValues: {
      id: data?.id || null,
      arquivo: "",
      porcentagem: "",
      monetario: "",
      dataVencimento: moment().toDate(),
    },
    validate: {},
  });

  const _onSubmit = async (submitData: any) => {
  };

  const onFormError = (event: any) =>
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });

  /// onChange={(value) => form.setFieldValue('dataVencimento', moment(value).format("DD/MM/YYYY"))}

  return (
    <PageLayout
      title="Página de Teste"
      headerActions={
        <Group position="center">
          <Button onClick={open}>Cadastrar Ativo</Button>
        </Group>
      }
    >
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        size="xl"
        title={
          <Text fz="xl" fw={500}>
            Cadastrar Ativo
          </Text>
        }
        overlayProps={{ opacity: 0.5, blur: 4 }}
      >
        <Divider />
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
          <Input.Wrapper label="Data de Abertura" required mt="md">
            <PercentageInput
              value={form.values.porcentagem}
              defaultValue={0}
              onChange={(value: any) => form.setValues({ porcentagem: value })}
            />
          </Input.Wrapper>
          Valor da porcentagem {form.values.porcentagem}
          <Input.Wrapper label="Data de Abertura" required mt="md">
            <ConcurrencyInput
              value={form.values.monetario}
              defaultValue={0}
              onChange={(value: any) => form.setValues({ monetario: value })}
            />
          </Input.Wrapper>
          Valor da porcentagem {form.values.monetario}
          <MapGeolocationComponent />
          <DateInput
            {...form.getInputProps("dataVencimento")}
            valueFormat="DD/MM/YYYY"
            mt="md"
            label="Data vencimento"
            placeholder="dd/mm/aaaa"
          />
          <>
            Valor da data de vencimento{" "}
            {moment(form.values.dataVencimento, "DD/MM/YYYY")
              .format("YYYY-MM-DD HH:mm:ss")
              .toString()}
          </>
        </form>
      </Drawer>
      <DataGridComponent
        fetching={false}
        visible={true}
        noRecordText="Nenhum ativo encontrado"
        state={reducer.state}
        onPaginationChange={onPaginationChange}
        columns={[
          { accessor: "produto.descricao", title: "Descricao" },
          { accessor: "detalhes", title: "Detalhes" },
          {
            accessor: "produto.a",
            title: "Numero de Série/Patrimônio",
          },
          {
            accessor: "lote",
            title: "Lote?",
            render: ({ lote }: any) => (
              <>{lote ? <Badge>Lote</Badge> : <Badge>Unidade</Badge>}</>
            ),
          },
          {
            accessor: "estado",
            title: "Estado",
            render: ({ estado }: any) => BadgeEstado(estado),
          },
          {
            accessor: "emUso",
            title: "Locado?",
            render: ({ emUso, locatario }: any) => (
              <>
                {emUso ? (
                  <Badge color="red">Locado</Badge>
                ) : (
                  <Badge color="teal">Disponível</Badge>
                )}
                <Hidden hide={!locatario}>
                  <Group mt="xs">
                    <Avatar src={locatario?.logo?.imageSourceSet} radius="sm" />
                    <div>
                      <Hidden hide={!locatario?.nomeFantasia}>
                        <Text
                          component="a"
                          href={`/empresas/${locatario?.id}`}
                          fw={500}
                        >
                          {locatario?.nomeFantasia}
                        </Text>
                      </Hidden>
                    </div>
                  </Group>
                </Hidden>
              </>
            ),
          },
          {
            accessor: "criacao",
            title: "Criação",
            render: ({ creationTime }: any) => (
              <Text size="xs" color="dimmed">
                {" "}
                {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "atualizacao",
            title: "Atualização",
            render: ({ modificationTime }: any) => (
              <Text size="xs" color="dimmed">
                {" "}
                {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <a href={`/ativos/${id}`}>
                  <ActionIcon color="gray">
                    <IconEye size={26} />
                  </ActionIcon>
                </a>
                {/*<Tooltip label="Cadastrar outro ativo semelhante a este">
                                        <ActionIcon color="blue" onClick={() => clonarAtivo(id)}>
                                            <IconCopy size={26} />
                                        </ActionIcon>
                            </Tooltip>*/}
                <Tooltip label="Enviar documentos e fotos">
                  <a href={`/ativos/${id}?action=enviar-documento`}>
                    <ActionIcon color="blue">
                      <IconUpload size={26} />
                    </ActionIcon>
                  </a>
                </Tooltip>
                <Tooltip label="Editar Ativo">
                  <ActionIcon color="blue" onClick={() => findItem(id)}>
                    <IconEdit size={26} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

/*

                <Divider my="sm" label="Imagens" />
                <Text color="gray" size="xs" mb="md">
                    Informe imagens do necessários para informar o locador sobre as condições do ativo.
                </Text>
                <UploadDropZone />
                <Divider my="sm" label="Documentos" />
                <Text color="gray" size="xs" mb="md">Insira os documentos necessários para informar o locador sobre as condições do ativo.
                    Laudos técnicos, notas fiscais, históricos, certidões, declarações e etc.</Text>
                        <UploadDropZone />
                        
    */
