import {
  Box,
  Card,
  Checkbox,
  Grid,
  Group,
  Paper,
  Select,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";

export const CondicoesTab = ({empresaID}: any) => {
  return (
    <Box my="lg">
      <Paper withBorder mb="md" p="md">
        <Text color="dimmed" size="sm">
          Informe as todas condições necessárias presentes do seu negócio, os
          condições não contempladas não serão inseridas no contrato.
        </Text>
      </Paper>
      <Grid gutter={5} gutterXs="sm" gutterMd="sm" gutterXl={10}>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Valor x Período
              </Text>
              <Checkbox
                size="md"
                checked
                disabled
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "diario", label: "Diário" }]}
            />
            <TextInput mt="sm" label="Valor" placeholder="R$ 00,00" />
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Logística
              </Text>
              <Checkbox
                size="md"
                checked
                disabled
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "diario", label: "Diário" }]}
            />
            <TextInput mt="sm" label="Valor do frete" placeholder="R$ 00,00" />
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Multa por atraso
              </Text>
              <Checkbox
                size="md"
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "", label: "" }]}
            />
            <TextInput mt="sm" label="Valor do frete" placeholder="R$ 00,00" />
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Inspeções Programadas
              </Text>
              <Checkbox
                size="md"
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "", label: "" }]}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Manutenção
              </Text>
              <Checkbox
                size="md"
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "", label: "" }]}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Sublocação
              </Text>
              <Checkbox
                size="md"
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "diario", label: "Diário" }]}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={4}>
          <Paper withBorder p="md">
            <Group position="apart">
              <Text fz="lg" fw={700}>
                Contraproposta
              </Text>
              <Checkbox
                size="md"
                alt="Obrigatório"
                styles={{ input: { cursor: "pointer" } }}
              />
            </Group>
            <Select
              mt="md"
              w="full"
              label="Tipo de Condição"
              placeholder="Qual a condição?"
              data={[{ value: "diario", label: "Diário" }]}
            />
          </Paper>
        </Grid.Col>
      </Grid>
      <Paper withBorder mt="md" p="md">
        <Group position="apart">
          <Text fz="lg" fw={700}>
            Outras condições
          </Text>
          <Checkbox
            size="md"
            alt="Obrigatório"
            styles={{ input: { cursor: "pointer" } }}
          />
        </Group>
        <Textarea mt="md" />
      </Paper>
    </Box>
  );
};
