import moment from "moment";

export class NumberUtils {

    public static valorToNumber(valor: any) {
        if (!valor)
            return null;

        const finalNumber = Number(valor);
        if (!finalNumber) {
            valor = valor.replace("R$ ", "");
            valor = valor.replace(".", "");
            valor = valor.replace(",", ".");
            return Number(valor);
        }
        return valor;
    }

    public static numberToValor(number: any) {
        const finalNumber = Number(number);
        if (finalNumber) {
            return finalNumber?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        } else {
            return "";
        }
    }

}

export class ObjectUtils {

    public static clone(data: any) {
        return Object.assign({}, data);
    }

    public static cleanNulls(obj: any) {
        const newObj = {} as any;
        Object.keys(obj).forEach((key) => {
            if (obj[key] != null && obj[key] !== "") {
                newObj[key] = obj[key];
            }
        });
        return newObj;
    }

    public static isNullOrEmpty(data: any) {
        return data === "" || data === undefined || data === null;
    }

}

export class DateUtils {

    public static format(data: any) {
        if (data) {
            return moment(data, "YYYY-MM-DD HH:mm:ss").toDate();
        }

        return moment().toDate();
    };

}

export class InfoUtils {

    public static formatCNPJ(cnpj: string) {
        if (!cnpj) {
            return "";
        }
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    public static formatCurrency(value: number) {
        if (!value) {
            return null;
        }
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    public static limitText(text: string, size: number) {
        if (!text) {
            return "";
        }

        if (text.length < size) {
            return text;
        }

        return text.substring(0, size - 3) + "...";
    }

}

export class FileUtils {

    public static readImageFile(file: File): Promise<any> {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                const image = new Image();
                if (reader.result) {
                    image.src = reader.result + "";
                    resolve(image);
                }
            };
            reader.onerror = function (event) {
                reject("Erro ao ler o arquivo: " + event.target?.error);
            };
            reader.readAsDataURL(file);
        });
    }

    public static async getFileMD5 (arquivo: any) {
        const arrayBuffer = await arquivo.arrayBuffer();
        const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
        return hashHex;
    }

}