import { Card, Indicator, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const AccessButton = ({ active, href, icon, label, badge, instructions }: any) => {

  const navigate = useNavigate();

  return (
    <Card
      shadow="sm"
      padding="md"
      pt="xl"
      px="sm"
      style={{
        marginBottom: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: active ? "pointer" : "not-allowed",
      }}
      onClick={() => navigate(href)}
    >
      {badge && (
        <Indicator inline label={badge} color={active ? "blue": "gray"} size={16}>
          <div style={{ width: "100%", textAlign: "center" }}>{icon}</div>
        </Indicator>
      )}
      {!badge && (
        <div style={{ width: "100%", textAlign: "center" }}>{icon}</div>
      )}
      <Text
        size="sm"
        fw={700}
        style={{ marginTop: "0.5rem", textAlign: "center" }}
      >
        {label}
      </Text>
      <Text
        size="sm"
        color="dimmed"
        style={{ marginTop: "0.5rem", textAlign: "center" }}
      >
        {instructions}
      </Text>
    </Card>
  );

}