import {
  Button,
  Group,
  Input,
  Loader,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import moment from "moment";
import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { NumberUtils } from "shared/Utils";
import { ConcurrencyInput } from "shared/components/ConcurrencyInput";
import ConsultaCEP from "shared/providers/data/ConsultaCEP";
import ConsultaCNPJ from "shared/providers/data/ConsultaCNPJ";

export const EmpresaForm = ({
  reducer,
  editMode,
  precadastroMode,
  onSubmit,
}: any) => {

  const { data, status } = reducer.state;
  const [isConsultandoCNPJ, setConsultandoCNPJ] = useState<boolean>(false);

  const format = (data: any) => {
    if (data)
      return moment(data, "YYYY-MM-DD HH:mm:ss").toDate();
    return moment().toDate();
  };

  const form = useForm({
    validateInputOnBlur: [
      "cnpj",
      "razaoSocial",
      "nomeFantasia",
      "inscricaoEstadual",
      "inscricaoMunicipal",
      "modo",
      "lucroLiquido",
      "email",
      "telefone",
      "cep",
      "numero",
      "complemento",
      "logradouro",
      "referencia",
      "bairro",
      "cidade",
      "estado",
      "pais",
      "latitude",
      "longitude",
    ],
    initialValues: {
      id: null,
      cnpj: "",
      razaoSocial: "",
      nomeFantasia: "",
      dataAbertura: "",
      inscricaoEstadual: "",
      inscricaoMunicipal: "",
      modo: "",
      lucroLiquido: "",
      contato: {
        telefone: "",
        email: "",
      },
      endereco: {
        cep: "",
        complemento: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        referencia: "",
        estado: "",
        pais: "",
        geolocation: {
          latitude: 0.0,
          longitude: 0.0,
        },
      },
    },
    validate: {
      cnpj: (value) => (value.length > 1 ? null : "Informe um CNPJ"),
      razaoSocial: (value) =>
        value !== "" ? null : "Informe uma razão social",
      nomeFantasia: (value) =>
        value !== "" ? null : "Informe um nome fantasia",
      modo: (value) => (value !== "" ? null : "Informe um modo"),
      lucroLiquido: (value) =>
        value !== "" ? null : "Informe o lucro liquido",
      contato: {
        email: (value) => (value !== "" ? null : "Informe um email"),
        telefone: (value) => (value !== "" ? null : "Informe um telefone"),
      },
      endereco: {
        cep: (value) => (value !== "" ? null : "Informe um CEP"),
        logradouro: (value) => (value !== "" ? null : "Informe um logradouro"),
        numero: (value) => (value !== "" ? null : "Informe um numero"),
        complemento: (value) =>
          value !== "" ? null : "Informe um complemento",
        referencia: (value) =>
          value !== "" ? null : "Informe um ponto de referencia",
        bairro: (value) => (value !== "" ? null : "Informe um bairro"),
        cidade: (value) => (value !== "" ? null : "Informe uma cidade"),
        estado: (value) => (value !== "" ? null : "Informe um estado"),
        pais: (value) => (value !== "" ? null : "Informe um país"),
        geolocation: {
          latitude: (value) => (value !== 0 ? null : "Informe uma latitude"),
          longitude: (value) => (value !== 0 ? null : "Informe uma longitude"),
        },
      },
    },
  });

  const _onSubmit = async (submitData: any) => {
    onFormError({});

    if (form.values.inscricaoEstadual === "" && form.values.inscricaoMunicipal === "") {
      form.setFieldError("inscricaoEstadual", "Informe uma inscrição estadual ou municipal")
      form.setFieldError("inscricaoMunicipal", "Informe um inscrição municipal ou estadual")
      return;
    }

    let cleanData = Object.assign({}, submitData);
    Object.keys(cleanData).forEach(
      (k) => cleanData[k] == null && delete cleanData[k],
    );
    cleanData.lucroLiquido = NumberUtils.valorToNumber(cleanData?.lucroLiquido);
    cleanData.dataAbertura = moment(
      cleanData.dataAbertura,
      "DD/MM/YYYY",
    ).format("YYYY-MM-DD");
    cleanData.cnpj = cleanData.cnpj.replace(/[^a-z0-9]/gi, "");
    const id = cleanData.id;
    delete cleanData.id;
    delete cleanData.logo;
    delete cleanData.modificationTime;
    delete cleanData.creationTime;
    onSubmit({ ...cleanData }, id);
  };

  const onFormError = (event: any) => {
    if (form.values.endereco.geolocation.latitude == 0.0 || form.values.endereco.geolocation.latitude == 0.0) {
      notifications.show({
        message: "Informe a geolocalização da empresa no mapa do formulário",
        color: "orange",
      });
    } else {
      notifications.show({
        message: "Há algum campo incorreto no formulário",
        color: "orange",
      });
    }
  }

  const consultarDados = async (values: any) => {
    if (
      values.cnpj !== "" &&
      values.cnpj.length > 17 &&
      values.razaoSocial === ""
    ) {
      try {
        setConsultandoCNPJ(true);
        const resultadoCNPJ = await ConsultaCNPJ.consultar(
          values.cnpj.replace(/\D/g, ""),
        );
        form.setValues({ ...values, ...resultadoCNPJ });
      } catch (e: any) {
        notifications.show({
          message: e.message || "",
          color: "orange",
        });
      }
      setConsultandoCNPJ(false);
    }
    if (
      values.endereco.cep !== "" &&
      values.endereco.cep.length > 8 &&
      values.endereco.logradouro === ""
    ) {
      try {
        const resultadoCEP = await ConsultaCEP.consultar(
          values.endereco.cep.replace(/\D/g, ""),
        );
        form.setValues({ endereco: { ...values.endereco, ...resultadoCEP, geolocation: values.endereco } });
      } catch (e: any) {
        notifications.show({
          message: e.message || "",
          color: "orange",
        });
      }
    }
  };

  useEffect(() => {
    if (data?.object && (editMode || precadastroMode)) {
      const dataAbertura = format(data?.object?.dataAbertura);
      delete data?.object?.dataAbertura;
      form.setValues({ ...data?.object, dataAbertura: dataAbertura });
    }
  }, [data]);

  const [debounced] = useDebouncedValue(form.values, 1000);
  useEffect(() => {
    consultarDados(form.getTransformedValues());
  }, [debounced]);

  return (
    <>
      <LoadingOverlay visible={!!status.isFetching} overlayBlur={2} />
      <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
        <Text fz="md" mt="md" fw={500}>
          Informações Fiscais
        </Text>
        <SimpleGrid
          cols={2}
          mt="md"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Input.Wrapper label="CNPJ" required error={form.errors?.cnpj}>
            <Input<any>
              component={IMaskInput}
              rightSection={isConsultandoCNPJ ? <Loader size="xs" /> : <></>}
              disabled={data && editMode}
              mask="00.000.000/0000-00"
              placeholder="00.000.000/0000-00"
              {...form.getInputProps("cnpj")}
            />
          </Input.Wrapper>
          <TextInput
            label="Inscrição Estadual"
            disabled={data && editMode}
            placeholder="Inscrição Estadual"
            {...form.getInputProps("inscricaoEstadual")}
          />
          <TextInput
            label="Inscrição Municipal"
            disabled={data && editMode}
            placeholder="Inscrição Municipal"
            {...form.getInputProps("inscricaoMunicipal")}
          />
        </SimpleGrid>
        <SimpleGrid
          cols={2}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <TextInput
            label="Razão Social"
            disabled={data && editMode}
            placeholder="Razão Social"
            required
            {...form.getInputProps("razaoSocial")}
          />
          <TextInput
            label="Nome Fantasia"
            placeholder="Nome Fantasia"
            {...form.getInputProps("nomeFantasia")}
          />
        </SimpleGrid>
        <Select
          label="Modo"
          placeholder="Escolha uma modo"
          mt="md"
          data={[
            { value: "LOCADOR", label: "Locador", key: "locador" },
            { value: "LOCATARIO", label: "Locatário", key: "locatario" },
            { value: "AMBOS", label: "Ambos", key: "ambos" },
          ]}
          {...form.getInputProps("modo")}
        />
        <Input.Wrapper label="Data de Abertura" required mt="md" error={form.errors?.dataAbertura}>
          <DateInput
            disabled={editMode}
            {...form.getInputProps("dataAbertura")}
            valueFormat="DD/MM/YYYY"
            placeholder="dd/mm/aaaa"
          />
        </Input.Wrapper>
        <Input.Wrapper mt="md" label="Lucro Líquido" required error={form.errors?.lucroLiquido}>
          <ConcurrencyInput
            label="Lucro Líquido"
            {...form.getInputProps("lucroLiquido")}
            placeholder="R$ 255.000,00"
          />
        </Input.Wrapper>
        <Text fz="md" mt="md" fw={500}>
          Informações de Contato
        </Text>
        <SimpleGrid
          cols={2}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Input.Wrapper label="Telefone" required error={form.errors?.contato}>
            <Input<any>
              component={IMaskInput}
              mask="(00) 00000-0000"
              placeholder="(88) 98888-8888"
              {...form.getInputProps("contato.telefone")}
            />
          </Input.Wrapper>
          <TextInput
            label="Email"
            placeholder="email@empresa.com"
            required
            {...form.getInputProps("contato.email")}
          />
        </SimpleGrid>
        <Text fz="md" mt="md" fw={500}>
          Endereço
        </Text>
        <SimpleGrid
          cols={2}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <Input.Wrapper label="CEP" required>
            <Input<any>
              component={IMaskInput}
              mask="00000-000"
              placeholder="00000-000"
              {...form.getInputProps("endereco.cep")}
            />
          </Input.Wrapper>
          <TextInput
            label="Logradouro"
            placeholder="Rua dos Pinheiros"
            required
            {...form.getInputProps("endereco.logradouro")}
          />
          <TextInput
            label="Número"
            placeholder="Num. 20"
            required
            {...form.getInputProps("endereco.numero")}
          />
        </SimpleGrid>
        <SimpleGrid
          cols={2}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <TextInput
            label="Complemento"
            placeholder="AP 102"
            {...form.getInputProps("endereco.complemento")}
          />
          <TextInput
            label="Referencia"
            placeholder="Prox. a padaria do Zé"
            required
            {...form.getInputProps("endereco.referencia")}
          />
        </SimpleGrid>
        <SimpleGrid
          cols={4}
          mt="xl"
          breakpoints={[{ maxWidth: "sm", cols: 1 }]}
        >
          <TextInput
            label="Bairro"
            placeholder="Vila Pery"
            required
            {...form.getInputProps("endereco.bairro")}
          />
          <TextInput
            label="Cidade"
            placeholder="Fortaleza"
            required
            {...form.getInputProps("endereco.cidade")}
          />
          <TextInput
            label="Estado"
            placeholder="Ceará"
            required
            {...form.getInputProps("endereco.estado")}
          />
          <TextInput
            label="País"
            placeholder="Brasil"
            required
            {...form.getInputProps("endereco.pais")}
          />
        </SimpleGrid>
        <Input.Wrapper label="Geolocalização" mt="md">
          <SimpleGrid
            cols={2}
            mt="md"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <TextInput
              label="Latitude"
              placeholder="Latitude"
              required
              {...form.getInputProps("endereco.geolocation.latitude")}
            />
            <TextInput
              label="Longitude"
              placeholder="Longitude"
              required
              {...form.getInputProps("endereco.geolocation.longitude")}
            />
          </SimpleGrid>
        </Input.Wrapper>
        <Group position="apart" mt="lg">
          <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Limpar
          </Button>
          <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Confirmar
          </Button>
        </Group>
      </form>
    </>
  );
};
