import {
  ActionIcon,
  Button,
  Card,
  Center,
  FileButton,
  Group,
  Menu,
  Modal,
  Text,
  Title
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconDownload,
  IconEye,
  IconFileUpload,
  IconPhotoUp,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { useUploadAtivoDocument } from "hooks/uploads/useUploadAtivoDocument";
import { useUploadAtivoImage } from "hooks/uploads/useUploadAtivoImage";
import moment from "moment";
import { EnviarDocumentoAtivoForm } from "partials/forms/EnviarDocumentoAtivo.form";
import { AtivoPanel } from "partials/views/Ativo.view";
import { ExemplarPanel } from "partials/views/Exemplar.view";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CardsCarousel } from "shared/components/Carrousel.component";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";

export const AtivoItemViewPage = () => {

  const reducer = useRemoteDataReducer();
  const documentReducer = useRemoteDataReducer();
  const modal = useDisclosure(false);
  const params = useParams<any>();
  const documentOperations = useRemoteOperation({ reducer: documentReducer });
  const { fetchSingle, remove } =
    useRemoteOperation({ reducer: reducer });
  const drawerForm = useDrawerForm({ reducer: documentReducer })
  const documentUpload = useUploadAtivoDocument({ reducer: documentReducer });
  const imageUpload = useUploadAtivoImage({ reducer: reducer });
  const [document, setDocument] = useState<any>({});
  const [files, setFiles] = useState<File>(new File([], ""));
  const { data, status } = reducer.state;
  const productTitle = data?.object?.produto?.descricao;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isDeleted)
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Imagem enviada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } else if (
      status?.isFetching &&
      !documentReducer.state?.data?.page &&
      !documentReducer.state.status?.isFetching &&
      !documentReducer.state.status?.isFailed
    ) {
      onPaginationChange(params.id, { page: 0, size: 10, sort: "desc" });
    }
  }, [status]);

  useEffect(() => {
    if (documentReducer.state.status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (documentReducer.state.status?.isDeleted)
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (documentReducer.state.status?.isSubmitted) {
      notifications.show({
        message: "Documento enviado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (documentReducer.state.status?.isDeleted) {
      notifications.show({
        message: "Documento deletado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } 
  }, [documentReducer.state.status]);

  useEffect(() => {
    findAtivo(params.id);
  }, [params.id]);

  useEffect(() => {
    if (files.name) {
      imageUpload.execute({
        files: [files],
        ativoId: reducer?.state?.data?.object?.id,
      });
      setFiles(new File([], ""));
    }
  }, [files]);

  const viewDocument = (a: any) => {
    setDocument(a);
    //modal[1].open();
  };

  const findAtivo = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.ativoitem}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onDelete = (entity: any) => {
    remove({
      endpoint: `${RemoteEndpoints.ativoitem}/${reducer?.state?.data?.object?.id}/documents/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });
  };

  const onImageSelected = (selectedFiles: File) => setFiles(selectedFiles);

  const onPaginationChange = (id: any, pagination: PaginationProps) => {
    documentOperations.fetchPage({
      endpoint: `${RemoteEndpoints.ativoitem}/${id}/documents`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const _onSubmit = (submitData: any) => {
    if (submitData.documento?.name) {
      documentUpload.execute({
        ...submitData,
        ativoId: reducer?.state?.data?.object?.id,
      });
    }
  };

  return (
    <PageLayout
      title={productTitle}
      headerActions={
        <Group position="center">
          <Button
            onClick={() => drawerForm.open("enviarDocumentoAtivo")}
            loading={documentReducer.state.status?.isSubmitting}
            leftIcon={<IconFileUpload />}
          >
            Enviar documentos
          </Button>
          <FileButton
            multiple={false}
            onChange={onImageSelected}
            accept="image/png,image/jpeg,image/jpg"
          >
            {(props) => (
              <Button loading={reducer.state.status?.isSubmitting} {...props}>
                Enviar imagem
              </Button>
            )}
          </FileButton>
        </Group>
      }
      mobileHeaderActions={
        <>
          <Menu.Item icon={<IconFileUpload size={14} />} onClick={() => drawerForm.open("enviarDocumentoAtivo")}>
            Enviar Documentos
          </Menu.Item>
          <Menu.Item icon={<IconPhotoUp size={14} />} onClick={(event) => console.log("")}>
            Enviar imagem
          </Menu.Item>
        </>
      }
    >
      <Modal
        opened={modal[0]}
        onClose={modal[1].close}
        title="Documento do Ativo"
        fullScreen
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <iframe
          frameBorder="0"
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
          height="100%"
          width="100%"
          className="iframe"
          src={document.url}
        ></iframe>
      </Modal>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="enviarDocumentoAtivo"
        title="Enviar documento do ativo"
      >
        <EnviarDocumentoAtivoForm
          reducer={documentReducer}
          onSubmit={_onSubmit}
        />
      </CustomDrawer>
      {/* Informações do ativo */}
      <Title order={3} mb="md">
        Dados do Exemplar
      </Title>
      <Card>
        <ExemplarPanel
          exemplar={reducer?.state?.data?.object}
          loading={reducer?.state?.status?.isFetching}
        />
      </Card>
       {/* Informações do ativo */}
       <Title order={3} my="md">
        Dados do Ativo
      </Title>
      <Card>
        <AtivoPanel
          ativo={reducer?.state?.data?.object?.ativo}
          loading={reducer?.state?.status?.isFetching}
        />
      </Card>
      {/* Imagens */}
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Imagens</Title>
      </Group>
      <Hidden hide={!data?.object?.images || data?.object?.images.length == 0}>
        <CardsCarousel
          imagens={reducer?.state?.data?.object?.images}
          loading={reducer?.state?.status?.isFetching}
        />
      </Hidden>
      <Hidden hide={data?.object?.images && data?.object?.images.length !== 0}>
        <Center my="lg">
          <Text color="dimmed" size="sm">
            Nenhuma imagem disponível para este ativo
          </Text>
        </Center>
      </Hidden>
      {/* Documentos */}
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Documentos</Title>
      </Group>
      <DataGridComponent
        minHeight={250}
        visible={true}
        state={documentReducer.state}
        onPaginationChange={console.log}
        noRecordText="Nenhum documento encontrado"
        columns={[
          { accessor: "title", title: "Titulo" },
          {
            accessor: "creationTime",
            title: "Data/Hora envio",
            render: (document: any) => (
              <>{moment(document.creationTime).format("DD/MM/YYYY HH:mm")}</>
            ),
          },
          {
            accessor: "actions",
            title: <Text mr="xs">Ações</Text>,
            textAlignment: "right",
            render: (document: any) => (
              <Group spacing={12} position="right" noWrap>
                <ActionIcon color="gray" onClick={() => viewDocument(document)}>
                  <IconEye size={26} />
                </ActionIcon>
                <a href={document.url} download>
                  <ActionIcon color="blue">
                    <IconDownload size={26} />
                  </ActionIcon>
                </a>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
