import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient } from "hooks";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const useTokenLogin = (properties: any) => {
  const execute = async (data: any) => {
    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });

    const params = new URLSearchParams();
    params.append("username", data.username);
    params.append("password", data.password);
    params.append("grant_type", "mfa");
    params.append("mfa_code", data.mfa_code);

    const username = "algafood-web";
    const password = "euqueroumcachodocabelodoseukoo";
    const auth = btoa(username + ":" + password);
    const basic_auth_header = { Authorization: "Basic " + auth };

    const response = await RemoteClient.create(
      RemoteEndpoints.authenticateMFA,
      params,
      {
        headers: {
          ...basic_auth_header,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    );
    if (response.data) {
      SecurityProvider.authenticate(
        response.data.access_token,
        response.data.refresh_token,
      );
      const token = await SecurityProvider.getAuthorizationToken();
      if (token) {
        properties.reducer.dispatch({ type: "SUBMIT_SUCCESS", payload: token });
      } else {
        properties.reducer.dispatch({ type: "ERROR", payload: response.error });
      }
    } else if (response.error) {
      properties.reducer.dispatch({ type: "ERROR", payload: response.error });
    }
  };

  return { execute: execute };
};
