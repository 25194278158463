import { useEffect, useRef } from "react";
import ChartJS from "chart.js/auto";
import { Chart, Colors } from "chart.js";
import { createStyles } from "@mantine/core";

export const DoughnutChartComponent = () => {
  const canvasRef = useRef<any>(null);

  const useStyles = createStyles((theme) => ({
    container: {
      maxHeight: 200
    },
  }));

  const { classes } = useStyles();

  Chart.register(Colors);

  useEffect(() => {
    const myChartRef = canvasRef?.current?.getContext("2d");

    const myChart = new ChartJS(myChartRef, {
      type: "doughnut",
      data: {
        labels: ["Red", "Orange", "Yellow", "Green", "Blue"],
        datasets: [
          {
            label: "Sales",
            data: [65, 59, 80, 81, 56],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    return () => {
      myChart.destroy();
    };
  }, []);

  return <canvas ref={canvasRef} className={classes.container} />;
};
