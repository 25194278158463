import { ActionIcon, Group, Text } from "@mantine/core";
import { IconEye } from "@tabler/icons-react";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { Link } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";

export const OrcamentoDataGrid = ({ reducer, onPaginationChange, onSign, ...rest }: any) => {

    return (
        <DataGridComponent
            visible={true}
            state={reducer.state}
            {...rest}
            noRecordText="Nenhum orçamento encontrado"
            onPaginationChange={onPaginationChange}
            columns={[
                {
                    accessor: "numeroOrcamento",
                    title: "Número",
                    render: ({ numeroOrcamento }: any) => (
                        <Text>
                            {numeroOrcamento ? numeroOrcamento : "-"}
                        </Text>
                    ),
                },
                {
                    accessor: "solicitante",
                    title: "Solicitante",
                    render: ({ solicitante }: any) => <EmpresaChip empresa={solicitante} />
                },
                {
                    accessor: "solicitada",
                    title: "Solicitada",
                    render: ({ solicitada }: any) => <EmpresaChip empresa={solicitada} />
                },
                {
                    accessor: "status",
                    title: "Status",
                    render: ({ status }: any) =>
                        <CustomBadge value={status} options={[
                            { value: "AGUARDANDO_RESPOSTA", text: "Aguardando Resposta", color: "teal" },
                            { value: "RESPONDIDO", text: "Respondido", color: "blue" }
                        ]} />
                },
                {
                    accessor: "criacao",
                    title: "Criação",
                    render: ({ creationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },

                {
                    accessor: "atualizacao",
                    title: "Atualização",
                    render: ({ modificationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ url, id }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            <Link to={`/orcamentos/${id}`}>
                                <ActionIcon color="gray">
                                    <IconEye size={26} />
                                </ActionIcon>
                            </Link>
                        </Group>
                    ),
                },
            ]}
        />
    );

}