import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { useUploadPagamentoAnexo } from "hooks/uploads/useUploadPagamentoAnexo";
import { PagamentoDataGrid } from "partials/datagrids/Pagamento.datagrid";
import { PagamentoForm } from "partials/forms/Pagamento.form";
import { PagamentoView } from "partials/views/Pagamento.view";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PagamentoHelper } from "shared/PagamentoHelper";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";

export const PagamentoListPage = () => {

  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const { fetchSingle, fetchPage, create, patch } = useRemoteOperation(
    { reducer: reducer }
  );

  const uploadAnexoPagamento = useUploadPagamentoAnexo({ reducer: reducer });
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating)
      onPaginationChange({ page: 0, size: 10, sort: "numeroPagamento,desc" });
    else if (status?.isUpdated) {
      notifications.show({
        message: "Pagamento atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onAnexoAction = (id: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.pagamento}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
    drawerForm.open("pagamentoAnexosDrawer");
  }

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.pagamentos}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });
    
  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.pagamentos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "numeroPagamento,desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    patch({
      endpoint: `${RemoteEndpoints.pagamento}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity
    });
  };

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.pagamento}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  /* Isso aqui é pra quem é administrador  */
  const onCreate = (entity: any) => {
    delete entity.id;
    create({
      endpoint: RemoteEndpoints.pagamentos,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  const editInfo = (id: any) => {
    findItem(id);
    drawerForm.open("editarPagamento");
  };

  const visualizarPagamentoAction = (id: any) => {
    findItem(id);
    drawerForm.open("visualizarPagamento");
  };

  return (
    <PageLayout title="Pagamentos">
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="visualizarPagamento"
        title="Visualizar Pagamento"
      >
        <PagamentoView 
          reducer={reducer} 
          onDocumentoCobrancaUpload={(file: File, id: any) => 
            uploadAnexoPagamento.execute(file, "DOCUMENTO_COBRANCA", id)
          }
          onComprovanteUpload={(file: File, id: any) => 
            uploadAnexoPagamento.execute(file, "COMPROVANTE", id)
          }
          onReciboUpload={(file: File, id: any) => 
            uploadAnexoPagamento.execute(file, "RECIBO_NOTA", id)
          }
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarPagamento"
        title="Editar Pagamento"
      >
        <PagamentoForm
          reducer={reducer}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
          editMode={true}
        />
      </CustomDrawer>
      <PagamentoDataGrid
        reducer={reducer}
        onPaginationChange={onPaginationChange}
        onView={visualizarPagamentoAction}
        onEdit={editInfo}
        onAnexo={(id: any) => onAnexoAction(id)}
      />
    </PageLayout>
  );

};
