import { Avatar, ActionIcon, Loader } from "@mantine/core";
import { Dropzone } from "@mantine/dropzone";
import { useHover } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconUpload } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";
import { AvatarResponsive } from "partials/responsive/Avatar.responsive";
import { useState, useRef } from "react";
import { FileUtils } from "shared/Utils";
import { BlobStorageService } from "shared/azurestorage/BlobStorageService.proto";
import { Hidden } from "shared/custom/Hidden";

export const LogoEmpresaUploadPanel = ({ empresaKey, logo }: any) => {

  const { hovered, ref } = useHover();
  const [isUploading, setIsUploading] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<any>(logo);
  const openRef = useRef<() => void>(() => { });

  const onFileDrop = async (files: File[]) => {
    if (empresaKey) {

      const file = files[0];
      const image = await FileUtils.readImageFile(file);
      const hash = await FileUtils.getFileMD5(file);
      const data = {
        filename: file.name,
        contentType: file.type,
        size: file.size,
        hash: hash,
        cropParameters: {
          width: image.width,
          height: image.height
        }
      };

      setIsUploading(true);

      const preUploadResult = await RemoteClient.partialUpdate(
        `${RemoteEndpoints.empresa}/${empresaKey}/logo`,
        data,
        RequestConfigGenerator.build(),
      );
      if (preUploadResult.data) {
        try {
          var sas = preUploadResult.data.logo.url + '?' + preUploadResult.data.logo.sasToken;
          await BlobStorageService.uploadFiles([file], sas);
          setCurrentPhoto({ thumbnailSourceSet: `${preUploadResult.data.logo.url} 420` });
          setIsUploading(false);
        } catch (e) {
          setIsUploading(false);
          notifications.show({
            message: "Erro ao atualizar dados da logo",
            color: "red",
          });
        }
      } else {
        setIsUploading(false);
        notifications.show({ message: "Erro ao enviar imagem", color: "red" });
      }
    } else {
      notifications.show({ message: "Erro ao enviar imagem", color: "red" });
    }

  };

  const showRejectedFiles = (files: any) => {
    let reason = ".";
    if (files[0].errors[0].code === "file-too-large") {
      reason = ": Excede tamanho de 15 MB.";
    }

    notifications.show({
      message: `Arquivo inválido${reason}`,
      color: "orange",
    });
  };

  return (
    <>
      <Hidden hide={isUploading}>
        <div
          ref={ref}
          style={{ position: "relative", display: "inline-block" }}
        >
          <AvatarResponsive size="xl" sourceSet={(currentPhoto || logo)?.thumbnailSourceSet} />
          {hovered && (
            <ActionIcon
              color="blue"
              size="lg"
              radius="md"
              variant="filled"
              onClick={() => openRef.current()}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <IconUpload size="1rem" />
            </ActionIcon>
          )}
        </div>
      </Hidden>
      <Hidden hide={!isUploading}>
        <Loader m="lg" />
      </Hidden>
      <Dropzone
        style={{ display: "none" }}
        multiple={false}
        openRef={openRef}
        onDrop={(files) => onFileDrop(files)}
        onReject={(files) => showRejectedFiles(files)}
      >
        {" "}
      </Dropzone>
    </>
  );
};
