import { DeviceUUID } from "device-uuid";
import SecurityProvider from "../security/SecurityProvider";

export default class RequestConfigGenerator {
  public static build(): any {
    const token = SecurityProvider.getAuthorizationStringToken();
    const deviceUUID = new DeviceUUID().get();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Device-UUID": deviceUUID,
        "Content-Type": "application/json",
      },
    };
  }
}
