import { IconGauge, IconBuildingSkyscraper, IconClipboardCheck, IconBox, IconPigMoney, IconBriefcase, IconSchoolBell, IconMagnet, IconUser, IconUserShield, IconKey, IconDevices, IconFileDollar } from "@tabler/icons-react";

export const data = [
    { link: "/dashboard", label: "Dashboard", icon: IconGauge },
    {
      link: "/minha-empresa",
      label: "Minha empresa",
      icon: IconBuildingSkyscraper,
      authorities: ["STAKEHOLDER"],
    },
    {
      link: "/pre-cadastro-empresa",
      label: "Pré-Cadastro Empresas",
      authorities: ["ADMINISTRATOR"],
      icon: IconBuildingSkyscraper,
    },
    {
      link: "/empresas",
      label: "Empresas",
      authorities: ["ADMINISTRATOR"],
      icon: IconBuildingSkyscraper,
    },
    { link: "/prospeccoes", label: "Prospecções", icon: IconClipboardCheck },
    { link: "/ativos", label: "Portifólio de Ativos", icon: IconBox },
    { link: "/ativos-locados", label: "Ativos Locados", icon: IconBox },
    { link: "/orcamentos", label: "Orçamentos", icon: IconFileDollar },
    /* { link: "/licitacoes", label: "Licitações", icon: IconTrophy }, */
    
    { link: "/pagamentos", label: "Pagamentos", icon: IconPigMoney },
    { link: "/processos", label: "Processos", icon: IconBriefcase },
    { link: "/solicitacoes", label: "Solicitações", icon: IconSchoolBell },
    {
      link: "/contas",
      label: "Contas",
      icon: IconUser,
      authorities: ["ADMINISTRATOR"],
    },
    {
      link: "/administradores",
      label: "Administradores",
      icon: IconUserShield,
      authorities: ["ADMINISTRATOR"],
    },
    {
      link: "/permissoes",
      label: "Permissões",
      authorities: ["ADMINISTRATOR"],
      icon: IconKey,
    },
    {
      link: "/dispositivos",
      label: "Dispositivos",
      authorities: ["ADMINISTRATOR"],
      icon: IconDevices,
    },
    /*
    { link: "/contratos", label: "Contratos", icon: IconFileAnalytics },
    { link: "/eventos", label: "Eventos", icon: IconCalendarEvent },
    { link: "/alertas", label: "Alertas", icon: IconAlertCircle },
    { link: "/notificacoes", label: "Notificações", icon: IconNotification },
    */
  ];