import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Group,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import "../../styles/styles.css";

export const NegociacaoContratoPanel = () => {
  return (
    <Box>
      <Group position="apart" noWrap spacing="xl" mt="md" mb="xl">
        <Title order={3}>Definição do contrato</Title>
      </Group>
      <Paper withBorder shadow="sm" p="lg" radius="md" mt="md">
        <Group position="apart" mb="xs">
          <Text weight={500}>Identificação das partes</Text>
        </Group>
        <Text
          size="sm"
          color="dimmed"
          style={{ textJustify: "inter-word", textAlign: "justify" }}
        >
          CONTRATANTE: _________________________________________________, C.P.F.
          nº _____________________, RG.: ______________________ residente e
          domiciliado na Rua
          _________________________________________________________ município de
          _______________________, estado de________________________.
          CONTRATADO: __________________________________________________, C.P.F.
          nº ______________________, RG.: _____________________ residente e
          domiciliado na Rua
          ___________________________________________________________ município
          de ________________________, estado de _______________________. As
          partes acima identificadas têm, entre si, justo e acertado o presente
          Contrato de Prestação de Serviços, que se regerá pelas cláusulas
          seguintes e pelas condições de preço, forma e termo de pagamento
          descritas no presente
        </Text>
      </Paper>
      <Paper withBorder shadow="sm" p="lg" radius="md" mt="md">
        <Group position="apart" mb="xs">
          <Text weight={500}>Do objeto do contrato</Text>
        </Group>
        <Text
          size="sm"
          style={{ textJustify: "inter-word", textAlign: "justify" }}
        >
          Cláusula 1ª. É objeto do presente contrato a prestação do serviço de
          produção e instalação de
          ____________________________________________________________
          _______________________________________________________________________
          _______________________________________________________________________
          em __________________________________________________________________
          e ___________________________________ e
          _________________________________ nas seguintes especificações:
          Material a ser utilizado: (descrição detalhada dos materiais a serem
          utilizados) (Descrição detalhada dos ambientes, como dimensões dos
          móveis, ambientes a serem mobiliados, etc)
        </Text>
        <Group mt="xs" position="apart">
          <Badge color="red">Reprovado pelo contratante</Badge>
          <Group>
            <ActionIcon variant="default" radius="md" size={36}>
              <IconCheck size="1.1rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon variant="default" radius="md" size={36}>
              <IconX size="1.1rem" stroke={1.5} />
            </ActionIcon>
          </Group>
        </Group>
      </Paper>
      <Paper withBorder shadow="sm" p="lg" radius="md" mt="md">
        <Group position="apart" mb="xs">
          <Text weight={500}>Obrigações do contratante</Text>
        </Group>
        <Text
          size="sm"
          style={{ textJustify: "inter-word", textAlign: "justify" }}
        >
          With Fjord Tours you can explore more of the magical fjord landscapes
          with tours and activities on and around the fjords of Norway
        </Text>
        <Group mt="xs" position="apart">
          <Badge>Aprovado pelo contratante</Badge>
          <Badge>Aprovado pelo contratado</Badge>
        </Group>
      </Paper>
      <Paper withBorder shadow="sm" p="lg" radius="md" mt="md">
        <Group position="apart" mb="xs">
          <Text weight={500}>Obrigações do contratado</Text>
        </Group>
        <Text
          size="sm"
          style={{ textJustify: "inter-word", textAlign: "justify" }}
        >
          With Fjord Tours you can explore more of the magical fjord landscapes
          with tours and activities on and around the fjords of Norway
        </Text>
        <Group mt="xs" position="apart">
          <Badge color="red">Reprovado pelo contratante</Badge>
          <Badge>Aprovado pelo contratado</Badge>
        </Group>
      </Paper>
    </Box>
  );
};
