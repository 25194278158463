import { Box, Group, SimpleGrid, Text } from "@mantine/core"
import { IconBasket, IconFileInvoice, IconTrophy, IconBox, IconBuilding, IconPaperclip, IconFileCheck, IconFileUpload } from "@tabler/icons-react"
import { EventosProcessosDashboardPanel } from "partials/panels/dashboard/EventosProcessosDashboard.panel"
import { SolicitacoesDashboardPanel } from "partials/panels/dashboard/SolicitacoesDashoard.panel"
import { AccessButton } from "../../component/AccessButton.component"
import { FastAccessButton } from "../../component/FastAccessButton.component"

export const DashboardStakeholderPanel = () => {

    return (
        <>
            <SimpleGrid
                cols={3}
                breakpoints={[
                    { maxWidth: 980, cols: 3, spacing: 'md' },
                    { maxWidth: 755, cols: 2, spacing: 'sm' },
                    { maxWidth: 600, cols: 1, spacing: 'sm' }
                ]}
            >
                <AccessButton
                    active
                    href="/portal/prospeccao"
                    icon={<IconFileInvoice size={46} color="gray" />}
                    badge="Novo"
                    label="Prospecção"
                    instructions="Solicite orçamentos e compare valores dos melhores ativos do mercado."
                />
                <AccessButton
                    href="/portal/loja"
                    icon={<IconBasket size={46} color="gray" />}
                    badge="Em Breve"
                    label="Loja"
                    instructions="Para aluguel de ativos de forma rápida, sem negociação de valores. (A critério da aprovação do locador)"
                />
                <AccessButton
                    href="/test/licitacoes"
                    icon={<IconTrophy size={46} color="gray" />}
                    badge="Em Breve"
                    label="Licitação"
                    instructions="Para grandes empresas. Um processo criterioso de seleção e negociação de locadoras, ativos, valores e contratos."
                />
            </SimpleGrid>
            <Text size="xl" weight={700} style={{ marginBottom: "1rem" }}>
                Acesso Rápido
            </Text>
            <SimpleGrid
                cols={5}
                breakpoints={[
                    { maxWidth: 980, cols: 4, spacing: 'md' },
                    { maxWidth: 755, cols: 3, spacing: 'sm' },
                    { maxWidth: 600, cols: 2, spacing: 'sm' }
                ]}
            >
                <FastAccessButton
                    href="/ativos?action=cadastrar-ativo"
                    icon={<IconBox size={32} color="gray" />}
                    label="Cadastrar novo ativo"
                />
                <FastAccessButton
                    href="/empresas?action=cadastrar-empresa"
                    icon={<IconBuilding size={32} color="gray" />}
                    label="Cadastrar nova empresa"
                />
                <FastAccessButton
                    href="/prospeccoes?action=iniciar-prospeccao"
                    icon={<IconPaperclip size={32} color="gray" />}
                    label="Iniciar nova prospeccção"
                />
                <FastAccessButton
                    href="/prospeccoes?action=iniciar-prospeccao"
                    icon={<IconFileCheck size={32} color="gray" />}
                    label="Atualizar Parametrização da empresa"
                />
                <FastAccessButton
                    href="/prospeccoes?action=iniciar-prospeccao"
                    icon={<IconFileUpload size={32} color="gray" />}
                    label="Atualizar Documentos Empresa"
                />
            </SimpleGrid>
            <SimpleGrid
                cols={2}
                breakpoints={[
                    { maxWidth: 600, cols: 1, spacing: 'sm' }
                ]}
            >
                <Box>
                    <SolicitacoesDashboardPanel />
                </Box>
                <Box>
                    <EventosProcessosDashboardPanel />
                </Box>
            </SimpleGrid>

        </>
    )

}