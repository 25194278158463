import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import moment from "moment";
import { RemoteClient } from "../infrastructure/RemoteClient";

export default class ConsultaCNPJ {
  static async consultar(cnpj: string) {
    const resultado = await RemoteClient.find(
      `${RemoteEndpoints.consultaCNPJ}${cnpj}`,
    );
    if (resultado.code === 200) {
      const dados = resultado.data;
      return {
        razaoSocial: dados.razao_social,
        nomeFantasia: dados.estabelecimento.nome_fantasia,
        contato: {
          email: dados.estabelecimento.email,
          telefone: `(${dados.estabelecimento.ddd1}) ${dados.estabelecimento.telefone1}`,
        },
        dataAbertura: moment(
          dados.estabelecimento.data_inicio_atividade,
          "YYYY-mm-DD",
        ).toDate(),
        endereco: {
          logradouro: dados.estabelecimento.logradouro,
          numero: dados.estabelecimento.numero,
          complemento: dados.estabelecimento.complemento || "",
          referencia: "",
          bairro: dados.estabelecimento.bairro,
          cep: dados.estabelecimento.cep,
          cidade: dados.estabelecimento.cidade.nome,
          estado: dados.estabelecimento.estado.nome,
          pais: dados.estabelecimento.pais.nome,
          geolocation: {
            latitude: 0,
            longitude: 0,
          },
        },
        cnaes: [dados.estabelecimento.atividade_principal.subclasse],
      };
    } else {
      throw new Error("Não foi possivel consultar CNPJ.");
    }
  }
}
