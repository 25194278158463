import {
  Button,
  Group,
  Loader,
  LoadingOverlay,
  MultiSelect,
  PasswordInput,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect, useState } from "react";
import { Hidden } from "shared/custom/Hidden";

export const AdministratorForm = ({
  reducer,
  onSubmit,
  editMode
}: any) => {

  const permissionsReducer = useRemoteDataReducer();
  const permissionsOperations = useRemoteOperation({
    reducer: permissionsReducer,
  });

  const { data, status } = reducer.state;

  const form = useForm({
    validateInputOnBlur: ["nome", "matricula", "permissions", "papel"],
    initialValues: {
      id: "",
      nome: "",
      matricula: "",
      account: {
        email: "",
        password: "",
        password2: "",
        permissions: [],
      },
    },
    validate: {
      nome: (value) => (value.length > 1 ? null : "Informe um nome"),
      matricula: (value) => (value !== "" ? null : "Informe uma matrícula"),
      account: (value) =>
        value?.email !== "" || editMode ? null : "Informe um email",
    },
  });

  useEffect(() => {
    if(editMode){
      form.setValues({ ...data?.object });
    }
  }, [data]);

  useEffect(() => {
    permissionsOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.permissoes}/list?type=ADMINISTRATOR`,
      headers: RequestConfigGenerator.build(),
    });
  }, []);

  const _onSubmit = async (data: any) => {
    delete data.account.password2;
    const id = data.id;
    delete data.id;
    if (editMode) {
      delete data.account;
    }
    onSubmit(data, id);
  };

  const onFormError = (event: any) =>
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });

  const processPermissions = (permissions: any) => {
    if (!permissions || permissions.length < 1) {
      return [];
    }
    return permissions?.map((p: any) => {
      return { value: p.id, label: `${p.description}` };
    });
  };

  return (
    <>
      <LoadingOverlay visible={!!status?.isFetching} overlayBlur={2} />
      <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
        <TextInput
          mt="md"
          label="Nome"
          placeholder="Paola B"
          required
          {...form.getInputProps("nome")}
          autoComplete="new-password"
          data-testid="nome"
        />
        <TextInput
          mt="md"
          label="Email"
          placeholder="paola@usurpadora.com.mx"
          required
          data-testid="email"
          autoComplete="new-password"
          {...form.getInputProps("account.email")}
          disabled={editMode}
          rightSection={
            permissionsReducer.state.status?.isFetching ? (
              <Loader size="xs" />
            ) : (
              <></>
            )
          }
        />
        <Hidden hide={editMode}>
          <SimpleGrid
            cols={2}
            mt="xl"
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
          >
            <PasswordInput
              label="Senha"
              placeholder="**********"
              data-testid="password"
              autoComplete="new-password"
              {...form.getInputProps("account.password")}
            />
            <PasswordInput
              label="Confirme sua senha"
              placeholder="**********"
              {...form.getInputProps("account.password2")}
              autoComplete="password"
              data-testid="password2"
            />
          </SimpleGrid>
        </Hidden>
        <TextInput
          mt="md"
          label="Matrícula"
          placeholder="01457"
          description="Caso sua empresa identifique os colaboradores"
          {...form.getInputProps("matricula")}
          data-testid="matricula"
        />
        <MultiSelect
          mt="md"
          disabled={editMode}
          rightSection={
            permissionsReducer.state.status?.isFetching ? (
              <Loader size="xs" />
            ) : (
              <></>
            )
          }
          data={processPermissions(permissionsReducer.state?.data?.object)}
          label="Permissões"
          data-testid="permissoes"
          placeholder="Ex: Contratos, Processos, administradors"
          description="Escolha as funcionalidades que este administrador pode ter acesso"
          {...form.getInputProps("account.permissions")}
        />
        <Group position="apart" mt="lg">
          <Button
            data-testid="cancel"
            variant="subtle"
            disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}
          >
            Limpar
          </Button>
          <Button data-testid="submit" type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
            Confirmar
          </Button>
        </Group>
      </form>
    </>
  );
};
