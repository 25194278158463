import { DataTable, DataTableSortStatus } from "mantine-datatable";
import { useEffect, useState } from "react";

export interface DataGridRowItem {
  name: string;
  title: string;
  render: (item: any) => JSX.Element;
  hideHeadCell?: boolean;
  hideGridCell?: boolean;
  hide?: boolean;
}

export const DataGridComponent = ({
  minHeight,
  state,
  noRecordText,
  columns,
  onPaginationChange,
}: any) => {
  
  const data = state?.data;
  const [page, setPage] = useState(data?.page?.number || 1);
  const [pageSize, setPageSize] = useState(10);
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "creationTime",
    direction: "desc",
  });

  useEffect(() => {
    if(data.page){
      onPaginationChange({
        page: page - 1,
        size: pageSize /*, sort: sortStatus?.columnAccessor*/,
      });
    }
  }, [page, pageSize, sortStatus]);

  /*
        
        defaultColumnProps={{
          textAlignment: 'center',
        }}
  */

  return (
      <DataTable
        withBorder
        borderRadius="md"
        fetching={state?.status?.isFetching || state?.status?.isDeleting || state.status?.isUpdating || state.status?.isSubmitting }
        striped
        horizontalSpacing="sm"
        verticalSpacing="xs"
        minHeight={minHeight || 500}
        highlightOnHover
        fontSize="sm"
        columns={columns}
        noRecordsText={noRecordText}
        records={data?.page?.content}
        totalRecords={data?.page?.totalElements || 0}
        recordsPerPage={data?.page?.size || 10}
        page={page}
        onPageChange={setPage}
        recordsPerPageOptions={[10, 25, 50, 100]}
        onRecordsPerPageChange={setPageSize}
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        
      />
  );

};

/*
defaultColumnProps={{
          noWrap: true,
          ellipsis: true
        }}*/
