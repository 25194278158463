import { Box, Button, Group, NumberInput, Select, SimpleGrid, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCalendar, IconPlus } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ItemProspeccaoForm = ({ onSubmit, reducer, compact }: any) => {

    const location = useLocation();

    const reducerClasse = useRemoteDataReducer();
    const reducerSubclasse = useRemoteDataReducer();
    const reducerGrupo = useRemoteDataReducer();
    const reducerFamilia = useRemoteDataReducer();
    const reducerMarca = useRemoteDataReducer();

    const classeOperations = useRemoteOperation({ reducer: reducerClasse });
    const subclasseOperations = useRemoteOperation({ reducer: reducerSubclasse });
    const grupoOperations = useRemoteOperation({ reducer: reducerGrupo });
    const familiaOperations = useRemoteOperation({ reducer: reducerFamilia });
    const marcaOperations = useRemoteOperation({ reducer: reducerMarca });

    const form = useForm({
        initialValues: {
            descricao: "",
            marca: "",
            produto: "",
            classe: "",
            subclasse: "",
            grupo: "",
            familia: "",
        },
        validate: {
            descricao: (value) => value !== "" ? null : "Informe uma descrição",
        },
    });

    useEffect(() => {
        if (form.values.marca === "") {
            marcaOperations.fetchSingle({
                endpoint: `${RemoteEndpoints.marcas}/listar`,
                headers: RequestConfigGenerator.build(),
            });
        }
    }, [location]);

    useEffect(() => {
        if (form.values.classe === "") {
            classeOperations.fetchSingle({
                endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=CLASS`,
                headers: RequestConfigGenerator.build(),
            });
        }
        if (form.values.classe !== "") {
            const classId = reducerClasse?.state?.data?.object[0]?.id;
            subclasseOperations.fetchSingle({
                endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=SUBCLASS&parent=${classId}`,
                headers: RequestConfigGenerator.build(),
            });
        }
        if (form.values.subclasse !== "") {
            const subclassId = reducerSubclasse?.state?.data?.object[0]?.id;
            grupoOperations.fetchSingle({
                endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=GROUP&parent=${subclassId}`,
                headers: RequestConfigGenerator.build(),
            });
        }
        if (form.values.grupo !== "") {
            const groupId = reducerGrupo?.state?.data?.object[0]?.id;
            familiaOperations.fetchSingle({
                endpoint: `${RemoteEndpoints.classificacaoProdutos}/listar?categoria=FAMILY&parent=${groupId}`,
                headers: RequestConfigGenerator.build(),
            });
        }
    }, [form.values.classe, form.values.subclasse, form.values.grupo]);

    const _onSubmit = async (submitData: any) => {
        onSubmit(submitData);
    };

    const onFormError = (event: any) =>
        notifications.show({
            message: "Há algum campo incorreto no formulário",
            color: "red",
        });

    return (
        <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
            <TextInput
                label="Descrição do Item"
                placeholder="Ex: Furadeira de Impacto ou Escavadeira"
                {...form.getInputProps("descricao")}
            />
            <SimpleGrid
                mt="xs"
                cols={ compact ? 5 : 1}
                breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            >
                <Select
                    {...form.getInputProps("marca")}
                    label="Marca"
                    placeholder="Ex: Bosch"
                    defaultValue={null}
                    data={
                        [...(reducerMarca?.state?.data?.object || []), { id: null, nome: "Sem marca" } ].map((result: any) => {
                            return { value: result.id, label: result.nome };
                        }) || []
                    }
                />
                <Select
                    
                    {...form.getInputProps("classe")}
                    label="Classe do Ativo"
                    placeholder="Escolha um tipo de ativo"
                    data={
                        reducerClasse?.state?.data?.object?.map((result: any) => {
                            return { value: result.valor, label: result.titulo };
                        }) || []
                    }
                />
                <Select
                    {...form.getInputProps("subclasse")}
                    label="Subclasse do Ativo"
                    placeholder="Escolha um tipo de ativo"
                    data={
                        reducerSubclasse?.state?.data?.object?.map((result: any) => {
                            return { value: result.valor, label: result.titulo };
                        }) || []
                    }
                    disabled={form.values.classe === ""}
                />
                <Select
                    {...form.getInputProps("grupo")}
                    label="Grupo do Ativo"
                    placeholder="Escolha um tipo de ativo"
                    data={
                        reducerGrupo?.state?.data?.object?.map((result: any) => {
                            return { value: result.valor, label: result.titulo };
                        }) || []
                    }
                    disabled={form.values.subclasse === ""}
                />
                <Select
                    {...form.getInputProps("familia")}
                    label="Familia do Ativo"
                    placeholder="Escolha um tipo de ativo"
                    data={
                        reducerFamilia?.state?.data?.object?.map((result: any) => {
                            return { value: result.valor, label: result.titulo };
                        }) || []
                    }
                    disabled={form.values.grupo === ""}
                />
                <NumberInput
                    {...form.getInputProps("qualidadeLote")}
                    label="Quantidade de itens disponíveis"
                    placeholder="10"
                    startValue={0}
                />
                <DatePickerInput
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label="Disponibilidade (Início)"
                    valueFormat="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                />
                <DatePickerInput
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    label="Disponibilidade (Final)"
                    valueFormat="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                />
            </SimpleGrid>
            <Group position="apart" mt="xs">
                <Box></Box>
                <Button size="xs" color="blue" type="submit" loading={reducer?.state?.status?.isSubmitting} variant="filled" leftIcon={<IconPlus />}>
                    Adicionar Item de Prospecção
                </Button>
            </Group>
        </form>
    );

}