import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconEdit, IconTrash, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { PermissionForm } from "partials/forms/Permission.form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const PermissaoPage = () => {

  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer })
  const { fetchSingle, update, remove, fetchPage, create } = useRemoteOperation(
    { reducer: reducer },
  );
  const { data, status } = reducer.state;
  const action = new URLSearchParams(useLocation().search).get("action");

  useEffect(() => {
    if (action === "cadastrar-permissao") {
      drawerForm.open("cadastrarPermissao")
    }
  }, [action]);

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
    else if (status?.isDeleted)
      notifications.show({
        message: "Permissão excluída com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Permissão cadastrada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Permissão atualizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } 
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onDelete = (entity: any) =>
    remove({
      endpoint: `${RemoteEndpoints.permissao}/${entity.id}`,
      id: entity.id,
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.permissoes}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    update({
      endpoint: `${RemoteEndpoints.permissao}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  };

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.permissao}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const actionEditar = (id: any) => {
    findItem(id);
    drawerForm.open("editarPermissao");
  }

  const onCreate = (entity: any) => {
    create({
      endpoint: RemoteEndpoints.permissoes,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  return (
    <PageLayout
      title="Permissões"
      headerActions={
        <Group position="center">
          <Button onClick={() => drawerForm.open("cadastrarPermissao")}>Cadastrar Permissão</Button>
        </Group>
      }
    >
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastrarPermissao"
        title="Cadastrar Permissão"
      >
        <PermissionForm
          reducer={reducer}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarPermissao"
        title="Editar Permissão"
      >
        <PermissionForm
          reducer={reducer}
          editMode={true}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
        />
      </CustomDrawer>
      <DataGridComponent
        fetching={false}
        visible={true}
        noRecordText="Nenhuma permissão encontrada"
        state={reducer.state}
        onPaginationChange={onPaginationChange}
        columns={[
          { accessor: "permission", title: "Permissão" },
          {
            accessor: "description",
            title: "Descrição",
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <Tooltip label="Editar Ativo">
                  <ActionIcon color="blue" onClick={() => actionEditar(id)}>
                    <IconEdit size={26} />
                  </ActionIcon>
                </Tooltip>
                <ActionIcon color="red" onClick={() => onDelete({ id: id })}>
                  <IconTrash size={26} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};

/*

                <Divider my="sm" label="Imagens" />
                <Text color="gray" size="xs" mb="md">
                    Informe imagens do necessários para informar o locador sobre as condições do ativo.
                </Text>
                <UploadDropZone />
                <Divider my="sm" label="Documentos" />
                <Text color="gray" size="xs" mb="md">Insira os documentos necessários para informar o locador sobre as condições do ativo.
                    Laudos técnicos, notas fiscais, históricos, certidões, declarações e etc.</Text>
                        <UploadDropZone />
                        
    */
