import { Group, ActionIcon, Text } from "@mantine/core";
import { IconScanEye } from "@tabler/icons-react";
import moment from "moment";
import { DataGridComponent } from "shared/custom/DataGridWrapper";

export const DocumentoDataGrid = ({ reducer, onPaginationChange }: any) => {

    return (
        <DataGridComponent
            visible={true}
            state={reducer.state}
            noRecordText="Nenhum documento encontrado"
            onPaginationChange={onPaginationChange}
            columns={[
                { accessor: "title", title: "Título", textAlign: 'right', ellipsis: true },
                {
                    accessor: "criacao",
                    title: "Hora/Data Criação",
                    render: ({ creationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },
                {
                    accessor: "atualizacao",
                    title: "Atualização",
                    render: ({ modificationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ url }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            <a href={`${url}`}>
                                <ActionIcon color="gray">
                                    <IconScanEye size={26} />
                                </ActionIcon>
                            </a>
                        </Group>
                    ),
                },
            ]}
        />
    );

}