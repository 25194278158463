import { Box, Title, Text, ActionIcon, Group, Badge } from "@mantine/core";
import { IconEye, IconEdit } from "@tabler/icons-react";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { OrcamentoDataGrid } from "partials/datagrids/Orcamento.datagrid";
import { ItemProspeccaoForm } from "partials/forms/ItemProspeccaoForm.form";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";

export const ProspeccaoItemPanel = ({ prospeccaoID, listItens, onSubmit, reducer, onPaginationChange }: any) => {

    const _onSubmit = (data: any) => {
        onSubmit(data);
    }

    return (
        <Box mt="sm">
            <ItemProspeccaoForm onSubmit={_onSubmit} reducer={reducer} compact={listItens} />
            <Hidden hide={!listItens}>
                <Text fz="xl" fw={500}>Lista de Itens</Text>
                <Box mt="md">
                <DataGridComponent
                    visible={true}
                    state={reducer.state}
                    noRecordText="Nenhum item no momento"
                    onPaginationChange={onPaginationChange}
                    columns={[
                        { accessor: "descricao", title: "Descrição" },
                        {
                            accessor: "marca",
                            title: "Marca",
                            render: ({ produto, dadosIdentificacaoProduto }: any) => <>{ produto?.descricao || dadosIdentificacaoProduto?.descricao }</>
                        },
                        {
                            accessor: "classificacao",
                            title: "Classificação",
                            render: ({ produto, dadosIdentificacaoProduto }: any) => (
                                <>
                                    <Badge size="xs" mr="sm">
                                        {produto?.classe?.titulo}
                                    </Badge>
                                    <Badge size="xs" mr="sm">
                                        {produto?.subclasse?.titulo}
                                    </Badge>
                                    <Badge size="xs" mr="sm">
                                        {produto?.grupo?.titulo}
                                    </Badge>
                                    <Badge size="xs">{produto?.familia?.titulo}</Badge>
                                </>
                            )
                        },
                        {
                            accessor: "periodo",
                            title: "Período",
                            render: ({ dataRetirada, dataDevolucao }: any) => <>
                                <Text size="sm">{moment(dataRetirada).format("DD/MM/YYYY [às]")}</Text>
                                <Text size="sm">{moment(dataDevolucao).format("DD/MM/YYYY [às]")}</Text>
                            </>
                        },
                    ]}
                />
                </Box>
            </Hidden>
        </Box>
    )

}
