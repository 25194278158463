import { Button, Tabs, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconBox,
  IconCheck,
  IconFileDollar,
  IconInfoCircle,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { ProspeccaoInfoTab } from "./tabs/ProspeccaoInfo.tab";
import { ProspeccaoItemsTab } from "./tabs/ProspeccaoItems.tab";
import { ProspeccaoOrcamentoTab } from "./tabs/ProspeccaoOrcamento.tab";
import { ObjectUtils } from "shared/Utils";
import { PaginationProps } from "shared/models/Pagination.props";
import { notifications } from "@mantine/notifications";

export const ProspeccaoViewPage = () => {

  const params = useParams<any>();
  const prospeccaoID = params.id;
  const authority = SecurityProvider.getAuthority();
  const location = useLocation();

  const reducerItensProspeccao = useRemoteDataReducer();
  const reducerProspeccao = useRemoteDataReducer();

  const { fetchSingle, update } = useRemoteOperation({
    reducer: reducerProspeccao
  });

  const prospeccaoItemReducer = useRemoteDataReducer();
  const prospeccaoItemOperations = useRemoteOperation(
    { reducer: prospeccaoItemReducer }
  );

  const { data, status } = reducerProspeccao.state;

  useEffect(() => {
    if (prospeccaoID && !data?.object) {
      reducerProspeccao.dispatch({ type: "INITIATING" });
      reducerItensProspeccao.dispatch({ type: "INITIATING" });
    }
  }, [location]);

  useEffect(() => {
    if (status?.isInitiating) {
      findProspeccao(prospeccaoID);
      findItensProspeccao(prospeccaoID);
    } 
    if (status?.isSuccessful && data?.object?.status === "FINALIZADO") {
      notifications.show({
        message: "Prospecção finalizada!",
        color: "green",
        icon: <IconCheck />,
      });
    } 
    if (status?.isFailed) {
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    }
  }, [status]);

  useEffect(() => {
    if (prospeccaoID && prospeccaoItemReducer.state.status?.isInitiating && !prospeccaoItemReducer.state.data?.object) {
      findProspeccaoItens(prospeccaoID, { page: 0, size: 10, sort: "desc" });
    }
  }, [prospeccaoItemReducer.state.status]);

  useEffect(() => {
    if (prospeccaoItemReducer.state.status?.isSubmitted) {
      notifications.show({
        message: "Item adicionado!",
        color: "green",
        icon: <IconCheck />,
      });
    }
  }, [prospeccaoItemReducer.state.status]);

  const findProspeccao = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.prospeccao}/${id}`,
      properties: { reducer: reducerProspeccao },
      headers: RequestConfigGenerator.build(),
    });

  const finalizarProspeccao = (data: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.prospeccao}/${prospeccaoID}/finalizar`,
      properties: { reducer: reducerProspeccao },
      headers: RequestConfigGenerator.build(),
      data: { status: "FINALIZADO" },
    });
  };

  const findItensProspeccao = (id: any) =>
    prospeccaoItemOperations.fetchPage({
      endpoint: `${RemoteEndpoints.prospeccao}/${id}/itens`,
      properties: { reducer: reducerItensProspeccao },
      headers: RequestConfigGenerator.build(),
    });

  const onCreateItem = (entity: any) => {
    entity = ObjectUtils.cleanNulls(entity);
    prospeccaoItemOperations.create({
      endpoint: `${RemoteEndpoints.prospeccao}/${data?.object?.id}/itens`,
      properties: { reducer: reducerItensProspeccao },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  }

  const findProspeccaoItens = (id: any, pagination: PaginationProps) => {
    prospeccaoItemOperations.fetchPage({
      endpoint: `${RemoteEndpoints.prospeccoes}/${id}/items`,
      properties: { reducer: reducerItensProspeccao },
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const openConfirmModal = (id: any) => modals.openConfirmModal({
    centered: true,
    title: 'Deseja concluir esta prospecção?',
    children: (
      <Text size="sm">
        Todos orçamentos aprovados serão convertidos em solicitações. Considere uma última verificação nos itens aprovados.
      </Text>
    ),
    labels: { confirm: 'Confirmar', cancel: 'Cancelar' },
    onConfirm: () => finalizarProspeccao(id),
  });

  return (
    <PageLayout
      title={`Prospecção #${data?.object?.numeroProspeccao || ""}`}
      headerActions={
        <Hidden hide={data?.object?.status === "FINALIZADO" || ["ADMINISTRATOR"].includes(authority)}>
          <Button
            color="teal"
            onClick={() => openConfirmModal(prospeccaoID)}
            loading={status?.isUpdating}
            disabled={status?.isUpdating}
          >
            Finalizar Prospecção
          </Button>
        </Hidden>
      }
    >
      <Tabs radius="xs" defaultValue="items">
        <Tabs.List>
          <Tabs.Tab
            value="items"
            icon={<IconBox color="gray" size="1.3rem" />}
          >
            Itens
          </Tabs.Tab>
          <Tabs.Tab
            value="orcamentos"
            icon={<IconFileDollar color="gray" size="1.3rem" />}
          >
            Orçamentos
          </Tabs.Tab>
          <Tabs.Tab
            value="informacoes"
            icon={<IconInfoCircle color="gray" size="1.3rem" />}
          >
            Informações
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="items" pt="xs">
          <ProspeccaoItemsTab
            reducer={prospeccaoItemReducer}
            onNewItem={onCreateItem}
            prospeccao={data?.object}
          />
        </Tabs.Panel>
        <Tabs.Panel value="informacoes" pt="xs">
          <ProspeccaoInfoTab
            prospeccao={data?.object}
          />
        </Tabs.Panel>
        <Tabs.Panel value="orcamentos" pt="xs">
          <ProspeccaoOrcamentoTab
            prospeccaoID={data?.object?.id}
          />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};
