import { Group, ActionIcon, Text } from "@mantine/core";
import { IconEye, IconEdit, IconPaperclip } from "@tabler/icons-react";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const PagamentoDataGrid = ({ reducer, onPaginationChange, onView, onEdit }: any) => {

  const authority = SecurityProvider.getAuthority();

  return (
    <DataGridComponent
      visible={true}
      state={reducer.state}
      noRecordText="Nenhum pagamento encontrado"
      onPaginationChange={onPaginationChange}
      columns={[
        {
          accessor: "numeroPagamento",
          title: "Número",
          render: ({ numeroPagamento }: any) => (
            <Text>
              {numeroPagamento ? numeroPagamento : "-"}
            </Text>
          ),
        },
        {
          hidden: !["ADMINISTRATOR"].includes(SecurityProvider.getAuthority()),
          accessor: "beneficiario",
          title: "Beneficiário",
          render: ({ empresaBeneficiaria }: any) => <EmpresaChip empresa={empresaBeneficiaria} />
        },
        {
          accessor: "pagador",
          title: "Pagador",
          render: ({ empresaPagadora }: any) => <EmpresaChip empresa={empresaPagadora} />
        },
        {
          accessor: "valor",
          title: "Valor",
          render: ({ valor }: any) => <>{InfoUtils.formatCurrency(valor)}</>
        },
        {
          accessor: "dataVencimento",
          title: "Vencimento",
          render: ({ dataVencimento }: any) =>
            <Text size="xs" color="dimmed">
              {moment(dataVencimento, "YYYY-MM-DDTHH:mm:ss").fromNow()}
            </Text>
        },
        { accessor: "descricao", title: "Descrição", render: ({ descricao }: any) => <>{InfoUtils.limitText(descricao, 20)}</> },
        {
          accessor: "status",
          title: "Status",
          render: ({ status }: any) =>
            <CustomBadge value={status} options={[
              { value: "GENERATED", text: "Gerado", color: "teal" },
              { value: "CONFIRMED", text: "Confirmado", color: "blue" },
              { value: "BOM", text: "Bom", color: "gray" }
            ]} />
        },
        {
          accessor: "criacao",
          title: "Criação",
          render: ({ creationTime }: any) =>
            <Text size="xs" color="dimmed">
              {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
            </Text>
        },
        {
          accessor: "atualizacao",
          title: "Atualização",
          render: ({ modificationTime }: any) =>
            <Text size="xs" color="dimmed">
              {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
            </Text>
        },
        {
          accessor: "actions",
          title: <Text>Ações</Text>,
          textAlignment: "right",
          render: ({ id }: any) => (
            <Group spacing={12} position="right" noWrap>
              <ActionIcon
                color="blue"
                onClick={() => onView(id)}
              >
                <IconEye size={26} />
              </ActionIcon>
              <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
                <ActionIcon color="blue" onClick={() => onEdit(id)}>
                  <IconEdit size={26} />
                </ActionIcon>
              </Hidden>
            </Group>
          ),
        },
      ]}
    />
  );

}
