import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";

export const useUpdatePagamentoProcesso = (properties: any) => {

  const execute = async (
    data: any,
    idPagamento: any,
  ) => {

    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });

    const createResult = await RemoteClient.partialUpdate(
      `${RemoteEndpoints.pagamento}/${idPagamento}`,
      data,
      RequestConfigGenerator.build(),
    );
    if (createResult.data) {
      debugger
      properties.reducer.dispatch({
        type: "UPDATE_SUCCESS",
        payload: createResult.data,
      });
    } else {
      properties.reducer.dispatch({
        type: "ERROR",
        payload: createResult.error,
      });
    }

  };

  return { execute: execute };

};
