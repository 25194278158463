import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const DispositivosPage = () => {

  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchPage, patch } = useRemoteOperation(
    { reducer: reducer },
  );
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed) notifications.show({ message: data?.error?.message });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isDeleted){
      notifications.show({
        message: "Dispositivo deletado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
    else if (status?.isUpdated) {
      notifications.show({
        message: "Dispositivo atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.dispositivos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    patch({
      endpoint: `${RemoteEndpoints.dispositivos}/detail/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity
    });
  }

  const deny = (device: any) => onUpdate({ status: 'UNAUTHORIZED' }, device.id); 

  const approve = (device: any) => onUpdate({ status: 'AUTHORIZED' }, device.id); 

  return (
    <PageLayout title="Dispositivos">
      <DataGridComponent
        visible={true}
        noRecordText="Nenhum dispositivo encontrado"
        state={reducer.state}
        onPaginationChange={onPaginationChange}
        columns={[
          { accessor: "deviceCode", title: "Código" },
          {
            accessor: "dateTime",
            title: "Hora/Data",
            render: ({ dateTime }: any) => (
              <Text size="xs" color="dimmed">
                {moment.utc(dateTime).format("DD/MM/YYYY [às] HH:mm")}
              </Text>
            ),
          },
          { accessor: "deviceModel", title: "Modelo" },
          { accessor: "deviceOperatingSystem", title: "Sistema Operacional" },
          { accessor: "deviceBrowser", title: "Navegador" },
          {
            accessor: "status",
            title: "Status",
            render: ({ status }: any) =>
              <CustomBadge value={status} options={[
                { value: "NONE", text: "Em análise", color: "gray" },
                { value: "ATENDIDO", text: "Atendido", color: "blue" },
                { value: "NEGADO", text: "Negado", color: "red" }
              ]} />
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (item: any) => (
              <Group spacing={12} position="right" noWrap>
                <Hidden hide={item?.status !== "NONE"}>
                  <Tooltip label="Negar">
                    <ActionIcon color="gray" onClick={() => deny(item)}>
                      <IconCircleX size={26} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip label="Aprovar">
                    <ActionIcon color="gray" onClick={() => approve(item)}>
                      <IconCircleCheck size={26} />
                    </ActionIcon>
                  </Tooltip>
                </Hidden>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
