import { Title } from "@mantine/core";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { ExemplarAtivoDataGrid } from "partials/datagrids/ExemplarAtivo.datagrid";
import { useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";

export const ProcessoAtivosTab = ({ processoID }: any) => {

  const reducer = useRemoteDataReducer();
  const remoteAtivosOperations = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (processoID && !data?.object) {
      reducer.dispatch({ type: "INITIATING" });
      findAtivosProcesso(processoID);
    }
  }, [processoID]);

  const findAtivosProcesso = (id: any) =>
    remoteAtivosOperations.fetchPage({
      endpoint: `${RemoteEndpoints.processo}/${id}/ativos`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

    const [modalState, setModalState] = useState({ images: [], open: false });

    const onImageView = (images: any) => {
      const image = images.map((item: any) => { return { src: item.imageSourceSet } })
      setModalState({ images: image, open: true});
    }

  return (
    <>
      <Title mt="md" mb="md" order={3}>
        Ativos (Exemplares)
      </Title>
      <Lightbox
        open={modalState.open}
        close={() => setModalState({ images: [], open: false})}
        slides={modalState.images}
      />
      <ExemplarAtivoDataGrid 
        onImageView={onImageView}
        reducer={reducer} 
        flags={["view"]}
      />
    </>
  );
};
