import { Group, ActionIcon, Text } from "@mantine/core";
import { IconScanEye } from "@tabler/icons-react";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { DataGridComponent } from "shared/custom/DataGridWrapper";

export const SimpleSolicitacaoDataGrid = ({ reducer, onPaginationChange }: any) => {

    return (
        <DataGridComponent
            visible={true}
            state={reducer.state}
            noRecordText="Nenhuma solicitação aberta no momento"
            onPaginationChange={onPaginationChange}
            columns={[
                { accessor: "numeroSolicitacao", title: "Número", sortable: true },
                {
                    accessor: "empresaSolicitante",
                    title: "Solicitante",
                    render: ({ empresaSolicitante }: any) => (
                        <EmpresaChip empresa={empresaSolicitante} />
                    ),
                  },
                {
                    accessor: "criacao",
                    title: "Hora/Data Criação",
                    render: ({ creationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ url }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            <a href={`/solicitacoes/${url}`}>
                                <ActionIcon color="gray">
                                    <IconScanEye size={26} />
                                </ActionIcon>
                            </a>
                        </Group>
                    ),
                },
            ]}
        />
    );

}