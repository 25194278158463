import { Badge, Group, Avatar, Text, Paper } from "@mantine/core"
import { InfoUtils } from "shared/Utils"
import { Hidden } from "shared/custom/Hidden"

export const ProspeccaoItemPanel = ({ prospectionItem }: any) => {

    return (
        <Paper withBorder p="md">
            <div>
                <Text size="sm" fw={500}>{InfoUtils.limitText(prospectionItem?.descricao || "", 100)}</Text>
                <Hidden hide={!prospectionItem?.descricaoClasse && !prospectionItem?.descricaoSubclasse && !prospectionItem?.descricaoGrupo && !prospectionItem?.descricaoFamilia}>
                    <Badge size="xs" mr="xs">
                        {prospectionItem?.descricaoClasse}
                    </Badge>
                    <Badge size="xs" mr="xs">
                        {prospectionItem?.descricaoSubclasse}
                    </Badge>
                    <Badge size="xs" mr="xs">
                        {prospectionItem?.descricaoGrupo}
                    </Badge>
                    <Badge size="xs" mr="xs">
                        {prospectionItem?.descricaoFamilia}
                    </Badge>
                </Hidden>
                <Text size="xs" color="dimmed">
                    {prospectionItem?.modelo}
                </Text>
                <Hidden hide={!prospectionItem?.marca}>
                    <Group noWrap mt="xs">
                        <Avatar size="sm" src={prospectionItem?.marca?.logo?.url} />
                        <div>
                            <Text size="xs" color="dimmed">
                                {prospectionItem?.marca?.nome}
                            </Text>
                        </div>
                    </Group>
                </Hidden>
            </div>
        </Paper>
    )

}