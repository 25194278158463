import {
  ActionIcon,
  Badge,
  Box,
  Button,
  CloseButton,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Paper,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconEye, IconFile } from "@tabler/icons-react";
import moment from "moment";
import { NumberUtils } from "shared/Utils";
import { UploadDropZone } from "shared/components/UploadDropZone.component";
import { Hidden } from "shared/custom/Hidden";

export const PagamentoView = ({
  reducer,
  onDocumentoCobrancaUpload,
  onComprovanteUpload,
  onReciboUpload
}: any) => {

  const { status, data } = reducer.state;
  const pagamento = data?.object;

  return (
    <Box>
      <LoadingOverlay visible={status?.loading} overlayBlur={2} />
      <Grid mt="sm">
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Status</Text>
          <Badge color="blue">{pagamento?.status}</Badge>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Valor</Text>
          <Text size="sm">{NumberUtils.numberToValor(pagamento?.valor)}</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Text size="xs" fw={500}>Data Vencimento</Text>
          <Text size="sm">{moment(pagamento?.dataVencimento).format("DD/MM/YYYY")}</Text>
        </Grid.Col>
      </Grid>
      <Box mt="md">
        <Text size="xs" fw={500}>Descrição</Text>
        <Text size="sm">{pagamento?.descricao}</Text>
      </Box>
      <Box mt="md">
        <Text size="xs" fw={500}>Observações</Text>
        <Text size="sm">{pagamento?.observacoes || "Nenhuma"}</Text>
      </Box>
      <>
        <Text fz="md" mt="md" fw={500}>
          Arquivos Anexados
        </Text>
        <>
          <Hidden hide={pagamento?.comprovante}>
            <Input.Wrapper mt="md" label="Enviar comprovante de pagamento">
              <UploadDropZone
                mt="md"
                multiple={false}
                disabled={pagamento?.comprovante}
                onDrop={(files) => onComprovanteUpload(files[0], pagamento?.id)}
                accept={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />
            </Input.Wrapper>
          </Hidden>
          <Hidden hide={!pagamento?.comprovante}>
            <Paper withBorder p="xs" mt="sm">
              <Group position="apart" noWrap spacing="xl">
                <Group noWrap>
                  <IconFile size={32} color="gray" />
                  <div>
                    <Text component="a" href="#" size="sm">
                      Comprovante
                    </Text>
                  </div>
                </Group>
                <ActionIcon component="a" href={pagamento?.comprovante?.url}>
                  <IconEye />
                </ActionIcon>
              </Group>
            </Paper>
          </Hidden>
        </>
        <>
          <Hidden hide={pagamento?.documentoCobranca}>
            <Input.Wrapper mt="md" label="Enviar documento (boleto)">
              <UploadDropZone
                mt="md"
                multiple={false}
                disabled={pagamento?.documentoCobranca || data?.object?.status === "CONFIRMED"}
                onDrop={(files) => onDocumentoCobrancaUpload(files[0], pagamento?.id)}
                accept={["application/pdf"]}
              />
            </Input.Wrapper>
          </Hidden>
          <Hidden hide={!pagamento?.documentoCobranca}>
            <Paper withBorder p="xs" mt="sm">
              <Group position="apart" noWrap spacing="xl">
                <Group noWrap>
                  <IconFile size={32} color="gray" />
                  <div>
                    <Text
                      component="a"
                      href={pagamento?.documentoCobranca?.url}
                      size="sm"
                    >
                      Boleto
                    </Text>
                  </div>
                </Group>
                <ActionIcon component="a" href={pagamento?.documentoCobranca?.url}>
                  <IconEye />
                </ActionIcon>
              </Group>
            </Paper>
          </Hidden>
        </>
        <>
          <Hidden hide={pagamento?.reciboNota}>
            <Input.Wrapper mt="md" label="Enviar recibo / nota">
              <UploadDropZone
                mt="md"
                multiple={false}
                disabled={data?.reciboNota || data?.object?.status !== "CONFIRMED"}
                onDrop={(files) => onReciboUpload(files[0], pagamento?.id)}
                accept={["application/pdf"]}
              />
            </Input.Wrapper>
          </Hidden>
          <Hidden hide={!pagamento?.reciboNota}>
            <Paper withBorder p="xs" mt="sm">
              <Group position="apart" noWrap spacing="xl">
                <Group noWrap>
                  <IconFile size={32} color="gray" />
                  <div>
                    <Text component="a" href={pagamento?.reciboNota?.url} size="sm">
                      Recibo / Nota Fiscal
                    </Text>
                  </div>
                </Group>
                <ActionIcon component="a" href={pagamento?.reciboNota?.url}>
                  <IconEye />
                </ActionIcon>
              </Group>
            </Paper>
          </Hidden>
        </>
      </>
    </Box>
  );

};
