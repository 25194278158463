import { Button, Divider, Drawer, Group, Text, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";
import { ProspeccaoItemPanel } from "partials/panels/ProspeccaoItem.panel";
import { AtivosALocarPanel } from "partials/panels/AtivosALocar.panel";

export const ProspeccaoItemsTab = ({ prospeccao, reducer, onNewItem, onPaginationChange }: any) => {


  const [opened, { open, close }] = useDisclosure(false);
  

  return (
    <>
      <Group position="apart" noWrap spacing="xl">
        <Title order={3} mt="md" mb="md">Itens de Prospecção</Title>
        <Button size="xs" onClick={open} leftIcon={<IconPlus />}>
          Adicionar Itens de Prospecção
        </Button>
      </Group>
      <Drawer
        position="right" 
        size="70%" 
        opened={opened} 
        onClose={close}
        title={
          <Text fz="xl" fw={500}>
            Itens de Prospecção
          </Text>
        }
      >
        <Divider />
        <ProspeccaoItemPanel 
          listItens 
          reducer={reducer}
          onSubmit={onNewItem} 
          onPaginationChange={(item: any) => console.log()}
        />
      </Drawer>
      <AtivosALocarPanel 
        prospection={prospeccao}
        reducer={reducer}
        prospectionID={prospeccao?.id}
        onPaginationChange={onPaginationChange}
      />
    </>
  )

}