import { Avatar, MediaQuery, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useEffect, useState } from "react";

export const AvatarResponsive = ({ sourceSet, size }: any) => {

    const [ thumbnail, setThumbnail ] = useState<string>("#");
    const { width } = useViewportSize();

    const parseString = (str: string) => {
        if(!str) {
            return [];
        }

        const pairs = str?.split(', ');
        const result = pairs.map(pair => {
          const [url, size] = pair.split(' ');
          return {
            url: url,
            size: parseInt(size, 10)
          };
        });
      
        return result;
    }

    const images = parseString(sourceSet);
    
    useEffect(() => {
        if(images.length > 0){
            images.map((item: any, index: number) => {
                if((width < item?.size && width > smaller(index)) || index === images?.length-1) {
                    setThumbnail(item.url);
                }
            })
        }
    }, [sourceSet])

    const smaller = (index: number) => {
        return index > 0 && index <= images.length && images[index - 1].size !== undefined
        ? Math.max(images[index - 1].size - 1, 0)
        : 0;
    }

    return (
        <Avatar size={size} src={thumbnail} radius="sm" />
    );

}