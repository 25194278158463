import {
    Card,
    LoadingOverlay,
    Timeline,
    Button,
    Text,
    Title,
    Box,
  } from "@mantine/core";
  import { IconDots, IconMessageDots } from "@tabler/icons-react";
  import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
  import { useRemoteOperation, RequestConfigGenerator } from "hooks";
  import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
  import moment from "moment";
import { SimpleSolicitacaoDataGrid } from "partials/datagrids/SimpleSolicitacao.datagrid";
  import { useEffect } from "react";
  import { useLocation } from "react-router-dom";
  import { Hidden } from "shared/custom/Hidden";
import { PaginationProps } from "shared/models/Pagination.props";
  
  export const SolicitacoesDashboardPanel = () => {
   
    const reducer = useRemoteDataReducer();
    const { fetchSingle, update, remove, fetchPage, create } = useRemoteOperation(
      { reducer: reducer },
    );
    const { data, status } = reducer.state;
  
    useEffect(() => {
      reducer.dispatch({ type: "INITIATING" });
    }, [location]);
  
    const onPaginationChange = (pagination: PaginationProps) => {
      fetchPage({
        endpoint: `${RemoteEndpoints.solicitacoes}`,
        requestParameters: {
          page: pagination.page,
          size: pagination.size,
          sort: pagination.sort || "desc",
        },
        headers: RequestConfigGenerator.build(),
      });
    };
  
    useEffect(() => {
      if (status?.isInitiating)
        onPaginationChange({ page: 0, size: 5, sort: "desc" });
    }, [status]);
  
    return (
      <>
        <Title mt="md" mb="md" order={3}>
          Solicitações Recentes
        </Title>
        <SimpleSolicitacaoDataGrid reducer={reducer} onPaginationChange={onPaginationChange} />
      </>
    );
  };
  