import { Group, Avatar, Text, Box, rem, Image, ActionIcon, Stack } from "@mantine/core"
import { IconCamera } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden"


export const ExemplarAtivoChip = ({ exemplar, onImageView }: any) => {

    const ativo = exemplar?.ativo;

    return (
        <Group noWrap style={{ minWidth: rem(60) }}>
            <ActionIcon color="gray" onClick={() => onImageView()}>
                <IconCamera />
            </ActionIcon>
            <Stack>
                <Text
                    component={Link}
                    to={`/ativos/${ativo?.id}`}
                    fw={500}
                >
                    {ativo?.detalhes} - { ativo?.produto ? ativo?.produto?.marca?.nome : ativo?.dadosIdentificacaoProduto?.marca?.nome }
                </Text>
                <Text
                    color="gray"
                >
                    Série/Pat.: {exemplar?.numeroSeriePatrimonio}
                </Text>
            </Stack>
        </Group>
    );

}