import { Paper, rem, Center, Group, Box, Text } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"


export const InformeParametrosBuscaProspeccaoPanel = () => {

    return (
        <Paper p="lg" mt={rem(40)} style={{ textAlign: "center" }}>
          <Center my="lg">
            <Group>
              <IconSearch
                size={rem(64)}
                style={{ color: "gray", marginRight: 16 }}
              />
              <Box>
                <Text size="lg" weight={700} style={{ marginBottom: 8 }}>
                  Informe os parametros da sua busca
                </Text>
                <Text size="sm">
                  Infelizmente, não encontramos nenhum resultado para a sua
                  pesquisa. Tente novamente com palavras-chave diferentes.
                </Text>
                <ul style={{ textAlign: "left", marginTop: 16 }}>
                  <li>Tente utilizar sinônimos ou termos similares</li>
                  <li>
                    Verifique se todas as palavras estão escritas corretamente
                  </li>
                  <li>Limite o número de filtros aplicados</li>
                </ul>
              </Box>
            </Group>
          </Center>
        </Paper>
    )

}