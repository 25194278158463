import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { DashboardAdministradorPanel } from "../../../partials/panels/dashboard/DashboardAdministrador.panel";
import { DashboardStakeholderPanel } from "../../../partials/panels/dashboard/DashboardStakeholder.panel";

export const DashboardPage = () => {

  return (
    <PageLayout title="Dashboard">
      <Hidden hide={!["ADMINISTRATOR"].includes(SecurityProvider.getAuthority())}>
        <DashboardAdministradorPanel />
      </Hidden>
      <Hidden hide={!["STAKEHOLDER"].includes(SecurityProvider.getAuthority())}>
        <DashboardStakeholderPanel />
      </Hidden>
    </PageLayout>
  );
};
