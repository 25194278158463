import {
  ActionIcon,
  Button,
  Group,
  Text
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconEdit, IconUserCog, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { AdministratorForm } from "partials/forms/Administrador.form";
import { AccountPanel } from "partials/panels/Account.panel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const AdministradoresPage = () => {

  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchPage, create, fetchSingle, update } = useRemoteOperation({
    reducer: reducer,
  });
  const drawerForm = useDrawerForm({ reducer: reducer });
  const { data, status } = reducer.state;
  const action = new URLSearchParams(useLocation().search).get("action");
  const [conta, setConta] = useState<any>(null);

  useEffect(() => {
    if (action === "cadastrar-administrador") {
      drawerForm.open("cadastrarAdministrador");
    }
  }, [action]);

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isDeleted)
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      close();
      notifications.show({
        message: "Administrador cadastrado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Administrador atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [status]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.administradores}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) => {
    fetchPage({
      endpoint: `${RemoteEndpoints.administradores}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onUpdate = (entity: any, id: any) => {
    update({
      endpoint: `${RemoteEndpoints.administrador}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });
  };

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.administrador}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onCreate = (entity: any) => {
    create({
      endpoint: RemoteEndpoints.administradores,
      properties: { reducer: reducer },
      data: entity,
      headers: RequestConfigGenerator.build(),
    });
  };

  const editInfo = (company: any) => {
    findItem(company?.id);
    drawerForm.open("editAdministratorDrawer");
  };

  const viewAccount = (account: any) => {
    setConta(account);
    drawerForm.open("visualizarConta")
  };

  return (
    <PageLayout
      title="Administradores"
      headerActions={
        <Group position="center">
          <Button onClick={() => drawerForm.open("cadastrarAdministrador")}>Cadastrar Administrador</Button>
        </Group>
      }
    >
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="visualizarConta"
        title="Dados da conta"
      >
        <AccountPanel account={conta} />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastrarAdministrador"
        title="Cadastrar Administrador"
      >
        <AdministratorForm
          reducer={reducer}
          editMode={false}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editAdministratorDrawer"
        title="Editar Administrador"
      >
        <AdministratorForm
          reducer={reducer}
          editMode={true}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
        />
      </CustomDrawer>
      <DataGridComponent
        state={reducer.state}
        visible={true}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhum administrador encontrado"
        columns={[
          { accessor: "nome", title: "Nome" },
          { accessor: "matricula", title: "Matricula" },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (company: any) => (
              <Group spacing={12} position="right" noWrap>
                <Hidden hide={!company?.account}>
                  <ActionIcon
                    color="gray"
                    onClick={() => viewAccount(company?.account)}
                  >
                    <IconUserCog size={26} />
                  </ActionIcon>
                </Hidden>
                <ActionIcon color="blue" onClick={() => editInfo(company)}>
                  <IconEdit size={26} />
                </ActionIcon>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );

};
