import { Badge, Box, Card, Divider, Grid, Group, LoadingOverlay, Paper, SimpleGrid, Text } from "@mantine/core";
import moment from "moment";

export const ProcessoPanel = ({ processo, loading }: any) => {
  return (
    <Paper withBorder p="md">
      <LoadingOverlay visible={loading} />
      <SimpleGrid
        cols={1}
        breakpoints={[
          { minWidth: "xl", cols: 4 },
          { minWidth: "lg", cols: 3 },
          { minWidth: "md", cols: 2 },
          { minWidth: "sm", cols: 2 },
        ]}
      >
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Número Processo</Text>
            <Text size="sm">{processo?.numeroProcesso}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Status</Text>
            <Badge color="blue">{processo?.status}</Badge>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Canal</Text>
            <Badge color="blue">{processo?.canal}</Badge>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Modo</Text>
            <Badge color="blue">{processo?.modo}</Badge>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Data Inicio</Text>
            <Text size="sm">{moment(processo?.creationTime).format("DD/MM/YYYY")}</Text>
          </Box>
        </Group>
        <Group>
          <Divider size="md" orientation="vertical" />
          <Box>
            <Text size="xs" fw={500} color="gray">Data Final</Text>
            <Text size="sm">{moment(processo?.dataVencimento).format("DD/MM/YYYY")}</Text>
          </Box>
        </Group>
      </SimpleGrid>
    </Paper>
  );
};
