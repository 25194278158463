import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Card,
  Group,
  Menu,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconDownload,
  IconEdit,
  IconEye,
  IconFileUpload,
  IconPlus,
  IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import moment from "moment";
import { AtivoItemForm } from "partials/forms/AtivoItem.form";
import { AtivoPanel } from "partials/views/Ativo.view";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";

export const AtivoViewPage = () => {

  const reducer = useRemoteDataReducer();
  const ativoItemReducer = useRemoteDataReducer();
  const params = useParams<any>();
  const action = new URLSearchParams(useLocation().search).get("action");
  const ativoItemOperations = useRemoteOperation({ reducer: ativoItemReducer });
  const { fetchSingle } = useRemoteOperation({ reducer: reducer });
  const drawerForm = useDrawerForm({ reducer: ativoItemReducer })
  const { data, status } = reducer.state;
  const productTitle = data?.object?.produto?.descricao;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (status?.isDeleted)
      notifications.show({
        message: "Operação realizada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    else if (status?.isSubmitted) {
      notifications.show({
        message: "Imagem enviada com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } else if (
      status?.isFetching &&
      !ativoItemReducer.state?.data?.page &&
      !ativoItemReducer.state.status?.isFetching &&
      !ativoItemReducer.state.status?.isFailed
    ) {
      onPaginationChange(params.id, { page: 0, size: 10, sort: "desc" });
    }
  }, [status]);

  useEffect(() => {
    if (ativoItemReducer.state.status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (ativoItemReducer.state.status?.isSubmitted) {
      notifications.show({
        message: "Item cadastrado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    } else if (ativoItemReducer.state.status?.isDeleted) {
      notifications.show({
        message: "Item deletado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (ativoItemReducer.state.status?.isUpdated) {
      notifications.show({
        message: "Item atualizado com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
      close();
    }
  }, [ativoItemReducer.state.status]);

  useEffect(() => {
    findAtivo(params.id);
  }, [params.id]);

  const findAtivo = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.ativo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (id: any, pagination: PaginationProps) => {
    ativoItemOperations.fetchPage({
      endpoint: `${RemoteEndpoints.ativo}/${id}/itens`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const onCreate = (entity: any) =>
    ativoItemOperations.create({
      endpoint: RemoteEndpoints.ativoitens,
      properties: { reducer: reducer },
      data: { ...entity, ativo: params.id },
      headers: RequestConfigGenerator.build(),
    });

  const onUpdate = (entity: any, id: any) =>
    ativoItemOperations.update({
      endpoint: `${RemoteEndpoints.ativoitem}/${id}`,
      properties: { reducer: ativoItemReducer },
      headers: RequestConfigGenerator.build(),
      data: entity,
    });

  const findItem = (id: any) =>
  ativoItemOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.ativoitem}/${id}`,
      properties: { reducer: ativoItemReducer },
      headers: RequestConfigGenerator.build(),
    });

  const abrirModalCadastro = () => {
    // Preencher os dados do ativo item com os dados já existentes
    // do ativo
    ativoItemReducer.dispatch({
      type: "SUCCESS_SINGLE",
      payload: {
        valorCompra: data?.object.valorCompraBase,
        valorLocacao: data?.object.valorLocacaoBase,
        batnaMin: data?.object.batnaMinBase,
        batnaMax: data?.object.batnaMaxBase,
      }
    });
    drawerForm.open("cadastrarAtivoItem");
  }

  const actionEditar = (id: any) => {
    findItem(id);
    drawerForm.open("editarAtivoItem");
  }

  return (
    <PageLayout
      title={productTitle}
      headerActions={
        <Group position="center">
          <Button
            onClick={() => abrirModalCadastro()}
            loading={reducer.state.status?.isSubmitting}
            leftIcon={<IconPlus />}
          >
            Cadastrar Exemplar de Ativo
          </Button>
        </Group>
      }
      mobileHeaderActions={
        <>
          <Menu.Item icon={<IconFileUpload size={14} />} onClick={() => abrirModalCadastro()}>
            Cadastrar Exemplar de Ativo
          </Menu.Item>
        </>
      }
    >
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="cadastrarAtivoItem"
        title="Cadastrar Exemplar de Ativo"
      >
        <AtivoItemForm
          reducer={ativoItemReducer}
          onSubmit={(entity: any, id: any) => onCreate(entity)}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="editarAtivoItem"
        title="Editar Exemplar de Ativo"
      >
        <AtivoItemForm
          editMode
          reducer={ativoItemReducer}
          onSubmit={(entity: any, id: any) => onUpdate(entity, id)}
        />
      </CustomDrawer>
      {/* Informações do ativo */}
      <Title order={3} mb="md">
        Dados do Ativo
      </Title>
      <Card>
        <AtivoPanel
          ativo={reducer?.state?.data?.object}
          loading={reducer?.state?.status?.isFetching}
        />
      </Card>
      {/* Itens */}
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Unidades Cadastradas</Title>
      </Group>
      <DataGridComponent
        minHeight={250}
        visible={true}
        state={ativoItemReducer.state}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhuma unidade encontrada"
        columns={[
          { accessor: "numeroSeriePatrimonio", title: "Numero Série / Patrimonio" },
          { accessor: "detalhes", title: "Detalhes" },
          {
            accessor: "estado",
            title: "Estado",
            render: ({ estado }: any) =>
              <CustomBadge value={estado} options={[
                { value: "PIX", text: "Pix", color: "blue" },
                { value: "CONTA_BANCARIA", text: "Conta Bancária", color: "blue" },
              ]} />
          },
          {
            accessor: "emUso",
            title: "Locado?",
            render: ({ emUso, locatario }: any) => (
              <>
                {emUso ? (
                  <Badge color="red">Locado</Badge>
                ) : (
                  <Badge color="teal">Disponível</Badge>
                )}
                <Hidden hide={!locatario}>
                  <Group mt="xs">
                    <Avatar src={locatario?.logo?.imageSourceSet} radius="sm" />
                    <div>
                      <Hidden hide={!locatario?.nomeFantasia}>
                        <Text
                          component="a"
                          href={`/empresas/${locatario?.id}`}
                          fw={500}
                        >
                          {locatario?.nomeFantasia}
                        </Text>
                      </Hidden>
                    </div>
                  </Group>
                </Hidden>
              </>
            ),
          },
          {
            accessor: "lote",
            title: "Lote?",
            render: ({ lote }: any) => (
              <>{lote ? <Badge>Lote</Badge> : <Badge>Unidade</Badge>}</>
            ),
          },
          {
            accessor: "actions",
            title: <Text mr="xs">Ações</Text>,
            textAlignment: "right",
            render: ({ id }: any) => (
              <Group spacing={12} position="right" noWrap>
                <Link to={`/ativo-item/${id}`}>
                  <ActionIcon color="gray">
                    <IconEye size={26} />
                  </ActionIcon>
                </Link>
                <Tooltip label="Editar Exemplar de Ativo">
                  <ActionIcon color="blue" onClick={() => actionEditar(id)}>
                    <IconEdit size={26} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
