import { Box, Input, SegmentedControl } from "@mantine/core";
import { ProdutoForm } from "partials/forms/Produto.form";
import { ProdutoSelectForm } from "partials/selectforms/Produto.selectform";
import { Hidden } from "shared/custom/Hidden";

export const CadastroSelecaoProdutoPanel = ({
  form,
  error,
  value,
  onChange,
  classe,
  selectControl,
  onSelectControl,
}: any) => {
  return (
    <Box>
      <Input.Wrapper
        label="Código de barras"
        description="Informe o código de barras e procuraremos o produto em nossa base, isso deve facilitar o cadastro do ativo. Caso não tenha, recomendamos buscar o código na internet de acordo com o produto."
      >
        <SegmentedControl
          fullWidth
          mt="sm"
          value={selectControl ? "true" : "false"}
          onChange={(value) => onSelectControl(value === "true" ? true : false)}
          data={[
            { label: "Tenho o código de barras ou NCM", value: "true" },
            { label: "Não tenho o código de barras", value: "false" },
          ]}
        />
      </Input.Wrapper>
      <Hidden hide={!selectControl}>
        <ProdutoSelectForm
          form={form}
          error={error}
          value={value}
          onChange={onChange}
        />
      </Hidden>
      <Hidden hide={selectControl}>
        <ProdutoForm
          form={form}
          error={error}
          value={value}
          onChange={onChange}
          classe={classe}
          selectControl={selectControl}
        />
      </Hidden>
    </Box>
  );
};
