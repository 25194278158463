import {
  Button,
  Group,
  Loader,
  MultiSelect,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";

export const PermissionForm = ({
  reducer,
  editMode,
  onSubmit,
}: any) => {

  const { data, status } = reducer.state;

  const form = useForm({
    validateInputOnBlur: ["permission", "valor"],
    initialValues: {
      id: null,
      permission: "",
      description: "",
    },
    validate: {
      permission: (value) => (value !== "" ? null : "Informe uma permissão"),
      description: (value) => (value !== "" ? null : "Informe uma descrição"),
    },
  });

  useEffect(() => {
    form.setValues({
      permission: form.values.permission.toUpperCase().replace(" ", "_"),
    });
  }, [form.values]);

  useEffect(() => {
    if (data?.object && editMode) {
      form.setValues(data?.object);
    }
  }, [data]);

  const _onSubmit = async (submitData: any) => {
    const id = submitData.id;
    delete submitData.id;
    onSubmit(submitData, id);
  };

  const onFormError = (event: any) => {
    console.error(event)
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  return (
    <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
      <TextInput
        mt="md"
        label="Permissão"
        disabled={editMode}
        {...form.getInputProps("permission")}
        placeholder="Permissão"
      />
      <Textarea
        disabled={editMode}
        mt="md"
        placeholder="Descrição"
        label="Descrição"
        {...form.getInputProps("description")}
      />
      <MultiSelect
        mt="md"
        data={[
          { value: "ADMINISTRATOR", label: "Administrador" },
          { value: "STAKEHOLDER", label: "Stakeholder" },
        ]}
        label="Tipos de Contas"
        placeholder="Tipos de Contas"
        {...form.getInputProps("accountTypes")}
      />
      <Group position="apart" mt="lg">
        <Button variant="subtle" disabled={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
          Limpar
        </Button>
        <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
          Confirmar
        </Button>
      </Group>
    </form>
  );
};
