import { Drawer, Text, Divider } from "@mantine/core"


export const CustomDrawer = ({ tag, drawerFormState, title, children, size }: any) => {

    return (
        <Drawer
            opened={drawerFormState.isOpened() && drawerFormState.parameters.tag === tag}
            onClose={drawerFormState.close}
            position="right"
            size={ size || "xl"}
            title={
                <Text fz="xl" fw={500}>
                    { title }
                </Text>
            }
            overlayProps={{ opacity: 0.5, blur: 4 }}
        >
            <Divider />
            { children }
        </Drawer>
    )

}