import {
    Button,
    Group,
    Skeleton,
    Text,
    Title
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
    IconCircleCheck,
    IconX
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { OrcamentoItemDataGrid } from "partials/datagrids/OrcamentoItem.datagrid";
import { OrcamentoItemRespostaForm } from "partials/forms/OrcamentoItemResposta.form";
import { OrcamentoRespostaForm } from "partials/forms/OrcamentoResposta.form";
import { OrcamentoPanel } from "partials/views/Orcamento.view";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";

export const OrcamentoViewPage = () => {

    const [idItemResposta, setIdItemResposta] = useState<string>();
    const location = useLocation();
    const reducerOrcamento = useRemoteDataReducer();
    const reducerOrcamentoItens = useRemoteDataReducer();
    const remoteOrcamentoOperations = useRemoteOperation({ reducer: reducerOrcamento });
    const remoteOrcamentoItemsOperations = useRemoteOperation({
        reducer: reducerOrcamentoItens
    });
    const drawerForm = useDrawerForm({ reducer: reducerOrcamento });
    const params = useParams<any>();
    const processoID = params.id;
    const { data, status } = reducerOrcamento.state;

    useEffect(() => {
        if (status?.isFailed)
            notifications.show({
                message: data?.error?.message,
                color: "red",
                icon: <IconX />,
            });
    }, [status, location?.pathname]);

    useEffect(() => {
        if (processoID && !data?.object) {
            reducerOrcamento.dispatch({ type: "INITIATING" });
            findSolicitacao(processoID);
            findAtivosSolicitacao(processoID, { page: 0, size: 10, sort: "desc" });
        }
    }, [location]);

    const findSolicitacao = (id: string) =>
        remoteOrcamentoOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.orcamento}/${id}`,
            properties: { reducer: reducerOrcamento },
            headers: RequestConfigGenerator.build(),
        });

    const findAtivosSolicitacao = (id: string, pagination: PaginationProps) =>
        remoteOrcamentoItemsOperations.fetchPage({
            endpoint: `${RemoteEndpoints.orcamento}/${id}/itens`,
            properties: { reducer: reducerOrcamentoItens },
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            headers: RequestConfigGenerator.build(),
        });

    const onRespond = (idItem: string) => {
        setIdItemResposta(idItem);
        drawerForm.open("enviarRespostaItemDrawer");
    }

    const onApprove = (idOrcamento: any, idItem: any) => {
        remoteOrcamentoItemsOperations.patch({
            endpoint: `${RemoteEndpoints.orcamento}/${idOrcamento}/aprovacao/${idItem}`,
            properties: { reducer: reducerOrcamento },
            headers: RequestConfigGenerator.build(),
            data: { statusAprovacao: 'APROVADO' },
        });
    }

    const onView = (idItem: any) => {

    }

    const onDeny = (idOrcamento: any, idItem: any) => {
        remoteOrcamentoItemsOperations.patch({
            endpoint: `${RemoteEndpoints.orcamento}/${idOrcamento}/aprovacao/${idItem}`,
            properties: { reducer: reducerOrcamento },
            headers: RequestConfigGenerator.build(),
            data: { statusAprovacao: 'REPROVADO' },
        });
    }

    const responderOrcamento = (submitData: any) => {
        remoteOrcamentoOperations.patch({
            endpoint: `${RemoteEndpoints.orcamento}/${data?.object?.id}/finalizar`,
            properties: { reducer: reducerOrcamento },
            headers: RequestConfigGenerator.build(),
            data: submitData
        });
    }

    const responderOrcamentoItem = (idItem: any, model: any) => {  
        remoteOrcamentoItemsOperations.patch({
            endpoint: `${RemoteEndpoints.orcamento}/${data?.object?.id}/responder/${idItem}`,
            properties: { reducer: reducerOrcamento },
            headers: RequestConfigGenerator.build(),
            data: model
        });
    }

    const responderOrcamentoAction = (id: any) => {
        drawerForm.open("finalizarRespostaDrawer");
    }

    const openConfirmModal = (id: any) => modals.openConfirmModal({
        centered: true,
        title: 'Deseja concluir a resposta deste orçamento?',
        children: (
            <Text size="sm">
                Considere uma última verificação nos itens do orçamento.
            </Text>
        ),
        labels: { confirm: 'Confirmar', cancel: 'Cancelar' },
        onConfirm: () => responderOrcamentoAction(id),
    });

    const [modalState, setModalState] = useState({ images: [], open: false });

    const onImageView = (images: any) => {
        const image = images.map((item: any) => { return { src: item.imageSourceSet } })
        setModalState({ images: image, open: true });
    }

    return (
        <PageLayout
            title={status?.isFetching ? <Skeleton /> : `Orçamento #${data?.object?.numeroOrcamento || ""}`}
            headerActions={
                <Group position="center">
                    <Hidden
                        hide={
                            status?.isUpdating ||
                            (data?.object?.status !== "AGUARDANDO_RESPOSTA" && data?.object?.status !== "REABERTO")
                        }
                    >
                        <Button
                            color="blue"
                            onClick={() => openConfirmModal(data?.object?.id)}
                            leftIcon={<IconCircleCheck />}
                            loading={!!status?.isUpdating}
                        >
                            Concluir a Resposta ao Orçamento
                        </Button>
                    </Hidden>
                </Group>
            }
        >
            <CustomDrawer
                drawerFormState={drawerForm}
                tag="enviarRespostaItemDrawer"
                title="Responder Item"
            >
                <OrcamentoItemRespostaForm
                    reducer={reducerOrcamentoItens}
                    onSubmit={(submitData: any) => responderOrcamentoItem(idItemResposta, submitData)}
                />
            </CustomDrawer>
            <CustomDrawer
                drawerFormState={drawerForm}
                tag="finalizarRespostaDrawer"
                title="Finalizar Resposta"
            >
                <OrcamentoRespostaForm
                    reducer={reducerOrcamento}
                    onSubmit={responderOrcamento}
                />
            </CustomDrawer>
            <Title mt="md" mb="md" order={3}>
                Informações do Orçamento
            </Title>
            <OrcamentoPanel
                orcamento={data?.object}
                loading={status?.isFetching}
            />
            <Title mt="md" mb="md" order={3}>
                Items do Orçamento
            </Title>
            <OrcamentoItemDataGrid
                onImageView={onImageView}
                onPaginationChange={(pagination: PaginationProps) => findAtivosSolicitacao(processoID+"", pagination)}
                reducer={reducerOrcamentoItens}
                onRespond={(idItem: any) => onRespond(idItem)}
                onApprove={(idItem: any) => onApprove(data?.object?.id, idItem)}
                onDeny={(idItem: any) => onDeny(data?.object?.id, idItem)}
                onView={(idItem: any) => onView(idItem)}
                flags={["view"]}
            />
        </PageLayout>
    );
};
