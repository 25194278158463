import { Divider, Group, ThemeIcon } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconCircleCheck, IconCircleDashed, IconExclamationMark } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { ProspeccaoItemPanel } from "./ProspeccaoItem.panel";
import { ProspeccaoItemSelectComponent } from "./ProspeccaoItemSelect.panel";
import { ProspeccaoForm } from "../forms/Prospeccao.form";
import { ProspeccaoSelectComponent } from "./ProspeccaoSelect.panel";
import { PaginationProps } from "shared/models/Pagination.props";

export const ProspeccaoPanelComponent = ({ parameters, value, setValue, hideAdd }: any) => {

    const location = useLocation();
    const prospeccaoReducer = useRemoteDataReducer();
    const prospeccaoOperations = useRemoteOperation(
        { reducer: prospeccaoReducer }
    );

    const prospeccaoItemReducer = useRemoteDataReducer();
    const prospeccaoItemOperations = useRemoteOperation(
        { reducer: prospeccaoItemReducer }
    );

    const prospeccaoDrawerForm = useDrawerForm({ reducer: prospeccaoReducer });
    const prospeccaoItemDrawerForm = useDrawerForm({ reducer: prospeccaoItemReducer });

    useEffect(() => {
        prospeccaoReducer.dispatch({ type: "INITIATING" });
        prospeccaoItemReducer.dispatch({ type: "INITIATING" });
    }, [location]);

    const prospeccaoReducerStatus = prospeccaoReducer.state.status;

    useEffect(() => {
        /* Um id de prospecção for informado */
        if (prospeccaoReducerStatus?.isInitiating && parameters.prospectionID && !prospeccaoReducer.state.data?.object) {
            findProspeccao(parameters.prospectionID);
        }
        /* Quando a nova prospeccao for cadastrada, ou quando a busca por prospecção por ID retornar dados ela será selecionada automaticamente */
        if ((prospeccaoReducerStatus?.isSubmitted || prospeccaoReducerStatus?.isSuccessful) && prospeccaoReducer.state.data?.object) {
            setValue({ prospeccao: prospeccaoReducer.state.data?.object })
        }
        /* Um id de prospecção não for informado, busca uma página de prospecções  */
        if (prospeccaoReducerStatus?.isInitiating && !parameters.prospectionID && !prospeccaoReducer.state.data?.object) {
            findProspeccoes({ page: 0, size: 5, sort: "desc" });
        }
    }, [prospeccaoReducerStatus]);

    useEffect(() => {
        /* Um id de item de prospecção for informado */
        if (prospeccaoItemReducer.state.status?.isInitiating && parameters.prospectionItemID && !prospeccaoItemReducer.state.data?.object && value.prospeccao?.id) {
            findProspeccaoItem(value.prospeccao?.id, parameters.prospectionItemID);
        }
        /* Quando o novo item de prospeccao for cadastrado, ou quando a busca por item de prospecção por ID retornar dados ela será selecionada automaticamente */
        if ((prospeccaoItemReducer.state.status?.isSubmitted || prospeccaoItemReducer.state.status?.isSuccessful) && prospeccaoItemReducer.state.data?.object) {
            setValue({ ...value, prospeccaoItem: prospeccaoItemReducer.state.data?.object })
        }
        /* Um id de item de prospecção não for informado, busca uma página de item de  prospecções  */
        if (value.prospeccao && prospeccaoItemReducer.state.status?.isInitiating && !parameters.prospectionItemID && !prospeccaoItemReducer.state.data?.object) {
            findProspeccaoItens(value.prospeccao.id, { page: 0, size: 10, sort: "desc" });
        }
    }, [prospeccaoItemReducer.state.status, value.prospeccao]);

    useEffect(() => {
        if(prospeccaoItemReducer.state.status?.isSubmitted){
            notifications.show({
                message: "Item adicionado!",
                color: "green",
                icon: <IconCheck />,
            });
        }
    }, [prospeccaoItemReducer.state.status]);

    const onCreateProspeccao = (entity: any) => {
        prospeccaoOperations.create({
            endpoint: RemoteEndpoints.prospeccoes,
            properties: { reducer: prospeccaoReducer },
            data: entity,
            headers: RequestConfigGenerator.build(),
        });
    };

    const onCreateProspeccaoItem = (entity: any) => {
        if (!value?.prospeccao?.id) {
            notifications.show({
                message: "Informe uma prospecção",
                color: "orange",
                icon: <IconExclamationMark />,
            });
            return;
        }
        prospeccaoItemOperations.create({
            endpoint: `${RemoteEndpoints.prospeccao}/${value?.prospeccao?.id}/itens`,
            properties: { reducer: prospeccaoItemReducer },
            data: entity,
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccoes = (pagination: PaginationProps) => {
        prospeccaoOperations.fetchPage({
            endpoint: `${RemoteEndpoints.prospeccoes}`,
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccao = (id: any) => {
        prospeccaoOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}`,
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccaoItem = (id: any, idItem: any) => {
        prospeccaoItemOperations.fetchSingle({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/itens/${idItem}`,
            headers: RequestConfigGenerator.build(),
        });
    };

    const findProspeccaoItens = (id: any, pagination: PaginationProps) => {
        prospeccaoItemOperations.fetchPage({
            endpoint: `${RemoteEndpoints.prospeccao}/${id}/itens`,
            requestParameters: {
                page: pagination.page,
                size: pagination.size,
                sort: pagination.sort || "desc",
            },
            headers: RequestConfigGenerator.build(),
        });
    };

    return (
        <>
            <CustomDrawer
                drawerFormState={prospeccaoDrawerForm}
                tag="cadastrarProspeccao"
                title="Nova Prospecção"
            >
                <ProspeccaoForm
                    reducer={prospeccaoReducer}
                    onSubmit={(entity: any) => onCreateProspeccao(entity)}
                />
            </CustomDrawer>
            <CustomDrawer
                drawerFormState={prospeccaoItemDrawerForm}
                tag="cadastrarItemProspeccao"
                title="Itens de Prospecção"
            >
                <ProspeccaoItemPanel
                    listItens={false}
                    prospeccaoID={value.prospeccao?.id}
                    reducer={prospeccaoItemReducer}
                    onSubmit={onCreateProspeccaoItem}
                    onPaginationChange={(item: any) => console.log()}
                />
            </CustomDrawer>
            <Group w="100%" noWrap>
                <ThemeIcon color={value.prospeccao ? "teal" : "blue"} size={24} radius="xl">
                    <Hidden hide={!value.prospeccao}><IconCircleCheck size="1rem" /></Hidden>
                    <Hidden hide={value.prospeccao}><IconCircleDashed size="1rem" /></Hidden>
                </ThemeIcon>
                <ProspeccaoSelectComponent
                    reducer={prospeccaoReducer}
                    value={value.prospeccao}
                    hideAdd={hideAdd}
                    onCreateAction={() => prospeccaoDrawerForm.open("cadastrarProspeccao")}
                    onSelect={(prospeccao: any) => setValue({ prospeccaoItem: null, prospeccao: prospeccao })}
                    onPaginationChange={(pagination: PaginationProps) => findProspeccoes(pagination)}
                />
            </Group>
            <Divider my="sm" />
            <Group w="100%" noWrap>
                <ThemeIcon color={value.prospeccao ? (value.prospeccaoItem ? "teal" : "blue") : "gray"} size={24} radius="xl">
                    <Hidden hide={!value.prospeccaoItem}><IconCircleCheck size="1rem" /></Hidden>
                    <Hidden hide={value.prospeccaoItem}><IconCircleDashed size="1rem" /></Hidden>
                </ThemeIcon>
                <ProspeccaoItemSelectComponent
                    reducer={prospeccaoItemReducer}
                    hideAdd={hideAdd}
                    value={value.prospeccaoItem}
                    prospection={value.prospeccao}
                    disabled={value.prospeccao ? false : true}
                    onCreateAction={() => prospeccaoItemDrawerForm.open("cadastrarItemProspeccao")}
                    onSelect={(prospeccaoItem: any) => setValue({ ...value, prospeccaoItem: prospeccaoItem })}
                    onPaginationChange={(pagination: PaginationProps) => findProspeccaoItens(value.prospeccao.id, pagination)}
                />
            </Group>
        </>
    );

}