import { FileUtils } from "./Utils";

export class PagamentoHelper {

    public static async generateFile(file: any) {
        const hash = await FileUtils.getFileMD5(file);
        return {
            filename: file.name,
            contentType: file.type,
            size: file.size,
            hash: hash,
        };
    };

    /*
    public static handle(data: any, idPagamento: string) {

        const tipo = data?.comprovante?.name
            ? "comprovante"
            : data?.documentoCobranca?.name
                ? "documentoCobranca"
                : data?.reciboNota?.name
                    ? "reciboNota"
                    : null;

        let file = data?.comprovante
            ? data?.comprovante?.name
            : data?.documentoCobranca?.name
                ? data?.documentoCobranca
                : data?.reciboNota?.name
                    ? data?.reciboNota
                    : null;

        delete data.arquivo;

        let sendData = {
            observacoes: data?.observacoes,
            status: data?.status,
            comprovante:
            data?.comprovante?.name && data?.comprovante?.name != ""
                    ? this.generateFile(data?.comprovante)
                    : null,
            documentoCobranca:
            data?.documentoCobranca?.name &&
            data?.documentoCobranca?.name != ""
                    ? this.generateFile(data?.documentoCobranca)
                    : null,
            reciboNota:
            data?.reciboNota?.name && data?.reciboNota?.name != ""
                    ? this.generateFile(data?.reciboNota)
                    : null,
        } as any;

        if (sendData.comprovante) {
            delete sendData.comprovante;
        }
        if (sendData.documentoCobranca) {
            delete sendData.documentoCobranca;
        }
        if (sendData.reciboNota) {
            delete sendData.reciboNota;
        }

        sendData = ObjectUtils.cleanNulls(sendData);

        return {
            tipo: tipo,
            arquivo: file,
            dados: sendData
        };
    }

    public static handleCreate(data: any){
        const tipo = data?.documentoCobranca?.name ? "documentoCobranca" : null;
        const file = data?.documentoCobranca?.name ? data?.documentoCobranca : null;
        delete data.id;
        delete data.arquivo;
    
        data.dataVencimento = moment(
          data.dataVencimento,
          "YYYY-MM-DD HH:mm:ss",
        ).toISOString();
    
        const sendData = {
          ...data,
          documentoCobranca: data?.documentoCobranca?.name
            ? PagamentoHelper.generateFile(data?.documentoCobranca)
            : null,
        };
    
        delete sendData.reciboNota;

        return {
            tipo: tipo,
            arquivo: file,
            dados: sendData
        };
    }*/

}