import { useEffect, useRef } from "react";
import ChartJS from "chart.js/auto";
import { Chart, Colors } from "chart.js";
import { createStyles } from "@mantine/core";

export const LineChartComponent = () => {
  const canvasRef = useRef<any>(null);

  Chart.register(Colors);

  const useStyles = createStyles((theme) => ({
    container: {
      maxHeight: 200
    },
  }));

  const { classes } = useStyles();

  useEffect(() => {
    const myChartRef = canvasRef?.current?.getContext("2d");

    const myChart = new ChartJS(myChartRef, {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Sales",
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: "rgba(255, 99, 132, 1)",
            fill: false,
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    return () => {
      myChart.destroy();
    };
  }, []);

  return <canvas ref={canvasRef} className={classes.container} />;
};
