import {
  Badge,
  Button,
  Group,
  Text,
  Image,
  Accordion,
  LoadingOverlay,
  Box,
  Stack,
  Loader,
  MultiSelect,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircle, IconUser, IconUserOff, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { useRemoteOperation, RequestConfigGenerator } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { RedefinirSenhaForm } from "partials/forms/RedefinirSenha.form";
import { useEffect } from "react";

export const AccountPanel = ({ account }: any) => {
  const accountReducer = useRemoteDataReducer();
  const accountPasswordReducer = useRemoteDataReducer();
  const accountPermissionReducer = useRemoteDataReducer();
  const accountQRCodeReducer = useRemoteDataReducer();
  const permissionsReducer = useRemoteDataReducer();
  const accountOperations = useRemoteOperation({ reducer: accountReducer });
  const accountQRCodeOperations = useRemoteOperation({
    reducer: accountQRCodeReducer,
  });
  const permissionsOperations = useRemoteOperation({
    reducer: permissionsReducer,
  });
  const accountPasswordOperations = useRemoteOperation({
    reducer: accountPasswordReducer,
  });
  const accountPermissionOperations = useRemoteOperation({
    reducer: accountPermissionReducer,
  });

  const desativarConta = (id: any) => {
    accountOperations.update({
      endpoint: RemoteEndpoints.accounts + "/account/" + id + "/disable",
      properties: { reducer: accountReducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const ativarConta = (id: any) => {
    accountOperations.update({
      endpoint: RemoteEndpoints.accounts + "/account/" + id + "/enable",
      properties: { reducer: accountReducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const actionRedefinirSenha = (data: any) => {
    accountPasswordOperations.patch({
      endpoint:
        RemoteEndpoints.accounts +
        "/account/" +
        accountReducer.state.data?.object?.id +
        "/change-account-password",
      properties: { reducer: accountPasswordReducer },
      data: {
        newPassword: data?.password,
      },
      headers: RequestConfigGenerator.build(),
    });
  };

  const generateQRCode = (id: any) =>
    accountQRCodeOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.account}/${id}/generate-mfa-qrcode`,
      properties: { reducer: accountQRCodeReducer },
      headers: RequestConfigGenerator.build(),
    });

  const fetchAccount = (id: any) =>
    accountOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.account}/${id}`,
      properties: { reducer: accountReducer },
      headers: RequestConfigGenerator.build(),
    });

  const ativarInativarContaAction = () => {
    if (accountReducer.state.data?.object?.enabled) {
      desativarConta(account);
    } else {
      ativarConta(account);
    }
  };

  useEffect(() => {
    accountReducer.dispatch({ type: "INITIATING" });
    accountQRCodeReducer.dispatch({ type: "INITIATING" });
    generateQRCode(account);
    fetchAccount(account);
  }, [account]);

  const processPermissions = (permissions: any) => {
    if (!permissions || permissions.length < 1) {
      return [];
    }
    return permissions?.map((p: any) => {
      return { value: p.id, label: `${p.description}` };
    });
  };

  const form = useForm({
    initialValues: {
      permissions: [],
    },
  });

  useEffect(() => {
    if (accountReducer.state.data?.object) {
      permissionsOperations.fetchSingle({
        endpoint: `${RemoteEndpoints.permissoes}/list?type=${accountReducer.state.data?.object?.type}`,
        properties: { reducer: permissionsReducer },
        headers: RequestConfigGenerator.build(),
      });
    }
  }, [accountReducer.state]);

  useEffect(() => {
    if (accountPermissionReducer.state?.status?.isUpdated) {
      notifications.show({
        message: "Permissões alteradas com sucesso!",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (accountPermissionReducer.state?.status?.isFailed) {
      notifications.show({
        message: "Erro ao alterar permissões",
        color: "red",
        icon: <IconX />
      });
    }
  }, [accountPermissionReducer.state]);


  const cross = (all: any[], current: any[]): any[] => {
    const result = all
      .filter((permission) => current?.includes(permission.permission))
      .map((item) => item.id);
    return result || [];
  };

  useEffect(() => {
    if (permissionsReducer?.state.data?.object) {
      form.setValues({
        permissions: cross(
          permissionsReducer?.state.data?.object,
          accountReducer.state.data?.object?.permissions,
        ) as never[],
      });
    }
  }, [permissionsReducer?.state.status]);

  const _onSubmit = async (submitData: any) =>
    accountPermissionOperations.patch({
      endpoint:
        RemoteEndpoints.accounts +
        "/account/" +
        accountReducer.state.data?.object?.id,
      properties: { reducer: accountPasswordReducer },
      data: submitData,
      headers: RequestConfigGenerator.build(),
    });

  const onFormError = (event: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  return (
    <>
      <LoadingOverlay
        visible={accountReducer?.state?.status?.isFetching || false}
      />
      <Accordion>
        <Accordion.Item value="conta">
          <Accordion.Control>Informações de Conta</Accordion.Control>
          <Accordion.Panel>
            <Stack>
              <Text size="xs" color="dimmed">
                {accountReducer?.state.data?.object?.id}
              </Text>
              <Box>Email: {accountReducer?.state.data?.object?.email}</Box>
              <Group>
                <Badge>{accountReducer?.state.data?.object?.type}</Badge>
                <Badge>
                  {accountReducer?.state.data?.object?.enabled
                    ? "Ativo"
                    : "Inativo"}
                </Badge>
              </Group>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="qrcode">
          <Accordion.Control>QR Code</Accordion.Control>
          <Accordion.Panel>
            <Image
              maw={180}
              mx="auto"
              radius="md"
              src={accountQRCodeReducer?.state.data?.object?.qrcode}
              alt="QR Code de Autenticação multifatores"
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="password">
          <Accordion.Control>Troca de Senha</Accordion.Control>
          <Accordion.Panel>
            <RedefinirSenhaForm
              onSubmit={actionRedefinirSenha}
              data={accountPasswordReducer.state.data?.object}
              submiting={accountPasswordReducer.state.status?.isUpdating}
            />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="ativacao">
          <Accordion.Control>Ativação de Conta</Accordion.Control>
          <Accordion.Panel>
            <Button
              fullWidth
              leftIcon={
                accountReducer.state.data?.object?.enabled ? (
                  <IconUserOff />
                ) : (
                  <IconUser />
                )
              }
              loading={accountReducer.state.status?.isUpdating}
              onClick={ativarInativarContaAction}
            >
              {accountReducer.state.data?.object?.enabled
                ? "Inativar conta"
                : "Ativar conta"}
            </Button>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="permissoes">
          <Accordion.Control>Permissões</Accordion.Control>
          <Accordion.Panel>
            <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
              <MultiSelect
                disabled={permissionsReducer.state.status?.isFetching}
                rightSection={
                  permissionsReducer.state.status?.isFetching ? (
                    <Loader size="xs" />
                  ) : (
                    <></>
                  )
                }
                data={processPermissions(
                  permissionsReducer.state?.data?.object,
                )}
                label="Permissões"
                placeholder="Ex: Contratos, Processos, administradors"
                description="Escolha as funcionalidades que esta conta pode ter acesso"
                {...form.getInputProps("permissions")}
              />
              <Group position="apart" mt="lg">
                <Button
                  variant="subtle"
                  disabled={accountPermissionReducer.state.status?.isSubmitting}
                >
                  Limpar
                </Button>
                <Button
                  type="submit"
                  loading={accountPermissionReducer.state.status?.isUpdating}
                >
                  Confirmar
                </Button>
              </Group>
            </form>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
