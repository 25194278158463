import { Badge, Group, Text } from "@mantine/core";
import moment from "moment";
import { ExemplarAtivoChip } from "partials/chip/ExemplarAtivo.chip";
import { InfoUtils } from "shared/Utils";
import { CustomBadge } from "shared/components/CustomBadge.component";
import { DataGridComponent } from "shared/custom/DataGridWrapper";

export const SolicitacaoItemDataGrid = ({ reducer, onPaginationChange, onRespond, onApprove, onDeny, onView, onImageView, ...rest }: any) => {

    const _onImageView = (item: any) => onImageView(item);

    return (
        <DataGridComponent
            visible={true}
            fetching={reducer.state.status?.isFetching || reducer.state.status?.isUpdating || reducer.state.status?.isSubmitting}
            state={reducer.state}
            {...rest}
            noRecordText="Nenhum item de orçamento encontrado"
            onPaginationChange={onPaginationChange}
            columns={[
                {
                    accessor: "ativoItem",
                    title: "Exemplar",
                    render: ({ ativoItem }: any) => <ExemplarAtivoChip exemplar={ativoItem} onImageView={_onImageView} />
                },
                {
                    accessor: "valor",
                    title: "Valor",
                    render: ({ valor }: any) => <>{InfoUtils.formatCurrency(valor) ? InfoUtils.formatCurrency(valor): <Badge color="gray">A definir</Badge>}</>
                },
                {
                    accessor: "dataHoraRetirada",
                    title: "Data/Hora Retirada",
                    render: ({ dataHoraRetirada }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment.utc(dataHoraRetirada).format("DD/MM/YYYY [às] HH:mm")}
                        </Text>
                    ),
                },
                {
                    accessor: "dataHoraDevolucao",
                    title: "Data/Hora Devolução",
                    render: ({ dataHoraDevolucao }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment.utc(dataHoraDevolucao).format("DD/MM/YYYY [às] HH:mm")}
                        </Text>
                    ),
                },
                {
                    accessor: "status",
                    title: "Status",
                    render: ({ status }: any) =>
                        <CustomBadge value={status} options={[
                            { value: "AGUARDO", text: "No Aguardo", color: "gray" },
                        ]} />
                },
                {
                    accessor: "orcamentoStatusAprovacao",
                    title: "Status Aprovação",
                    render: ({ orcamentoStatusAprovacao }: any) =>
                        <CustomBadge value={orcamentoStatusAprovacao} options={[
                            { value: "AGUARDO", text: "No Aguardo", color: "gray" },
                        ]} />
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ id, status, orcamentoStatusAprovacao }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            
                        </Group>
                    ),
                },
            ]}
        />
    );

}

/*
<Hidden hide={["ADMINISTRATOR"].includes(authority)}>
                                <Hidden hide={status === "RESPONDIDO"}>
                                    <ActionIcon color="gray" onClick={() => onRespond(id)}>
                                        <IconMessage2Share size={26} />
                                    </ActionIcon>
                                </Hidden>
                                <Hidden hide={status !== "RESPONDIDO" || orcamentoStatusAprovacao === "APROVADO" || orcamentoStatusAprovacao === "REPROVADO"}>
                                    <ActionIcon color="teal" onClick={() => onApprove(id)}>
                                        <IconCheck size={26} />
                                    </ActionIcon>
                                    <ActionIcon color="orange" onClick={() => onDeny(id)}>
                                        <IconX size={26} />
                                    </ActionIcon>
                                </Hidden>
                            </Hidden>
                            <ActionIcon color="gray" onClick={() => onView(id)}>
                                <IconEye size={26} />
                            </ActionIcon>
                            */