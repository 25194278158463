import { Group, NumberInput, Select, SimpleGrid } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { DateUtils } from "shared/Utils";

export const SolicitacaoOrcamentoForm = ({ dadosSolicitacao, prospeccao, formasPagamento, onChange }: any) => {

    const form = useForm({
        validateInputOnBlur: [
            "quantidade",
            "dataInicioLocacao",
            "dataFinalLocacao",
            "modoPagamento"
        ],
        initialValues: {
            quantidade: dadosSolicitacao?.quantidade ? dadosSolicitacao?.quantidade : "",
            dataInicioLocacao: prospeccao ? "" : dadosSolicitacao?.dataInicioLocacao ? dadosSolicitacao?.dataInicioLocacao : "",
            dataFinalLocacao: prospeccao ?  "" : dadosSolicitacao?.dataInicioLocacao ? dadosSolicitacao?.dataInicioLocacao : "",
            modoPagamento: dadosSolicitacao?.modoPagamento ? dadosSolicitacao?.modoPagamento : "",
        },
        validate: {
            quantidade: (value: string) => value ? null : "Informe uma quantidade",
            dataInicioLocacao: (value: string) => value ? null : "Informe a Data de Inicio da Locação",
            dataFinalLocacao: (value: string) => value ? null : "Informe a Data de devolução",
            modoPagamento: (value: string) => value ? null : "Informe um modo de pagamento",
        },
    });

    useEffect(() => {
        var dataInicioLocacao = DateUtils.format(form.values?.dataInicioLocacao);
        var dataFinalLocacao = DateUtils.format(form.values?.dataFinalLocacao);
        onChange({ ...form.values, dataHoraRetirada: dataInicioLocacao, dataHoraDevolucao: dataFinalLocacao });
    }, [ form.values ]);

    return (
        <>
            <SimpleGrid
                cols={2}
                mt="md"
                breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            >
                <DatePickerInput
                    mt="md"
                    label="Data da necessidade dos ativos"
                    placeholder="Pick dates range"
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("dataInicioLocacao")}
                />
                <DatePickerInput
                    mt="md"
                    label="Data da necessidade dos ativos"
                    placeholder="Pick dates range"
                    valueFormat="DD/MM/YYYY"
                    {...form.getInputProps("dataFinalLocacao")}
                />
            </SimpleGrid>
            <Select
                label="Modo de Pagamento"
                placeholder="Modo de Pagamento"
                mt="md"
                {...form.getInputProps("modoPagamento")}
                data={formasPagamento?.map((item: any) => { return { label: item, value: item } })}
            />
        </>
    )

}