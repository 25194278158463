import {
  Box,
  Button,
  Group,
  Loader,
  Paper,
  Text,
  Title
} from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconCircleCheck,
  IconCircleX,
  IconFileDollar
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { AtivoDataGrid } from "partials/datagrids/Ativo.datagrid";
import { SolicitacaoPanel } from "partials/views/Solicitacao.view";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import Lightbox from "yet-another-react-lightbox";
import { ContratoOperationsPanel } from "../../../partials/panels/ContratoOperations.panel";
import { PagamentoPanel } from "../../../partials/panels/Pagamento.panel";
import { SolicitacaoItemDataGrid } from "partials/datagrids/SolicitacaoItem.datagrid";

export const SolicitacaoViewPage = () => {

  const authority = SecurityProvider.getAuthority();
  const authorizationToken = SecurityProvider.getAuthorizationToken();
  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const reducerAtivosSolicitacao = useRemoteDataReducer();
  const remoteProcessoOperations = useRemoteOperation({ reducer: reducer });
  const remoteAtivosOperations = useRemoteOperation({
    reducer: reducerAtivosSolicitacao
  });
  const params = useParams<any>();
  const processoID = params.id;
  const { data, status } = reducer.state;
  const solicitacao = data?.object;

  useEffect(() => {
    if (processoID && !data?.object) {
      reducer.dispatch({ type: "INITIATING" });
      findSolicitacao(processoID);
      findAtivosSolicitacao(processoID, { page: 0, size: 10, sort: "numeroSolicitacao,desc" });
    }
  }, [location]);

  const findSolicitacao = (id: any) =>
    remoteProcessoOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.solicitacao}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const findAtivosSolicitacao = (id: any, pagination: PaginationProps) =>
    remoteAtivosOperations.fetchPage({
      endpoint: `${RemoteEndpoints.solicitacao}/${id}/ativos`,
      properties: { reducer: reducerAtivosSolicitacao },
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "numeroSolicitacao,desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const aprovarSolicitacao = () => {
    remoteProcessoOperations.patch({
      endpoint: `${RemoteEndpoints.solicitacao}/${data?.object?.id}/aprovar`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const reprovarSolicitacao = () => {
    remoteProcessoOperations.patch({
      endpoint: `${RemoteEndpoints.solicitacao}/${data?.object?.id}/reprovar`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const notificarSolicitacao = (id: string) => {
    remoteProcessoOperations.fetchSingle({
      endpoint: `${RemoteEndpoints.solicitacao}/${id}/notificar`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
  };

  const openAproveModalConfirm = (id: any) => modals.openConfirmModal({
    centered: true,
    title: 'Tem certeza?',
    children: (
      <Text size="sm">
        Tem certeza que deseja aprovar esta solicitação?
        Por favor verifique se voce ainda possui o item em estoque.
      </Text>
    ),
    labels: { confirm: 'Sim, tenho o item', cancel: 'Não' },
    onConfirm: () => aprovarSolicitacao(),
  });

  const openDenyModalConfirm = (id: any) => modals.openConfirmModal({
    centered: true,
    title: 'Tem certeza?',
    children: (
      <Text size="sm">
        Tem certeza que deseja negar esta solicitação?
      </Text>
    ),
    labels: { confirm: 'Sim', cancel: 'Não' },
    onConfirm: () => reprovarSolicitacao(),
  });

  const [modalState, setModalState] = useState({ images: [], open: false });

  const onImageView = (images: any) => {
    const image = images.map((item: any) => { return { src: item.imageSourceSet } })
    setModalState({ images: image, open: true });
  }

  return (
    <PageLayout
      title={`Solicitação #${data?.object?.numeroSolicitacao}`}
      headerActions={
        <Group position="center">
          <Hidden
            hide={
              ["ADMINISTRATOR"].includes(authority) || authorizationToken.resource_group !== solicitacao?.empresaSolicitada?.id ||
              (status?.isUpdating ||
                data?.object?.status !== "SOLICITADO")
            }
          >
            <Button color="teal" onClick={openAproveModalConfirm}>
              <IconCircleCheck />
            </Button>
            <Button color="red" onClick={openDenyModalConfirm}>
              <IconCircleX />
            </Button>
          </Hidden>
          <Hidden hide={!reducer.state.status?.isUpdating}>
            <Loader />
          </Hidden>
        </Group>
      }
    >
      <Title mt="md" mb="md" order={3}>
        Informações do Processo
      </Title>
      <SolicitacaoPanel
        solicitacao={data?.object}
        loading={status?.isFetching}
      />
      <Hidden
        hide={!["APROVADO", "CONCLUIDO"].includes(data?.object?.status)}
      >
        <Hidden hide={!data?.object?.pagamentoSolicitacao && !data?.object?.pagamentoComissao}>
          <Title mt="md" mb="md" order={3}>
            Pagamentos
          </Title>
        </Hidden>
        <Hidden hide={!data?.object?.pagamentoSolicitacao}>
          <Paper withBorder p="xs">
            <PagamentoPanel
              title="Pagamento / Luva"
              pagamento={data?.object?.pagamentoSolicitacao}
              onAction={() => notificarSolicitacao(data?.object?.id)}
            />
          </Paper>
        </Hidden>
        <Hidden hide={!data?.object?.pagamentoComissao}>
          <Paper withBorder p="xs" mt="xs">
            <PagamentoPanel
              title="Comissão"
              pagamento={data?.object?.pagamentoComissao}
              onAction={() => notificarSolicitacao(data?.object?.id)}
            />
          </Paper>
        </Hidden>
        <Title mt="md" mb="md" order={3}>
          Contrato e Assinaturas
        </Title>
        <Paper withBorder p="xs">
          <Hidden hide={(data?.object?.pagamentoComissao && data?.object?.pagamentoComissao?.status !== "CONFIRMED")}>
            <ContratoOperationsPanel
              solicitacao={data?.object}
              contrato={data?.object?.contrato}
              onAction={() => notificarSolicitacao(data?.object?.id)}
            />
          </Hidden>
        </Paper>
      </Hidden>
      <Title mt="md" mb="md" order={3}>
        Ativos
      </Title>
      <Lightbox
        open={modalState.open}
        close={() => setModalState({ images: [], open: false })}
        slides={modalState.images}
      />
      <SolicitacaoItemDataGrid
        onPaginationChange={(pagination: PaginationProps) => findAtivosSolicitacao(processoID + "", pagination)}
        onImageView={onImageView}
        reducer={reducerAtivosSolicitacao}
        flags={["view"]}
      />
    </PageLayout>
  );
};

/*
onRespond={(idItem: any) => onRespond(idItem)}
        onApprove={(idItem: any) => onApprove(data?.object?.id, idItem)}
        onDeny={(idItem: any) => onDeny(data?.object?.id, idItem)}
        onView={(idItem: any) => onView(idItem)}
*/
