import { Button, Tabs, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import {
  IconBox,
  IconFileCheck,
  IconFileDollar,
  IconFileStack,
  IconInfoCircle,
} from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden";
import { PageLayout } from "shared/custom/page/PageLayout";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { ProcessoAtivosTab } from "./tabs/ProcessoAtivos.tab";
import { ProcessoContratosTab } from "./tabs/ProcessoContratos.tab";
import { ProcessoDocumentosTab } from "./tabs/ProcessoDocumentos.tabs";
import { ProcessoPagamentosTab } from "./tabs/ProcessoPagamentos.tab";
import { ProcessoTimelineInfoTab } from "./tabs/ProcessoTimelineInfo.tab";

export const ProcessoViewPage = () => {

  const authority = SecurityProvider.getAuthority();
  const location = useLocation();
  const reducerProcesso = useRemoteDataReducer();
  const { fetchSingle, update } = useRemoteOperation({
    reducer: reducerProcesso,
  });
  const params = useParams<any>();
  const processoID = params.id;
  const { data, status } = reducerProcesso.state;

  useEffect(() => {
    if (processoID && !data?.object) {
      reducerProcesso.dispatch({ type: "INITIATING" });
      findProcesso(processoID);
    }
  }, [location]);

  useEffect(() => {
    if (status?.isInitiating) {
      findProcesso(processoID);
    }
  }, [status]);

  const findProcesso = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.processo}/${id}`,
      properties: { reducer: reducerProcesso },
      headers: RequestConfigGenerator.build(),
    });

    const openConfirmModal = (id: any) => modals.openConfirmModal({
      centered: true,
      title: 'Deseja finalizar este processo?',
      children: (
        <Text size="sm">
          Atenção! Esta ação não poderá ser desfeita.
        </Text>
      ),
      labels: { confirm: 'Confirmar', cancel: 'Cancelar' },
      onConfirm: () => finalizarProcesso(reducerProcesso.state.data?.object?.id),
    });

  const finalizarProcesso = (id: any) => {
    const sendData = { status: "FINALIZADO" };
    update({
      endpoint: `${RemoteEndpoints.processo}/${id}`,
      properties: { reducer: reducerProcesso },
      headers: RequestConfigGenerator.build(),
      data: sendData,
    });
  };

  return (
    <PageLayout
      title={`Processo #${data?.object?.numeroProcesso || ""}`}
      headerActions={
        <Hidden hide={data?.object?.status === "FINALIZADO" || ["ADMINISTRATOR"].includes(authority)}>
          <Button
            color="teal"
            onClick={openConfirmModal}
            loading={reducerProcesso.state.status?.isUpdating}
            disabled={reducerProcesso.state.status?.isUpdating}
          >
            Finalizar Processo
          </Button>
        </Hidden>
      }
    >
      <Tabs radius="xs" defaultValue="informacoes">
        <Tabs.List>
          <Tabs.Tab
            value="informacoes"
            icon={<IconInfoCircle color="gray" size="1.3rem" />}
          >
            Informações e Eventos
          </Tabs.Tab>
          <Tabs.Tab
            value="ativos"
            icon={<IconBox color="gray" size="1.3rem" />}
          >
            Ativos (Exemplares)
          </Tabs.Tab>
          <Tabs.Tab
            value="pagamentos"
            icon={<IconFileDollar color="gray" size="1.3rem" />}
          >
            Pagamentos
          </Tabs.Tab>
          <Tabs.Tab
            value="contratos"
            icon={<IconFileCheck color="gray" size="1.3rem" />}
          >
            Contratos
          </Tabs.Tab>
          <Tabs.Tab
            value="documentos"
            icon={<IconFileStack color="gray" size="1.3rem" />}
          >
            Documentos
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="informacoes" pt="xs">
          <ProcessoTimelineInfoTab
            processoID={processoID}
            reducerProcesso={reducerProcesso}
          />
        </Tabs.Panel>
        <Tabs.Panel value="ativos" pt="xs">
          <ProcessoAtivosTab processoID={processoID} />
        </Tabs.Panel>
        <Tabs.Panel value="pagamentos" pt="xs">
          <ProcessoPagamentosTab
            processoID={processoID}
            locadorID={data?.object?.locatario?.id}
          />
        </Tabs.Panel>
        <Tabs.Panel value="contratos" pt="xs">
          <ProcessoContratosTab
            processoID={processoID}
            locadorID={data?.object?.locatario?.id}
          />
        </Tabs.Panel>
        <Tabs.Panel value="documentos" pt="xs">
          <ProcessoDocumentosTab
            processoID={processoID}
            locadorID={data?.object?.locatario?.id}
          />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};
