import { Input } from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import React, { useState } from "react";
import NumberFormat, {
  NumberFormatBase,
  NumberFormatValues,
  NumericFormat,
} from "react-number-format";

export const PercentageInput = ({ value, defaultValue, onChange }: any) => {
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    onChange,
  });

  return (
    <Input<any>
      component={NumericFormat}
      value={_value}
      onChange={(event: any) => handleChange(event.currentTarget.value)}
      decimalScale={2}
      suffix={" %"}
      fixedDecimalScale
      decimalSeparator="."
      placeholder="00.00 %"
    />
  );
};
