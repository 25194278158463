import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient } from "../infrastructure/RemoteClient";

export default class ConsultaCEP {
  static async consultar(cep: string) {
    const resultado = await RemoteClient.find(
      `${RemoteEndpoints.consultaCEP}/${cep}/json`,
    );
    if (resultado.code === 200) {
      return {
        logradouro: resultado.data.logradouro,
        bairro: resultado.data.bairro,
        cidade: resultado.data.localidade,
        estado: resultado.data.uf,
      };
    } else {
      throw new Error("Não foi possivel consultar CEP.");
    }
  }
}
