
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { TokenChecker } from "./TokenChecker";
import SecurityProvider from "shared/providers/security/SecurityProvider";

export const PrivateRoute = ({ children, authorities }: any): JSX.Element => {

  const containsAuthority = authorities
    ? authorities.includes(SecurityProvider.getAuthority())
    : true;
  const isAuthenticated = SecurityProvider.isAuthenticated();
  if (isAuthenticated) {
    if (containsAuthority) {
      return <TokenChecker>{children}</TokenChecker>;
    }
    return <Navigate to="/404" />;
  }

  return <Navigate to="/login" />;
};

export const OnceAuthenticatedRedirect = ({
  children,
  to404,
}: any): JSX.Element => {
  return !SecurityProvider.isAuthenticated() ? (
    <>{children}</>
  ) : (
    <Navigate to={to404 ? "/404" : "/dashboard"} />
  );
};
