import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";

export const useDrawerForm = ({ reducer }: any) => {

  const [opened, { open, close }] = useDisclosure(false);
  const [ parameters, setParameters ] = useState({});

  useEffect(() => {
    if((reducer.state.status?.isSubmitted || reducer.state.status?.isUpdated) && opened) {
      close();
    } 
  }, [reducer.state.status]);

  const openDrawer = (tag: any, opts?: any) => {
    setParameters({ ...parameters, ...opts, tag: tag });
    if(!opened)
      open();
  };

  const closeDrawer = () => {
    if(opened){
      close();
      setParameters({ ...parameters, tag: null });
    }
  }; 

  const isDrawerOpened = () => {
    return opened;
  }

  return { open: openDrawer, close: closeDrawer, isOpened: isDrawerOpened, parameters: parameters };

};
