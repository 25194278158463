import { Group, Text, useMantineTheme, rem } from "@mantine/core";
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconExclamationMark,
} from "@tabler/icons-react";
import { Dropzone, DropzoneProps } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";

export const UploadDropZone = (props: Partial<DropzoneProps>) => {
  const theme = useMantineTheme();

  const showRejectedFiles = (files: any) => {
    let reason = ".";
    if (files[0].errors[0].code === "file-too-large") {
      reason = ": Excede tamanho de 15 MB.";
    } else {
      reason = "Problemas com arquivo."
    }

    notifications.show({
      message: `Arquivo inválido${reason}`,
      color: "orange",
      icon: <IconExclamationMark />,
    });
  };

  const onDrop = (files: any) => {};

  return (
    <Dropzone
      onDrop={(files) => onDrop(files)}
      onReject={(files) => showRejectedFiles(files)}
      maxSize={15 * 1024 * 1024}
      accept={props.accept}
      {...props}
    >
      <Group
        position="center"
        spacing="xl"
        style={{ minHeight: rem(220), pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload
            size="3.2rem"
            stroke={1.5}
            color={
              theme.colors[theme.primaryColor][
                theme.colorScheme === "dark" ? 4 : 6
              ]
            }
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            size="3.2rem"
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto size="3.2rem" stroke={1.5} />
        </Dropzone.Idle>
        <div>
          <Text size="xl" inline color={props.disabled ? "dimmed" : "black"}>
            Arraste arquivos para cá ou clique para selecionar arquivos
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            Os arquivos não podem exceder 15MB
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
};
