import {
  Card,
  LoadingOverlay,
  Timeline,
  Button,
  Title,
  Text,
} from "@mantine/core";
import { IconDots, IconMessageDots } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { ProcessoPanel } from "partials/views/Processo.view";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PaginationProps } from "shared/models/Pagination.props";

export const ProcessoTimelineInfoTab = ({ processoID, reducerProcesso }: any) => {

  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const { fetchPage } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (processoID && !data?.object) {
      reducer.dispatch({ type: "INITIATING" });
      findEventosProcesso(processoID, { page: 0, size: 5, sort: "horaDataCriacao,desc" });
    }
  }, [location]);

  const findEventosProcesso = (id: any, pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.processo}/${id}/eventos`,
      properties: { reducer: reducer },
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "horaDataCriacao,desc", // &sort=title,asc
      },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <>
      <Title mt="md" mb="md" order={3}>
        Informações do Processo
      </Title>
      <ProcessoPanel
        processo={reducerProcesso?.state?.data?.object}
        loading={reducerProcesso?.state?.status?.isFetching}
      />
      {reducer?.state.data?.page?.content && (
        <>
          <Title mt="md" mb="md" order={3}>
            Eventos
          </Title>
          <Card mt="md" withBorder>
            <LoadingOverlay visible={reducer.state?.status?.isFetching!} />
            <Timeline active={1} bulletSize={24} lineWidth={2} mt="md" ml="md">
              {!reducer?.state.data?.page?.first && (
                <Timeline.Item>
                  <Button
                    size="xs"
                    compact
                    onClick={() =>
                      findEventosProcesso(processoID, {
                        page: reducer.state?.data?.page?.number! - 1,
                        size: 5,
                        sort: "desc"
                      })
                    }
                    leftIcon={<IconDots size="1rem" />}
                  >
                    Mais Novas
                  </Button>
                </Timeline.Item>
              )}
              {reducer?.state.data?.page?.content?.map((item: any, index: any) => (
                <Timeline.Item
                  radius="md"
                  title={item.titulo}
                  bullet={<IconMessageDots size={12} />}
                  key={index}
                >
                  <Text color="dimmed" size="sm">
                    {item.descricao}
                  </Text>
                  <Text size="xs" mt={4}>
                    {moment(item.dataHoraAlvo, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                  </Text>
                </Timeline.Item>
              ))}
              {!reducer?.state.data?.page?.last && (
                <Timeline.Item>
                  <Button
                    size="xs"
                    compact
                    onClick={() =>
                      findEventosProcesso(processoID, {
                        page: (reducer.state?.data?.page?.number || 0) + 1,
                        size: 5,
                        sort: "desc"
                      })
                    }
                    leftIcon={<IconDots size="1rem" />}
                  >
                    Mais Antigas
                  </Button>
                </Timeline.Item>
              )}
            </Timeline>
          </Card>
        </>
      )}
    </>
  );
};
