import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";
import { PagamentoHelper } from "shared/PagamentoHelper";
import { BlobStorageService } from "shared/azurestorage/BlobStorageService.proto";
import { data } from "shared/custom/navbar/Menu.data";

export const useUploadPagamentoAnexo = (properties: any) => {

    const execute = async (
        file: File,
        tipo: string | null,
        idPagamento: any,
    ) => {
        properties.reducer.dispatch({ type: "SUBMITTING", payload: null });
        const data = await PagamentoHelper.generateFile(file);
        const createResult = await RemoteClient.partialUpdate(
            `${RemoteEndpoints.pagamento}/upload/${idPagamento}?type=${tipo}`,
            data,
            RequestConfigGenerator.build(),
        );
        if (createResult.data) {
            try {
                var sas = createResult.data.anexo.url + '?' + createResult.data.anexo.sasToken;
                await BlobStorageService.uploadFiles([file], sas);
                properties.reducer.dispatch({
                    type: "UPDATE_SUCCESS",
                    payload: createResult.data?.pagamento,
                });
            } catch (e) {
                properties.reducer.dispatch({
                    type: "ERROR",
                    payload: createResult.error,
                });
            }
        } else {
            properties.reducer.dispatch({
                type: "ERROR",
                payload: createResult.error,
            });
        }

    };

    return { execute: execute };
};
