import { Group, Avatar, Text, Box, rem } from "@mantine/core"
import { Link } from "react-router-dom";
import { Hidden } from "shared/custom/Hidden"


export const EmpresaChip = ({ empresa, onImageView, loading }: any) => {
  
  const select = (logo: any) => {
    if(logo?.thumbnailSourceSet){
      return logo?.thumbnailSourceSet.split(",")[0].split(" ")[0];
    }
    return "#";
  }

  return (
    <Group noWrap style={{ minWidth: rem(60) }}>
      <Avatar src={select(empresa?.logo)} radius="sm" />
      <Box>
        <Hidden hide={!empresa?.nomeFantasia}>
          <Text
            component={Link}
            to={`/empresas/${empresa?.id}`}
            fw={500}
          >
            {empresa?.nomeFantasia}
          </Text>
        </Hidden>
      </Box>
    </Group>
  );

}