import { Badge, Card, Indicator, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const FastAccessButton = ({ href, icon, label, badge }: any) => {

    const navigate = useNavigate();

    return (
        <Card
            shadow="sm"
            padding="md"
            pt="xl"
            px="sm"
            style={{
                marginBottom: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
            }}
            onClick={() => navigate(href)}
        >
            {badge && (
                <Badge color="teal" style={{ marginBottom: "0.5rem" }}>
                    {badge}
                </Badge>
            )}
            <Indicator inline label="Novo" size={16}>
                <div style={{ width: "100%", textAlign: "center" }}>{icon}</div>
            </Indicator>
            <Text size="sm" style={{ marginTop: "0.5rem", textAlign: "center" }}>
                {label}
            </Text>
        </Card>
    );
}