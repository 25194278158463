import {
  Box,
  Button,
  Group,
  SimpleGrid,
  Textarea
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateUtils } from "shared/Utils";

export const ProspeccaoForm = ({ reducer, editMode, onSubmit }: any) => {

  const { data, status } = reducer.state;

  const form = useForm({
    validateInputOnBlur: ["status", "valor", "objetivo", "dataVencimento"],
    initialValues: {
      id: null,
      objetivo: "",
      dataInicioObjetivo: moment().toDate(),
      dataFinalObjetivo: moment().add(1, 'months').toDate()
    },
    validate: {
      objetivo: (value) => value !== "" ? null : "Informe um objetivo"
    },
  });

  useEffect(() => {
    if (data?.object && editMode)
      form.setValues(data?.object);
  }, [data]);

  const _onSubmit = (submitData: any) => {
    const id = submitData.id;
    delete submitData.id;
    var dataInicioObjetivo = DateUtils.format(form.values?.dataInicioObjetivo);
    var dataFinalObjetivo = DateUtils.format(form.values?.dataFinalObjetivo);
    onSubmit({ ...submitData, dataInicioObjetivo: dataInicioObjetivo, dataFinalObjetivo: dataFinalObjetivo }, id);
  };

  const onFormError = (event: any) => {
    notifications.show({
      message: "Há algum campo incorreto no formulário",
      color: "red",
    });
  };

  return (
    <form onSubmit={form.onSubmit((a) => _onSubmit(a), onFormError)}>
      <Textarea
        mt="md"
        placeholder="Ex: Maquinário Pesado para a obra do condomínio Morada do Sabiá"
        description="Insira uma descrição curta e objetiva, ela ajudará a identificar esta prospecção em outro momento"
        label="Objetivo da prospecção"
        {...form.getInputProps("objetivo")}
      />
      <SimpleGrid
        mt="md"
        cols={2}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <DatePickerInput
          label="Data Início"
          description="A partir de quando sua empresa precisará destes equipamentos?"
          placeholder="Data Início"
          valueFormat="DD/MM/YYYY"
          {...form.getInputProps("dataInicioObjetivo")}
        />
        <DatePickerInput
          label="Data Limite"
          description="Qual a data limite que a sua empresa precisará destes equipamentos?"
          placeholder="Data limite"
          valueFormat="DD/MM/YYYY"
          {...form.getInputProps("dataFinalObjetivo")}
        />
      </SimpleGrid>
      <Group position="apart" mt="md">
        <Box></Box>
        <Button type="submit" loading={!!status?.isFetching || !!status?.isSubmitting || !!status?.isUpdating}>
          Iniciar
        </Button>
      </Group>
    </form>
  );
  
};
