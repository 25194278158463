import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";
import { FileUtils } from "shared/Utils";
import { BlobStorageService } from "shared/azurestorage/BlobStorageService.proto";

export const useUploadAssinaturaContrato = (properties: any) => {

  const execute = async (contratoID: string, file: File) => {
    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });

    const hash = await FileUtils.getFileMD5(file);
    const fileData = {
      filename: file.name,
      contentType: file.type,
      size: file.size,
      hash: hash,
    };

    const createResult = await RemoteClient.create(
      `${RemoteEndpoints.contrato}/${contratoID}/assinar`,
      { imagemAssinatura: fileData },
      RequestConfigGenerator.build(),
    );    
    if (createResult.data) {
      try {
        var sas = createResult.data.assinatura.url + '?' + createResult.data.assinatura.sasToken;
        await BlobStorageService.uploadFiles([file], sas);
        properties.reducer.dispatch({
          type: "UPDATE_SUCCESS",
          payload: createResult.data?.contrato,
        });
      } catch (e) {
        properties.reducer.dispatch({
          type: "ERROR",
          payload: createResult.error,
        });
      }
    } else {
      properties.reducer.dispatch({
        type: "ERROR",
        payload: createResult.error,
      });
    }
  };

  return { execute: execute };
};
