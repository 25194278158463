import {
  Button,
  Group,
  Title,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import { useDrawerForm } from "hooks/useDrawerForm";
import { PagamentoDataGrid } from "partials/datagrids/Pagamento.datagrid";
import { PagamentoForm } from "partials/forms/Pagamento.form";
import { PagamentoView } from "partials/views/Pagamento.view";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CustomDrawer } from "shared/custom/CustomDrawer";
import { Hidden } from "shared/custom/Hidden";
import { PaginationProps } from "shared/models/Pagination.props";
import SecurityProvider from "shared/providers/security/SecurityProvider";
import { useCadastroPagamentoProcesso } from "hooks/useCadastroPagamentoProcesso";
import { useUpdatePagamentoProcesso } from "hooks/useUpdatePagamentoProcesso";
import { useUploadPagamentoAnexo } from "hooks/uploads/useUploadPagamentoAnexo";

export const ProcessoPagamentosTab = ({ processoID }: any) => {

  const authority = SecurityProvider.getAuthority();
  const location = useLocation();
  const reducer = useRemoteDataReducer();
  const drawerForm = useDrawerForm({ reducer: reducer });

  const uploadAnexoPagamento = useUploadPagamentoAnexo({ reducer: reducer });
  const cadastroPagamentoProcesso = useCadastroPagamentoProcesso({ reducer: reducer });
  const atualizacaoPagamentoProcesso = useUpdatePagamentoProcesso({ reducer: reducer });

  const { fetchPage, fetchSingle } =
    useRemoteOperation({ reducer: reducer });

  const { data, status } = reducer.state;

  useEffect(() => {
    if (processoID && !data?.object) {
      reducer.dispatch({ type: "INITIATING" });
      onPaginationChange(processoID, { page: 0, size: 10, sort: "desc" });
    }
  }, [location]);

  useEffect(() => {
    if (status?.isSubmitted) {
      notifications.show({
        message: "Pagamento salvo com sucesso.",
        color: "teal",
        icon: <IconCheck />,
      });
    } else if (status?.isUpdated) {
      notifications.show({
        message: "Pagamento atualizado com sucesso.",
        color: "teal",
        icon: <IconCheck />,
      });
    }
  }, [reducer.state]);

  const criarPagamento = (data: any) => {
    cadastroPagamentoProcesso.execute(processoID, data);
  };

  const editarPagamento = async (formData: any, id: any) => {
    atualizacaoPagamentoProcesso.execute(formData, id);
  };

  const onPaginationChange = (id: any, pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.processo}/${id}/pagamentos`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  const editarPagamentoAction = (id: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.pagamento}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
    drawerForm.open("pagamentoEditarDrawer");
  };

  const visualizarPagamentoAction = (id: any) => {
    fetchSingle({
      endpoint: `${RemoteEndpoints.pagamento}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });
    drawerForm.open("pagamentoViewDrawer");
  };

  return (
    <>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="pagamentoCadastroDrawer"
        title="Cadastrar Pagamento para o Processo"
      >
        <PagamentoForm
          reducer={reducer}
          onSubmit={(entity: any, id: any) => criarPagamento(entity)}
          editMode={false}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="pagamentoEditarDrawer"
        title="Editar pagamento"
      >
        <PagamentoForm
          reducer={reducer}
          onSubmit={(entity: any, id: any) => editarPagamento(entity, id)}
          editMode={true}
        />
      </CustomDrawer>
      <CustomDrawer
        drawerFormState={drawerForm}
        tag="pagamentoViewDrawer"
        title="Visualizar Pagamento"
      >
        <PagamentoView
          reducer={reducer}
          onDocumentoCobrancaUpload={(file: File, id: any) => 
            uploadAnexoPagamento.execute(file, "DOCUMENTO_COBRANCA", id)
          }
          onComprovanteUpload={(file: File, id: any) => 
            uploadAnexoPagamento.execute(file, "COMPROVANTE", id)
          }
          onReciboUpload={(file: File, id: any) => 
            uploadAnexoPagamento.execute(file, "RECIBO_NOTA", id)
          }
        />
      </CustomDrawer>
      <Group position="apart" noWrap spacing="xl" my="md">
        <Title order={3}>Pagamentos</Title>
        <Hidden hide={["ADMINISTRATOR"].includes(authority)}>
          <Button onClick={() => drawerForm.open("pagamentoCadastroDrawer")}>
            Novo Pagamento
          </Button>
        </Hidden>
      </Group>
      <PagamentoDataGrid
        reducer={reducer}
        onEdit={(id: any) => editarPagamentoAction(id)}
        onPaginationChange={(pagination: PaginationProps) =>
          onPaginationChange(processoID, pagination)
        }
        onView={visualizarPagamentoAction}
      />
    </>
  );

};
