import { AtivoPage } from "pages/ativo/ativo-list/Ativo.page";
import { ContratoPage } from "deadcode/contrato/Contrato.page";
import { DashboardPage } from "pages/base/dashboard/Dashboard.page";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  OnceAuthenticatedRedirect,
  PrivateRoute,
} from "shared/custom/PrivateRoute";
import { PreCadastroOrganizacaoListPage } from "pages/suporte/precadastro/PreCadastroEmpresaList.page";
import { NotFoundPage } from "pages/base/not-found/NotFoundPage.page";
import { AtivoViewPage } from "pages/ativo/ativo-view/AtivoView.page";
import { ProcessoPage } from "pages/processos-locacao/processo/Processo.page";
import { RecomendacaoProcessosPage } from "deadcode/licitacao/Licitacoes.page";
import { VisualizarContratoPage } from "deadcode/contrato/VisualizarContrato.page";
import { ProcessoViewPage } from "pages/processos-locacao/processo/ProcessoView.page";
import { SolicitacaoListPage } from "pages/processos-locacao/solicitacoes/SolicitacoesList.page";
import { SolicitacaoViewPage } from "pages/processos-locacao/solicitacoes/SolicitacaoView.page";
import { AccountListPage } from "pages/suporte/accounts/AccountList.page";
import { PermissaoPage } from "pages/suporte/permissoes/Permissoes.page";
import { DispositivosPage } from "pages/suporte/devices/Dispositivos.page";
import { ComponentTest } from "deadcode/ComponentTest.page";
import { AdministradoresPage } from "pages/suporte/administradores/Administradores.page";
import { NegociacaoLicitacaoPage } from "deadcode/licitacao/NegociacaoLicitacao.page";
import { OrcamentoListPage } from "pages/processos-locacao/orcamento/OrcamentoList.page";
import { OrcamentoViewPage } from "pages/processos-locacao/orcamento/OrcamentoView.page";
import { AtivosLocadosListPage } from "pages/ativo/locados/AtivoLocados.page";
import { ProspeccaoListPage } from "pages/prospeccao/prospeccao-list/ProspeccaoList.page";
import { ProspeccaoViewPage } from "pages/prospeccao/prospeccao-view/ProspeccaoView.page";
import { PortalProspeccaoPage } from "pages/prospeccao/portal-prospeccao/PortalProspeccao.page";
import { AtivoLocacaoViewPage } from "pages/ativo/ativo-locacao-view/AtivoLocacaoView.page";
import { PagamentoListPage } from "pages/processos-locacao/pagamento/PagamentoList.page";
import { SolicitarOrcamentoPage } from "pages/processos-locacao/orcamento/SolicitarOrcamento.page";
import { ProspeccaoItemViewPage } from "pages/prospeccao/prospeccao-item-view/ProspeccaoItemView.page";
import { AtivoItemViewPage } from "pages/ativo/ativo-item-view/AtivoItemViewPage.page";
import { LoginPage } from "pages/authentication/login/Login.page";
import { RecoverPasswordPage } from "pages/authentication/recuperar-senha/RecuperarSenha.page";
import { OrganizationListPage } from "pages/empresa/empresa/EmpresaList.page";
import { OrganizationViewPage } from "pages/empresa/empresa/EmpresaView.page";
import { MinhaEmpresaViewPage } from "pages/empresa/minha-empresa/MinhaEmpresaView.page";

const RouteListComponent = (): JSX.Element => (
  <BrowserRouter>
    <Routes>
      {/* Rotas fechadas */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/pre-cadastro-empresa"
        element={
          <PrivateRoute>
            <PreCadastroOrganizacaoListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/empresas"
        element={
          <PrivateRoute>
            <OrganizationListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/empresas/:id"
        element={
          <PrivateRoute>
            <OrganizationViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/minha-empresa"
        element={
          <PrivateRoute>
            <MinhaEmpresaViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ativos-locados"
        element={
          <PrivateRoute>
            <AtivosLocadosListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ativos"
        element={
          <PrivateRoute>
            <AtivoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ativos/:id"
        element={
          <PrivateRoute>
            <AtivoViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ativo-item/:id"
        element={
          <PrivateRoute>
            <AtivoItemViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/ativo-locacao/:id"
        element={
          <PrivateRoute>
            <AtivoLocacaoViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/contratos"
        element={
          <PrivateRoute>
            <ContratoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/contratos/:id"
        element={
          <PrivateRoute>
            <VisualizarContratoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/processos"
        element={
          <PrivateRoute>
            <ProcessoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/processos/:id"
        element={
          <PrivateRoute>
            <ProcessoViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/solicitar-orcamento/:id"
        element={
          <PrivateRoute>
            <SolicitarOrcamentoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/pagamentos"
        element={
          <PrivateRoute>
            <PagamentoListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/portal/prospeccao"
        element={
          <PrivateRoute>
            <PortalProspeccaoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/prospeccoes"
        element={
          <PrivateRoute>
            <ProspeccaoListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/prospeccoes/:id"
        element={
          <PrivateRoute>
            <ProspeccaoViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/prospeccoes/:id/item/:idItem"
        element={
          <PrivateRoute>
            <ProspeccaoItemViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/orcamentos"
        element={
          <PrivateRoute>
            <OrcamentoListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/orcamentos/:id"
        element={
          <PrivateRoute>
            <OrcamentoViewPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/solicitacoes"
        element={
          <PrivateRoute>
            <SolicitacaoListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/solicitacoes/:id"
        element={
          <PrivateRoute>
            <SolicitacaoViewPage />
          </PrivateRoute>
        }
      />
      {/* Interna */}
      <Route
        path="/administradores"
        element={
          <PrivateRoute>
            <AdministradoresPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/permissoes"
        element={
          <PrivateRoute>
            <PermissaoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/dispositivos"
        element={
          <PrivateRoute>
            <DispositivosPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/contas"
        element={
          <PrivateRoute>
            <AccountListPage />
          </PrivateRoute>
        }
      />
      {/* Rotas abertas */}
      <Route
        path="/login"
        element={
          <OnceAuthenticatedRedirect>
            <LoginPage />
          </OnceAuthenticatedRedirect>
        }
      />
      <Route
        path="/recover"
        element={
          <OnceAuthenticatedRedirect to404>
            <RecoverPasswordPage />
          </OnceAuthenticatedRedirect>
        }
      />
      {/* Outras rotas */}
      <Route path="/404" element={<NotFoundPage />} />
      <Route path="*" element={<NotFoundPage />} />
      {/* Rotas de teste */}
      <Route
        path="/test"
        element={
          <PrivateRoute authorities={["ADMINISTRATOR"]}>
            <ComponentTest />
          </PrivateRoute>
        }
      />
      <Route
        path="/test/licitacoes"
        element={
          <PrivateRoute authorities={["ADMINISTRATOR"]}>
            <RecomendacaoProcessosPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/test/licitacoes/1"
        element={
          <PrivateRoute authorities={["ADMINISTRATOR"]}>
            <NegociacaoLicitacaoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/test/contrato/1"
        element={
          <PrivateRoute authorities={["ADMINISTRATOR"]}>
            <VisualizarContratoPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/test/contrato"
        element={
          <PrivateRoute authorities={["ADMINISTRATOR"]}>
            <ContratoPage />
          </PrivateRoute>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default RouteListComponent;
