import { ActionIcon, Badge, Group, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconEye, IconX } from "@tabler/icons-react";
import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RequestConfigGenerator, useRemoteOperation } from "hooks";
import { useRemoteDataReducer } from "hooks/remote/useCRUDHooks";
import moment from "moment";
import { EmpresaChip } from "partials/chip/Empresa.chip";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { DataGridComponent } from "shared/custom/DataGridWrapper";
import { PageLayout } from "shared/custom/page/PageLayout";
import { useModal } from "shared/hooks/ModalProvider";
import { PaginationProps } from "shared/models/Pagination.props";

export const ProcessoPage = () => {
  
  const location = useLocation();
  const params = useParams<any>();
  const { t } = useTranslation();
  const modal = useModal();
  const reducer = useRemoteDataReducer();
  const { fetchSingle, fetchPage } = useRemoteOperation({ reducer: reducer });
  const { data, status } = reducer.state;

  useEffect(() => {
    if (status?.isFailed)
      notifications.show({
        message: data?.error?.message,
        color: "red",
        icon: <IconX />,
      });
    else if (
      status?.isUpdated ||
      status?.isSubmitted ||
      status?.isDeleted ||
      status?.isDeleted
    )
      notifications.show({ message: "Operação realizada com sucesso!" });
    else if (status?.isInitiating && !modal.state?.parameters?.id && !params.id)
      onPaginationChange({ page: 0, size: 10, sort: "desc" });
    else if (status?.isInitiating && params.id) findItem(params.id);
  }, [status, location?.pathname]);

  useEffect(() => {
    reducer.dispatch({ type: "INITIATING" });
  }, [location]);

  const onSearch = (parameters: any) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.processos}`,
      requestParameters: {
        page: parameters.page,
        size: parameters.size,
        sort: parameters.sort,
        term: parameters.term,
      },
      headers: RequestConfigGenerator.build(),
    });

  const onPaginationChange = (pagination: PaginationProps) =>
    fetchPage({
      endpoint: `${RemoteEndpoints.processos}`,
      requestParameters: {
        page: pagination.page,
        size: pagination.size,
        sort: pagination.sort || "desc",
      },
      headers: RequestConfigGenerator.build(),
    });

  const findItem = (id: any) =>
    fetchSingle({
      endpoint: `${RemoteEndpoints.processo}/${id}`,
      properties: { reducer: reducer },
      headers: RequestConfigGenerator.build(),
    });

  return (
    <PageLayout title="Processos">
      <DataGridComponent
        visible={true}
        state={reducer.state}
        onPaginationChange={onPaginationChange}
        noRecordText="Nenhum processo encontrado"
        columns={[
          { accessor: "numeroProcesso", title: "Número" },
          {
            accessor: "modo",
            title: "Modo",
            render: ({ modo }: any) => <Badge>{modo}</Badge>,
          },
          {
            accessor: "locador",
            title: "Locatário",
            render: ({ locador }: any) => (
              <EmpresaChip empresa={locador} />
            ),
          },
          {
            accessor: "locatario",
            title: "Locatário",
            render: ({ locatario }: any) => (
              <EmpresaChip empresa={locatario} />
            ),
          },
          {
            accessor: "status",
            title: "Status",
            render: ({ status }: any) => <Badge>{status}</Badge>,
          },
          {
            accessor: "criacao",
            title: "Criação",
            render: ({ creationTime }: any) => (
              <Text size="xs" color="dimmed">
                {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "atualizacao",
            title: "Atualização",
            render: ({ modificationTime }: any) => (
              <Text size="xs" color="dimmed">
                {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
              </Text>
            ),
          },
          {
            accessor: "actions",
            title: <Text>Ações</Text>,
            textAlignment: "right",
            render: (processo: any) => (
              <Group spacing={12} position="right" noWrap>
                <a href={`/processos/${processo.id}`}>
                  <ActionIcon color="gray">
                    <IconEye size={26} />
                  </ActionIcon>
                </a>
              </Group>
            ),
          },
        ]}
      />
    </PageLayout>
  );
};
