import { Group, ActionIcon, Text, Tooltip } from "@mantine/core";
import { IconEye, IconEdit, IconSignature } from "@tabler/icons-react";
import moment from "moment";
import { DataGridComponent } from "shared/custom/DataGridWrapper";

export const ContratoDataGrid = ({ reducer, onPaginationChange, onSign }: any) => {

    return (
        <DataGridComponent
            visible={true}
            state={reducer.state}
            noRecordText="Nenhum contrato encontrado"
            onPaginationChange={onPaginationChange}
            columns={[
                { 
                    accessor: "numeroContrato", 
                    title: "Número", 
                    textAlign: 'right', 
                    ellipsis: true,
                    render: ({ numeroContrato }: any) => (
                        <Text>
                            { numeroContrato ? numeroContrato : "-" } 
                        </Text>
                    ),
                },
                {
                    accessor: "documentoContrato.title",
                    title: "Titulo",
                },
                {
                    title: "Data Vencimento",
                    render: ({ dataVencimento }: any) => (
                        <Text>
                            {moment(dataVencimento).format("DD/MM/YYYY").toString()}
                        </Text>
                    ),
                },
                {
                    accessor: "status",
                    title: "Status",
                },
                {
                    accessor: "criacao",
                    title: "Criação",
                    render: ({ creationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(creationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },
                {
                    accessor: "atualizacao",
                    title: "Atualização",
                    render: ({ modificationTime }: any) => (
                        <Text size="xs" color="dimmed">
                            {moment(modificationTime, "YYYY-MM-DDTHH:mm:ss").fromNow()}
                        </Text>
                    ),
                },
                {
                    accessor: "actions",
                    title: <Text>Ações</Text>,
                    textAlignment: "right",
                    render: ({ id }: any) => (
                        <Group spacing={12} position="right" noWrap>
                            <Tooltip label="Visualizar e/ou Assinar">
                                <ActionIcon
                                    color="blue"
                                    onClick={() => onSign(id)}
                                >
                                    <IconSignature size={26} />
                                </ActionIcon>
                            </Tooltip>
                        </Group>
                    ),
                },
            ]}
        />
    );

}