import { Group, MediaQuery, Title } from "@mantine/core";
import { ModalHelpChip } from "partials/modals/ModalHelpChip.component";

export const HeaderCustom = ({ children, title }: any) => {
  return (
    <Group position="apart" noWrap spacing="xl" mb="xl">
      <div>
        <Title order={1}>{title}</Title>
      </div>
      <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
        <>
          {children}
        </>
      </MediaQuery>
    </Group>
  );
};
