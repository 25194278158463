import { Avatar, Badge, Box, Button, Grid, Group, Paper, Text, Image } from "@mantine/core"
import { useStyles } from "./AtivoLocacaoPanel.style";
import { Carousel } from "@mantine/carousel";
import { IconClipboardText, IconFileDollar, IconClipboardCheck } from "@tabler/icons-react";
import { Hidden } from "shared/custom/Hidden";
import { Link } from "react-router-dom";

export const AtivoLocacaoPanel = ({
    images, ativo, produto, context, hideSolicitacaoButton, hideOrcamentoButton, hideAddProspeccaoButton, onAddSolicitacao, onAddOrcamento, prospectionID, itemID
}: any) => {

    const { classes } = useStyles();

    const slides = () => { 
        if(images && images.length > 0){
            return images?.map((image: any) => (
                <Carousel.Slide key={image}>
                    <Image withPlaceholder placeholder="Imagem indisponível" src={image.imageSourceSet} height={440} />
                </Carousel.Slide>
            ));
        } else {
            return (
                <Carousel.Slide>
                    <Image withPlaceholder placeholder="Sem imagem disponível" src="#" height={440} />
                </Carousel.Slide>
            );
        }
    }

    return (
        <Grid gutter="md">
            <Grid.Col span={7}>
                <Carousel
                    withIndicators
                    loop
                    classNames={{
                        root: classes.carousel,
                        controls: classes.carouselControls,
                        indicator: classes.carouselIndicator,
                    }}
                >
                    {slides()}
                </Carousel>
            </Grid.Col>
            <Grid.Col span={5}>
                <Paper p="xs">
                    <Text size="xl" fw={700}>
                        {ativo?.produto?.descricao || ativo?.dadosIdentificacaoProduto?.descricao}
                    </Text>
                    <>
                        <Badge size="xs" mr="sm">
                            {ativo?.produto?.classe?.titulo}
                        </Badge>
                        <Badge size="xs" mr="sm">
                            {ativo?.produto?.subclasse?.titulo}
                        </Badge>
                        <Badge size="xs" mr="sm">
                            {ativo?.produto?.grupo?.titulo}
                        </Badge>
                        <Badge size="xs">{ativo?.produto?.familia?.titulo}</Badge>
                    </>
                    <Hidden hide={!ativo?.produto?.marca}>
                        <Group noWrap mt="sm">
                            <Avatar size="md" src={ativo?.produto?.marca?.logoURL} />
                            <div>
                                <Text size="xs">{ativo?.produto?.marca?.nome}</Text>
                            </div>
                        </Group>
                    </Hidden>
                    {/*<Box mt="sm">
                        <Text size="xs" color="dimmed">
                            Estado do ativo
                        </Text>
                        <Badge color="blue">{ativo?.estado}</Badge>
                </Box>*/}
                    <Box mt="xl">
                        <Hidden hide={hideSolicitacaoButton}>
                            <Button
                                leftIcon={<IconClipboardText />}
                                component={Link}
                                variant="filled"
                                fullWidth
                                size="lg"
                                to={onAddSolicitacao}
                            >
                                Solicitar Locação
                            </Button>
                        </Hidden>
                        <Hidden hide={hideOrcamentoButton}>
                            <Button
                                leftIcon={<IconFileDollar />}
                                component={Link}
                                variant="filled"
                                fullWidth
                                size="lg"
                                mt="xs"
                                to={onAddOrcamento}
                            >
                                Solicitar Orçamento
                            </Button>
                        </Hidden>
                        <Hidden hide={hideAddProspeccaoButton}>
                            <Button
                                leftIcon={<IconClipboardCheck />}
                                variant="outline"
                                fullWidth
                                size="lg"
                                mt="xs"
                                component={Link}
                                to={onAddSolicitacao}
                            >
                                Adicionar a prospecção
                            </Button>
                        </Hidden>
                    </Box>
                </Paper>
            </Grid.Col>
        </Grid>
    )

}