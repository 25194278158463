import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";
import { FileUtils } from "shared/Utils";
import { BlobStorageService } from "shared/azurestorage/BlobStorageService.proto";

export const useUploadAtivoImage = (properties: any) => {

  const execute = async ({ files, ativoId, empresaId }: any) => {

    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });

    const send = await Promise.all(
      files.map(async (file: any) => {
        const hash = await FileUtils.getFileMD5(file);
        return {
          filename: file.name,
          contentType: file.type,
          size: file.size,
          hash: hash,
        };
      })
    );

    try {
      const result = await RemoteClient.create(
        `${RemoteEndpoints.ativoitem}/${ativoId}/imagens`,
        send,
        RequestConfigGenerator.build(),
      );
      if (result.data) {
        try {
          var sas = result.data.imagens[0].url + '?' + result.data.imagens[0].sasToken;
          await BlobStorageService.uploadFiles([files[0]], sas);
          properties.reducer.dispatch({
            type: "SUBMIT_SUCCESS",
            payload: result.data?.ativo,
          });
        } catch (e) {
          properties.reducer.dispatch({
            type: "ERROR",
            payload: result.error,
          });
        }
      } else if (result.error) {
        properties.reducer.dispatch({ type: "ERROR", payload: result.error });
      }
    } catch (e) {
      properties.reducer.dispatch({
        type: "ERROR",
        payload: "Erro ao enviar arquivo",
      });
    }
  };

  return { execute: execute };
  
};
