import { RemoteEndpoints } from "constants/RemoteEndpoints.const";
import { RemoteClient, RequestConfigGenerator } from "hooks";
import { FileUtils } from "shared/Utils";
import { BlobStorageService } from "shared/azurestorage/BlobStorageService.proto";
import { FileUpload } from "shared/providers/infrastructure/FileUpload";

export const useUploadDocumentoProcesso = (properties: any) => {
  
  const execute = async (idProcesso: any, submitData: any, file: File) => {
    properties.reducer.dispatch({ type: "SUBMITTING", payload: null });

    const hash = await FileUtils.getFileMD5(file);
    const fileData = {
      filename: file.name,
      contentType: file.type,
      size: file.size,
      hash: hash,
    };

    const sendData = {
      title: submitData.title,
      description: submitData.description,
      file: fileData,
    };

    

    const createResult = await RemoteClient.create(
      `${RemoteEndpoints.processo}/${idProcesso}/documentos`,
      sendData,
      RequestConfigGenerator.build(),
    );
    if (createResult.data) {
      try {
        var sas = createResult.data.documento.url + '?' + createResult.data.documento.sasToken;
        await BlobStorageService.uploadFiles([file], sas);
        debugger
        properties.reducer.dispatch({
          type: "SUBMIT_SUCCESS",
          payload: createResult.data?.documento,
        });
      } catch (e) {
        properties.reducer.dispatch({
          type: "ERROR",
          payload: createResult.error,
        });
      }
    } else {
      properties.reducer.dispatch({
        type: "ERROR",
        payload: createResult.error,
      });
    }
  };

  return { execute: execute };
};
